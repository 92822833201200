import { computed } from 'vue'
import CourseClass from '../classes/course.class'

export default class CourseComputed
{
    constructor(parameters) {
        this.course_class = new CourseClass()
        this.enrolled_courses = parameters.enrolled_courses
        this.selected_course = parameters.selected_course
    }

    computed_properties = ( () => {

        const getActiveCourseList = computed( () => {            
            const active_courses = this.course_class.getCourseListFromStore().filter( (course) => this.enrolled_courses.value.includes(course.id))
            this.selected_course.value = active_courses[0]
            return active_courses
        })

        const newlyAddedVideos = computed( () => {
            return this.course_class.getNewlyAddedVideosFromStore()
        })

        return { getActiveCourseList, newlyAddedVideos }
    })()
}