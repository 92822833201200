import Resumable from 'resumablejs'
import axios from 'axios';

export default class FileUploader {

    constructor(api_url, lesson_id) {
        const token = localStorage.getItem('token')
        this.token = token
        this.admin_url = process.env.VUE_APP_URL

        this.resumable = new Resumable({
            //target: 'http://roadmap.test/client/updates/upload_files',
            target: api_url,
            query:{'lesson_id': lesson_id},
            withCredentials : true,
            headers: {
                Authorization: token ? 'Bearer ' + token : null
            },
            maxChunkRetries: 1,
            simultaneousUploads: 1,
            testChunks: false,
            chunkSize: 20 * 1024 * 1024,
            fileType: ['mp4']
        });
    }

    initResumbleForFileUpload(file_upload_btn) {
        this.resumable.assignBrowse(file_upload_btn)
        return this.resumable
    }

    getUploadedFile(lesson_id) {
        return axios.get(`${this.admin_url}admin/get_uploaded_file/${lesson_id}`, {
            headers: {
                'Authorization': 'Bearer ' + this.token
            }
        }).then( (data) => {
            if ( data.status == 200 ) {
                return data.data
            } else {
                return {
                    response: false,
                    data: data
                }
            }
        }).catch( (err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}