<template>
    <v-container fluid>
      <!-- <DataTable 
        :getClientList="getClientList" 
        :headers="headers" 
        :button="button"
        @addclient="onclickaddclient"
      /> -->
      <TheLoader 
        :visible="loader"
      />
      <DataTable2
        :headers="headers"
        :items="getClientList"
        :loadingTable="loadingTable"
        @addClient="onclickaddclient"
        @sendGhlAll="sendGhlAll"
        @userRoadmap="userRoadmap"
        @editUser="editUser"
        @deleteUser="deleteUser"
        @OpenEmailForm="OpenEmailForm"
        @getTimeSpent="getTimeSpent"
        @search_membership="search_membership"
      />
      <DialogVue
        id="add-client"
        v-model="dialogaddclient" 
        :value="dialogaddclient" 
        :dialogsvalue="dialogsvalue" 
        :select_field="select_field"
        @closedialog="closedialog"
        @savedata="savedata"
      />
      <DialogVue
        id="edit-client"
        v-model="editdialog"
        :value="editdialog"
        :dialogsvalue="dialogsvalue"
        :edititem="toEdit"
        @closedialog="closedialog"
        @savedata="savedata"
        @submitForm="submitEditForm"
      />
      <DialogVue
        id="edit-client"
        v-model="deletedialog"
        :value="deletedialog"
        :dialogsvalue="dialogsvalue"
        :deleteitem="toDelete"
        @closedialog="closedialog"
        @submitDelete="submitDelete"
      />
      <DialogVue
        v-model="timespentdialog"
        :value="timespentdialog"
        :dialogsvalue="dialogsvalue"
        :timespentheaders="timespentheaders"
        :timespent="timespentuser"
        @closedialog="closedialog"
      />
      <!-- Dialog For Email -->
      <v-dialog
        v-model="dialog_email"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Send email to following contacts
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  label="Email Templates"
                  v-model="email_payload.template_id"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="From name"
                  v-model="email_payload.from_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="From email"
                  v-model="email_payload.from_email"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="To name"
                  v-model="email_payload.to_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="To email"
                  v-model="email_payload.to_email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="email_payload.send_alternative_email"
                  :disabled="email_payload.disabled_alternative_email"
                  label="Send Alternative Email"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email subject"
                  v-model="email_payload.email_subject"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <VueEditor v-model="email_payload.email_content" :editorToolbar="customToolbar" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="CloseEmailForm()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="SendEmail()"
            >
              Send Email
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End Dialog For Email -->
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import DataTable from '../../components/DataTable'
import DataTable2 from '../../components/DataTable2'
import DialogVue from '../../components/DiaLog'
import { VueEditor } from 'vue2-editor'
import TheLoader from '../../components/TheLoader'

export default {
  components: {
    // DataTable,
    DataTable2,
    DialogVue,
    VueEditor,
    TheLoader
  },
  props: [
  ],
  data () {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Business', value: 'business' },
        { text: 'Registration Date', value: 'created_at' },
        { text: 'Last Login', value: 'last_login' },
        { text: 'Number of Logins', value: 'login_count' },
        { text: 'Status', value: 'status' },
        // { text: '% Complete', value: 'complete' },
        { text: 'Priority Count', value: 'roadmap_prio_count', sortable: true },
        { text: 'Implemented Count', value: 'implemented_count', sortable: true },
        // { text: 'Membership Type', value: 'membership_type', sortable: true },
        { text: 'Membership Type', value: 'membership_type', sortable: false},
        { text: 'Billable Staff', value: 'maxStaff', sortable: true},
        { text: 'Actions', value: 'actions', align: 'end' }
      ],
      dialogaddclient: false,
      button: {
        title: 'Add Client',
        icon: 'mdi-plus'
      },
      dialogsvalue: {
        title: 'Add User',
        text_field: [
          {
            id:         0,
            label:      'Given Name *',
            hint:       'Client\'s Firstname',
            type:       'text'
          },
          {
            id:         1,
            label:      'Lastname *',
            hint:       'Client\'s Lastname',
            type:       'text'
          },
          {
            id:         2,
            label:      'Business *',
            hint:       'Client\'s business name',
            type:       'text',
            isclickable: true,
          },
          {
            id:         3,
            label:      'Email *',
            hint:       'Client\'s E-mail Address',
            type:       'email'
          },
          {
            id:         4,
            label:      'Phone *',
            hint:       'Client\'s Phone Number',
            type:       'number'
          },
          {
            id:         5,
            label:      'Website Link *',
            hint:       'Client\'s Website Link',
            type:       'text'
          },
        ],
      },
      select_field: {
        selection: [
          {
            id: 1,
            text: 'Mastermind',
          },
          {
            id: 2,
            text: 'Bridge'
          },
          {
            id: 3,
            text: 'Trial'
          },
          {
            id: 4,
            text: 'Cancelled'
          }
        ],
        id: 1,
        label: 'Membership Type *',
        hint: 'Client\'s Membership Type',
      },
      lastid: {},
      editdialog: false,
      timespentdialog: false,
      timespentuser: null,
      timespentheaders: [
        { text: 'Date', value: 'date' },
        { text: 'Duration', value: 'seconds_elapsed' },
      ],
      toEdit: null,
      deletedialog: false,
      toDelete: null,
      dialog_email: false,
      customToolbar: ['bold', 'italic', 'underline'],
      email_payload: {
        template_id: null,
        from_name: '',
        from_email: '',
        to_name: '',
        to_email: '',
        to_alternative_email: '',
        email_subject: '',
        email_content: '',
        send_alternative_email: false,
        disabled_alternative_email: true
      },
      search: null,
      loader: false,
      loadingTable: true,
    }
  },
  mounted () {
    if(this.getClientList.length > 0) {
      return this.loadingTable = false;
    }
  },
  created () {
    this.$store.dispatch('adminmain/setToTest', true)
    this.getclients();
    //this.getroadmaps();

    if(!sessionStorage.getItem('udata')) {
      const data = localStorage.getItem('udata');
      sessionStorage.udata = data;
      sessionStorage.token = localStorage.getItem('token');
    }
  },
  computed: {
    ...mapGetters({
      testdata:         'adminmain/getToTest',
      // getClientList:    'adminmain/getClientList',
      toEditData:       'adminmain/getToEditData'
    }),
    getClientList(){
      return this.$store.getters['adminmain/getClientList'](this.search)
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    onclickaddclient(){
      this.dialogaddclient = true
    },
    async sendGhlAll(){
      await this.$axios.post('/clients/send_ghl_all')
      .then(({data})=> {
        if (data.response) {
          this.showSnackBar(data.added + ' users added, ' + data.updated + ' users updated')
        }
      })
    },
    closedialog(){
      this.dialogaddclient = false
      this.editdialog = false
      this.timespentdialog = false
      this.deletedialog = false
    },
    async savedata(data){
      this.closedialog();
      const tp = {
        firstname: data.input.textfield[0],
        lastname: data.input.textfield[1],
        business: data.input.textfield[2],
        email: data.input.textfield[3],
        phone: data.input.textfield[4],
        link: data.input.textfield[5],
        role: 2,
        membership_type: data.input.select
      }

      await this.$axios.post('/emails/sendemail', tp)
      .then((res) => console.log(res));
      await this.$axios.post('/clients/addclient', tp)
      .then(({data}) => {
        if(data.response){
          this.getClientList.forEach(q => {
            this.lastid = q
          })
          this.dialogaddclient = false
          
          this.$axios.patch('/courses/editenrolledclients', { id: data.data.id, course_id: 3 })
          .then(({data}) => {
            if(data.response) {
              this.getclients();

              const tovuex = {
                id: this.lastid.id + 1,
                name: tp.firstname + " " + tp.lastname,
                phone: tp.phone,
                business: tp.business,
                updated_at: '',
                email: tp.email,
                complete: 0,
                link: tp.link
              }
              this.$store.dispatch('adminmain/setClient', tovuex);
            }
          })



          // let today = new Date().toISOString()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    getCount(arr) {
      return arr.length;
    },
    async getclients(){
      await this.$axios.get('/users/g_client_list')
        .then(({data}) => {
          if(data.response){
            this.loadingTable = false;
            let active = []
            let deleted_at = []
            data.data.forEach(q => {
              // sort billable staff by max
              let array_staff = q.staffs;

              if(array_staff == null) {
                return q.maxStaff = 0;
              } else {
                const highest_staff = array_staff.staff_count.reduce((max, current) => {
                  const currentStaff = current.people;
                  return currentStaff > max.people ? current : max;
                }, { people: 0 });
                
                q.maxStaff = highest_staff.people;
              }

              // sort implemented count
              q.implemented_count = this.getCount(q.roadmap_implemented);

              // sort roadmap prio
              q.roadmap_prio_count = this.getCount(q.roadmap_prio);

              if(q.deleted_at === null){
                active.push(q)
              }else{
                deleted_at.push(q)
              }
            })
            this.$store.dispatch('adminmain/setClientList', active)
            this.$store.dispatch('adminmain/set_clientlist_deleted', deleted_at)
          }
          else{
            localStorage.removeItem('token')
            localStorage.removeItem('udata')
            localStorage.removeItem('vuex')
            this.$router.push({name: 'index'})
          }
        })

      this.$axios.get('/users/g_membership_types')
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('adminmain/set_membership_type', data.data)
          }
        }).catch(err => console.error(err));
    },
    userRoadmap(obj) {
      this.$store.dispatch('auth/setSelectedUser', JSON.stringify(obj));
      this.$router.push({ name: 'clientdashboardv2', query: { id: obj.id, name: obj.name } });
    },
    async getTimeSpent(obj) {
      await this.$axios.get(`/clients/get_time_spent/${obj.id}`)
        .then(({data}) => {
          if(data.response) {
            this.timespentuser = {
              name: obj.name,
              time_spent: this.editTimeSpent(data.data)
            }
            this.timespentdialog = true;
          }
        }).catch(err => console.error(err));
    },
    editTimeSpent(obj) {
      let temparr = obj.map((a) => {
        return { date: a.check_in_time, seconds_elapsed: a.seconds_elapsed }
      })

      return temparr;
    },
    editUser(obj) {
      this.toEdit = obj;
      this.editdialog = true;
    },
    async submitEditForm(obj) {
      await this.$axios.patch('users/e_client', obj)
        .then(({data}) => {
          if (data.response) {
            this.showSnackBar(data.message);
            this.getclients();
            this.editdialog = false;
          }
        }).catch(err => err);
    },
    deleteUser(obj) {
      this.toDelete = obj;
      this.deletedialog = true;
    },
    async submitDelete(obj) {
      await this.$axios.delete(`clients/deleteclient/${obj.id}`)
        .then(({data}) => {
            this.showSnackBar(data.message);
            this.getclients();
            this.deletedialog = false;
        }).catch(err => err);
    },
    OpenEmailForm(data){
      // console.log(data);
      this.ClearEmailPayload();
      this.email_payload.from_name = JSON.parse(localStorage.getItem('udata')).name;
      this.email_payload.from_email = JSON.parse(localStorage.getItem('udata')).email;
      this.email_payload.to_name = data.name;
      this.email_payload.to_email = data.email;
      this.email_payload.to_alternative_email = data.alternative_email;
      this.email_payload.disabled_alternative_email = data.alternative_email ? false : true;
      this.dialog_email = true;
    },
    CloseEmailForm(){
      this.dialog_email = false;
    },
    ClearEmailPayload(){
      this.email_payload.template_id = null;
      this.email_payload.from_email = '';
      this.email_payload.to_name = '';
      this.email_payload.to_email = '';
      this.email_payload.email_subject = '';
      this.email_payload.email_content = '';
    },
    async SendEmail(){
      await this.$axios.post('clients/sendEmail', this.email_payload).then(({ data }) => {
        if(!data.response) return this.showSnackBar(data.message);
        this.dialog_email = false;
        this.showSnackBar(data.message);
      }).catch((err) => console.log(err));
    },
    search_membership(item){
      this.search = item
    },
    //async getroadmaps() {
    //  try {
    //    const res = await this.$axios.get('/client/getroadmaps');

    //    console.log(res);
    //  }catch(e) {
    //    console.error(e);
    //  }
    //},
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style> 