import axios from 'axios'
import Vue from 'vue'
import { clientRoadmapStore } from './../../store/client_roadmap.store'

export default class RoadmapClass
{
    constructor() {
        this.api_path = process.env.VUE_APP_API_URL
        this.api = axios.create({
            baseURL: this.api_path
        })
        this.bearer_token = localStorage.getItem('token')

        this.client_roadmap_store = clientRoadmapStore()

        this.toast = Vue.$toast
    }

    getClientStaffs(course_id, client_id) {
        this.api.get(`/adminclientdboard/getStaff/3/${client_id}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        })
        .then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.setStaffs(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    getClientStaffsFromStore() {
        return this.client_roadmap_store.getStaffs
    }

    selectRoadmapBillableStaff(id, is_checked) {
        this.api.patch(`/client/markStaff/${id}/${is_checked}`, {}, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.toast.success('You have successfully updated your billable staff.')
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Roadmap Data */
    async getRoadmapDataByUser(user_id) {
        await this.api.get(`/roadmap/g_dashboard_roadmap/${user_id}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {                
                this.client_roadmap_store.setRoadmapData(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    getRoadmapDataFromStore() {
        return this.client_roadmap_store.getRoadmaps
    }

    // Mark Roadmap Card as Completed
    setRoadmapCardAsCompleted(data, is_checked) {
        this.api.post('client/setProgress', {
            data: data,
            ischecked: is_checked
        }, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                //
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Roadmap Editing */
    async editRoadmapName(roadmap_data) {
        await this.api.post('adminclientdboard/editRoadmap', roadmap_data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.toast.success(result.message)                
                this.client_roadmap_store.updateRoadmapName(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    async removeRoadmapVideoLink(roadmap_data) {
        await this.api.post('client/removeVideo', roadmap_data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.updateRoadmapLink(result.data)
                this.toast.success(result.message)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    async addRoadmapVideoLink(roadmap_data) {
        await this.api.post('client/setVideo', roadmap_data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                roadmap_data.data.video_url = roadmap_data.item
                this.client_roadmap_store.updateRoadmapLink(roadmap_data.data)
                this.toast.success(result.message)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Tasks */
    getRoadmapTasks(url_query) {
        this.api.get(`client/getTask?${url_query}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (_data) => {
            const result = _data.data
            if ( result.response == true ) {
                this.client_roadmap_store.setTasks(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    getTasksFromStore() {
        return this.client_roadmap_store.getTasks
    }

    setTasksStatus(task) {
        this.api.post('client/toggleTask', task, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                task.is_done = !task.is_done
                this.client_roadmap_store.updateTaskStatus(task)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    addNewTask(task) {
        this.api.post('client/submitTask', task, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.setTasks(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    removeTask(task) {
        this.api.patch('client/deleteTask', task, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.setTasks(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Set Deadline */
    setDeadline(data) {
        this.api.post('client/setDeadline', data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.updateRoadmapDeadline(result.roadmap)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Set Progress */
    setRoadmapProgress(progress_data) {
        this.api.post('client/setProgress', progress_data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                progress_data.data.progress = progress_data.item
                progress_data.data.ischecked = result.data.ischecked
                this.client_roadmap_store.updateRoadmapProgress(progress_data.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Set Priority */
    setRoadmapPriority(priority_data) {
        this.api.post('client/addpriorityroadmap', priority_data, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                // Update Priority
                this.client_roadmap_store.updateRoadmapPriority(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Set Internal User */
    setRoadmapInternalUser(payload) {
        this.api.post('adminclientdboard/tagInternalUsers', payload, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                // Update Tagged To
                this.client_roadmap_store.updateRoadmapInternalUser(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    removeRoadmapInternalUser(payload) {
        this.api.patch(`roadmap/r_assigned_user/${payload}`, {}, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.updateRoadmapInternalUser(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }
// =========================== NOTES =================================
    getAllClientAccountNotes(payload) {
        this.api.get(`client/getComments?${payload}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.client_roadmap_store.setNotes(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }
    
    getClientAccountNotesFromStore() {
        return this.client_roadmap_store.getNotes
    }

    addClientAccountNotes(payload) {
        payload.user_id = JSON.parse(localStorage.getItem('udata')).id
        return this.api.post('client/addComment', payload, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.toast.success("You have successfully added a new note.")
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /* {"item":"This is a testing test","data":{"id":339,"order_id":0,"user_id":1399,"user":"Josh Smith","comments":"<p>This is a test</p>","created_at":"2 hours ago","isEdit":true,"nested_comment":[]}} */
    editClientAccountNotes(payload) {
        this.api.patch('client/editComment', payload).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                //
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /* Comment Id */
    deleteClientAccountNotes(payload) {
        return this.api.delete(`client/deleteComment/${payload}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.toast.success("You have successfully remove a note.")
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }
// =========================== NOTES =================================
}