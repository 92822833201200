<template>
    <v-container fluid>
      <!-- button for mark as complete -->
      <v-col cols="12">
        <v-icon class="mr-3">{{queryString.icon}}</v-icon><strong>{{queryString.name}}</strong>
        <v-spacer></v-spacer>
        <v-btn 
          color="info" 
          dark
          @click="markdone"
        >
          Mark as complete
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="12">

         <!-- video -->
        <div v-if="queryString.video_url.length > 0">
          <!-- <video :key="`${path}${queryString.video_url}`" width="100%" height="100%" controls controlsList="nodownload"> -->
          <video width="100%" height="100%" :key="`${path}${queryString.video_url}`" controls controlsList="nodownload">
            <source :src="`${path}${queryString.video_url}`" type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </div>
        
        <!-- pdf -->
        <div v-if="queryString.file_url != '' && queryString.file_url != null" class="mt-2" >
            <div v-for="(item, itemindex) in queryString.file_url" :key="itemindex">
               <div
                  v-if="item.split('.').pop() === 'xlsx' || item.split('.').pop() === 'docx'"
               >
                  <VueDocPreview :value="`${path}${item}`" type="office"></VueDocPreview>
               </div>
               <div
                  v-if="item.split('.').pop() === 'pdf'"
               >
                  <!-- <PdfVuer :src="`${path}${item}`"></PdfVuer> -->
                  <iframe :src="`${path}${item}`" width="100%" height="1000px"></iframe>
               </div>
            </div>
         </div>
        <!-- Hourly rate -->
        <!-- <v-card class="pa-2 mt-2" v-if="queryString.name == 'Hourly Rate Training Basic & Advanced'">
            <v-card-actions>
               <strong>Hourly Rate Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'hourly.rate.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         <!-- KPI's -->
        <!-- <v-card class="pa-2 mt-2" v-else-if="queryString.name == 'Week 34 - Key Performance Indicators'">
            <v-card-actions>
               <strong>KPI Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'kpi.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->

         <!-- ATTACHMENTS -->
        <div class="mt-2" v-else>
            <!-- ADDITIONAL VIDEO UPLOAD -->
            <v-card class="card" elevation="0" v-if="queryString.additional_files != null && queryString.additional_files != ''">
               <v-card-title> Additional Video Files </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in queryString.additional_files" :key="i">
                  <strong class="ml-2">{{param.name}}</strong>

                  <v-spacer></v-spacer>
                  <a v-if="user.role_id != 5" id="download" :href="`${path}${param.file}`" download target="_blank"> Download </a>
                  <div @click="trialModal = true" v-else id="download">Download</div>
               </v-card-actions>
            </v-card>
            <!-- END OF ADDITIONAL VIDEO UPLOAD -->

            <!-- FILE UPLOAD -->
            <v-card class="card" elevation="0" v-if="queryString.file_url != null && queryString.file_url != ''">
               <v-card-title> File Uploaded </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in queryString.file_url" :key="i">
                  <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png" alt="" style="cursor:default;" width="50" height="50" />
                  <a :href="`${path}${param}`" v-html="`${param}`" target="_blank"></a>
                  <v-spacer></v-spacer>
                  <a id="download" v-if="user.role_id != 5" :href="`${path}${param}`" download target="_blank"> Download </a>
                  <div @click="trialUser" v-else id="download">Download</div>
               </v-card-actions>
               <v-card-actions>
                  <small class="red--text">Click the download button, press right click on the photo and then save image.</small>
               </v-card-actions>
            </v-card>
            <!-- END OF FILE UPLOAD -->

            <!-- CONTENT -->
            <v-card elevation="0" class="card" v-if="queryString.contents != null && queryString.contents != '' && queryString.contents != 'null'">
              <v-card-text 
                ref="content"
                v-html="renderedText"
                @click="replaceLink(); trialUser()"
              ></v-card-text>
            </v-card>
            <!-- END OF CONTENT -->

            <v-dialog
               v-model="trialModal"
               persistent
               max-width="50%"
            >
               <v-card
               >
                  <v-card-title>
                     <v-spacer></v-spacer>
                     <v-icon class="red--text" @click="trialModal = false">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text
                    class="pt-5"
                  >
                    <p>Upgrade your membership now to gain unlimited access to our Mastermind content and take your learning to the next level.</p>
                    <p>To upgrade your account and start exploring our full range of offerings, contact us at success@profitabletradesmen.com or give us a call at 07480533921.</p>
                  </v-card-text>
               </v-card>
            </v-dialog>
        </div>
      </v-col>
    </v-container>
</template>

<script>
import qs from 'qs';
import VueDocPreview from 'vue-doc-preview'
import { mapGetters } from 'vuex'

// import VuePdf from 'vue-pdf';
// import PdfVuer from 'pdfvuer';

export default {
  components: {
    VueDocPreview,
    // VuePdf,
    // PdfVuer
  },
  props: [
  ],
  data: () => ({
    urlparams: {},
    path: null,
    publicPath: process.env.VUE_APP_URL,
    tempContent: null,
    renderedText: null,
    isDisabled: false,
    trialModal: false
  }),
  mounted () {
      this.replaceLink();

      // this function is used for video pausing feature
      // const video = document.querySelector('video');

      // video.ontimeupdate = (event) => {
      //    let pauseTime = 10;
      //    if(event.target.currentTime >= pauseTime) {
      //       video.pause();
      //       alert('Magbayad kna');
      //    }
      // };
  },
  created () {
    this.urlparams = this.$route.query
    this.path = process.env.VUE_APP_URL
  },
  computed: {
    queryString() {
      return qs.parse(this.urlparams.query);
    },
    queryStringContent() {
      return qs.parse(this.urlparams.query).contents;
    },
    user() {
      return JSON.parse(this.getUserData);
    },
    contentChecker() {
      return this.tempContent ? this.tempContent : this.queryString.contents
    },
    fileExt() {
      if(this.queryString.file_url) {
        return this.queryString.file_url[0].split('.').pop();
      } else {
        return null;
      }
    },
    ...mapGetters({
      getUserData: 'auth/getUserData'
    }), 
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async markdone(){
      await this.$axios.patch(`/client/markdonelesson/${this.queryString.id}`)
      .then(({data}) => {
        this.$store.dispatch('clientstate/setLessonItems', data.data.original.lessons)
      })
    },
    trialUser() {
      if(this.user.role_id == 5) {
        this.trialModal = true;
      }
    },
    replaceLink() {
      if(localStorage.getItem('token')) {
        if(this.user.role_id == 5) {
          this.renderedText = this.queryStringContent.replace(/<a\s+([^>]*\s+)?href=(['"])(?:(?!\2).)*\2([^>]*)>/gi, '<a $1 $3 v-on:click.prevent>');
        } else {
          this.renderedText = this.queryStringContent;
        }
        return;
      } else {
        this.showSnackBar('Please login first!');
        this.$store.dispatch('auth/setCurrentRoute', this.$route.query);
        this.$router.push({ name: 'index' }).catch(e => console.error(e));
      }
    },

    // async downloadFile(url, url2) {
    //   await axios({
    //     url: `${url}${url2}`,
    //     method: 'GET',
    //     responseType: 'blob',

    //   }).then((res) => {
    //     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                            
    //                         var docUrl = document.createElement('x');
    //                         docUrl.href = FILE;
    //                         docUrl.setAttribute('download', 'file.pdf');
    //                         document.body.appendChild(docUrl);
    //                         docUrl.click();
    //   });
    // }
    // async download(str) {
    //   this.$axios.get('/courses/downloadFile?image=' + str, {responseType: 'blob'})
    //     .then(({data}) => {
    //       // const url = window.URL.createObjectURL(new Blob(data.data));
    //       // const link = document.createElement('a');
    //       // link.href = url;
    //       // link.setAttribute('download', str);
    //       // document.body.appendChild(link);
    //       // link.click();
    //       let blob = fetch(data).then(r => r.blob());
    //     });
    // },
  },
  watch: {
    '$route' (to){
      this.urlparams = to.query
      this.replaceLink();
      this.trialModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
  #download {
    color: #fff;
    background-color: #1976d2;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    text-decoration: none;
  }
  .card {
    border: 1px solid #abb1bc;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

</style>