<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="3"
      >
        <ListItem
          :setup="view_data"
          :coursedesc="getCourse"
          :lessonitems="getLessonItems"
          :hasProject="getHasCourse"
          :courseList="getSelected"
          :headerTag="headerTag"
          @showroadmap="showroadmap"
          @showcourseselection="showcourseselection"
          @scourse="scourse"
        />
        <!-- <DialogVue
          v-model="dialogroadmap" 
          :value="dialogroadmap" 
          :dialogsvalue="dialogsvalue"
          :getRoadmap="getRoadmap" 
          @closedialog="closedialog"
        /> -->
        <!-- <v-dialog
          v-model="dialogroadmap"
          
        >
          <v-card>
            <DataTable3 
              :roadmaps="getRoadmap"
              @editpriorityroadmap="editpriorityroadmap"
              @savecolor="savecolordata"
              @tickcheckroadmap="tickcheckroadmap"
            />
            <DialogVue
              v-model="dialogcolorroadmap" 
              :value="dialogcolorroadmap"
              :dialogsvalue="dialogcolor" 
              @closedialog="closedialog"
              @savecolor="savecolordata"
            />
          </v-card>
        </v-dialog> -->
      </v-col>
      <v-col
        cols="9"
      >
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListItem from '../../components/ListItem'
// import DataTable3 from '../../components/DataTable3'
// import DialogVue from '../../components/DiaLog'

import { mapGetters } from 'vuex'
export default {
  components: {
    ListItem,
    // DataTable3,
    // DialogVue
  },
  props: [
  ],
  data: () => ({
    headerTag: [
      { text: 'Title', value: 'name' },
      { text: 'Tags', value: 'tags' },
      { text: 'Action', value: 'action', align: 'end' }
    ],
    dialogroadmap: false,
    dialogcolorroadmap: false,
    view_data: {
      course: true,
      course_title: 'Test Course Title',
      complete_progress: 15
    },
    dialogcolor: {
      isTable: false,
      color: true,
      title: 'Add Priority',
      colors: [
        {
          name: '1',
          color: '#A76571'
        },
        {
          name: '2',
          color: '#BD8C94'
        },
        {
          name: '3',
          color: '#D3B2B8'
        }
      ]
    },
  }),
  mounted () {
    // this.scourse();
  },
  created () {
    this.fcourse()
  },
  computed: {
    ...mapGetters({
      getHasCourse:         'clientstate/getHasCourse',
      getCourseList:        'clientstate/getCourseList',
      getCourse:            'clientstate/getCourse',
      getLessonItems:       'clientstate/getLessonItems',
      getRoadmap:           'clientstate/getRoadmap',
      getColor:			        'clientstate/getColor',
      getSelected:          'clientstate/getSelected',
      getUser:                     'auth/getUserData',
    })
  },
  methods: {
    async getroadmap(){
      await this.$axios.get('/client/getroadmaps')
      .then(({data}) => {
        this.$store.dispatch('clientstate/setRoadmap', data.data)
      })
    },
    showroadmap(){
      this.dialogroadmap = true
    },
    closedialog(){
      this.dialogroadmap = false
      this.dialogcolorroadmap = false
    },
    showcourseselection(){
      this.$store.dispatch('clientstate/setHasCrouse', false)
    },
    async fcourse(){
      await this.$axios.get('/client/selectcourse')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('clientstate/setSelectedCourse', data.data)
        }
      })
    },
    async fetchStaff(id) {
      await this.$axios.get(`client/getStaff/${id}`)
        .then(({data}) => {
          this.$store.dispatch('clientstate/setStaff', data.data);
        })
    },
    async scourse(id) {
      const user = JSON.parse(this.getUser);
      await this.$axios.get(`/adminclientdboard/gcourse/${id}/${user.id}`)
        .then(({data}) => {
          if(data.response) {
            this.getroadmap(id);
            this.fetchStaff(id);
            this.$store.dispatch('clientstate/setCourse', data.course);
            this.$store.dispatch('clientstate/setSelected', data.course);
            this.$store.dispatch('clientstate/setLessonItems', data.data);
            this.$store.dispatch('clientstate/set_issues')
            // this.$store.dispatch('clientstate/set_issue_solution', data.data_issues)
            this.$store.dispatch('clientstate/setHasCrouse', true);
            this.courseitem = data.data;
          }
        }).catch(err => err);
    },
    editpriorityroadmap(){
      this.dialogcolorroadmap = true
    },
    async savecolordata(data){
      let tp = {
        color: data.input,
        item_id: this.getColor,
      }
      await this.$axios.post('/client/addpriorityroadmap', tp)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getroadmap()
        }
      })
    },
    async tickcheckroadmap(data){
      let tp = {
        id:		data.id,
        ischecked: data.ischecked
      }
      await this.$axios.patch(`/client/markcheckbox/${tp.id}/${tp.ischecked}`)
      .then(({data}) => {
        if(data.response){
          this.getroadmap()
        }
      })
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>