<template>
  <div>
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-col
          cols="6"
        >
          <v-text-field
            filled
            dense
            prepend-inner-icon="search"
            :label="search_title"
          >
          </v-text-field>
        </v-col>
      </v-col>
      <v-card
        class="mt-5 ml-2 mr-2"
        width="344"
        v-for="(item, index) in cardsitem"
        :key="index"
      >
        <v-img
          class="pointer"
          :src="item.imagesource"
          height="200px"
          @click="selectCourse(item.id)"
        ></v-img>

        <v-card-title
          class="pointer"
          @click="selectCourse(item.id)"
        >
          <small>{{item.course_title}}</small>
        </v-card-title>

        <v-card-subtitle
          class="pointer"
          @click="selectCourse(item.id)"
        >
          Author: {{item.get_user.name}}
        </v-card-subtitle>

        <v-card-actions
        >
          <v-btn
            v-if="item.publish == 1"
            color="orange lighten-2"
            text
            @click="selectCourse(item.id)"
          >
            Published
          </v-btn>

          <v-btn
            v-else
            color="orange lighten-2"
            text
          >
            Not yet publish
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="opendeletecourse(item)"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-row 
      justify="center"
    >
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title
            class="justify-center"
          >
            <p>Are you sure you want to delete <em>{{selectedCourse.course_title}}?</em></p>
          </v-card-title>
            <v-card-actions
              class="justify-center"
            >
              <v-btn 
                @click="deleteDialog = false;"
                color="red darken-1"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="deleteCourse();"
              >
                Proceed
              </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {
    
  },
  props: [
    'cardsitem', 'search_title', 'routeurl'
  ],
  data: () => ({
    deleteDialog: false,
    selectedCourse: [],
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    opendeletecourse(data) {
      this.deleteDialog = true;
      this.selectedCourse = data;
    },
    async deleteCourse() {
      await this.$axios.delete(`/courses/deletecourse/${this.selectedCourse.id}`)
        .then(({data}) => {
          this.deleteDialog = false;
          console.log(data);
          this.$store.dispatch('adminmain/setCourseItem', data.data);
        })
    },
    async getCourse(id) {
      await this.$axios.get(`/courses/getcourse/${id}`)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('adminmain/setSelectedCourse', data.data)
            this.$store.dispatch('templatelibrarystate/setCategory', id);
            this.$axios.get(`/library/read/${id}`)
              .then(({data}) => {
                this.$store.dispatch('templatelibrarystate/setLessonFiles', data.data);
              })
          }
        }).catch(err => err)
    },
    selectCourse(id) {
      this.getCourse(id)
      this.$router.push({ name: this.routeurl, params: { id: id } }).catch(err => err)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>