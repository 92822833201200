<template>
  <v-row
    class="flex"
  >
    <TheLoader 
      :visible="loader"
    />
    <v-col
      cols="10"
    >
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        dense
      >
        <v-tab
          v-for="(item, index) in items"
          :key="index"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="tab === 0">
        <Curriculum />
      </v-tabs-items>
      <v-tabs-items v-if="tab === 2">
        <SettingsTab />
      </v-tabs-items>
      <!-- <v-tabs-items
        v-if="tab === 3"
      >
        <TemplateLibrary />
      </v-tabs-items> -->
    </v-col>
    <v-col
      cols="2"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on"
            class="mt-2 pl-10 pr-10"
          >
            Action
          </v-btn>
        </template>
        <v-list>
          <v-list-item style="cursor: pointer;">
            <v-list-item-title @click="$router.push({name: 'preview', params: { id: course_id }})"><v-icon class="mr-2">mdi-eye</v-icon>Preview</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="gcourse[0].publish == 0" style="cursor: pointer;" @click="publish">
            <v-list-item-title><v-icon class="mr-2">mdi-publish</v-icon>Publish</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-if="gcourse[0].publish == 1" style="cursor: pointer;" @click="editcourse">
            <v-list-item-title><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import Curriculum from './components/CurriCulum'
import SettingsTab from './components/SettingsTab'
// import TemplateLibrary from '../../components/templatelibrary/TemplateLibraryComponent.vue';
import TheLoader from '../../components/TheLoader'
import { mapGetters } from 'vuex'

export default {
  components: {
    Curriculum,
    SettingsTab,
    TheLoader,
    // TemplateLibrary
  },
  props: [
  ],
  data: () => ({
    tab: null,
    items: [
      'Curriculum', 
      'Bulk Importer', 
      'Settings',
      // 'Template Library'
    ],
    course_id: null
  }),
  mounted () {
  },
  created () {
    //console.log('here lessons: ', this.$route.params.id)
    this.course_id = this.$route.params.id
    // console.log(this.course_id)
  },
  computed: {
    ...mapGetters({
      loader:       'auth/getLoader',
    }),

    gcourse(){
      return this.$store.getters['adminmain/getCourseInformation'](this.course_id)
    },

  },
  methods: {
    hide() {
      this.$store.dispatch('auth/setHide');
    },
    show() {
      this.$store.dispatch('auth/setShow');
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async publish(){
      this.show();
      await this.$axios.post(`/courses/publish/${this.$route.params.id}`)
      .then(({data}) => {
        this.hide();
        if(data.response){
          this.showSnackBar(data.message)
        }
      })
    },
    editcourse(){
      this.showSnackBar('Under Development!')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>