import Vue from 'vue'
export default {
    namespaced: true,
    state: {
      selectedcourse: null,
      hasCourse: false,
      course: {},
      lessonItems: [],
      todolist: [],
      todoparent: {},
      roadmap: [],
      color: {},
      videolist: [],
      list: [],
      selectedcomments: null,
      comments: [],
      roadmap_progress: null,
      staff: null,
      courselist: [],
      selected: null,

      snackbar: {
         text: '',
         status: false,
         timeout: 5000,
         success: false
      },

      
      // HOURLY RATE
      hourlyRateLoadingStatus: false,
      hourlyRateLists: [],

      // overhead calculator
      salaries: {},
      otherEmployeeCosts: {},
      advertising: {},
      insuranceBusiness: {},
      officeSupplies: {},
      professionalFees: {},
      utilities: {},
      vehicleExpense: {},
      
      // KPI's
      KPILoadingStatus: false,
      KPILists: [],

      tasks: null,
      issue_solution: [],
      videos: [],
      searched_videos: [],
      selectedRoadmap: null,
      issues: [],
      issues_tag: [],
      quarterly_tasks: [],
      quarterly_goals: []
    },
    mutations: {
      setSelectedCourse(state, payload){
        state.selectedcourse = [...payload]
      },
      setHasCourse(state, payload){
        state.hasCourse = payload
      },
      setCourse(state, payload){
        state.course = payload
      },
      setLessonItems(state, payload){
        const data = payload.reduce(function(previous, current) {
          var object = previous.filter(object => object.name === current.name);
          if (object.length == 0) {
            previous.push(current);
          }
          return previous;
        }, []);
        state.lessonItems = [...data]
      },
      setTodoList(state, payload){
        state.todolist = [...payload]
      },
      setTodoParent(state, payload){
        state.todoparent = payload
      },
      setRoadmap(state, payload){
        state.roadmap = [...payload]
      },
      setColor(state, payload){
        state.color = payload
      },
      setVideoList(state, payload){
        state.videolist = [...payload]
      },
      setRoadmaps(state, payload) {
        state.roadmaps = payload
      },
      setComments(state, payload) {
        state.selectedcomments = payload
      },
      SET_COMMENTS(state, payload) {
        state.comments = [...payload]
      },
      setProgress(state, payload) {
        state.roadmap_progress = payload
      },
      setStaff(state, payload) {
        state.staff = payload
      },
      // setCourseUserList(state, payload) {
      //   state.courselist = payload
      // },
      set_course_user_list(state, payload){
        state.courselist = [...payload]
      },
      setSelected(state, payload) {
        state.selected = payload
      },

      SET_SNACKBAR(state,payload) {
         state.snackbar = payload
      },

      // HOURLY RATE
      SET_HOURLY_RATE_LISTS(state, payload) {
         state.hourlyRateLists = payload
      },
      SET_DIALOG_VARIABLES(state, payload) {
         state.salaries           = payload.salaries;
         state.otherEmployeeCosts = payload.otherEmployeeCosts;
         state.advertising        = payload.advertising;
         state.insuranceBusiness  = payload.insuranceBusiness;
         state.officeSupplies     = payload.officeSupplies;
         state.professionalFees   = payload.professionalFees;
         state.utilities          = payload.utilities;
         state.vehicleExpense     = payload.vehicleExpense;
      },
      SET_HOURLY_RATE_LOADING_STATE(state, payload) {
         state.hourlyRateLoadingStatus = payload
      },
      
      
      // KPI
      SET_KPI_LISTS(state, payload) {
         state.KPILists = payload
      },
      SET_KPI_LOADING_STATE(state, payload) {
         state.KPILoadingStatus = payload
      },

      SET_TASK(state, payload) {
         state.tasks = payload;
      },
      set_issue_solution(state, payload){
         state.issue_solution = [...payload]
      },
      set_searched_videos(state, payload){
         state.searched_videos = [...payload]
      },
      SET_SELECTED_ROADMAP(state, payload) {
         state.selectedRoadmap = payload;
      },
      set_issues(state, payload){
         state.issues = [...payload]
      },
      set_quarterly_tasks(state, payload){
         state.quarterly_tasks = payload
      },
      set_quarterly_goals(state, payload){
         state.quarterly_goals = payload
      },
      SET_ISSUES_TAG(state, payload) {
         state.issues_tag = payload;
      }
    },
    getters: {
      getCourseList(state){
        return state.selectedcourse
      },
      getHasCourse(state){
        return state.hasCourse
      },
      getCourse(state){
        return state.course
      },
      getLessonItems(state){
        return state.lessonItems
      },
      search_tags : (state) => {
         let data = []
         let data_complete = []
         let init = 0
         state.lessonItems.map(q => {
            q.get_child.map(qq => {
               let nt = []
               qq.tags.map(qqq => {
                  nt.push(qqq.toLowerCase())
               })
               qq.tags = nt
               if(init < 12){
                  data.push(qq)
                  init++
               }
               data_complete.push(qq)
            })
         })
         return data;
         // if((tags === '' || tags === null)){
         //    return data
         // }
         // if(tags !== null || tags !== ''){
         //    return data_complete.filter(q => {
         //       return q.tags.includes(tags)
         //       || q.name.toLowerCase().includes(tags)
         //    })
         // }
         // return data_complete.filter(q => {
         //    console.log(_search)
         //    if(tags !== null || tags !== ''){
         //       return q.tags.includes(tags)
         //       || q.name.toLowerCase().includes(tags)
         //    }
         //    if(_search !== null || _search !== ''){
         //       return q.issue_solution === _search
         //    }
         // })
      },
      search_issue_solution : state => (search) => {
         let data_complete = []
         state.lessonItems.map(q => {
            q.get_child.map(qq => {
               data_complete.push(qq)
            })
         })
         if(search !== null || search !== ''){
            return data_complete.filter(q => {
               return q.issue_solution === search
            })
         }
      },
      get_issue_solution : state => state.issue_solution,
      getTodoList(state){
        return state.todolist
      },
      getTodoParent(state){
        return state.todoparent
      },
      getRoadmap(state){
        return state.roadmap
      },
      getColor(state){
        return state.color
      },
      getVideoList(state){
        return state.videolist
      },
      getComments(state) {
        return state.comments
      },
      getProgress(state) {
        return state.roadmap_progress
      },
      getStaff(state) {
        return state.staff
      },
      // getCourseUserList(state) {
      //   return state.courselist
      // },
      getCourseUserList : state => state.courselist,
      getSelected(state) {
         return state.selected
      },

      // snackbar
      getSnackbar: state => state.snackbar,
      
      // HOURLY RATE
      getHourlyRateLoadingStatusGetters : state => state.hourlyRateLoadingStatus,
      getAllHourlyRateGetters: state => state.hourlyRateLists,
      getSingleHourlyRate: (state) => (id) => {
         return state.hourlyRateLists.filter( item => item.id === id);
      },

      // KPI
      getKPILoadingStatusGetters : state => state.KPILoadingStatus,
      getYearListOfKPI(state) {
         // return state.KPILists.map((item) => {
         //    return item.year
         // });
         return state.KPILists.map((item) => { return { text: `Year ${item.year} | ${item.created_at}`, value: item.year } });
      },
      getSingleKPI: (state) => (year) => {
         return state.KPILists.filter( item => item.year === year);
      },

      getTasks: (state) => state.tasks,

      get_searched_videos : state => state.searched_videos,
      getSelectedRoadmap: state => state.selectedRoadmap,
      get_issues(state) {
         return state.issues;
      },
      get_quarterly_tasks(state) {
         return state.quarterly_tasks;
      },
      get_quarterly_goals(state) {
         return state.quarterly_goals;
      },
      getIssuesTag: (state) => state.issues_tag
    },
      actions: {
         set_issue_solution({commit}, payload){
            commit('set_issue_solution', payload)
         },
         setSelectedCourse({commit}, payload){
            commit('setSelectedCourse', payload)
         },
         setHasCrouse({commit}, payload){
            commit('setHasCourse', payload)
         },
         setCourse({commit}, payload){
            commit('setCourse', payload)
         },
         setLessonItems({commit}, payload){
            commit('setLessonItems', payload)
         },
         setTodoList({commit}, payload){
            commit('setTodoList', payload)
         },
         setTodoParent({commit}, payload){
            commit('setTodoParent', payload)
         },
         setRoadmap({commit}, payload){
            commit('setRoadmap', payload)
         },
         setColor({commit}, payload){
            commit('setColor', payload)
         },
         setVideoList({commit}, payload){
            commit('setVideoList', payload)
         },
         setRoadmaps({commit}, payload) {
            commit('setRoadmaps', payload)
         },
        //  setComments({commit}, payload) {
        //     commit('setComments', payload)
        //  },
         async setComments({ commit }, payload) {
          await Vue.axios.get(`client/getComments`, payload)
            .then(({data}) => {
              commit('SET_COMMENTS', data.data);
            });
         },
         setProgress({commit}, payload) {
            commit('setProgress', payload)
         },
         setStaff({commit}, payload) {
            commit('setStaff', payload)
         },
         // setCourseUserList({commit}, payload) {
         //   commit('setCourseUserList', payload)
         // },
         async fetch_courses_of_user({commit}, payload){
            await Vue.axios.get(`/adminclientdboard/ucourselist/${payload}`)
            .then(({data}) => {
               commit('set_course_user_list', data.data)
            })
         },
         setSelected({ commit }, payload) {
            commit('setSelected', payload)
         },

         // FOR HOURLY RATE CALCULATOR
         async actionGetHourlyRateLists({commit}) {
            commit("SET_HOURLY_RATE_LOADING_STATE", true);
            await Vue.axios.get('/client/hourlyCalculator')
            .then((response) => {
               commit('SET_HOURLY_RATE_LISTS', response.data.data)
               commit("SET_HOURLY_RATE_LOADING_STATE", false);
            }).catch((error) => {
               console.log(error);
            })
         },

         //save overhead calc variables to state 
         actionSaveToStore({commit},payload) {
            commit("SET_DIALOG_VARIABLES", payload);
         },

         async actionSaveHourlyRate({commit, state}, payload) {

            commit("SET_HOURLY_RATE_LOADING_STATE", true);

            let datas = {
               primaryData: payload,
               overhead: {
                  salaries:           state.salaries,
                  otherEmployeeCosts: state.otherEmployeeCosts,
                  advertising:        state.advertising,
                  insuranceBusiness:  state.insuranceBusiness,
                  officeSupplies:     state.officeSupplies,
                  professionalFees:   state.professionalFees,
                  utilities:          state.utilities,
                  vehicleExpense:     state.vehicleExpense,
               }
            }
            console.log('data to save ',datas);
            await Vue.axios.post('/client/hourlyCalculator', {...datas} )
            .then(() => {
               commit("SET_HOURLY_RATE_LOADING_STATE", false);
            }).catch((error) => {
               console.log(error);
            })
         },

         async actionDeleteCalculator({commit}, id) {
            commit("SET_HOURLY_RATE_LOADING_STATE", true);

            await Vue.axios.delete(`/client/hourlyCalculator/${id}`)
            .then(() => {
               commit("SET_HOURLY_RATE_LOADING_STATE", false);
            }).catch((error) => {
               console.log(error);
            })
         },
         
         // FOR KPI CALCULATOR
         async actionGetKPILists({ commit }, id) {
            commit("SET_KPI_LOADING_STATE", true);
            const tp = {
               id: id
            }

            await Vue.axios.get('/client/kpiCalculator', tp)
            .then((response) => {
               commit('SET_KPI_LISTS', response.data.data)
               commit("SET_KPI_LOADING_STATE", false);
            }).catch((error) => {
               console.log(error);
            })
         },
         
         // SAVING KPI TO DATABASE
         async actionSaveKPI({commit}, payload) {
            
            commit("SET_KPI_LOADING_STATE", true);
            
            let datas = {
               primaryData: payload,
            }
            let result = '';
            await Vue.axios.post('/client/kpiCalculator', {...datas} )
            .then((response) => {
               console.log(response.data);

               if(response.status == 200) {
                  commit("SET_KPI_LOADING_STATE", false);
                  let successPayload = {
                     text: payload.id != '' ? 'Successfully Update KPI' : 'Successfully Saved KPI',
                     status: true,
                     timeout: 2000,
                     success: true
                  }
                  commit("SET_SNACKBAR", successPayload);
                  result = 'success';
               } else {
                  commit("SET_KPI_LOADING_STATE", false);
                  let errorPayload = {
                     text: `${payload.year} Year already exists`,
                     status: true,
                     timeout: 5000,
                     success: false
                  }
                  commit("SET_SNACKBAR", errorPayload);
                  result = 'error';
               }
            })

         return result;
      },
      async actionDeleteKPI({ commit }, id) {
         commit("SET_KPI_LOADING_STATE", true);

            await Vue.axios.delete(`/client/kpiCalculator/${id}`)
            .then((response) => {

               if(response.status == 200) {
                  commit("SET_KPI_LOADING_STATE", false);

                  let successPayload = {
                     text: 'Successfully Delete KPI',
                     status: true,
                     timeout: 2000,
                     success: true
                  }

                  commit("SET_SNACKBAR", successPayload);
               } else {

                  commit("SET_KPI_LOADING_STATE", false);

                  let errorPayload = {
                     text: 'Error, Something went wrong',
                     status: true,
                     timeout: 2000,
                     success: false
                  }

                  commit("SET_SNACKBAR", errorPayload);
               }

            }).catch((error) => {
               console.log(error);
            })
         },

         setTask({ commit }, payload) {
            commit('SET_TASK', payload);
         },

         set_searched_videos({commit}, payload){
            commit('set_searched_videos', payload)
         },
         setSelectedRoadmap({ commit }, payload) {
            commit('SET_SELECTED_ROADMAP', payload);
         },
         /*async set_issues({commit}){
            await Vue.axios.get('/client/issues/roadmapQuery')
            .then(({data}) => {
               // let myarr = [];
               // data.data.map(a => {
               //    console.log(a);
               // })
               // let data_to_out = []
               // data.data.map(q => {
               //    q.tags.map(qq => {
               //       data_to_out.push({
               //          tags: qq,
               //          issue_solution: q.issue_solution,
               //          video_url: q.video_url,
               //          file_url: q.file_url,
               //          name: q.name,
               //          contents: q.contents,
               //          additional_files: q.additional_files,
               //          hash: q.hash,
               //          roadmap: q.roadmap !== undefined ? q.roadmap : null
               //       })
               //    })
               // })
               // commit('set_issues', data_to_out)
               commit('set_issues', data.data)
            })
         },*/
         /*async set_issues_admin({ commit }, v) {
          await Vue.axios.get(`/client/issues/roadmapQuery/${v}`)
          .then(({data}) => {
            let myarr = [];
            data.data.map(a => {
               myarr.push(...a.tags);
            })

            let uniquearr = Array.from(new Set(myarr));
            //  let data_to_out = []
            //  data.data.map(q => {
            //     q.tags.map(qq => {
            //        data_to_out.push({
            //           tags: qq,
            //           issue_solution: q.issue_solution,
            //           video_url: q.video_url,
            //           file_url: q.file_url,
            //           name: q.name,
            //           contents: q.contents,
            //           additional_files: q.additional_files,
            //           hash: q.hash,
            //           roadmap: q.roadmap !== undefined ? q.roadmap : null
            //        })
            //     })
            //  })
            //  commit('set_issues', data_to_out)
            commit('SET_ISSUES_TAG', uniquearr);
            commit('set_issues', data.data)
          })
         },*/
         set_quarterly_tasks({ commit }, payload) {
            commit('set_quarterly_tasks', payload);
         },
         set_quarterly_goals({ commit }, payload) {
            commit('set_quarterly_goals', payload);
         },
         
      }
}