<template>
  <div>
    <div v-if="setup.preview">
      <!-- preview -->
      <v-card
        class="flex"
      >
        <v-card-title><small>{{setup.course_title}}</small></v-card-title>
        <v-list subheader>
          <v-list-group
            v-for="item in lists"
            :key="item.id"
          >
            <template v-slot:activator>
              <v-list-item-content @click="setChild(item.get_child, item.id);">
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in coursechild"
              :key="child.id"
            >
              <v-list-item-content 
                @click="adminCurrentPlay(child)"
                style="cursor: pointer;"
              >
                <v-list-item-title><v-icon>{{child.icon}}</v-icon> <small>{{child.name}}</small></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </div>
    <!-- client listing of videos -->
    <div v-else-if="setup.course">
      <!-- tagboard -->
      <v-card
        class="flex"
      >
        <div>
         <!-- <v-virtual-scroll
         :bench="benched"
         :items="items"
         height="600"
         item-height="64"
         > -->
         
          <v-list subheader class="scrollable">
            <!-- <p
              class="py-1 my-1 ml-2 font-weight-medium caption"
              style="cursor: pointer; color: #706666"
              @click="showcourseselection"
            >
              <v-icon x-small>mdi-pencil-outline</v-icon> {{selectedcourse.course_title}}
            </p> -->
            <CourseSelection 
              :items="getCourseUserList"
              @scourse="scourse"
            />
            <v-btn
              class="mb-5"
              color="primary"
              @click="openTags"
              block
              elevation="0"
            >
              Search Tags
            </v-btn>
            <!-- OPEN TAG -->
            <v-row
              justify="center"
            >
              <v-dialog
                v-model="tagDialog"
                width="90%"
                persistent
              >
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-icon small color="red" @click="tagDialog = false">mdi-close</v-icon>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-tabs
                      v-model="tab"
                      color="basil"
                      grow
                    >
                      <v-tab
                        href="#tab-1"
                      >
                        Videos
                      </v-tab>
                      <v-tab
                        href="#tab-2"
                      >
                        Issues
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item
                        value="tab-1"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <v-text-field
                              class="mt-4"
                              dense
                              v-model="searchTag"
                              label="Search tag here"
                              outlined
                              v-debounce:500ms="search_fn"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-col
                          cols="12"
                          v-if="search_no_result"
                          class="text-center"
                        >
                          <label>Sorry, no video on your search result</label> <strong><i>{{ searchTag }}</i></strong>
                        </v-col>
                        <v-row
                          v-if="get_searched_videos.length > 0 || search_no_result"
                        >
                          <v-col
                            v-for="(video, videoindex) in get_searched_videos"
                            :key="videoindex"
                            cols="3"
                          >
                            <v-card
                              elevation="1"
                              class="mt-5 mb-5"
                              @click="proceedLink(video)"
                              :disabled="disableSearch(!video.bridge)"
                            >
                              <v-card-title>
                                <video v-if="video.video_url != null" :key="`${path}${video.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload" class="mb-3">
                                  <source :src="`${path}${video.video_url}`" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <v-img
                                  v-else
                                  src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                  contain
                                  width="250"
                                  height="175"
                                />
                              </v-card-title>
                              <v-card-subtitle>
                                <strong
                                  :style="video.name.length > 20 ? 'fontSize: 14px;' : 'fontSize: 16px;'"
                                >
                                  <v-icon>{{video.icon}}</v-icon>
                                  {{video.name}}
                                </strong>
                              </v-card-subtitle>
                              <v-card-text>
                                <v-row>
                                  <v-chip
                                    v-for="(tag, tagindex) in video.tags"
                                    :key="tagindex"
                                    class="mb-3 mr-2"
                                  >
                                    <small>{{tag}}</small>
                                  </v-chip>
                                </v-row>
                                <br/>
                                <label
                                  v-if="video.tags.length > 0"
                                >
                                  <v-icon>mdi-exclamation-thick</v-icon>
                                  Has additional file
                                </label>
                                <label
                                  v-else
                                >
                                  <v-icon>mdi-exclamation-thick</v-icon>
                                  No additional file
                                </label>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else-if="search_tags.length > 0 || search_no_result"
                        >
                        <v-col
                            v-for="(video, videoindex) in search_tags"
                            :key="videoindex"
                            cols="3"
                          >
                            <v-card
                              elevation="1"
                              class="mt-5 mb-5"
                              @click="proceedLink(video)"
                            >
                              <v-card-title>
                                <video v-if="video.video_url != null" :key="`${path}${video.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload" class="mb-3">
                                  <source :src="`${path}${video.video_url}`" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <v-img
                                  v-else
                                  src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                  contain
                                  width="250"
                                  height="175"
                                />
                              </v-card-title>
                              <v-card-subtitle>
                                <strong
                                  :style="video.name.length > 20 ? 'fontSize: 14px;' : 'fontSize: 16px;'"
                                >
                                  <v-icon>{{video.icon}}</v-icon>
                                  {{video.name}}
                                </strong>
                              </v-card-subtitle>
                              <v-card-text>
                                <v-row>
                                  <v-chip
                                    v-for="(tag, tagindex) in video.tags"
                                    :key="tagindex"
                                    class="mb-3 mr-2"
                                  >
                                    <small>{{tag}}</small>
                                  </v-chip>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item
                        value="tab-2"
                      >
                        <v-row
                        >
                          <v-col
                            cols="3"
                            align-self="stretch"
                            class="fill-height"
                          >
                            <v-card
                              class="elevation-1 rounded-0"
                            >
                              <div
                                v-for="item in getSearchIssueCategory"
                                :key="item.id"
                              >
                                <v-list-group>
                                  <template v-slot:activator>
                                    <v-list-item-content>
                                      <v-list-item-title><strong>{{ item.name }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                  </template>

                                  <v-list-item
                                    v-for="item_child in item.children"
                                    :key="item_child.id"
                                  >
                                    <v-list-item-content
                                      @click="filterVideos(item_child)"
                                      class="d-flex align-center"
                                    >
                                      <v-list-item-title id="cursor"><em>{{ item_child.name }}</em> <small color="grey--text" class="ml-1">({{ item_child.children.length }})</small></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-group>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col
                            cols="9"
                            class="mt-5"
                          >
                            <v-card
                              class="elevation-0"
                              v-if="selected_issue.length > 0"
                            >
                              <v-row>
                                <v-col
                                  cols="4"
                                  v-for="items in selected_issue"
                                  :key="items.id"
                                >
                                  <v-card
                                    v-for="items_child in items.children"
                                    :key="items_child.id"
                                    class="elevation-1 d-block-flex justify-center"
                                    @click="proceedLink(items_child)"
                                    :disabled="disableSearch(!items_child.bridge)"
                                  >
                                    <v-card-text>
                                      <video v-if="items_child.video_url != null" width="100%" height="100%" :key="items_child.video_url" controls controlsList="nodownload">
                                        <source :src="`${path}${items_child.video_url.video_url}`" type="video/mp4"/>
                                        Your browser does not support the video tag.
                                      </video>
                                      <v-img
                                        v-else
                                        src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                        contain
                                        width="250"
                                        height="175"
                                      />
                                      <p class="black--text">{{ items_child.name }}</p>
                                    </v-card-text>
                                    <v-card-actions
                                      class="pt-0"
                                      v-if="items_child.additional_files.length > 0"
                                    >
                                      <v-icon class="grey--text">mdi-exclamation</v-icon>
                                      <span class="grey--text">has additional file</span>
                                    </v-card-actions>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="elevation-0"
                              v-else
                            >
                              Select an issue category first!
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
            <!-- END OF OPEN TAG -->
            <v-list-group
              v-for="item in lessonitems"
              :key="item.id"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{item.name}}
                  </v-list-item-title>
                  <small>{{item.get_child_completed_count}} / {{item.get_child_count_count}}</small>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.get_child"
                :key="child.id"
                :disabled="JSON.parse(user).is_unlocked2 ? false : Boolean(!child.bridge)"
              >
                <v-list-item-content style="cursor: pointer;">
                  <v-list-item-title 
                    @click="pushToCurrentLesson(child)"
                  >
                    <v-icon v-if="child.is_done == 1">
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    {{child.name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

          </v-list>
         <!-- </v-virtual-scroll> -->
        </div>
      </v-card>
    </div>
    <div v-else-if="setup.adminclientdboard">
      <v-card
        class="flex"
      >
        <v-card-title><small>{{setup.course_title}}</small></v-card-title>
        <v-list subheader>
          <v-list-group
            v-for="item in lists"
            :key="item.id"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.get_child"
              :key="child.id"
            >
              <v-list-item-content 
                @click="adminCurrentPlay(child)"
                style="cursor: pointer;
              ">
                <v-list-item-title>
                  <v-icon v-if="child.is_done == 1">
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    {{child.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </div>
    <!-- SETTINGS -->
    <!-- Listing of client when clicking Settings in Admin-->
    <div v-else-if="setup.settings">
      <v-card>
        <v-card-title
        >
          Current Enrolled Clients | 
          <v-btn
            class="ml-1"
            text
            color="primary"
            @click="videoAccessibility()"
          >
            Video Accessibility
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            class="white--text black"
            @click="addClient"
          >
            <v-icon>mdi-plus</v-icon>Add Client
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Search Client"
            hide-details
            v-model="clientSearch"
          ></v-text-field>
        </v-card-text>
        <v-data-table
          :headers="setup.headers"
          :items="setup.enrolledClients"
          :search="clientSearch"
        >
          <!-- eslint-disable-next-line
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>         -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="editDialog(item)"
              class="mr-1"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <!-- DELETE DIALOG -->
      <v-row
        justify="center"
      >
        <v-dialog
          v-model="deletedialog"
          justify="center"
          max-width="500"
        >
          <v-card>
            <v-card-title
              class="justify-center"
            >
              Are you sure you want to delete {{clientToDelete.name}}?
            </v-card-title>
            <v-card-actions
              class="justify-center"
            >
              <v-btn 
                @click="deletedialog = false;"
                color="red darken-1"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="deleteItem();"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- END OF DELETE DIALOG -->
      <!-- EDIT DIALOG -->
      <v-row
        justify="center"
      >
        <v-dialog
          v-model="editdialog"
          justify="center"
          max-width="500"
        >
          <v-card>
            <v-card-title
              class="justify-center"
            >
              Edit {{clientToEdit.name}}
            </v-card-title>
            <v-card-text>
              <v-select
                dense
                outlined
                :items="items_is_unlocked"
                item-text="name"
                item-value="value"
                label="Unlock Staff (5-9)"
                v-model="clientToEdit.is_unlocked"
              >
                
              </v-select>
              <v-select
                dense
                outlined
                :items="items_is_unlocked"
                item-text="name"
                item-value="value"
                label="Unlock Staff (15-30)"
                v-model="clientToEdit.is_unlocked2"
              >
                
              </v-select>
            </v-card-text>
            <v-card-actions
              class="justify-center"
            >
              <v-btn 
                @click="editdialog = false;"
                color="red darken-1"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="editItem();"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- END OF EDIT DIALOG -->
    </div>
    <!-- END OF SETTINGS -->
    <div v-else-if="setup.islist">
      <v-card
        class="flex mt-5"
      >
        <!--<v-row>
          <v-col cols="12">
            <v-btn
              color="info"
              text
              @click="addsectiontodo"
            >
              <v-icon>mdi-plus</v-icon>
              add section
            </v-btn>
          </v-col>
        </v-row>-->
        <v-list subheader two-line>
          <v-list-group
            v-for="item in lists"
            :key="item.id"
            elevation="2"
            class="ma-5 flex"
            style="border: 1px solid black;"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong class="blue--text">
                    <v-icon class="mr-2">{{item.icon}}</v-icon>
                    <small>
                      {{item.name}}
                    </small>
                  </strong>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="addchildlist(item.is_first, item.is_second, item.is_third); tovuexparent(item);"
                  icon
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
            <div v-if="item.is_first">
              <v-list-item
                v-for="child in item.get_child"
                :key="child.id"
              >
                <v-list-item-content
                  dense
                >
                  <v-list-item-title>
                    <v-checkbox
                      v-model="child.ischecked"
                      v-if="child.hascheckbox"
                      hide-details
                    >
                      <template slot="label">
                        <v-row>
                          <v-col 
                            cols="8"
                            v-html="child.name"
                          >
                            <small>
                              <!-- {{child.name}} -->
                            </small>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                  </v-list-item-title>

                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-else-if="item.is_second">
              <v-data-table
                :headers="headerstodo"
                :items="item.get_child"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>
                      {{ props.item['name'] }}
                    </td>
                    <td>
                      <label v-if="props.item['complete'] == null">0</label>
                      <label v-else>{{ props.item['complete'] }}</label>
                    </td>
                    <td>{{ props.item['deadline_date'] }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-data-table
                :headers="headerthird"
                :items="item.get_child"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>
                      {{ props.item['name'] }}
                    </td>
                    <td>
                      {{ props.item['assigned_to'] }}
                    </td>
                    <td style="color: blue;" @click="openvideo(props.item['get_video'])" >
                      <div v-if="props.item['get_video'] != null">
                        <label style="cursor: pointer;" v-if="props.item['get_video'].video_url != null">{{ props.item['get_video'].name }}</label>
                        <label style="cursor: pointer;" v-else>{{ props.item['get_video'].name }} - No Video Yet</label>
                      </div>
                      <div v-else>
                        No Video
                      </div>
                    </td>
                    <td>
                      {{ props.item['week_no'] }}
                    </td>
                    <td>
                      {{ props.item['deadline_date'] }}
                    </td>
                    <td>
                      {{ props.item['status'] }}
                    </td>
                    <td>
                      <label v-if="props.item['complete'] == null">0</label>
                      <label v-else>{{ props.item['complete'] }}</label>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-list-group>
        </v-list>
      </v-card>
    </div>
    <!-- Admin setup of lesson/video -->
    <div v-else>
      <!-- Course setup -->
      <v-card
        class="flex"
      >
        <!-- <v-btn
          style="fontTransform: none !important;"
          block
          class="mb-5 mt-5"
          flat
        >Search Tags / Videos</v-btn> -->
        <v-list 
          subheader 
          class="scrollable"
        >
          <v-btn block color="info" @click="addchapter">Add Chapter</v-btn>
            <draggable
              :list="setup"
              :animation="200"
              group="parents"
              @start="drag=true"
              @end="end()"
            >
              <v-list-group
                v-for="item in setup"
                :key="item.id"
                elevation="2"
                class="ma-2"
                eager="true"
                v-model="item.active"
                @click="setChild(item.get_child, item.id); setParent(item); routerPush(item)"
              >
                <template v-slot:activator>
                  <v-list-item-content @click="setChild(item.get_child, item.id), setParent(item); routerPush(item);">
                    <v-list-item-title>
                      {{item.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <draggable
                  :list="coursechild"
                  :animation="200"
                  group="child"
                  @start="drag=true"
                  @end="endChild()"
                >
                  <v-list-item
                    v-for="child in coursechild"
                    :key="child.id"
                  >
                    <v-list-item-content style="cursor: pointer; border: 1px solid black;" class="pl-2 pr-2 ma-2" @click="navigateToVideo(child)">
                      <v-list-item-title><v-icon>{{child.icon}}</v-icon> <small>{{child.name}}</small></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
                <v-btn block color="primary" @click="addlesson(); tovx(item);">Add Lesson</v-btn>
              </v-list-group>
            </draggable>
        </v-list>
      </v-card>
    </div>
</div>
  
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import CourseSelection from '../components/AutoComplete.vue'

import qs from 'qs';

  export default {
    components: {
      draggable,
      CourseSelection
    },
    props: [
      'setup', 
      'chapter', 
      'hname', 
      'lists', 
      'hasProject', 
      'courseList', 
      'coursedesc', 
      'lessonitems',
      'islist',
      'headerstodo',
      'headerthird',
      'clientslist',
      'headerTag',
      // 'disabled'
    ],
    data: () => ({
      parent_course_id: null,
      clientToDelete: {},
      clientToEdit: {},
      deletedialog: false,
      editdialog: false,
      disabledroadmapdialog: false,
      tagDialog: false,
      sortedParent: [],
      sendOrder: null,
      sortedChild: [],
      sendOrderChild: null,
      childId: null,
      items_is_unlocked: [
        {
          name: 'Unlocked',
          value: 1
        },
        {
          name : 'Locked',
          value: 0
        }
      ],
      is_unlocked: null,
      searchTag: null,
      path: null,
      search_solution: null,
      search_no_result: false,
      tab: null,
      item_videos_issue_solution: [],
      issue_header: [
        { text: 'Problem', value: 'tags', sortable: false },
        { text: 'Solution', value: 'issue_solution', sortable: false },
        { text: 'Video', value: 'video_url', sortable: false },
        { text: 'Documents', value: 'file_url', sortable: false },
        { text: 'Percentage'},
        { text: 'Priority'}
      ],
      search_issues: null,
      issues_dialog: false,
      selected_issue: [],
      video_dialog: false,
      selected_video: {},
      clientSearch: null,
      card_disabled: {
        opacity: 0.5
      }
    }),

    mounted () {
      //this.getIssueCategory();
    },
    created () {
      this.parent_course_id = this.$route.params.id
      this.path = process.env.VUE_APP_URL
    },
    computed: {
      ...mapGetters({
        coursechild:              'adminmain/getCourseChildList',
        loader:                   'auth/getLoader',
        course:                   'adminmain/getSelectedCourse',
        selectedcourse:           'clientstate/getSelected',
        selectedlessonitems:      'clientstate/getLessonItems',
        user:                     'auth/getUserData',
        get_searched_videos:      'clientstate/get_searched_videos',
        lessonList:               'adminmain/getLessonList',
        getCourseUserList:        'clientstate/getCourseUserList',
        getSearchIssueCategory:   'adminmain/getSearchIssueCategory',
        course_users:             'adminmain/getCurrentEnrolledClients',
        selected_user:            'auth/getSelectedUser'
      }),
      search_tags(){
        // return this.$store.getters['clientstate/search_tags'](this.searchTag, this.search_solution)
        return this.$store.getters['clientstate/search_tags']
      },
      // search_issue_solution(){
      //   return this.$store.getters['clientstate/search_issue_solution'](this.search_solution)
      // },
      get_issue_solution(){
        return this.$store.getters['clientstate/get_issue_solution']
      },
      get_get_issues(){
        return this.$store.getters['clientstate/get_issues']
      },
      filterTag() {
        return this.searchTag
      },
      check_extension(item) {
        return item.split('.').pop();
      },
      user_selected() {
        return JSON.parse(this.user);
      }
    },
    methods: {
      hide() {
        this.$store.dispatch('auth/setHide');
      },
      show() {
        this.$store.dispatch('auth/setShow');
      },
      async end() {
        [...this.setup].map((item, index) => {
          const newSort = index + 1;
          item.hasChanged = item.sortOrder !== newSort;
          if (item.hasChanged) {
            item.sortOrder = newSort;
          }
          this.sortedParent.push({ id: item.id, name: item.name, newindex: item.sortOrder });
        });
        this.sendOrder = {
          parent_id: this.$route.params.id,
          item: this.sortedParent
        };
        await this.$axios.post('/courses/setHierarchy', this.sendOrder)
          .then(({data}) => {
            this.$store.dispatch('adminmain/setCourseList', data.data);
            this.sortedParent = [];
            this.sendOrder = null;
          })
      },
      async setChild(arr, id) {
        this.show();
        await this.$store.dispatch('adminmain/setCourseChildList', arr)
        this.childId = id;
        this.hide();
      },
      async endChild() {
        this.show();
        [...this.coursechild].map((item, index) => {
          const newSort = index + 1;
          item.hasChanged = item.sortOrder !== newSort;
          if(item.hasChanged) {
            item.sortOrder = newSort;
          }
          this.sortedChild.push({ id: item.id, name: item.name, newindex: item.sortOrder });
        });
          this.sendOrderChild = {
            course_id: this.$route.params.id,
            parent_id: this.childId,
            item: this.sortedChild
          };
          await this.$axios.post('/courses/setHierarchyChild', this.sendOrderChild)
            .then(({data}) => {
              if(data.response) {
                this.$store.dispatch('adminmain/setCourseList', data.data);
                this.sortedChild = [];
                this.sendOrderChild = null;
                this.childId = null;
                this.hide();
              }
            });
      },
      addClient() {
        this.$emit('addClient', true)
      },
      addchapter(){
        this.$emit('addchapter', true)
      },
      addlesson(){
        this.$emit('addlesson', true)
      },
      showroadmap(){
        this.$emit('showroadmap')
      },
      tovx(data){
        this.$store.dispatch('adminmain/setParentChapterData', data)
      },
      scourse(data){
        this.$emit('scourse', data)
      },
      showcourseselection(){
        this.$emit('showcourseselection', false)
      },
      addsectiontodo(){
        this.$emit('addsectiontodo', true)
      },
      addchildlist(first, second, third){
        this.$emit('addchildlist', {first: first, second: second, third: third})
      },
      tovuexparent(data){
        this.$store.dispatch('clientstate/setTodoParent', data)
      },
      openvideo(data){
        this.$emit('openvideo', data)
      },
      deleteDialog(data) {
        this.deletedialog = true;
        this.clientToDelete = {...data};
      },
      editDialog(data) {
        this.editdialog = true;
        this.clientToEdit = {...data};
      },
      deleteItem(){
        this.deletedialog = false;
        this.$emit('deleteItem', this.clientToDelete)
      },
      editItem() {
        this.editdialog = false;
        this.$emit('editItem', this.clientToEdit);
      },
      async navigateToVideo(child) {
         scrollTo(0,0); 
         await this.$store.dispatch('adminmain/setSelectedLessonChild', child);
         this.$router.push({name: 'curriculumchild', 
            query: {
               id: child.id,
               name: child.name,
               icon: child.icon,
               parent_course_id: child.parent_id,
               parent: false,
               video_url: child.video_url,
               file_url: child.file_url,
               contents: child.contents,
               additional_files: child.additional_files,
               tags: child.tags,
               issue_solution: child.issue_solution,
               is_archived: child.is_archived
            }
         }).catch(err => err);
      },
      videoAccessibility() {
         this.$router.push({ name: 'video-accessibility', query: { id: this.$route.params.id } })
         scrollTo(0,0);
      },
      pushToCurrentLesson(item) {
        const query = qs.stringify(item);

        this.$router.push({ name: 'currentlesson', query: { query } }).catch(err => err);
        scrollTo(0,0);
      },
      adminCurrentPlay(item) {
        const query = qs.stringify(item);
        this.$router.push({ name: 'currentplay', query: { query } }).catch(err => err);
        scrollTo(0,0);
      },
      openTags() {
        this.tagDialog = true;
      },
      proceedLink(item) {
        const query = qs.stringify(item);
        const route = this.$router.resolve({ name: 'clientcurrentlesson', query: { query } });
        window.open(route.href, '_blank');
      },
      async search_fn(){
        const course_id = this.$store.getters['clientstate/getCourse']
        if(this.searchTag !== ''){
          await this.$axios.post('client/search_via_tags_videos', {
            data:         this.searchTag,
            course_id:    course_id.id
          })
          .then(({data}) => {
            if(data.data.length > 0){
              this.$store.dispatch('clientstate/set_searched_videos', data.data)
              return
            }else{
              this.$store.dispatch('clientstate/set_searched_videos', [])
              this.search_no_result = true
              return
            }
          })
        }else{
          this.$store.dispatch('clientstate/set_searched_videos', [])
        }
      },
      async issue_solution_fn(){
        const course_id = this.$store.getters['clientstate/getCourse']
        if(this.search_solution !== '' || this.search_solution !== null){
          await this.$axios.post('client/search_via_issue_solution', {
            data:         this.search_solution,
            course_id:    course_id.id
          })
          .then(({data}) => {
            this.$store.dispatch('clientstate/set_searched_videos', data.data)
          })
        }
      },
      /*async show_list(item){
        await this.$axios.get('client/issues/g_issue_videos', {
          issue_solution: item.issue_solution
        })
        .then(({data}) => {
          this.item_videos_issue_solution = [...data.data]
        })
      },*/
      async setParent(arr) {
        await this.$store.dispatch('adminmain/setSelectedLesson', arr);
      },
      async get_data(item){
        let payload = {
          course_id: this.selectedcourse.id,
          tags: item
        }

        await this.$axios.get('/client/issues/get_data_video', payload)
        .then(({data}) => {
          this.selected_issue = data.data;
        })
      },
      play_video(item) {
        this.issues_dialog = false;
        
        this.selected_video = item;
      },
      filterVideos(item) {
        this.selected_issue = [];
        this.selected_issue.push(...item.children);
      },
      /*async getIssueCategory() {
        await this.$axios.get('/course/g_issue')
          .then(({data}) => {
            this.$store.dispatch('adminmain/setSearchIssueCategory', data.data);
          })
      },*/
      routerPush(item) {
        this.$router.push({name: 'curriculumchild', query: {id: item.id, name: item.name, parent: true}}).catch(err => console.error(err));
      },
      disableSearch(boolean) {
        // this function works only for trial users
        if(this.user_selected.role_id != 5 && this.user_selected.membership_type == 'Mastermind') {
          return false;
        } else {
          // if the video is Bridge
          if(boolean) {
            return this.card_disabled;
          } else {
            return false;
          }
        }
      }
    },
    watch: {
      searchTag: function(){
        // if(val !== '' || val !== null){
          this.search_solution = null
          this.search_no_result = false
        // }
      },
      search_solution: function(){
        // if(val !== '' || val !== null){
          this.searchTag = null
        // }
      },
      '$route' (to) {
        this.urlparams = to.query;
      }
    }
  }
</script>
<style>
.scrollable{
  height:100vh;
  overflow-y:auto
}

#cursor {
  cursor: pointer;
}
</style>
