<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="v-card-title-border">{{ account.name }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-btn-toggle v-model="client_account_tab" 
                    tile
                    color="deep-purple accent-3"
                    group class="mb-6">
                    <v-btn value="course">
                        <v-icon class="mr-2">mdi-school-outline</v-icon>
                        Course
                    </v-btn>
                    <v-btn value="roadmap" @click="methods.moveToClientPages(`/admin/client/roadmap/${account.id}`)">
                        <v-icon class="mr-2">mdi-sitemap</v-icon>
                        Roadmap
                    </v-btn>
                    <v-btn value="clientanalytics" @click="methods.moveToClientPages(`/admin/client/analytic/${account.id}`)">
                        <v-icon class="mr-2">mdi-monitor-eye</v-icon>
                        Activity Feeds
                    </v-btn>
                </v-btn-toggle>
                <v-row            
                    justify="space-between">
                    <v-col cols="3">
                        <v-combobox
                            v-model="selected_course"
                            placeholder="Select Course"
                            label="Select Course"
                            :items="computed_properties.getActiveCourseList.value"
                            item-text="course_title"></v-combobox>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mt-1 mb-3"
                            color="primary"
                            style="width: 100%;" @click="methods.openSearchVideoDialog()">
                            <v-icon class="mr-2">mdi-magnify-expand</v-icon>
                            <small>Search</small>
                        </v-btn>

                        <v-list style="max-height: 100vh;overflow-y: auto;">
                            <v-list-group>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title><strong>New Videos</strong></v-list-item-title>
                                        <v-list-item-subtitle>{{ computed_properties.newlyAddedVideos.value.length }} newly added videos</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                                <v-list-item link
                                    v-for="(lesson, index) in computed_properties.newlyAddedVideos.value"
                                    :key="index" @click="methods.selectedLesson(lesson)">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ lesson.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-list-group
                                v-for="(chapter, index) in (selected_course ? selected_course.get_ucourse : [])"
                                :key="index"
                                color="primary"
                                no-action>                       
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ chapter.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item link
                                    v-for="(lesson, index) in chapter.get_child"
                                    :key="index" @click="methods.selectedLesson(lesson)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ lesson.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                    </v-col>
                    <v-col cols="9">
                        <v-card v-if="selected_item != null">
                            <v-card-title>
                                <v-icon class="mr-2">{{ selected_item.icon }}</v-icon>
                                <small>{{ selected_item.name }}</small>
                                <v-spacer></v-spacer>
                                <v-btn small fab text color="success" @click="methods.copyLink(account.id, selected_item.id)">
                                    <v-icon>mdi-link</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <VideoViewer v-if="selected_item.video_url != null"
                                    :video_url="selected_item.video_url"></VideoViewer>

                                <FileViewer v-if="selected_item.file_url != null"
                                    :file_url="selected_item.file_url[0]"></FileViewer>                        
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="selected_item.contents != 'null'" v-html="selected_item.contents"></v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <AdditionalFilesViewer
                                    :files="selected_item.additional_files"></AdditionalFilesViewer>
                            </v-card-text>                    
                            <v-divider></v-divider>
                            <v-card-text>                        
                                <TagsViewer
                                    :tags="selected_item.tags"></TagsViewer>
                            </v-card-text>
                        </v-card>
                    </v-col>              
                </v-row>                
            </v-card-text>
        </v-card>

        <DialogSearchVideoTags :value="search_video_dialog" @close_dialog="methods.closeSearchVideoDialog()"></DialogSearchVideoTags>
    </v-container>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue2-helpers/vue-router';
import CourseMethods from './modules/methods/course.method'
import CourseComputed from './modules/computed/course.computed';

import store from '@/states'

import AdditionalFilesViewer from './subcomponents/AdditionalFilesViewer.vue';
import FileViewer from './subcomponents/FileViewer.vue';
import TagsViewer from './subcomponents/TagsViewer.vue';
import VideoViewer from './subcomponents/VideoViewer.vue';

import DialogSearchVideoTags from '@/modules/admin/pchildcomponents/DialogSearchVideoTags.vue';

// Reference Variable
const account = ref('')
const selected_course = ref()
const enrolled_courses = ref()
const client_account_tab = ref('course')
const selected_item = ref()
const search_video_dialog = ref(false)

// Common Variables
const router = useRouter()
const route = useRoute()

// Classes
const method_class = new CourseMethods({
    selected_item: selected_item,
    search_video_dialog: search_video_dialog
})

const computed_class = new CourseComputed({
    enrolled_courses: enrolled_courses,
    selected_course: selected_course
})

// Methods
const methods = method_class.methods

// Computed
const computed_properties = computed_class.computed_properties

onMounted( () => {
    const client_id = route.params.client_id
    const client_list = store.state.adminmain.clientlist    
    const selected_client = client_list.find( (client) => client.id == client_id)
    
    if ( typeof selected_client == 'undefined' ) {
        router.push({path: '/clientlist'})
    }
    enrolled_courses.value = selected_client.course_id
    account.value = selected_client
    methods.getAllCourses()
})

watch( selected_course, () => {
    methods.getAllNewlyAddedVideos(selected_course.value.id)    
    methods.getSearchedVideos(selected_course.value.id, account.value.id)    
})
</script>

<style lang="scss" scoped>
.v-card-title-border {
    border-left: 3px solid rgb(22, 100, 192);
}
</style>