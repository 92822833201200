<template>
  <v-app>
    <Top/>
    <v-snackbar 
      v-model="mSnackbar.show" 
      bottom="bottom" 
      multi-line="multi-line" 
      :timeout="4000" 
      :vertical="true">
      {{ mSnackbar.message }}<br/>
      <v-btn v-if="mSnackbar.button" @click="sendotp" color="primary">Click here to send out new OTP</v-btn>
    </v-snackbar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Top from './modules/topnav/index-nav.vue'
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Dublin')
import { mapGetters } from 'vuex'
export default {
  name: 'App',

  components: {
    Top
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      mSnackbar: 'auth/mSnackbar',
      getUserData: 'auth/getUserData'
    }),

    user() {
      return JSON.parse(this.getUserData);
    }
  },
  mounted() {
  },
  created() {
    this.check_in()
    window.addEventListener("beforeunload", this.check_out);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.check_out);
  },
  methods: {
    check_in(){
      if (Object.keys(this.getUserData).length > 0) {
        let payload = {
          last_check_out: localStorage.getItem('last_check_out'),
          login: true
        }
        this.$axios.post('clients/check_in', payload)
      }
    },
    check_out() {
      localStorage.setItem('last_check_out', moment(Date()).format('YYYY-MM-DD HH:mm:ss'))
    },
  }
};
</script>
