<template>
  <v-container
    fluid
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto"
          outlined
          max-width="30%"
        >
          <v-card-title
            class="blue darken-3 white--text"
          >
            Trial Registration
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                outlined
                dense
                label="First Name"
                v-model="trial_user.firstname"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Last Name"
                v-model="trial_user.lastname"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Email"
                v-model="trial_user.email"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Phone"
                v-model="trial_user.phone"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="submitTrialForm"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {

  },
  props: [

  ],
  data: () => ({
    trial_user: {},
    queryString: ''
  }),
  computed: {

  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async submitTrialForm() {

      this.updateQueryString();

      let topass = {
        ...this.trial_user,
        course_id: 3,
        role_id: 5,
        membership_type: 'Trial'
      }
      
      console.log(topass);

      await this.$axios.get('/trialRegistration/', topass)
        .then(({data}) => {
          if(data.response) {
            this.$router.push({ name: 'index' }).catch(err => err);
            this.showSnackBar(data.message);
            this.$axios.post('/a_client', topass)
              .then(({data}) => {
                if(data.response) {
                  const enrollclient = {
                    course_id:  topass.course_id,
                    id:         data.data.id
                  }

                  this.$axios.patch('/edittrialclients', enrollclient)
                    .then(({data}) => {
                      if(data.response) {
                        this.showSnackBar('You will proceed to login page now.')
                      } else {
                        this.showSnackBar('Something went wrong, please contact the developer / admin.')
                      }
                    })
                } else {
                  this.showSnackBar('Something went wrong, please contact the developer / admin.')
                }
              })
          } else {
            this.showSnackBar('Something went wrong, please contact the developer / admin.')
          }
        })
    },
    updateQueryString() {
      const searchParams = new URLSearchParams()
      searchParams.append('firstname', this.trial_user.firstname);
      searchParams.append('lastname', this.trial_user.lastname);
      searchParams.append('email', this.trial_user.email);
      searchParams.append('phone', this.trial_user.phone);

      this.queryString = searchParams.toString();
    }
  },
  mounted() {

  },
  created() {
    const query = new URLSearchParams(window.location.search);
    this.trial_user.firstname   = query.get('firstname');
    this.trial_user.lastname    = query.get('lastname');
    this.trial_user.email       = query.get('email');
    this.trial_user.phone       = query.get('phone');

    this.updateQueryString();
  },
  watch: {

  }
}
</script>