import { defineStore } from "pinia";

export const clientCourseStore = defineStore('client_course_store', {
    state: () => ({
        courses: [],
        newly_added_videos: [],
        client_progress: [],
        activity_feeds: []      
    }),
    getters: {
        getCourseList: (state) => state.courses,
        getNewlyAddedVideos: (state) => state.newly_added_videos,
        getActivityFeeds: (state) => state.activity_feeds
    },
    actions: {
        setCourseList(payload) {
            this.courses = [...payload]
        },
        // Newly Added Videos
        setNewlyAddedVideos(payload) {
            this.newly_added_videos = [...payload]
        },
        setClientProgress(payload) {
            this.client_progress = [...payload]
        },
        // Activity Feeds
        setActivityFeeds(payload) {
            this.activity_feeds = [...payload]
        }
    }
})