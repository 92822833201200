import ClientMain from '@/v2/modules/client/course/ClientDashboard.vue'
import TagBoard from '../TagBoard'
import Current from '../component/CurrentLesson'
import ResetPassword from '../../../components/ResetPassword'
import AnalyticsComponent from '../component/AnalyticsComponent'
import ShareCurrentLesson from '@/modules/client/component/ShareCurrentLesson'
import HourlyRateCalculatorView from '@/modules/client/pages/HourlyRateCalculatorView';
import KPICalculatorView from '@/modules/client/pages/KPICalculatorView';
import PlayComponent from '@/modules/client/component/PlayComponent'
// import ShareBoard from '@/modules/client/component/ShareBoard'

// import store from '@/states/index.js'

export default [
   /*{
        path: '/client',
        name: 'client',
        component: ClientMain,
        children: [
            {
                path: '/client/currentplay',
                name: 'currentlesson',
                component: Current,
            }
        ],
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       console.log('test:', role)
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },*/
   {
      path: '/play',
      name: 'play',
      component: PlayComponent
   },
   {
      path:'/analytics:id',
      name: 'analytics',
      component: AnalyticsComponent,
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },
   {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
   },
   {
      path: '/board',
      name: 'board',
      component: TagBoard,
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
      // children: [
      //   {
      //     path: '/currentlesson',
      //     name: 'currentlesson',
      //     component: Current
      //   }
      // ]
   },
    // {
    //   path: '/share',
    //   name: 'share',
    //   component: ShareBoard,
    //   children: [
    //     {
    //       path: '/share/currentlesson',
    //       name: 'sharecurrentlesson',
    //       component: ShareCurrentLesson
    //     }
    //   ]
    // }
   {
      path: '/clientdashboard/currentplay',
      name: 'sharecurrentlesson',
      component: ShareCurrentLesson,
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },
   {
      path: '/currentlesson',
      name: 'clientcurrentlesson',
      component: Current,
      meta: { requiresAuth: true },
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },
   {
      path: '/hourly-rate-calculator',
      name: 'hourly.rate.calculator',
      component: HourlyRateCalculatorView,
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },
   {
      path: '/kpi-calculator',
      name: 'kpi.calculator',
      component: KPICalculatorView,
   //    beforeEnter: (to, from, next) => {
   //       const role = JSON.parse(store.getters['auth/getUserData'])
   //       if(role.role_id === 2 || role.role_id === 1){
   //          next()
   //       }else{
   //          next('/')
   //       }
   //       return
   //   }
   },
]