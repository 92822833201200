<template>
    <v-container fluid class="pb-16">
        <v-data-table
            :headers="headers"
            :items="activity_feeds_computed.getActivityFeeds.value"
            :sort-by="['viewed_date','watch_duration']"
            :sort-desc="[true, true]"
            multi-sort>
        </v-data-table>
    </v-container>
</template>

<script setup>
import { ref, watch } from 'vue'
import { defineProps } from 'vue'

// Methods and Computed
import ActivityFeedsMethods from './methods/activityfeed.methods'
import ActivityFeedsComputed from './computed/activityfeed.computed'

const props = defineProps(['account'])

// Reference Variables
const headers = ref([
    {text: 'Content', width: '70%', align: 'left', value: 'content', sortable: false},
    {text: 'Viewed Date', align: 'center', value: 'viewed_date', sortable: true},
    {text: 'Completed Date', align: 'center', value: 'completed_date', sortable: false},
    {text: 'Duration (Minutes)', align: 'center', value: 'watch_duration', sortable: true},
])

// Classes
const activity_feeds_method_class = new ActivityFeedsMethods()
const activity_feeds_computed_class = new ActivityFeedsComputed()

// Methods
const activity_feeds_methods = activity_feeds_method_class.methods

// Computed
const activity_feeds_computed = activity_feeds_computed_class.computed_properties

watch( props, () => {
    if ( typeof props.account.id != 'undefined' ) {
        activity_feeds_methods.getAccountActivityFeeds(props.account.id)        
    }
})

</script>