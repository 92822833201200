import CourseClass from '@/v3/pages/admin/courses/client/modules/classes/course.class.js'

export default class CourseMethods
{
    constructor(parameters) {
        // Parameters
        this.selected_item = parameters.selected_item
        this.search_video_dialog = parameters.search_video_dialog

        // Store
        this.store = parameters.store
        
        // Classes
        this.course_class = new CourseClass()
    }

    methods = ( () => {

        const getClientAccountById = (client_id) => {
            console.log(this.store)
        }

        const getAllCourses = () => {
            this.course_class.getAllCourses()            
        }

        const moveToClientPages = (url) => {
            window.location.href = url
        }

        const selectedLesson = (item) => {
            this.selected_item.value = item
        }

        /** Newly Added Videos */
        const getAllNewlyAddedVideos = (course_id) => {
            this.course_class.getAllNewlyAddedVideos(course_id)
        }

        const copyLink = (user_id, lesson_id) => {
            this.course_class.generateCourseLessonLink(user_id, lesson_id)
        }

        // Search Videos
        const openSearchVideoDialog = () => {
            this.search_video_dialog.value = true
        }

        const closeSearchVideoDialog = () => {
            this.search_video_dialog.value = false
        }

        const getSearchedVideos = (course_id, user_id) => {
            this.course_class.getVideoCourseByTags(course_id, user_id)
        }

        return {
            getClientAccountById,
            getAllCourses,
            moveToClientPages,
            selectedLesson,
            getAllNewlyAddedVideos,
            copyLink,
            openSearchVideoDialog,
            closeSearchVideoDialog,
            getSearchedVideos
        }
    })()
}