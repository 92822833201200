<template>
    <v-row justify="center">
        <v-dialog
            v-model="view_course_lesson_model"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable>
            <v-card flat>
                <v-card-title>
                    <v-btn icon @click="closeCourseLessonDialog()">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab text :color="check_if_completed_or_not(lesson.id) == true ? 'success' : 'secondary'"
                                v-bind="attrs"
                                v-on="on"
                                @click="event_markascompleted(lesson)">
                                <v-icon>mdi-check-bold</v-icon>                            
                            </v-btn>
                        </template>
                        <span>Mark as Completed</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text v-if="lesson">
                    <div>
                        <VideoViewer
                            v-if="lesson.video_url != null"
                            :lesson="lesson"
                            :users_id="users_info.id"></VideoViewer>
                        
                        <FileViewer v-if="lesson.file_url != null"
                            :file_url="lesson.file_url[0]"></FileViewer>
                    </div>
                    <v-tabs
                        v-model="tab">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#about">
                            About
                        </v-tab>
                        <v-tab href="#additional_files">
                            Additional Files
                        </v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                value="about" style="padding-top:20px;">
                                <h3 style="color: black;">{{ lesson.name }}</h3>
                                <v-divider></v-divider>
                                <p class="mt-3" v-if="lesson.contents != 'null'" v-html="lesson.contents" style="text-align: justify;"></p>
                                <label style="font-size:14px;font-weight:700;" class="mt-6">TAGS</label>
                                <div>
                                    <v-chip
                                        class="ma-2"
                                        color="primary"
                                        flat
                                        v-for="(tag, index) in lesson.tags"
                                        :key="index">
                                        {{ tag }}
                                    </v-chip>    
                                </div>
                            </v-tab-item>
                            <v-tab-item value="additional_files">
                                <AdditionalFilesViewer
                                    :files="lesson.additional_files"></AdditionalFilesViewer>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoViewer from './VideoViewerMobile.vue';
import AdditionalFilesViewer from './AdditionalFilesViewer.vue';
import FileViewer from './FileViewer.vue';

import CourseByClient from '@/v2/modules/client/course/modules/Course.js';

export default {
    data: () => ({
        view_course_lesson_model: false,
        tab: null,
        client_course: new CourseByClient()
    }),
    components: {
        VideoViewer,
        AdditionalFilesViewer,
        FileViewer,
    },
    props: {
        show_course_lesson_dialog: Boolean,
        lesson: Object,
        users_info: Object,
    },
    computed: {
        ...mapGetters({
            get_ucourse_parent_by_users_id:     'course/get_ucourse_parent_by_users_id_getter',
            get_courses_by_user:                'course/get_courses_by_user_getter',
            get_course_completed_count:         'clientcourse/get_course_completed_count_getter',
            check_if_completed_or_not:          'clientcourse/check_if_completed_or_not_getter'
        })
    },
    methods: {
        closeCourseLessonDialog() {
            this.$emit('closeCourseLessonDialog')
        },
        event_markascompleted(child) {
            this.client_course.update_course_data_by_id(child.id, {
                is_done: 1,
                ucourse_id: child.id,
                users_id: this.users_info.id
            }, this.$store)
        }
    },
    watch: {
        show_course_lesson_dialog() {
            this.view_course_lesson_model = this.show_course_lesson_dialog
        }
    }
}
</script>