<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="v-card-title-border"> {{ users_info != null ? users_info.name : '' }} </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-tabs
                    v-model="course_tab"
                    left
                    icon-and-text>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-course">                        
                        <v-icon class="mr-2">mdi-school-outline</v-icon>
                        Course
                    </v-tab>

                    <v-tab href="#tab-roadmap">
                        <v-icon class="mr-2">mdi-sitemap</v-icon>
                        Roadmap
                    </v-tab>

                    <v-tab href="#tab-clientanalytics">
                        <v-icon class="mr-2">mdi-monitor-eye</v-icon>
                        Activity Feeds
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="course_tab">
                    <v-tab-item
                        value="tab-course"
                        class="mt-4">
                        <CourseByClient
                            :users_info="JSON.parse(this.getSelectedUser)"></CourseByClient>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-roadmap"
                        class="mt-4">
                        <RoadMapBoardTemp
                            :course_id="course_id"></RoadMapBoardTemp>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-clientanalytics"
                        class="mt-4">
                        <ActivityFeeds
                            :users_info="JSON.parse(this.getSelectedUser)"></ActivityFeeds>
                    </v-tab-item>                   
                </v-tabs-items>                
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import CourseByClient from './components/CourseByClient.vue'
import RoadMapBoardTemp from './components/RoadMapTemp.vue'
import ActivityFeeds from './components/ActivityFeeds.vue'

import Course from './modules/Course';
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        course_tab: null,
        course: new Course(),
        users_info: null,
        isSelected: false,
        dialogtrelloroadmap: false,
        selectedcomments: null,
        course_id: null    
    }),
    components: {
        CourseByClient,
        RoadMapBoardTemp,
        ActivityFeeds
    },
    computed: {
        ...mapGetters({
            getSelectedUser:            'auth/getSelectedUser',
            getUser:                    'auth/getUserData',
            get_courses_by_user:        'course/get_courses_by_user_getter'
        })
    },
    methods: {
        
    },
    async mounted() {
        this.course.get_all_courses(this.$store)

        /** Get information from URL */
        const query = this.$route.query
        if ( Object.keys(query).length > 0 ) {
            this.users_info = JSON.parse(this.getSelectedUser)
            const courses = await this.get_courses_by_user(this.users_info.course_id)

            if ( courses.length > 0 ) {                
                this.course_id = courses[0].id
            } else {
                this.course_id = 3
            }            

        } else {
            this.$router.push('/clientlist')
        }
    },
    create() {

    },
    watch: {
        
    }
}
</script>

<style scoped>
.v-card-title-border {
    border-left: 3px solid rgb(22, 100, 192);
}
</style>