<template>
    <v-container fluid class="pb-16">
        <v-data-table
            :headers="headers"
            :items="getActivityFeeds"
            :sort-by="['viewed_date','watch_duration']"
            :sort-desc="[true, true]"
            multi-sort>
        </v-data-table>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientAnalytics from '@/v2/modules/admin/courses/modules/ClientAnalytics';

export default {
    data: () => ({
        headers: [
            {text: 'Content', width: '70%', align: 'left', value: 'content', sortable: false},
            {text: 'Viewed Date', align: 'center', value: 'viewed_date', sortable: true},
            {text: 'Completed Date', align: 'center', value: 'completed_date', sortable: false},
            {text: 'Duration (Minutes)', align: 'center', value: 'watch_duration', sortable: true},
        ],
        client_analytics: new ClientAnalytics()        
    }),
    props: {
        users_info: Object
    },
    computed: {
        ...mapGetters({
            getActivityFeeds:   'course/getActivityFeedsGetter'
        })
    },
    mounted() {
        this.client_analytics.setStore(this.$store)
        this.client_analytics.setUsersId(this.users_info.id)
        this.client_analytics.getClientsActivityFeedData()        
    }
}
</script>