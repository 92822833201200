<template>
    <v-container fluid>
      <!-- button for mark as complete -->
      <v-col cols="12">
        <v-icon class="mr-3">{{to_show.icon}}</v-icon><strong>{{to_show.name}}</strong>
        <v-spacer></v-spacer>
        <v-btn 
          color="info" 
          dark
          @click="markdone"
        >
          Mark as complete
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="12">

         <!-- video -->
          <video width="100%" height="100%" :key="`${path}${to_show.video_url}`" controls controlsList="nodownload">
            <source :src="`${path}${to_show.video_url}`" type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        
        <!-- pdf -->
        <div v-if="to_show.file_url != '' && to_show.file_url != null" class="mt-2" >
            <div v-for="(item, itemindex) in to_show.file_url" :key="itemindex">
               <div
                  v-if="item.split('.').pop() === 'xlsx' || item.split('.').pop() === 'docx'"
               >
                  <VueDocPreview :value="`${path}${item}`" type="office"></VueDocPreview>
               </div>
               <div
                  v-if="item.split('.').pop() === 'pdf'"
               >
                  <!-- <PdfVuer :src="`${path}${item}`"></PdfVuer> -->
                  <iframe :src="`${path}${item}`" width="100%" height="1000px"></iframe>
               </div>
            </div>
         </div>
        <!-- Hourly rate -->
        <!-- <v-card class="pa-2 mt-2" v-if="to_show.name == 'Hourly Rate Training Basic & Advanced'">
            <v-card-actions>
               <strong>Hourly Rate Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'hourly.rate.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         <!-- KPI's -->
        <!-- <v-card class="pa-2 mt-2" v-else-if="to_show.name == 'Week 34 - Key Performance Indicators'">
            <v-card-actions>
               <strong>KPI Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'kpi.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->

         <!-- ATTACHMENTS -->
        <div class="mt-2" v-else>
            <!-- ADDITIONAL VIDEO UPLOAD -->
            <v-card class="card" elevation="0" v-if="to_show.additional_files != null && to_show.additional_files != ''">
               <v-card-title> Additional Video Files </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in to_show.additional_files" :key="i">
                  <strong class="ml-2">{{param.name}}</strong>

                  <v-spacer></v-spacer>
                  <a id="download" :href="`${path}${param.file}`" download target="_blank"> Download </a>
               </v-card-actions>
            </v-card>
            <!-- END OF ADDITIONAL VIDEO UPLOAD -->

            <!-- FILE UPLOAD -->
            <v-card class="card" elevation="0" v-if="to_show.file_url != null && to_show.file_url != ''">
               <v-card-title> File Uploaded </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in to_show.file_url" :key="i">
                  <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png" alt="" style="cursor:default;" width="50" height="50" />
                  <a :href="`${path}${param}`" v-html="`${param}`" target="_blank"></a>
                  <v-spacer></v-spacer>
                  <!-- <v-btn 
                     color="primary"
                     class="mr-5"
                     @click="download(param)"
                  >
                     Download
                  </v-btn> -->
                  <a id="download" :href="`${path}${param}`" download target="_blank"> Download </a>
               </v-card-actions>
               <v-card-actions>
                  <small class="red--text">Click the download button, press right click on the photo and then save image.</small>
               </v-card-actions>
            </v-card>
            <!-- END OF FILE UPLOAD -->

            <!-- CONTENT -->
            <v-card elevation="0" class="card" v-if="to_show.contents != null && to_show.contents != ''">
               <v-card-text 
                  v-html="to_show.contents"></v-card-text>
            </v-card>
            <!-- END OF CONTENT -->
        </div>
      </v-col>
    </v-container>
</template>

<script>
import qs from 'qs';
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  props: [

  ],
  data () {
    return {
        urlparams: null,
        data_to_pass: {},
        to_show: {},
        path: null
    }
  },
   mounted () {
      this.replaceLink();
   },
  created () {
    this.urlparams = this.$route.query
    this.data_to_pass = qs.parse(this.urlparams.query)
    this.fetch_record(this.data_to_pass)
    this.path = process.env.VUE_APP_URL
  },
  computed: {
   ...mapGetters({
      getUserData: 'auth/getUserData'
   })
  },
  methods: {
    async fetch_record(data){
        const to_pass = {course_id: data.course_id, name: data.name}
        await this.$axios.get('client/fetch_lesson', to_pass)
        .then(({data}) => {
            this.to_show = data.data
        })
    },
  },
   replaceLink() {
      if(localStorage.getItem('token')) {
         if(this.getUserData.role_id == 5) {
            this.renderedText = this.queryStringContent.replace(/<a\s+([^>]*\s+)?href=(['"])(?:(?!\2).)*\2([^>]*)>/gi, '<a $1 $3 v-on:click.prevent>');
         } else {
            this.renderedText = this.queryStringContent;
         }
         return;
      } else {
         this.showSnackBar('Please login first!');
         this.$store.dispatch('auth/setCurrentRoute', this.$route.query);
         this.$router.push({ name: 'index' }).catch(e => console.error(e));
      }
   },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>