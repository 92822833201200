<template>
   <v-container fluid>
      <!-- Video title -->
      <v-col cols="12">
         <v-icon class="mr-3">{{urlparams.icon}}</v-icon><strong>{{urlparams.name}}</strong>
         <!-- <v-spacer></v-spacer>
         <v-btn 
         color="info" 
         dark
         @click="markdone"
         >
         Mark as complete
         </v-btn>
         <v-spacer></v-spacer> -->
      </v-col>
      <v-col cols="12">
         <!-- Video -->
         <div v-if="urlparams.video_url != null">
            <video :key="`${path}${urlparams.video_url}`" width="100%" height="100%" controls controlsList="nodownload">
               <source :src="`${path}${urlparams.video_url}`" type="video/mp4"/>
               Your browser does not support the video tag.
            </video>
         </div>

         <!-- Hourly rate -->
         <!-- <v-card class="pa-2 mt-2" v-if="urlparams.name == 'Hourly Rate Training Basic & Advanced'">
            <v-card-actions>
               <strong>Hourly Rate Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'hourly.rate.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         <!-- KPI's -->
         <!-- <v-card class="pa-2 mt-2" v-else-if="urlparams.name == 'Week 34 - Key Performance Indicators'">
            <v-card-actions>
               <strong>KPI Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'kpi.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         
         <div class="mt-2" v-else>
            <!-- ADDITIONAL VIDEO UPLOAD -->
            <v-card class="card" elevation="0"  v-if="urlparams.additional_files != null">
               <v-card-title> Additional Video Files </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in urlparams.additional_files" :key="i">
                  <strong class="ml-2">{{param.name}}</strong>
                  <v-spacer></v-spacer>
                  <a id="download" :href="`${path}${param.file}`" download target="_blank"> Download </a>
               </v-card-actions>
            </v-card>
            <!-- END OF ADDITIONAL VIDEO UPLOAD -->

            <!-- FILE UPLOAD -->
            <v-card class="card" elevation="0" v-if="urlparams.file_url != null">
               <v-card-title> File Uploaded </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in urlparams.file_url" :key="i">
                  <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png" alt="" style="cursor:default;" width="50" height="50" />
                  <a :href="`${path}${param}`" v-html="`${param}`" target="_blank"></a>

                  <v-spacer></v-spacer>
                  <!-- <v-btn 
                  color="primary"
                  class="mr-5"
                  @click="download(param)"
                  >
                  Download
                  </v-btn> -->
                  <a id="download" :href="`${path}${param}`" download target="_blank"> Download </a>
               </v-card-actions>

               <v-card-actions>
                  <small class="red--text">Click the download button, press right click on the photo and then save image.</small>
               </v-card-actions>
            </v-card>
            <!-- END OF FILE UPLOAD -->

            <!-- CONTENT -->
            <v-card elevation="0" class="card" v-if="urlparams.contents != null && urlparams.contents != 'null' && urlparams.contents != ''">
               <v-card-text
                  v-html="urlparams.contents"></v-card-text>
            </v-card>
            <!-- END OF CONTENT -->
         </div>
      </v-col>
   </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    urlparams: {},
    path: null,
    publicPath: process.env.VUE_APP_URL
  }),
  mounted () {
  },
  created () {
    this.urlparams = this.$route.query
    this.path = process.env.VUE_APP_URL
  },
  computed: {
   ...mapGetters({
      getUserData: 'auth/getUserData'
   })
  },
  methods: {
    async markdone(){
      await this.$axios.patch(`/client/markdonelesson/${this.urlparams.id}`)
      .then(({data}) => {
        this.$store.dispatch('clientstate/setLessonItems', data.data.original.lessons)
      })
    },
    // async downloadFile(url, url2) {
    //   await axios({
    //     url: `${url}${url2}`,
    //     method: 'GET',
    //     responseType: 'blob',

    //   }).then((res) => {
    //     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                            
    //                         var docUrl = document.createElement('x');
    //                         docUrl.href = FILE;
    //                         docUrl.setAttribute('download', 'file.pdf');
    //                         document.body.appendChild(docUrl);
    //                         docUrl.click();
    //   });
    // }
    // async download(str) {
    //   this.$axios.get('/courses/downloadFile?image=' + str, {responseType: 'blob'})
    //     .then(({data}) => {
    //       // const url = window.URL.createObjectURL(new Blob(data.data));
    //       // const link = document.createElement('a');
    //       // link.href = url;
    //       // link.setAttribute('download', str);
    //       // document.body.appendChild(link);
    //       // link.click();
    //       let blob = fetch(data).then(r => r.blob());
    //     });
    // },
  },
  watch: {
    '$route' (to){
      this.urlparams = to.query
    }
  }
}
</script>

<style scoped lang="scss">
  #download {
    color: #fff;
    background-color: #1976d2;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    text-decoration: none;
  }
  .card {
    border: 1px solid #abb1bc;
  }
</style>