<template>
    <v-container fluid>
        <v-card text>
            <v-card-title>
                Upload Video
                <v-spacer></v-spacer>
                <v-btn id="file_upload_btn" color="primary">
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    Upload
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="loading">
                <v-progress-linear
                    color="primary"
                    :buffer-value="progress_value"
                    stream>
                </v-progress-linear>
                <label small>
                    {{ file_name }} ( {{ progress_value.toFixed(0) }} % )
                </label>
            </v-card-text>
            <v-card-text v-if="video_url != null">
                <video ref="video_component" width="100%" height="100%" controls controlsList="nodownload">
                    <source :src="video_url" type="video/mp4" />
                </video>
            </v-card-text>
            <v-card-actions v-if="video_url != null">
                <v-spacer></v-spacer>
                <v-btn 
                    color="error"
                    @click="evtDeleteUploadedLessonVideo()">
                    Delete Video
                  </v-btn>
                  <v-btn class="ml-2" color="success"
                    @click="event_open_assignlessontouser_dialog()">
                    <v-icon>mdi-plus</v-icon>
                    Assign Lesson to User
                  </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import FileUploader from '@/v3/lib/ext/file_uploader/fileuploader.js'
import Lesson from '@/v3/lib/core/courses/lesson.js'

export default {
    data: () => ({
        api_url: process.env.VUE_APP_URL,
        file_uploader: null,
        video_url: null,
        loading: false,
        progress_value: 0,
        file_name: null,
        lesson_class: null
    }),
    props: {
        lesson: Object
    },
    methods: {
        async evtDeleteUploadedLessonVideo() {
            const lesson = await this.lesson_class.deleteVideoFromLesson(this.lesson.id).then( (data) => data)
            if ( lesson.response == true ) {
                this.video_url = null
            }
        },
        event_open_assignlessontouser_dialog() {
            this.$emit('event_open_assignlessontouser_dialog')
        }
    },
    async mounted() {
        this.lesson_class = new Lesson()

        this.file_uploader = new FileUploader(`${this.api_url}admin/upload_files`, this.lesson.id)
        
        const file = await this.file_uploader.getUploadedFile(this.lesson.id).then( (data) => data)
        if ( file.response == true ) {
            this.video_url = `${this.api_url}/${file.data}`
        }

        const file_upload_btn = window.document.getElementById('file_upload_btn')
        const resumble = this.file_uploader.initResumbleForFileUpload(file_upload_btn)

        /* Events */
        resumble.on('fileAdded', (file) => {
            this.loading = true
            this.file_name = file.fileName
            this.file_size = file.size
            resumble.upload()
        })

        resumble.on('fileProgress', (file) => {
            this.progress_value = file.progress() * 100
        })

        resumble.on('fileSuccess', (file) => {
            resumble.removeFile(file)
        })

        resumble.on('fileError', (file, event) => {
            console.log(file, event)
            resumble.removeFile(file)
        })

        resumble.on('complete', async () => {
            // Pull the data from the server
            this.loading = false
            const file = await this.file_uploader.getUploadedFile(this.lesson.id).then( (data) => data)
            if ( file.response == true ) {
                this.video_url = `${this.api_url}${file.data}`
            }
        })
    },
    watch: {
        async lesson() {
            const file = await this.file_uploader.getUploadedFile(this.lesson.id).then( (data) => data)
            if ( file.response == true ) {
                this.video_url = `${this.api_url}${file.data}`
                this.$refs.video_component.load()
            }
        },
        video_url() {
            this.$refs.video_component.load()
        }
    }
}

</script>