<template>
    <v-dialog
        :value="value"
        persistent
        max-width="25%"
    >
        <v-card>
            <v-card-title>
                <strong>Are you sure you want to delete this account?</strong>
                <v-spacer/>
                <v-icon
                    @click="close_dialog"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text>
                Once you delete this account it can be retrieved, just message us (PT Dev Team)
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    color="red"
                    class="pl-10 pr-10 white--text"
                    @click="delete_account"
                >
                    <v-icon class="mr-3" small>mdi-delete-outline</v-icon>Delete 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  components: {

  },
  props: [
    'value',
    'data'
  ],
  data () {
    return {
        e: {
            password: null
        }
    }
  },
  mounted () {

  },
  created () {

  },
  computed: {

  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    async delete_account(){
        await this.$axios.delete(`/internal/users/delete_account/${this.data.id}`)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('adminclientdboard/set_internal')
                this.close_dialog()
            }
        })
    }
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>