<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
            >
                <v-text-field
                    label="search internal users"
                    prepend-icon="mdi-magnify"
                    v-model="search_user"
                >
                </v-text-field>
            </v-col>
            <v-col
                cols="4"
            ></v-col>
            <v-col
                cols="4"
            ></v-col>
            <v-col
                cols="4"
            >
                <v-row
                    class="float-right"
                >
                    <v-btn
                        color="primary"
                        style="textTransform: none !important;"
                        class="pl-10 pr-10"
                        @click="add_internal = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add Internal Users
                    </v-btn>
                    <v-switch
                        v-model="show_archived"
                        :label="`show archived admin`"
                        class="ml-5"
                        style="position: relative; bottom: 15px;"
                    ></v-switch>
                </v-row>
            </v-col>
            <v-col
                cols="12"
            >
                <v-data-table
                    :headers="headers"
                    :items="show_archived ? get_archived_admin : get_internal"
                    class="elevation-1"
                >
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item['name'] }}</td>
                            <td>{{ props.item['email'] }}</td>
                            <td>{{ props.item['created_at'] }}</td>
                            <td>
                                <v-row>
                                    <v-btn
                                        icon
                                        @click="edit_internal_user(props.item)"
                                    >
                                        <v-icon small>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="!show_archived"
                                        icon
                                        @click="warn_user(props.item)"
                                    >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        icon
                                        @click="warn_restore_user(props.item)"
                                    >
                                        <v-icon small>mdi-delete-restore</v-icon>
                                    </v-btn>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <InternalUserDialog
            :value="add_internal"
            @close_dialog="close_dialog"
        />
        <InternalEdit
            :value="edit_internal"
            :data="edit_user"
            @close_dialog="close_dialog"
        />
        <DeleteAdmin
            :value="to_delete_dialog"
            :data="to_delete"
            @close_dialog="close_dialog"
        />
        <RestoreDialog
            :value="to_restore_dialog"
            :data="to_restore_user"
            @close_dialog="close_dialog"
        />
    </v-container>
</template>

<script>
import InternalUserDialog from './inner/InternalUserDialog.vue'
import InternalEdit from './inner/EditInternalDialog.vue'
import DeleteAdmin from './inner/DeleteAdmin.vue'
import RestoreDialog from './inner/RestoreDialog.vue'


export default {
  components: {
    InternalUserDialog,
    InternalEdit,
    DeleteAdmin,
    RestoreDialog
  },
  props: [

  ],
  data () {
    return {
        search_user: null,
        headers: [
            {
                text: 'Name'
            },
            {
                text: 'Email'
            },
            {
                text: 'Created At'
            },
            {
                text: 'Action(s)'
            }
        ],
        add_internal: false,
        edit_internal: false,
        edit_user: {},
        to_delete_dialog: false,
        to_delete: {},
        show_archived: false,
        to_restore_user: {},
        to_restore_dialog: false
    }
  },
  async mounted () {
    await this.$store.dispatch('adminclientdboard/set_internal')
  },
  created () {

  },
  computed: {
    get_internal(){
        return this.$store.getters['adminclientdboard/get_internal'](this.search_user)
    },
    get_archived_admin(){
        return this.$store.getters['adminclientdboard/get_archived_admin']
    }
  },
  methods: {
    close_dialog(){
        this.add_internal = false
        this.edit_internal = false
        this.edit_user = {}
        this.to_delete_dialog = false
        this.to_restore_dialog = false
        this.to_restore_user = {}
    },
    edit_internal_user(data){
        this.edit_user = data
        this.$store.dispatch('internal/setEditData', data);
        this.edit_internal = true
    },
    warn_user(data){
        this.to_delete_dialog = true
        this.to_delete = data
    },
    warn_restore_user(data){
        this.to_restore_dialog = true
        this.to_restore_user = data
    }
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>