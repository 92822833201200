<template>
  <v-container fluid>
    <TheLoader 
      :visible="loadComponent"
    />
    <!-- <v-col
      cols="4"
      class="mt-2"
    >
      <CourseSelection 
        :items="getCourseList"
        @scourse="scourse"
      />
    </v-col> -->
    <v-col
      cols="12"
      v-if="!loadComponent">
        <v-row>
          <v-col
            cols="12"
            class="mt-5">
            <RoadmapBoard
              :roadmaps="getRoadmapClient"
              :staff="getStaff"
              :user="JSON.parse(getUser)"
              @closedialog="closedialog"
              @openmodal="openmodal"
              @tickcheckroadmap="tickcheckroadmap"
              @tickStaff="tickStaff"
              @submitPercentage="submitPercentage"
            ></RoadmapBoard>
            <DialogVue 
              v-model="dialogtrelloroadmap"
              :value="dialogtrelloroadmap"
              :info="getSelectedRoadmap"
              :dialogsvalue="dialogtrello"
              :comments="selectedcomments"
              :tasks="getTask"
              @savecolor="savecolordata"
              @closedialog="closedialog"
              @submitWithInfo="submitDeadline"
              @submitComment="submit"
              @submitPercentage="submitPercentage"
              @submitLink="submitLink"
              @removeLink="removeLink"
              @submitDelete="submitDelete"
              @submitEdit="submitEdit"
              @submitEditRoadmap="submitEditRoadmap"
              @submitTask="submitTask"
              @toggleTask="toggleTask"
              @deleteTask="deleteTask"
              @taggedPersonnel="successTagPersonnel"
              @removeAssignedUser="removeAssignedUser"
            />
          </v-col>
        </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogVue from '@/components/DiaLog.vue'
import TheLoader from '@/components/TheLoader.vue'

/**
 * New Roadmap Board Design
 */
import RoadmapBoard from '@/modules/admin/components/inner/RoadmapBoard.vue'

export default {
  components: {   
    DialogVue,
    RoadmapBoard,
    TheLoader    
  },
  props: {
    course_id: Number
  },
  data: () => ({
    urlparams: {},
    tab: null,
    selected: null,
    items: [
      'Course', 
      'Roadmap',
    ],
    setup: {
      adminclientdboard: true
    },
    islist: {
      preview: false,
      islist: true
    },
    toshow: {
      frompreview: true,
      hidden: false
    },
    headers: [
      {
        text: 'Billable Staff | Pipelining Staff',
        align: 'center',
        sortable: false,
        class: "indigo darken-1 white--text"
      },
      { text: 'Mindset', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'Leadership', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'Marketing', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'Sales', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'Team', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'SOP | Systems', align: 'center', class: "indigo darken-1 white--text" },
      { text: 'Cash', align: 'center', class: "indigo darken-1 white--text" },
    ],
    headerstodo: [
      { text: 'To-Do', class: "indigo darken-1 white--text" },
      { text: 'Complete', class: 'indigo darken-1 white--text' },
      { text: 'Deadline Date', class: 'indigo darken-1 white--text' },
    ],
    headerthird: [
      { text: 'To-Do', class: "indigo darken-1 white--text" },
      { text: 'Assigned To', class: 'indigo darken-1 white--text' },
      { text: 'Video Link', class: 'indigo darken-1 white--text' },
      { text: 'WK No. Assigned', class: 'indigo darken-1 white--text' },
      { text: 'Close Date', class: 'indigo darken-1 white--text' },
      { text: 'Status', class: 'indigo darken-1 white--text' },
      { text: '% Complete', class: 'indigo darken-1 white--text' },
    ],
    dialogcolorroadmap: false,
    showdialog: false,
    showdialogchild: false,
    dialogcolor: {
      isTable: false,
      color: true,
      title: 'Add Priority',
      colors: [
        {
          name: 'Priority 1',
          color: '#A76571'
        },
        {
          name: 'Priority 2',
          color: '#BD8C94'
        },
        {
          name: 'Priority 3',
          color: '#D3B2B8'
        }
      ]
    },
    dialogsvalue: {
      title: 'Add Section',
      text_field: [
        {
          id:         0,
          label:      'Section Name',
          hint:       'Section Name',
          type:       'text'
        },
      ],
      checkbox: [
        {
          id:           1,
          checked:      false,
          hasselection: false,
          label:        'Has Checkbox?'
        }
      ]
    },
    dialogsvaluechild: {
      title: 'Add Child',
      text_field: [
        {
          id:         0,
          label:      'To-Do Name',
          hint:       'To-Do Name',
          type:       'text'
        },
      ]
    },
    dialogfirst: {
      title: 'Add Talking Points',
      text_field: [
        {
          id:         0,
          label:      'To-Do Name',
          hint:       'To-Do Name',
          type:       'text'
        },
      ]
    },
    dialogsecond: {
      title: 'Add 3 Things',
      text_field: [
        {
          id:         0,
          label:      'To-Do',
          hint:       'To-Do',
          type:       'text'
        }
      ],
      hasdatepicker: true
    },
    dialogthird: {
      title: 'Add Pebbles',
      text_field: [
        {
          id:         0,
          label:      'To-Do',
          hint:       'To-Do',
          type:       'text'
        },
        {
          id:         1,
          label:      'Assign To',
          hint:       'Assign To',
          type:       'text'
        },
        {
          id:         2,
          label:      'Week No.',
          hint:       'Week No.',
          type:       'text' 
        },
        {
          id:         3,
          label:      'Status',
          hint:       'Status',
          type:       'text'
        }
      ],
      hasdatepicker: true,
      hasvideolink: true
    },
    which: {},
    dialogtrelloroadmap: false,
    dialogtrello: {
      trello: true,
      items: [
        {
          id: 0,
          title: 'Priority',
          items: [
            {
              id: 0,
              label: 'Set Priority',
              type: 'text'
            }
          ],
          icon: 'mdi-priority-high'
        },
        {
          id: 1,
          title: 'Deadline Date',
          icon: 'mdi-update',
          items: [
            {
              id: 1,
              label: 'Deadline Date',
              type: 'text'
            }
          ]
        }
      ],
      colors: [
        {
          name: 'Priority 1',
          color: '#A76571'
        },
        {
          name: 'Priority 2',
          color: '#BD8C94'
        },
        {
          name: 'Priority 3',
          color: '#D3B2B8'
        }
      ],
      progress: [
        {
          name: '-',
          value: null
        },
        {
          name: '10%',
          value: 10
        },
        {
          name: '20%',
          value: 20
        },
        {
          name: '30%',
          value: 30
        },
        {
          name: '40%',
          value: 40
        },
        {
          name: '50%',
          value: 50
        },
        {
          name: '60%',
          value: 60
        },
        {
          name: '70%',
          value: 70
        },
        {
          name: '80%',
          value: 80
        },
        {
          name: '90%',
          value: 90
        },
        {
          name: '100%',
          value: 100
        }
      ]
    },
    isSelected: null,
    selectedcomments: null,
    courseitem: null,
    selectedtasks: null,
    search_video_tags: false,
    loadComponent: true
  }),
  async mounted () {
    //this.fetchroadmap();
    // this.fetchClientTodoList();
    // this.flessonsforclient();
    this.scourse(3)
    let userData = JSON.parse(this.getUser);
    if(userData.role_id == 1) {
      this.getAllInternalUsers();
    }
    // this will reset the state of snackbar, to stop pop up whether the page reload
    this.$store.commit("auth/setMessage", {show: false, message: ''}, {root: 1} );

    // read course id first then load component
    setTimeout(() => {
      this.loadComponent = false;
    }, 1000);
    
  },
  async created () {
    this.urlparams = this.$route.query
    // this.weeklyRecap();
    await this.fcourses();    
  },
  computed: {
    ...mapGetters({
      getCourseList:            'adminclientdboard/getCourseList',
      getCourse:                'adminclientdboard/getCourse',
      getRoadmapClient:         'adminclientdboard/getRoadmapClient',
      getColor:			        'clientstate/getColor',
      getTodoClient:            'adminclientdboard/getTodoClient',
      getTodoParent:            'clientstate/getTodoParent',
      getVideoList:             'adminclientdboard/getVideoList',
      getLoader:                'auth/getLoader',
      getUser:                  'auth/getUserData',
      getStaff:                 'adminclientdboard/getStaff',
      getTask:                  'adminclientdboard/getTask',
      getSelectedRoadmap:       'clientstate/getSelectedRoadmap',
      getUserData:              'auth/getSelectedUser',
    }),

    user() {
      return JSON.parse(this.getUser);
    },
    selectedUser() {
      return JSON.parse(this.getUserData);
    }
  },
  methods: {
   ...mapActions({
      getAllInternalUsers : 'adminmain/getListOfInternalUsers',
   }),
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    search_video_tags_fn(){
      this.search_video_tags = true
    },
    close_dialog(){
      this.search_video_tags = false
    },
    async fcourses(){
      await this.$axios.get(`/adminclientdboard/ucourselist/${this.urlparams.id}`)
      .then(({data}) => {
        this.$store.dispatch('adminclientdboard/setCourseList', data.data)
      })
    },
    async scourse(id){
      /*this.$axios.get(`/adminclientdboard/gcourse/${id}/${this.urlparams.id}`)
      .then( async ({data}) => {
        if(data.response){*/
          await this.fetchroadmap(this.urlparams.id);
          await this.fetchStaff(id);
          //this.$store.dispatch('adminclientdboard/setCourse', data.data);
          /*let for_search_data = []
          data.data.map(q => {
            q.get_child.map(qq => {
              for_search_data.push(qq)
            })
          })*/
          //this.$store.dispatch('clientstate/set_issues_admin', `${this.urlparams.id}`);
          //this.$store.dispatch('adminclientdboard/videos_tags_for_search', for_search_data)
          /*this.courseitem = data.data;
        }
      })*/
    },
    async flessonsforclient(){
      await this.$axios.get(`/adminclientdboard/getvideos/${this.urlparams.id}`)
      .then(({data}) => {
        this.$store.dispatch('adminclientdboard/setVideoList', data.data)
      })
    },
    async fetchroadmap(){
      await this.$axios.get(`/roadmap/g_dashboard_roadmap/${this.urlparams.id}`)
      .then(({data}) => {
        let result = [];
        for(let item of Object.keys(data.data)) {
          result.push(data.data[item]);
        }
        this.$store.dispatch('adminclientdboard/setRoadmapClient', result);
      })
    },
    async fetchStaff(data) {
      await this.$axios.get(`/adminclientdboard/getStaff/${data}/${this.urlparams.id}`)
        .then(({data}) => {
          this.$store.dispatch('adminclientdboard/setStaff', data.data);
        })
    },
    async tickcheckroadmap(obj){
      let tp = {
        id:		obj.id,
        ischecked: obj.ischecked
      }
      await this.$axios.patch(`/client/markcheckbox/${tp.id}/${tp.ischecked}`)
      .then(({data}) => {
        if(data.response){
          this.fetchroadmap(obj.course_id);
        }
      })
    },
    async tickStaff(obj) {
      let tp = {
        id: obj.id,
        ischecked: obj.ischecked
      }
      await this.$axios.patch(`client/markStaff/${tp.id}/${tp.ischecked}`)
        .then(({data}) => {
          if(data.response) {
            this.fetchroadmap(obj.course_id);
            this.fetchStaff(obj.course_id);
          }
        }).catch(err => err);
      
    },
    editpriorityroadmap(){
      this.dialogcolorroadmap = true
    },
    addsectiontodo(){
      this.showdialog = true
    },
    addchildlist(item){
      this.which = item
      this.showdialogchild = true
    },
    closedialog(){
      this.showdialog = false
      this.showdialogchild = false
      this.dialogcolorroadmap = false
      this.videodialog = false
      this.wysiwygdialog = false
      this.dialogtrelloroadmap = false
      this.dialoguser = false
    },
    async savecolordata(obj){
      let tp = {
        color: obj.input,
        course_id: obj.item.course_id,
        item_id: obj.item.id
      }
      await this.$axios.post('/client/addpriorityroadmap', tp)
      .then(({data}) => {
        if(data.response){
          // this.closedialog()
          this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
          this.fetchroadmap(obj.item.course_id)
        }
      })
    },
    async fetchClientTodoList(){
      let payload = {
        user_id: this.urlparams.id
      };
      await this.$axios.get(`/todoadmin/fetchclienttodoList`, payload)
      .then(({data}) => {
        //this.todo_data = data.result;
        this.$store.dispatch('adminclientdboard/setTodoClient', data.result)
      });
    },
    async savedata(data){
      let tp = {
        name:         data.input.textfield[0],
        hascheckbox:  data.input.checkbox[1] == undefined ? 0 : 1,
        user_id:      this.urlparams.id
      }
      await this.$axios.post('/todoadmin/addparent', tp)
      .then(({data}) => {
        if(data.response){
          this.fetchClientTodoList()
          this.closedialog()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    async savedatachild(data){
      //let tp = {
      //  name:         data.input.textfield[0],
      //  parent_id:    this.getTodoParent.id,
      //  hascheckbox:  this.getTodoParent.hascheckbox
      //}
      let tp = {
        name:           data.input.textfield[0],
        parent_id:      this.getTodoParent.id,
        hascheckbox:    this.getTodoParent.hascheckbox,
        deadline_date:  data.input.date,
        assign_to:      data.input.textfield[1],
        week_no:        data.input.textfield[2],
        status:         data.input.textfield[3],
        lesson_id:      data.input.videolesson.id
      }
      await this.$axios.post('/todoclient/addchild', tp)
      .then(({data}) => {
        if(data.response){
          this.fetchClientTodoList()
          this.closedialog()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    async openmodal(obj) {
      this.isSelected = obj;
      this.$store.dispatch('clientstate/setSelectedRoadmap', obj);
      this.dialogtrelloroadmap = true;      

      const tp = {
        course_id: obj.course_id,
        roadmap_id: obj.id,
        name: obj.name,
        user_id: obj.user_id
      }
      await this.modalsetup(tp);
    },
    async modalsetup(tp) {
      await this.getComments(tp);
      await this.getTasks(tp);    
    },
    submitDeadline(obj) {
      this.$axios.post('client/setDeadline', obj)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.roadmap);
            this.fetchroadmap(obj.data.course_id)          
          }
        })
    },
    async submit(item) {
      const tp = {
        comments: item.item,
        course_id: item.data.course_id,
        roadmap_id: item.data.id,
        user_id: this.user.id,
        user_role_id: this.user.role_id,
        name: item.data.name,
        ref_id: item.parent.id
      }

      await this.$axios.post('/client/addComment', tp)
        .then(({data}) => {
          if(data.response) {
            this.closedialog();
            this.fetchroadmap(item.data.course_id);
            // this.$axios.post('emails/notifyUsers', tp)
            //   .then(({data}) => {
            //     if(data.response) {
            //       this.showSnackBar(data.message);
            //     }
            //   }).catch(err => err);
          }
        }).catch(err => err);
    },
    async getComments(obj) {
      await this.$axios.get(`client/getAdminComments`, obj)
        .then(({data}) => {
          this.selectedcomments = data.data;
          this.$store.dispatch('clientstate/setComments', data.data);
        });
    },
    async submitPercentage(obj) {
      await this.$axios.post('client/setProgress', obj)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
            // this.closedialog();
            this.fetchroadmap(obj.data.course_id);
          }
        }).catch(e => console.error(e));
    },

    async submitLink(obj) {
      await this.$axios.post('client/setVideo', obj)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
            this.fetchroadmap(obj.data.course_id);
            this.showSnackBar(data.message);
          }
        }).catch(err => err);
    },
    async removeLink(obj) {
      await this.$axios.post('client/removeVideo', obj)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
            this.fetchroadmap(obj.data.course_id);
            this.showSnackBar(data.message);
          }
        })
    },
    async submitDelete(obj) {
      await this.$axios.delete(`client/deleteComment/${obj.id}`)
        .then(({data}) => {
          if(data.response) {
            this.showSnackBar(data.message);
            this.closedialog();
            this.fetchroadmap(obj.data.course_id);
          }
        }).catch(err => err);
    },
    async submitEdit(obj) {
      await this.$axios.patch('client/editComment', obj)
        .then(({data}) => {
          if(data.response) {
            this.showSnackBar(data.message);
            this.closedialog();
            this.fetchroadmap(obj.data.course_id);
          }
        }).catch(err => err);
    },
    // submitAdminComment(item) {
    //   const tp = {
    //     comments: item.item,
    //     course_id: item.data.course_id,
    //     roadmap_id: item.data.id,
    //     user_id: this.user.id,
    //     user_role_id: this.user.role_id,
    //     name: item.data.name
    //   }

    //   console.log('tp =>', tp);
    // }
    // async weeklyRecap() {
    //   await this.$axios.get('emails/weeklyRecap')
    //     .then(({data}) => {
    //       console.log(data);
    //     })
    // },
    async submitEditRoadmap(obj) {
      await this.$axios.post('adminclientdboard/editRoadmap', obj)
        .then(({data}) => {
            if(data.response) {
              this.showSnackBar(data.message);
              this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
              this.fetchroadmap(obj.course_id);
            } else {
              this.showSnackBar(`An error has occured, please contact the developer. Error: ${data.message}`)
            }
        }).catch(err => this.showSnackBar(`An error has occured, please contact the developer. Error: ${err}`));
    },
    async submitTask(obj) {
      await this.$axios.post('client/submitTask', obj)
        .then(({data}) => {
          this.$store.dispatch('adminclientdboard/setTask', data.data);
        }).catch(err => console.error(err));
    },
    async getTasks(obj) {
      await this.$axios.get(`client/getTask`, obj)
        .then(({data}) => {
          this.$store.dispatch('adminclientdboard/setTask', data.data);
        });
    },
    async toggleTask(obj) {
      await this.$axios.post('client/toggleTask', obj)
        .then(({data}) => {
          this.$store.dispatch('adminclientdboard/setTask', data.data);
        }).catch(err => console.error(err));
    },
    async deleteTask(obj) {
      await this.$axios.patch('client/deleteTask', obj)
        .then(({data}) => {
          this.$store.dispatch('adminclientdboard/setTask', data.data);
        }).catch(err => console.error(err));
    },

    //  after tagging personnel, fetch the roadmap data so the list of roadmap in vuex will refresh
   successTagPersonnel(payload) {
      console.log('*', payload);
      this.fetchroadmap(payload.course_id);
      this.$axios.get(`roadmap/g_selected_roadmap/${payload.roadmap_id}`)
      .then(({data}) => {
        this.showSnackBar(`Succefully tagged ${payload.internalUser} to this Roadmap`);
        this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
      }).catch(e => this.showSnackbar(e.message));
   },
   async removeAssignedUser(payload) {
      await this.$axios.patch(`roadmap/r_assigned_user/${payload.id}`)
          .then(({data}) => {
              if(data.response) {
                  this.showSnackBar(data.message);
                  this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                  this.fetchroadmap(payload.course_id);
              }
          })
  },

  },
  watch: {
    
  }
}
</script>

<style scoped lang="scss">
</style>