<template>
  <div>
    <v-card
      elevation="0"
    >
      <v-card-title> 
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text black float-right"
          @click="addClient"
        >
          <v-icon>mdi-plus</v-icon> Add Client
        </v-btn>
        <v-switch
          v-model="show_archived"
          :label="`show archived users`"
          class="ml-5"
        ></v-switch>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="show_archived ? get_clientlist_deleted : items"
        :search="search"
        :hide-default-footer="true"
        :loading="loadingTable"
        disable-pagination
      >
        <!-- header membership type modification -->
        <template v-slot:[`header.membership_type`]="{ header }">
          {{ header.text }}
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-1"
                style="cursor: pointer;"
                v-bind="attrs"
                v-on="on"
              >
                mdi-filter-variant
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, itemindex) in get_membership_type"
                :key="itemindex"
              >
                <v-list-item-title
                  style="cursor: pointer;"
                  @click="search_membership(item.membership_type); search_deleted_member(item.membership_type)"
                >
                  <v-icon>mdi-square-small</v-icon>
                  {{ item.membership_type }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <!-- end header -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_login="{ item }">
          <span
            @click="getTimeSpent(item.id, item.firstname)"
            id="last_login"      
          >
            {{ item.last_login_date ? item.last_login_date : '-' }}
          </span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <div id="chips-container">
            <v-chip
              id="chip"
              :class="`${getStatus(item)}`"
              :style="getStatus(item) === 'Engaging' ? 'color: green' : 'color: red'"
            >
              <v-icon
                small
                :class="`${item.status}`"
              >
                {{ `${ getStatus(item) == 'Engaging' ? 'mdi-check' : 'mdi-close' }` }}
              </v-icon>
            </v-chip>

          </div>
       
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.priorities="{ item }">
          {{ item.roadmap_prio_count }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.implemented_count="{ item }">
          {{ item.implemented_count }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.membership_type="{ item }">
          <v-chip>
            {{ item.membership_type || 'Not Set' }}
          </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <!-- <template v-slot:item.billable="{ item }">
          <v-chip
          >
            {{ getStaffCount(item.staffs) }}
          </v-chip>
        </template> -->
         <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-menu
            :offset-y="true"
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-icon
                v-show="!show_archived"
                small
                style="cursor: pointer;"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list
              dense
            >
            <v-list-item
                dense
                @click="OpenEmailForm(item)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-email
                </v-icon>
                Email
              </v-list-item>
              <v-list-item dense @click="userRoadmap(item)">
                <v-icon small class="mr-2">mdi-tab</v-icon>Roadmap
              </v-list-item>
              <v-list-item dense @click="gotoRoadmapV2(item)">
                <v-icon small class="mr-2">mdi-tab</v-icon>Roadmap V2
              </v-list-item>
              <v-list-item
                dense
                @click="editUser(item)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-pencil
                </v-icon>
                Edit
              </v-list-item>
              <v-list-item
                dense
                @click="deleteUser(item)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-delete
                </v-icon>

                Remove
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon
            v-if="show_archived"
            small
            style="cursor: pointer;"
            @click="restore_user(item)"
          >
            mdi-restore
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  <v-dialog
      v-model="show_restore_dialog"
      persistent
      max-width="25%"
    >
      <v-card>
        <v-card-title>
            <strong>You are about to restore this account</strong>
            <v-spacer/>
            <v-icon
                @click="show_restore_dialog = false"
            >
                mdi-close
            </v-icon>
        </v-card-title>
        <v-card-text>
            If you encountered an error, just message us (PT Dev Team)
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                class="pl-10 pr-10"
                block
                @click="restore_data_fn"
            >
                <v-icon class="mr-3" small>mdi-delete-restore</v-icon>Restore 
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// import dayjs from 'dayjs';

export default {
  components: {

  },
  props: [
    'headers',
    'items',
    'loadingTable'
  ],
  data: () => ({
    search: '',
    show_archived: false,
    show_restore_dialog: false,
    restore_data: {},
    icons: {
      success: ['mdi-check'],
      failed: ['mdi-close']
    },
    item_status: {
      success: ''
    },
    searcharr: null,
    time_spent: null
  }),
  mounted() {
  },
  computed: {
    get_clientlist_deleted(){
      return this.$store.getters['adminmain/get_clientlist_deleted'](this.searcharr)
    },
    get_membership_type(){
      return this.$store.getters['adminmain/get_membership_type']
    }
  },
  methods: {
    gotoRoadmapV2(item) {
        this.$router.push(`/admin/client/course/${item.id}`)
    },
    sendGhlAll() {
      this.$emit('sendGhlAll', true);
    },
    addClient() {
      this.$emit('addClient', true);
    },
    userRoadmap(obj) {
      this.$emit('userRoadmap', obj);
    },
    editUser(obj) {
      this.$emit('editUser', obj);
    },
    deleteUser(obj) {
      this.$emit('deleteUser', obj);
    },
    getTimeSpent(id, name) {
      const topass = {
        id: id,
        name: name
      };
      this.$emit('getTimeSpent', topass);
    },
    OpenEmailForm(obj){
      this.$emit('OpenEmailForm', obj);
    },
    restore_user(data){
      this.restore_data = data
      this.show_restore_dialog = true
    },
    async restore_data_fn(){
      await this.$axios.patch(`internal/users/restore_admin/${this.restore_data.id}`)
        .then(({data}) => {
            if(data.response){
              this.show_restore_dialog = false
              this.$store.dispatch('adminmain/get_users')
            }
        })
    },
    search_membership(item){
      this.$emit('search_membership', item)
    },
    search_deleted_member(item){
      this.searcharr = item
    },
    getCount(item) {
      return item.length;
    },
    getStaffCount(item) {
      if(item) {
        // let staff = item.staff_count.reduce((a, b) => {
        //   return a + b.people;
        // }, 0)
        let staff = Math.max(...item.staff_count.map(a => a.people));

        if(typeof staff == 'number' && isFinite(staff)) {
          return staff;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getStatus(item) {
      const day_status = dayjs().subtract(14, 'day');

      const user_date = item.last_login_date == null ? item.created_at : item.last_login_date;

      const user_last_login_date = dayjs(user_date);

      const check = user_last_login_date.diff(day_status, 'day');

      if(check < 0 || check > 14) {
        return 'Not-Engaging';
      } else {
        return 'Engaging';
      }
    }
  }
}
</script>

<style>
#chips-container .v-chip.Engaging, .v-icon.Engaging{
  background: #d7f7c2;
  color: #467e4a;
}

#chips-container .v-chip.Not-Engaging, .v-icon.Not-Engaging{
  background: #ffe7f1;
  color: #c4214f;
}

#last_login {
  cursor: pointer;
}

</style>