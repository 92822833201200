<template>
    <v-dialog
        v-model="search_video_tags_model"
        persistent
        fullscreen
        scrollable>
        <v-card>
            <v-card-title>
                <small>Client Video / Tags</small>
                <v-spacer/>
                <v-icon @click="close_dialog()">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text style="height: 70%;">
                <v-tabs
                    v-model="tab"
                    color="basil"
                    grow>
                    <v-tab
                        href="#tab-1">
                        Videos
                    </v-tab>
                </v-tabs>
                <v-tabs-items
                    v-model="tab">
                    <v-tab-item
                        value="tab-1">
                        <v-text-field
                            prepend-inner-icon="mdi-magnify"
                            label="search video / tags"
                            v-model="search"
                            append-icon="mdi-close"
                            filled
                            dense
                            @click:append="search = null">
                        </v-text-field>
                        <v-data-iterator
                            :items="get_search_video_tags"
                            :search="search">
                            <template v-slot:default="props">
                                <v-row>
                                    <v-col
                                        v-for="item in props.items"
                                        :key="item.id"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        lg="3">
                                        <v-card flat @click="proceedLink(item)">
                                            <v-card-text>
                                                <video v-if="item.video_url != null" :key="`${path}${item.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload" class="mb-3">
                                                        <source :src="`${path}${item.video_url}`" type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                </video>
                                                <v-img v-else
                                                    src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                                    contain
                                                    width="250"
                                                    height="175"></v-img>
                                                <div class="mt-3">
                                                    <p class="black--text">{{ item.name }}</p>
                                                    <v-divider></v-divider>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-iterator>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import qs from 'qs';
import { mapGetters } from 'vuex';

export default {
    components: {

    },
    props: [
        'show_search_video_tags_dialog'
    ],
    data () {
        return {
            search: null,
            path: null,
            tab: null,
            search_video_tags_model: false,
        }
    },
    mounted () {
    },
    created () {
        this.path = process.env.VUE_APP_URL
    },
    computed: {
        ...mapGetters({
            get_search_video_tags: 'adminclientdboard/get_all_search_videos_tags'
        })
    },
    methods: {
        close_dialog(){
            this.$emit('close_dialog')
        },
        proceedLink(item) {
            const query = qs.stringify(item);
            const route = this.$router.resolve({ name: 'clientcurrentlesson', query: { query } });
            window.open(route.href, '_blank');
        },
    },
    watch: {
        show_search_video_tags_dialog() {
            this.search_video_tags_model = this.show_search_video_tags_dialog
        }
    }
}
</script>

<style scoped lang="scss">
  #cursor {
    cursor: pointer;
  }
</style>