<template>
    <v-container fill-height fluid>
      <v-card
        v-if="params.parent"
        class="flex pa-2"
      >
        <v-card-title>
          Edit: {{params.name}}
          <v-spacer></v-spacer>
          <v-btn 
            outlined
            class="mr-2"
            @click="discardchanges()"
          >
            DISCARD CHANGES
          </v-btn>
          <v-btn 
            color="black" 
            dark
            class="mr-2"
            @click="savechanges(params)"
          >
            SAVE
          </v-btn>
          <v-icon @click="deleteicon(params.id)">mdi-delete-outline</v-icon>
        </v-card-title>
        <v-card-text>
          <v-sheet
            color="#D3D3D3"
            class="pa-5"
          >
            <small>Chapter title</small>
            <v-text-field
              dense
              :label="params.name"
              v-model="name"
              outlined
            >
            </v-text-field>
          </v-sheet>
        </v-card-text>
      </v-card>
      
      <!-- current tab -->
      <v-card
        class="flex"
        v-else
      >
        <v-card-title>
          <small>{{lesson.name}}</small>
          <v-spacer></v-spacer>
          <v-btn 
            outlined
            class="mr-2"
            @click="showUpdateLessonChapterDialog()"
          >
            UPDATE CHAPTER
          </v-btn>
          <v-btn 
            outlined
            class="mr-2"
            @click="discardchanges()"
          >
            DISCARD CHANGES
          </v-btn>
          <v-btn 
            color="black" 
            dark
            class="mr-2"
            @click="savechild(lesson)"
          >
              SAVE
          </v-btn>
          <v-icon
            class="mx-1"
            v-if="!child.is_archived"
            @click="archiveLesson(lesson)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-else
            class="mx-1"
            @click="archiveLesson(lesson)"
          >
            mdi-eye-off
          </v-icon>
          <v-icon 
            @click="deleteicon(lesson.id)"
          >
            mdi-delete-outline
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-sheet
            color="#D3D3D3"
            class="pa-5"
          >
            <small>Chapter title</small>
            <v-text-field
              dense
              :label="lesson.name"
              v-model="name"
              outlined
            ></v-text-field>
            <small>Upload File</small>
            <!--<v-file-input
              @change="extfile()"
              truncate-length="15"
              v-model="url"
            ></v-file-input>-->
            <FileUploader
                :lesson="lesson"
                @event_open_assignlessontouser_dialog="event_open_assignlessontouser_dialog"></FileUploader>
                <!--<video  :key="`${video_path}${lesson.video_url}`" v-if="lesson.video_url != null" width="100%" height="100%" controls controlsList="nodownload">
                  <source :src="`${video_path}${lesson.video_url}`" type="video/mp4"/>
                  <source :src="`${video_path}${lesson.video_url}`" type="video/ogg"/>
                  Your browser does not support the video tag.
                </video>
                <div
                  v-if="lesson.video_url"
                >
                  <v-btn 
                    color="error"
                    @click="deletevideo(lesson.id)"
                  >
                    Delete Video
                  </v-btn>
                  <v-btn class="ml-2" color="success"
                    @click="event_open_assignlessontouser_dialog()">
                    <v-icon>mdi-plus</v-icon>
                    Assign Lesson to User
                  </v-btn>
                </div>-->
                <!-- ADDITIONAL VIDEO UPLOAD -->
                <div
                  class="mt-2"
                >
                  <v-card
                    class="card"
                    elevation="0"
                  >
                    <v-card-title>
                      Additional Video Files
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <small>Upload Video Files (it only accepts .mp4 extensions)</small>
                      <v-text-field
                        class="mt-3"
                        dense
                        v-model="additional_url_name"
                        label="Name of video file"
                        outlined
                      >
                      </v-text-field>
                      <v-file-input
                        class="mt-0"
                        outlined
                        dense
                        truncate-length="15"
                        v-model="additional_url"
                      ></v-file-input>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions
                      v-for="(param, i) in lesson.additional_files"
                      :key="i"
                    >
                      <a 
                        :href="`${video_path}${param.file}`"
                        class="ml-2"
                      >{{param.name}}</a> 
                      <v-spacer></v-spacer>
                      <v-btn text @click="deletevideofile(i)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <!-- END OF ADDITIONAL VIDEO UPLOAD -->
                <!-- FILE UPLOAD -->
                <div 
                  v-if="lesson.file_url != null"
                  class="mt-2"
                >
                  <v-card 
                    class="card" 
                    elevation="0"
                  >
                    <v-card-title>
                      File Uploaded
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions
                      v-for="(param, i) in lesson.file_url"
                      :key="param"
                    >
                      <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png
                      " alt="" style="cursor:default;" width="50" height="50">
                      <a :href="`${video_path}${param}`" v-html="`${param}`" target="_blank"></a>
                      <v-spacer></v-spacer>
                      <v-btn text @click="deletefile(i)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <!-- END OF FILE UPLOAD -->
                <!-- WYSIWYG -->
                  {{ content }}
                <div
                  v-if="lesson.contents === null || lesson.contents === '' || isEdit === true"
                  class="mt-2"
                  :transition="200"
                >
                  <v-expansion-panels class="card" elevation="0">
                    <v-expansion-panel
                      v-for="(item, i) in 1"
                      :key="i"
                    >
                      <v-expansion-panel-header>
                        <small>Add Text <em class="ml-1">Optional</em></small>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <VueEditor 
                          :editorToolbar="customToolbar"
                          v-model="content"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <!-- END OF WYSIWYG -->
                <!-- SHOW TEXT -->
                <div
                  v-else
                  class="mt-2"
                >
                  <v-card>
                    <v-card-text>
                      <v-expansion-panels class="card" elevation="0">
                        <v-expansion-panel
                          v-for="(item, i) in 1"
                          :key="i"
                        >
                          <v-expansion-panel-header>
                            <small>Contents</small>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content
                            class="pl-5 pb-5"
                            v-html="lesson.contents"
                          >
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="isEdit = true"
                      >       
                        <v-icon class="mr-1">mdi-clipboard-edit-outline</v-icon>
                        Edit Content
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <!-- END OF SHOW TEXT -->
                <!-- ADD TAGS -->
                <div
                  class="mt-2"
                >
                  <v-card
                    class="card"
                    elevation="0"
                  >
                    <v-card-title>
                      Add Tags
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      class="pb-0"
                    >
                      <small class="red--text">* To add multiple tags, separate them using <strong> a comma</strong>. " , "</small>
                      <v-text-field
                        v-model="submitTag"
                        class="mt-2"
                        small
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions
                      class="px-4"
                    >
                      <small>Available Tags:</small>
                      <small
                        v-for="tag in lesson.tags"
                        :key="tag"
                        class="ml-1"
                        style="border: 1px solid #d3d4d6; border-radius: 10px; padding: 2px 3px;"
                      >
                        <em>{{ tag }}</em><v-icon class="ml-1" small color="red" @click="removeTag(tag)">mdi-close</v-icon>
                      </small>
                    </v-card-actions>
                  </v-card>
                </div>
                <!-- END OF ADD TAGS -->
                <!-- add issues solutions -->
              </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            outlined
            class="mr-2"
            @click="discardchanges()"
          >
            DISCARD CHANGES
          </v-btn>
          <v-btn 
            color="black" 
            dark
            class="mr-2"
            @click="savechild(lesson)"
          >
              SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
      <DialogVue
          v-model="changeChapterDialog" 
          :dialogsvalue="dialogChaptersChildData" 
          @closedialog="closeUpdateLessonChapterDialog"
          @savedata="onUpdateLessonChapter"
        />
      <AssignLessonToUser
        :open_dialog="open_assignlessontouser_dialog"
        :course_id="course_id"
        :subject_id="params.id"
        @close_dialog="event_close_assignlessontouser_dialog"></AssignLessonToUser>
    </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import DialogVue from './DiaLog';
import { mapGetters } from 'vuex'
import AssignLessonToUser from '@/v2/modules/admin/roadmap/components/AssignLessonToUser.vue';

import FileUploader from '@/v3/components/lib/FileUploader.vue';

export default {
  components: {
    VueEditor,
    DialogVue,
    AssignLessonToUser,
    FileUploader
  },
  props: [
    'params',
    'tags',
    'lesson'
  ],
  data: () => ({
    name: null,
    url: null,
    url_file: null,
    video_path: null,
    content: '',
    editcontent: '',
    file_url: {
      extension: null,
      name: '',
    },
    fileurldialog: false,
    customToolbar: ['bold', 'italic', 'underline'],
    isEdit: false,
    additional_url: null,
    additional_url_name: null,
    changeChapterDialog: false,
    dialogChaptersChildData: {
      title: 'Update Lessons Chapter',
      isUpdateChapter: true
    },
    submitTag: '',
    issue_solution: '',
    showCategory: false,
    getCategory: [{text: 'Marketing', value: 'Marketing'}, {text: 'Team', value: 'Team'}, {text: 'Sales', value:'Sales'}],
    issue_category: {
      selectedCategory: null
    },
    categoryModal: false,
    issueCategory: null,
    open_assignlessontouser_dialog: false,
    course_id: null,
  }),
  computed: {
    ...mapGetters({
      child: 'adminmain/getSelectedLessonChild',
      parent: 'adminmain/getSelectedLesson',

    })
  }, 
  mounted () {
    this.course_id = this.$route.params.id
    console.log(this.course_id)
  },
  created () {
    this.video_path = process.env.VUE_APP_URL
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    // update parent_id of this lesson
    async onUpdateLessonChapter(data) {
      this.showSnackBar('Please wait...')
      await this.$axios.patch(`/courses/updateChapterLesson/${this.$route.query.id}`, {
        course_id: this.$route.params.id, 
        parent_id: data.input.parent_course_id 
      })
        .then((response) => {
          this.$store.dispatch('adminmain/setCourseChildList',[]);
          if(response.status === 200) {

              this.$store.dispatch('adminmain/setCourseList', response.data.courseList.original.data)
              this.$store.dispatch('adminmain/setCourseChildList', response.data.courseChildList)

              const message = {
                show:true,
                message: 'Successfully Lesson Chapter updated',
              }
              this.changeChapterDialog = false;
              this.$store.dispatch("auth/setMessage", message);

          } else {
              const message = {
                show:true,
                message: response.message,
              }
              this.$store.dispatch("auth/setMessage", message);
          }
        }).catch(error => console.log(error));
    },
    showUpdateLessonChapterDialog() {
      this.changeChapterDialog = true;
    },
    closeUpdateLessonChapterDialog() {
      this.changeChapterDialog = false;
    },
    extfile() {
      this.file_url.extension = this.url.name.split('.').pop();
      this.file_url.name  = this.url.name.split('.').slice(0, -1).join('.');
    },
    async deleteicon(data){
      this.$emit('deletedata', {data: data})
    },
    savechanges(data){
      let dtoemit = {
        id: data.id,
        name: this.name !== null ? this.name : data.name 
      }
      this.$emit('saveparentchanges', dtoemit)
    },
    savechild(data){
      let dtoemit = {
        id: data.id,
        name: this.name !== null ? this.name : data.name,
        video: this.url !== null ? this.url : data.video_url,
        contents: this.content !== '' ? this.content : data.contents,
        additional_file: this.additional_url,
        additional_file_name: this.additional_url_name,
        tag: this.submitTag !== null ? this.submitTag : data.tag,
        issue_solution: this.issue_solution
      }

        this.$emit('savechildchanges', dtoemit)
    },
    deletefile(id) {
      let obj = {
        index: id,
        item_id: this.params.id
      }
      this.$emit('deletefile', obj);      
    },
    deletevideofile(id) {
      let obj = {
        index: id,
        item_id: this.params.id
      }
      this.$emit('deletevideofile', obj);
    },
    discardchanges() {
      this.$emit('discardchanges');
    },
    alertmsg() {
      alert('Under Development!');
    },
    deletevideo(id) {
      this.$emit('deletevideo', id);
    },
    // submitTags(item) {
    //   const tp = {
    //     tag: item,
    //     id: this.params.id,
    //     course_id: this.$route.params.id
    //   }
    //   this.$emit('submitTags', tp);
    //   this.submitTag = null;
    // },
    removeTag(item) {
      const tp = {
        tag: item,
        id: this.params.id,
        course_id: this.$route.params.id
      }
      this.$emit('removeTag', tp);
    },
    archiveLesson(item) {
      const tp = {
        tag: item,
        id: this.params.id,
        course_id: this.$route.params.id
      }

      this.$emit('archiveLesson', tp);
    },
    watchCategory() {
      if(this.showCategory == false) {
        console.log('issue_category =>', this.issue_category);
        this.issue_category = {};
      } else {
        console.log('issue_category =>', this.issue_category);
      }
    },
    submitCategory() {
      console.log('category =>', this.issueCategory);
      this.getCategory.push({ text: this.issueCategory, value: this.issueCategory });
      this.categoryModal = false;
    },
    event_close_assignlessontouser_dialog() {
      this.open_assignlessontouser_dialog = false
    },
    event_open_assignlessontouser_dialog() {
      this.open_assignlessontouser_dialog = true
    }
  },
  watch: {
    '$route' (to) {
        this.urlparams = to.query;
      }
  }
}
</script>

<style scoped>
  .card {
    border: 1px solid #abb1bc;
  }

  /* .tags {
    border: 1px solid #333333;
  } */

</style>