<template>
    <v-dialog
        :value="value"
        persistent
        max-width="60%"
    >
        <v-card>
            <v-card-title>
                <small>Edit Profile: {{data.name}}</small>
                <v-spacer/>
                <v-icon
                    @click="close_dialog"
                >mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    dense
                    outlined
                    label="First Name"
                    type="text"
                    v-model="toEditData.firstname"
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    label="Last Name"
                    type="text"
                    v-model="toEditData.lastname"
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    label="New Password"
                    type="text"
                    v-model="e.password"
                    @keyup.enter="update_user"
                >
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    class="pl-10 pr-10"
                    color="primary"
                    style="textTransform: none !important;"
                    @click="update_user"
                >
                    Save Changes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  props: [
    'value',
    'data'
  ],
  data () {
    return {
        e: {
            password: null
        },
        edituser: {}
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        toEditData: 'internal/getToEditData'
    }),
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    async update_user(){
        const tp = {
            id: this.toEditData.id,
            firstname: this.toEditData.firstname,
            lastname: this.toEditData.lastname,
            password: this.e.password
        }

        if(tp.password == null || tp.password == '') {
            this.$axios.patch('/internal/users/e_user', tp)
            .then(({data}) => {
                if(data.response) {
                    this.$store.dispatch('adminclientdboard/set_internal')
                    this.close_dialog()
                }
            })
        } else {
            await this.$axios.patch(`/internal/users/update_password/${this.e.password}/${this.data.id}`)
            .then(({data}) => {
                if(data.response){
                    this.$store.dispatch('adminclientdboard/set_internal')
                    this.close_dialog()
                }
            })
        }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">

</style>