import Vue from 'vue'

export default class ClientAnalytics {
    constructor() {
        this.store = null
        this.users_id = null
    }

    setStore(store) {
        this.store = store
    }
    getStore() {
        return this.store
    }

    setUsersId(users_id) {
        this.users_id = users_id
    }
    getUsersId() {
        return this.users_id
    }

    getClientsActivityFeedData() {
        Vue.axios.get(`admin/clientanalytics/getclientactivities/${this.getUsersId()}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    const wrangled_data = this._processClientsActivityFeedDataForViewing(result.data)
                    const store = this.getStore()
                    store.dispatch('course/setActivityFeedsAction', wrangled_data)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    _processClientsActivityFeedDataForViewing(data) {        
        const result = data.map( (item) => {
            const store = this.getStore()
            const course_title = store.getters['course/getCourseNameByIdGetter'](item.get_u_course_child.get_u_course_parent.course_id)
            const total_watch_minutes = item.get_lastest_viewed_video.reduce( (total_watch_minutes, current_watch_data) => {
                total_watch_minutes += current_watch_data.watch_duration
                return total_watch_minutes
            }, 0)
            
            return { 
                content: `${course_title}: ${item.get_u_course_child.get_u_course_parent.name} | ${item.get_u_course_child.name}`,
                viewed_date: item.get_lastest_viewed_video.length > 0 ? new Date(item.get_lastest_viewed_video[0].updated_at).toLocaleDateString() : null,
                completed_date: item.is_done == 1 ? new Date(item.updated_at).toLocaleDateString() : null,
                watch_duration: (total_watch_minutes / 60).toFixed(2)
            }
        })
        return result.sort((a, b) => new Date(b.viewed_date) - new Date(a.viewed_date))
    }
}
