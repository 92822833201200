<template>
  <v-row>
    <TheLoader 
      :visible="loader"
    />
    <v-col
      cols="12"
    >
      <h3 style="letter-spacing: 3px;">Courses</h3>
      <v-btn 
        style="float: right"
        color="black"
        dark
        @click="openaddcourse"
      >
        <v-icon class="mr-2">mdi-shape-square-plus</v-icon>New Course
      </v-btn>
      <br><br>
      <v-btn 
        style="float: right"
        color="black"
        dark
        @click="split_roadmap_urls"
      >
        <v-icon class="mr-2">mdi-wrench</v-icon>Fix URLs
      </v-btn>
    </v-col>
    <v-col
      cols="12"
    >
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tab
          v-for="(item, index) in items"
          :key="index"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-if="tab === 0">
        <Cards :cardsitem="getCourseList" :search_title="search_title" :routeurl="routeurl"/>
      </v-tabs-items>
    </v-col>
    <Dialog 
      v-model="dialogaddvideo" 
      :value="dialogaddvideo" 
      :dialogsvalue="dialogsvalue"
      @closedialog="closedialog"
      @savedata="savedata"
    />
  </v-row>
</template>

<script>
import Cards from '../../../components/CardsView'
import Dialog from '../../../components/DiaLog'
import TheLoader from '../../../components/TheLoader'
import { mapGetters } from 'vuex'
export default {
  components: {
    Cards, Dialog, TheLoader
  },
  props: [
  ],
  data () {
    return {
      tab: null,
      items: [
        'Courses', 'Membership & Bundles', 'Re-order',
      ],
      search_title: 'Search courses by name or instructor',
      routeurl: 'lesson',
      dialogaddvideo: false,
      dialogsvalue: {
        title: 'Add Course',
        text_field: [
          {
            id:         0,
            label:      'Course Title *',
            hint:       'Course Title',
            type:       'text'
          }
        ],
        checkbox: [
          {
            id:             1,
            checked:        false,
            hasselection:   true,
            label:          'roadmap'
          }
        ]
      }
    }
  },
  mounted () {
  },
  created () {
    this.flist()
  },
  computed: {
    ...mapGetters({
      getCourseList:    'adminmain/getCourseItem',
      availableClients: 'adminmain/getClientList',
      loader:       'auth/getLoader',
    })
  },
  methods: {
    hide() {
      this.$store.dispatch('auth/setHide');
    },
    show() {
      this.$store.dispatch('auth/setShow');
    },
    openaddcourse(){
      this.dialogaddvideo = true
    },
    closedialog(){
      this.dialogaddvideo = false
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async split_roadmap_urls() {
      this.showSnackBar("Please wait about 1 minute...")
      await this.$axios.post('/clients/split_roadmap_urls')
      .then(({data}) => {
        console.log(data)
        this.showSnackBar("Done, check console for details")
      })
    },
    async flist(){
      this.show();
      await this.$axios.get('/courses/list')
      .then(({data}) => {
        this.hide();
        if(data.response){
          this.$store.dispatch('adminmain/setCourseItem', data.data)
        }else{
          localStorage.removeItem('token')
          localStorage.removeItem('udata')
          localStorage.removeItem('vuex')
          this.$router.push({name: 'index'})
        }
      })
    },
    async savedata(data){
      this.show();
      const tp = {
        course_title: data.input.textfield[0],
        clients: data.input.clists
      }
      await this.$axios.post('/courses/addcourse', tp)
      .then(({data}) => {
        this.hide();
        if(data.response){
          this.flist()
          this.closedialog()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>