<template>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-card
          class="mx-auto"
          outlined
          style="width: 500px;"
        >
          <v-toolbar dark color="blue">
            <v-card-title>
              Reset Password
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              prepend-icon="lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="Password"
              label="Password"
              v-model="item.password"
              class="input-group--focused"
              @click:append="show = !show"
            >
            </v-text-field>
            <v-text-field
              prepend-icon="lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="Password"
              label="Password"
              v-model="item.password_confirmation"
              class="input-group--focused"
              @click:append="show = !show"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="info" class="white--text" @click="register">Register</v-btn> -->
            <v-btn color="primary" class="white--text" @click="submit(item)">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    item: {
      password: null,
      password_confirmation: null
    },
    show: false
  }),
  mounted () {
  },
  created () {
    if(localStorage.getItem('token') !== null){
      let ld = JSON.parse(localStorage.getItem('udata'))
      if(ld.role_id == 1){
        this.$router.push({name: 'landing'})
      }else{
        this.$router.push({name: 'client'})
      }
    }
  },
  computed: {
    ...mapGetters({
      mSnackbar:    'auth/mSnackbar'
    }),
  },
  methods: {
    // submit(obj) {
      // console.log('here =>', obj);
    // }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>