<template>
    <div style="margin-bottom: 5rem;overflow-x: scroll;overflow-y: hidden;">
        <RoadmapBoard
            @openmodal="openmodal"
            @tickcheckroadmap="tickcheckroadmap"
            @tickStaff="tickStaff"
            @submitPercentage="submitPercentage"
            :roadmaps="getRoadmap"
            :staff="getStaff"
            :user="JSON.parse(getUser)"></RoadmapBoard>
             
        <DialogVue 
            v-model="dialogtrelloroadmap"
            :value="dialogtrelloroadmap"
            :info="getSelectedRoadmap"
            :dialogsvalue="dialogtrello"
            :comments="comments"
            :tasks="getTask"
            @savecolor="savecolordata"
            @closedialog="closedialog"
            @submitWithInfo="submitDeadline"
            @submitComment="submit"
            @submitPercentage="submitPercentage"
            @submitDelete="submitDelete"
            @submitEdit="submitEdit"
            @submitTask="/*submitTask*/"
            @toggleTask="toggleTask"></DialogVue>

        <v-dialog
            v-model="trialUserDialog"
            persistent
            max-width="45%">
            <v-card>
            <v-card-text
                class="d-flex justify-center align-center flex-column mx-auto pt-5"
            >
                <v-icon
                x-large
                class="blue--text"
                >
                mdi-hand-wave-outline
                </v-icon>
                <h1
                class="mt-5"
                >
                Your 10 minute session for the day is up!
                </h1>
                <center>
                <p
                    class="mt-2 grey--text d-flex justify-center mx-auto"
                >
                    Log again tomorrow or upgrade your membership now to gain unlimited access to our Mastermind content.
                </p>
                </center>
                <v-btn
                class="mt-2 px-8"
                color="primary"
                rounded
                @click="logout(); trialUserDialog = false;"
                >
                OK
                </v-btn>
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="dialog"
            width="1300">
            <v-card
            class="overflow-auto"
            >
            <v-card-title>
                Welcome to the PT Roadmap. Watch the roadmap software overview:
                <v-spacer></v-spacer>
                <v-icon small color="red" @click="dialog = false">mdi-close</v-icon> 
            </v-card-title>
            <v-card-text
                class="pb-0"
            >
            <video width="100%" height="100%" controls controlsList="nodownload">
                <source src="https://api.profitabletradesmen.com/videos/1662970606.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            </v-card-text>
            <v-card-actions
                class="pl-5 pt-1"
            >
                <v-simple-checkbox
                @click="disable_popup()"
                v-model="checkbox"
                ></v-simple-checkbox>
                <span>Do not show this video again.</span>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import RoadmapBoard from '@/modules/admin/components/inner/RoadmapBoard.vue'
  import DialogVue from '@/components/DiaLog'
  import dayjs from 'dayjs'

  
  import { mapGetters } from 'vuex'
  
  export default {
    components: {
      RoadmapBoard,
      DialogVue,
    },
    props: {
      course_id: Number
    },
    data: () => ({
      tab: null,
      items: [
        'Roadmap', 
        'Videos',
      ],
      islist: {
        preview: false,
        islist: true
      },
      showdialog: false,
      showdialogchild: false,
      dialogcolorroadmap: false,
      headers: [
        {
          text: 'Billable Staff | Pipelining Staff',
          align: 'center',
          sortable: false,
          class: "indigo darken-1 white--text"
        },
        { text: 'Mindset', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'Leadership', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'Marketing', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'Sales', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'Team', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'SOP | Systems', align: 'center', class: "indigo darken-1 white--text" },
        { text: 'Cash', align: 'center', class: "indigo darken-1 white--text" },
      ],
      headerstodo: [
        { text: 'To-Do', class: "indigo darken-1 white--text" },
        { text: 'Complete', class: 'indigo darken-1 white--text' },
        { text: 'Deadline Date', class: 'indigo darken-1 white--text' },
      ],
      headerthird: [
        { text: 'To-Do', class: "indigo darken-1 white--text" },
        { text: 'Assigned To', class: 'indigo darken-1 white--text' },
        { text: 'Video Link', class: 'indigo darken-1 white--text' },
        { text: 'WK No. Assigned', class: 'indigo darken-1 white--text' },
        { text: 'Close Date', class: 'indigo darken-1 white--text' },
        { text: 'Status', class: 'indigo darken-1 white--text' },
        { text: '% Complete', class: 'indigo darken-1 white--text' },
      ],
      toshow: {
        frompreview: true,
        hidden: false
      },
      dialogsvalue: {
        title: 'Add Section',
        text_field: [
          {
            id:         0,
            label:      'Section Name',
            hint:       'Section Name',
            type:       'text'
          },
        ],
        checkbox: [
          {
            id:           1,
            checked:      false,
            hasselection: false,
            label:        'Has Checkbox?'
          }
        ]
      },
      dialogfirst: {
        title: 'What You See Is What You Get',
        text_field: [
          {
            id:         0,
            label:      'To-Do Name',
            hint:       'To-Do Name',
            type:       'text'
          },
        ]
      },
      dialogsecond: {
        title: 'Add 3 Things',
        text_field: [
          {
            id:         0,
            label:      'To-Do',
            hint:       'To-Do',
            type:       'text'
          }
        ],
        hasdatepicker: true
      },
      dialogthird: {
        title: 'Add Pebbles',
        text_field: [
          {
            id:         0,
            label:      'To-Do',
            hint:       'To-Do',
            type:       'text'
          },
          {
            id:         1,
            label:      'Assign To',
            hint:       'Assign To',
            type:       'text'
          },
          {
            id:         2,
            label:      'Week No.',
            hint:       'Week No.',
            type:       'text' 
          },
          {
            id:         3,
            label:      'Status',
            hint:       'Status',
            type:       'text'
          }
        ],
        hasdatepicker: true,
        hasvideolink: true
      },
      dialogcolor: {
        isTable: false,
        color: true,
        title: 'Add Priority',
        colors: [
          {
            name: 'Priority 1',
            color: '#A76571'
          },
          {
            name: 'Priority 2',
            color: '#BD8C94'
          },
          {
            name: 'Priority 3',
            color: '#D3B2B8'
          }
        ],
      },
      which: {},
      video_item: null,
      wysiwygdialog: false,
      videodialog: false,
      dialogvideo: {
        isTable: false,
        color: false,
        video: true,
        title: 'Viewing Video'
      },
      dialogwysiwyg: {
        isTable: false,
        color: false,
        video: false,
        wysiwyg: true,
        title: 'Text Editor'
      },
      dialogtrelloroadmap: false,
      dialogtrello: {
        trello: true,
        items: [
          {
            id: 0,
            title: 'Priority',
            items: [
              {
                id: 0,
                label: 'Set Priority',
                type: 'text'
              }
            ],
            icon: 'mdi-priority-high'
          },
          {
            id: 1,
            title: 'Deadline Date',
            icon: 'mdi-update',
            items: [
              {
                id: 1,
                label: 'Deadline Date',
                type: 'text'
              }
            ]
          }
        ],
        colors: [
          {
            name: 'Priority 1',
            color: '#A76571'
          },
          {
            name: 'Priority 2',
            color: '#BD8C94'
          },
          {
            name: 'Priority 3',
            color: '#D3B2B8'
          }
        ],
        progress: [
          {
            name: '-',
            value: null
          },
          {
            name: '10%',
            value: 10
          },
          {
            name: '20%',
            value: 20
          },
          {
            name: '30%',
            value: 30
          },
          {
            name: '40%',
            value: 40
          },
          {
            name: '50%',
            value: 50
          },
          {
            name: '60%',
            value: 60
          },
          {
            name: '70%',
            value: 70
          },
          {
            name: '80%',
            value: 80
          },
          {
            name: '90%',
            value: 90
          },
          {
            name: '100%',
            value: 100
          }
        ]
      },
      isSelected: null,
      selectedcomments: null,
      dialoguser: false,
      analyticstest: [
        {
          id: 1,
          name: 'Mindset',
          children: [
            {
              id: 2,
              name: 'Quadrant'
            },
            {
              id: 3,
              name: 'Resilience'
            }
          ]
        },
        {
          id: 4,
          name: 'Leadership',
          children: [
            {
              id: 5,
              name: 'Time Management'
            },
            {
              id: 6,
              name: 'Delegation'
            }
          ]
        }
      ],
      courseitem: false,
      selectedCourse: null,
      dialog: false,
      checkbox: false,
      loadComponent: true,
      trialUser: new Date(),
      timer: null,
      trialUserDialog: false
  
    }),
    beforeDestroy() {
      clearInterval(this.timer);
    },
    async mounted () {
      let show_welcome_dialog = localStorage.getItem('show_welcome_dialog')
      if ( show_welcome_dialog == null ) {
        localStorage.setItem('show_welcome_dialog', true)
        this.dialog = true
      }
       
      let user_created_at = this.checkDate(this.user.created_at);
      this.scourse(3)
      if(this.user.role_id == 5) {
        if(user_created_at) {
          if(this.user.isActive) {
            this.timer = setInterval(() => {
              const now = new Date();
              const timeElapsed = now - this.trialUser;
              if(timeElapsed >= 10 * 10 * 6000) {
                const to_pass = {
                  id:   this.user.id
                }
  
                this.$axios.patch('users/e_user_client', to_pass)
                  .then(({data}) => {
                    if(data.response) {
                      clearInterval(this.timer);
                      this.trialUserDialog = true;
                    }
                  }).catch(err => console.error(err));
              }
            }, 1000);
          } else {
            this.trialUserDialog = true;
            clearInterval(this.timer);
          }
        }
      }
  
  
      // await this.fcourses(this.getUser);
      const topass = JSON.parse(this.getUser)
      await this.$store.dispatch('clientstate/fetch_courses_of_user', topass.id)
  
      if(!sessionStorage.getItem('udata')) {
        const data = localStorage.getItem('udata');
        sessionStorage.udata = data;
        sessionStorage.token = localStorage.getItem('token');
  
        // const parsedData = JSON.parse(sessionStorage.udata);
        // await this.$axios.post(`clogin/${parsedData.id}`)
        //   .then(({data}) => {
        //     if(data.response) {
        //       this.showSnackBar('Login Successful!');
        //     }
        //   }).catch(err => err);
      }
  
      setTimeout(() => {
        this.loadComponent = false;
      }, 1000);
    },
    created () {
      // this.gettodolist()
      // this.getroadmap()
      // this.flessons()
      // this.fetchStaff()
  
    },
    computed: {
      ...mapGetters({
        getCourseList:    'clientstate/getCourseList',
        getTodoList:      'clientstate/getTodoList',
        getTodoParent:    'clientstate/getTodoParent',
        getRoadmap:       'clientstate/getRoadmap',
        getColor:			    'clientstate/getColor',
        getVideoList:     'clientstate/getVideoList',
        getUser:          'auth/getUserData',
        getStaff:         'clientstate/getStaff',
        getCourseUserList: 'clientstate/getCourseUserList',
        getSelected:      'clientstate/getSelected',
        getTask:          'clientstate/getTasks',
        getSelectedRoadmap: 'clientstate/getSelectedRoadmap',
        getUserData:        'auth/getSelectedUser',
        comments:      'clientstate/getComments',
        getDialogRoadmap: 'roadmapstate/getDialog',
      }),
  
      user() {
        return JSON.parse(this.getUser);
      }
    },
    methods: {
      hide() {
        this.$store.dispatch('auth/setHide');
      },
      show() {
        this.$store.dispatch('auth/setShow');
      },
      showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      gotoboard(data){
        if(data){
          this.$router.push({name: 'board'})
        }
      },
      async gettodolist(){
        this.$axios.get('/todoclient/glist')
        .then(({data}) => {
          this.$store.dispatch('clientstate/setTodoList', data.data)
        })
      },
      async flessons(){
        await this.$axios.get('/client/getcourselist')
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('clientstate/setVideoList', data.data)
          }
        })
      },
      // async fcourses(user){
      //   this.show();
      //   const item = JSON.parse(user);
      //   await this.$axios.get(`/adminclientdboard/ucourselist/${item.id}`)
      //   .then(({data}) => {
      //     if(data.response) {
      //       this.$store.dispatch('clientstate/setCourseUserList', data.data)
      //     } else {
      //       this.showSnackBar('Unexpected error, please contact the devs / admin.');
      //     }
      //     this.hide();
      //   })
      // },
      addsectiontodo(){
        this.showdialog = true
      },
      addchildlist(item){
        this.which = item
        this.showdialogchild = true
      },
      closedialog(){
        this.showdialog = false
        this.showdialogchild = false
        this.dialogcolorroadmap = false
        this.videodialog = false
        this.wysiwygdialog = false
        this.dialogtrelloroadmap = false
        this.dialoguser = false
      },
      async getroadmap(){
        // let course_id = id == null ? 3 : id;
  
        await this.$axios.get('/roadmap/g_roadmap')
        .then(({data}) => {
          this.$store.dispatch('clientstate/setRoadmap', data.data)
        })
      },
      async savedata(data){
        let tp = {
          name:         data.input,
          hascheckbox:  data.input.checkbox[1] == undefined ? 0 : 1
        }
        await this.$axios.post('/todoclient/addparent', tp)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('clientstate/setTodoList', data.data.original.data)
            this.closedialog()
          }else{
            this.showSnackBar(data.message)
          }
        })
      },
      async savedatachild(data){
        let tp = {
          name:           data.input.textfield[0] ? data.input.textfield[0] : data.input.content,
          parent_id:      this.getTodoParent.id,
          hascheckbox:    this.getTodoParent.hascheckbox,
          deadline_date:  data.input.date,
          assign_to:      data.input.textfield[1],
          week_no:        data.input.textfield[2],
          status:         data.input.textfield[3],
          lesson_id:      data.input.videolesson.id
        }
        await this.$axios.post('/todoclient/addchild', tp)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('clientstate/setTodoList', data.data.original.data)
            this.closedialog()
          }else{
            this.showSnackBar(data.message)
          }
        })
      },
      async tickcheckroadmap(obj){
        let tp = {
          id:		obj.id,
          ischecked: obj.ischecked
        }
        await this.$axios.patch(`/client/markcheckbox/${tp.id}/${tp.ischecked}`)
        .then(({data}) => {
          if(data.response){
            this.getroadmap(obj.course_id);
          }
        })
      },
      async tickStaff(obj) {
        let tp = {
          id: obj.id,
          user_id: obj.user_id
        }
        await this.$axios.patch(`client/markStaff/${tp.id}/${tp.user_id}`)
          .then(({data}) => {
            if(data.response) {
              this.getroadmap(obj.course_id);
              this.fetchStaff(obj.course_id);
            }
          }).catch(err => err);
        
      },
      editpriorityroadmap(){
        this.dialogcolorroadmap = true
      },
      async savecolordata(obj){
        let tp = {
          color: obj.input,
          course_id: obj.item.course_id,
          item_id: obj.item.id
        }
        await this.$axios.post('/client/addpriorityroadmap', tp)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
            this.getroadmap(obj.item.course_id)
          }
        })
      },
      openvideo(item){
        this.video_item = item
        this.videodialog = true
      },
      async openmodal(obj) {
        this.isSelected = obj;
        this.$store.dispatch('clientstate/setSelectedRoadmap', obj);
        this.$store.dispatch('roadmapstate/setDialog', true);
        this.$store.dispatch('roadmapstate/setSelectedRoadmap', obj);
        this.dialogtrelloroadmap = true;
        
        const tp = {
          course_id: obj.course_id,
          roadmap_id: obj.id,
          name: obj.name,
          user_id: JSON.parse(this.getUser).id
        }
  
        await this.setup(tp);
  
      },
      async setup(tp) {
        await this.getComments(tp);
        await this.getTasks(tp);
      },
      submitDeadline(obj) {
        this.$axios.post('client/setDeadline', obj)
          .then(({data}) => {
            if(data.response) {
              this.$store.dispatch('clientstate/setSelectedRoadmap', data.roadmap);
              this.getroadmap(obj.data.course_id)          
            }
          })
      },
      async submit(item) {
        const tp = {
          comments: item.item,
          course_id: item.data.course_id,
          roadmap_id: item.data.id,
          user_id: this.user.id,
          user_role_id: this.user.role_id,
          name: item.data.name
        }
        await this.$axios.post('/client/addComment', tp)
          .then(({data}) => {
            if(data.response) {
              // this.closedialog();
              // this.getroadmap(item.data.course_id);
              this.$axios.post('emails/notifyUsers', tp)
                .then(({data}) => {
                  if(data.response) {
                    this.showSnackBar(data.message);
                  }
                }).catch(err => err);
  
              // this.closedialog();
              this.openmodal(this.getSelectedRoadmap);
            }
          }).catch(err => err);
      },
      getComments(obj) {
        // this.$axios.get(`client/getComments`, obj)
        //   .then(({data}) => {
        //     this.selectedcomments = data.data;
        //     this.$store.dispatch('clientstate/setComments', data.data);
        //   });
        this.$store.dispatch('clientstate/setComments', obj);
      },
      async submitPercentage(obj) {
        await this.$axios.post('client/setProgress', obj)
          .then(({data}) => {
            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
            this.getroadmap(obj.data.course_id);
          })
      },
      async submitDelete(obj) {
        await this.$axios.delete(`client/deleteComment/${obj.id}`)
          .then(({data}) => {
            if(data.response) {
              this.showSnackBar(data.message);
              this.closedialog();
              this.getroadmap(obj.data.course_id);
            }
          }).catch(err => err);
      },
      async submitEdit(obj) {
        await this.$axios.patch('client/editComment', obj)
          .then(({data}) => {
            if(data.response) {
              this.showSnackBar(data.message);
              this.closedialog();
              this.getroadmap(obj.data.course_id);
            }
          }).catch(err => err);
      },
      async fetchStaff(id) {
        await this.$axios.get(`client/getStaff/${id}`)
          .then(({data}) => {
            this.$store.dispatch('clientstate/setStaff', data.data);
          })
      },
  
      fetchTemplateLibrary(id) {
        const toPass = {
          course_id: id
        };
  
        this.$store.dispatch('templatelibrarystate/setFiles', toPass);
        this.$store.dispatch('templatelibrarystate/setCategory', toPass.course_id);
      },
  
      async scourse(id) {
        const user = JSON.parse(this.getUser);
        await this.$axios.get(`/admin/g_course/${id}/${user.id}`)
          .then(({data}) => {
            if(data.response) {
              this.getroadmap(id);
              this.fetchStaff(id);
              this.fetchTemplateLibrary(id);
              this.$store.dispatch('clientstate/setCourse', data.course);
              this.$store.dispatch('clientstate/setSelected', data.course);
              this.$store.dispatch('clientstate/setLessonItems', data.data);
              this.$store.dispatch('clientstate/set_issues')
              // this.$store.dispatch('clientstate/set_issue_solution', data.data_issues)
              this.$store.dispatch('clientstate/setHasCrouse', true);
              this.courseitem = data.data;
            }
          }).catch(err => err);
      },
      async disable_popup() {
        this.dialog = false;
  
        await this.$axios.post('/client/removePopup', this.user)
          .then(({ data }) => {
            this.$store.dispatch('auth/setUserData', JSON.stringify(data.data));
          }).catch(err => this.showSnackBar('An error has occured, please contact the admin / developer.' + err));
      },
      async getTasks(obj) {
        await this.$axios.get(`client/getTask`, obj)
          .then(({data}) => {
            this.$store.dispatch('clientstate/setTask', data.data);
          }).catch(err => this.showSnackBar('An error has occured, please contact the admin / developer.' + err));
      },
      async toggleTask(obj) {
        await this.$axios.post('client/toggleTask', obj)
          .then(({data}) => {
            this.$store.dispatch('clientstate/setTask', data.data);
          }).catch(err => console.error(err));
      },
      logout(){
        this.$axios.post('/logout', { id: this.user.id }).catch(err => console.error(err));
        this.$axios.post('check_out', { id: this.user.id, status: 'logout' })
        this.$store.dispatch('auth/setIsLogged', false)
        this.$store.dispatch('auth/clearAuthToken');
        localStorage.clear();
        sessionStorage.clear();
        clearInterval(this.timer);
        this.$router.push({name: 'index'}).catch(err => err)
      },
      checkDate(date) {
        const trialDurationDays = 7; // Trial duration in days
        const currentDate = dayjs(); // Current date
        const diffInDays = currentDate.diff(dayjs(date).$d, 'day');
  
        return diffInDays > trialDurationDays;
  
        // console.log('usercreatedat', userCreatedAt.$d, now.$d);
  
        // return userCreatedAt.$d < now.$d;
      },
    },
    watch: {
    }
  }
  </script>
  
  <style scoped lang="scss">
  table.v-table thead th {
    font-size: 20px;
  }
  </style>