<template>
  <div>
    <div v-if="typeof toshow == 'undefined'">
      <v-row>
        <v-col
          cols="12"
          v-if="typeof button != 'undefined'"
        >
          <v-btn
            class="float-right"
            @click="addclient"
            color="black"
            dark
          >
          <v-icon class="mr-2">{{button.icon}}</v-icon>{{button.title}}</v-btn>
        </v-col>
        <v-col
          cols="12"
        >
          <v-data-table
            dense
            :headers="headers"
            :items="getClientList"
            class="elevation-1"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item['name'] }}</td>
                <td>{{ props.item['business'] }}</td>
                <td>{{ props.item['updated_at'] }}</td>
                <td>{{ props.item['login_count'] }}</td>
                <td>{{ props.item['complete'] }}</td>
                <td style="cursor: pointer;" @click="$router.push({name: 'clientdashboard', query: {id: props.item['id'], name: props.item['name']}})">Click Here</td>
                <td>{{ props.item['actions'] }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <strong style="cursor: pointer;" v-if="toshow.hidden" @click="showtable = !showtable">Click here to expand table</strong>
      <v-data-table
        v-if="showtable"
        :headers="headers"
        :items="getClientList"
        class="elevation-2"
        :hide-default-footer="true"
      >
        <template v-slot:item="props">
          <tr>
            <td><center><small>{{ props.item['people'] }}</small></center></td>
            <td :style="props.item['get_child'][0].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][0].color}`">
              <v-checkbox
                v-if="props.item['get_child'].name !== ''"
                v-model="props.item['get_child'][0].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][0])"
              >
                <template slot="label">
                  <small @click="gotoboard"><strong>{{props.item['get_child'][0].name}}</strong></small>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][0])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][1].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][1].color}`">
              <v-checkbox
                v-model="props.item['get_child'][1].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][1])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][1].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][1])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][2].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][2].color}`">
              <v-checkbox
                v-model="props.item['get_child'][2].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][2])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][2].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][2])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][3].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][3].color}`">
              <v-checkbox
                v-model="props.item['get_child'][3].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][3])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][3].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][3])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][4].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][4].color}`">
              <v-checkbox
                v-model="props.item['get_child'][4].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][4])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][4].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][4])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][5].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][5].color}`">
              <v-checkbox
                v-model="props.item['get_child'][5].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][5])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][5].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][5])">mdi-pencil</v-icon>
            </td>
            <td :style="props.item['get_child'][6].ischecked ? 'background: #00FF00;' : `background: ${props.item['get_child'][6].color}`">
              <v-checkbox
                v-model="props.item['get_child'][6].ischecked"
                hide-details
                @click="tickcheckroadmap(props.item['get_child'][6])"
              >
                <template slot="label">
                  <strong @click="gotoboard"><small>{{props.item['get_child'][6].name}}</small></strong>
                </template>
              </v-checkbox>
              <v-icon small @click="editpriorityroadmap(props.item['get_child'][6])">mdi-pencil</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'getClientList', 'headers', 'button', 'toshow'
  ],
  data () {
    return {
      showtable: true
    }
  },
  mounted () {
  },
  created () {
    if(!this.toshow.hidden){
      this.showtable = true
    }else{
      this.showtable = false
    }
  },
  computed: {
  },
  methods: {
    addclient(){
      this.$emit('addclient', true)
    },
    gotoboard(){
      this.$emit('gotoboard', true)
    },
    tickcheckroadmap(data){
      this.$emit('tickcheckroadmap', data)
    },
    editpriorityroadmap(data){
      this.$store.dispatch('clientstate/setColor', data)
      this.$emit('editpriorityroadmap')
    }
  },
  watch: {
  }
}
</script>

<style scoped>
</style>