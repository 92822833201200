import { computed } from "vue";
import RoadmapClass from "../classes/roadmap.class";

export default class RoadmapComputed {
    constructor() {
        this.roadmap_class = new RoadmapClass();
    }

    computed_properties = ( () => {

        const getBillableStaff = computed( () => {
            return this.roadmap_class.getClientStaffsFromStore()
        })

        const getRoadmapData = computed( () => {
            return this.roadmap_class.getRoadmapDataFromStore()
        })

        return { getBillableStaff, getRoadmapData }
    })()
}