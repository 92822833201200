import ClientDashboard from '../ClientDashboard.vue';

export default [
    {
        path: '/clientv2',
        component: ClientDashboard,
        name: 'clientv2',
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');
            if ( token ) {
                next()
            } else {
                localStorage.clear()
                next('/')
            }
        }        
    }
]