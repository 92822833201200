import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios.js'
import router from './router.js'
import store from './states/index.js'
import Vuelidate from 'vuelidate'
import vueDebounce from 'vue-debounce'
import { createPinia } from 'pinia'
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

const pinia = createPinia()

const options = {
    // You can set your default options here
};

Vue.use(axios)
Vue.use(Vuelidate)
Vue.use(vueDebounce)
Vue.use(pinia)
Vue.use(Toast, options);

Vue.config.productionTip = false

new Vue({
  router: router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
