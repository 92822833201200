<template>
  <v-container fluid>
    <TheLoader :visible="loader" />
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" background-color="transparent" dense>
          <v-tab href="#tab-1">
            Roadmap
          </v-tab>
          <v-tab href="#tab-2">
            Issues
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <ListItem :setup="settingsetup" @addClient="addClient" @deleteItem="deleteItem" @editItem="editItem" class="pb-3" />
            <v-card>
              <v-card-title class="justify-center">
                Disabled Roadmap
              </v-card-title>
              <v-card-text>
                <v-select hide-details outlined dense item-text="name" item-value="value" label="Disabled Roadmap"
                  :items="disabled_roadmap" v-model="course.disabled_roadmap" @change="changeRoadmap()"></v-select>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-2" style="margin-bottom:200px;">
            <v-card class="card pa-2" elevation="0">
              <v-card-title>
                <v-select v-model="issue_category.selectedCategory" outlined dense label="Select category"
                  :items="getIssueCategory" @change="set_category(issue_category.selectedCategory)" item-text="name"
                  item-value="id" return-object class="mt-6"></v-select>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="categoryModal = true">
                  <v-icon>mdi-plus</v-icon> Add Category
                </v-btn>
              </v-card-title>
              <v-row>
                <v-col cols="8">
                  <v-skeleton-loader type="paragraph" class="mt-2"
                    v-if="issue_category.selectedCategory && !show_issues_folders"></v-skeleton-loader>
                  <v-card class="elevation-3 mt-2" v-if="show_issues_folders">
                    <v-card-title>
                      <v-edit-dialog :return-value.sync="selected_category.name" large @save="edit_category_name()">
                        {{ selected_category.name }}
                        <template v-slot:input>
                          <v-text-field v-model="selected_category.name"></v-text-field>
                        </template>
                      </v-edit-dialog>
                      <v-btn class="ml-5" text @click="delete_category_dialog = true"><v-icon
                          color="red">mdi-delete</v-icon></v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="add_issue_dialog = true">
                        Add Issue
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col v-if="getSubIssueCategory.length == 0" cols="12">
                          No issues
                        </v-col>
                        <v-col cols="3" v-for="(issue, issue_index) in getSubIssueCategory" :key="issue_index">
                          <v-btn text style="width:100%;height:150px;" @click="set_selected_issue(issue)">
                            <v-icon size="100"
                              v-if="issue_category.selectedSubCategory.id == issue.id">mdi-folder-open</v-icon>
                            <v-icon size="100" v-else>mdi-folder</v-icon>
                          </v-btn>
                          <br>
                          <h4 style="text-align:center;">{{ issue.name }}</h4>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-skeleton-loader type="paragraph" class="mt-5"
                    v-if="issue_category.selectedSubCategory.id && !show_videos_for_issues"></v-skeleton-loader>
                  <v-card class="elevation-3 mt-5" v-if="show_videos_for_issues">
                    <v-card-title>
                      <v-edit-dialog :return-value.sync="issue_category.selectedSubCategory.name" large
                        @save="edit_issue_name()">
                        {{ issue_category.selectedSubCategory.name }}
                        <template v-slot:input>
                          <v-text-field v-model="issue_category.selectedSubCategory.name"></v-text-field>
                        </template>
                      </v-edit-dialog>
                      <v-btn class="ml-5" text @click="delete_issue_dialog = true"><v-icon
                          color="red">mdi-delete</v-icon></v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="toggle_add_video()">
                        Add Video
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row v-if="getIssueVideoList.length == 0">
                        <v-col cols="12">
                          No videos
                        </v-col>
                      </v-row>
                      <v-row v-for="(video, video_index) in getIssueVideoList" :key="video_index">
                        <v-col cols="10">
                          <h4 style="text-align:left;">{{ video.name }}</h4>
                        </v-col>
                        <v-col cols="1">
                          <v-btn text color="red" @click="remove_video(video, video_index)">Remove</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Video Selection -->

                <v-col cols="4">
                  <v-card v-if="add_video" class="elevation-5 mt-2"
                    style="overflow-y:scroll;overflow-x:hidden;height:400px;">
                    <v-row class="d-flex justify-space-between">
                      <v-col cols="12" v-for="(item, index) in courselist" :key="index">
                        <v-list-group :key="index" no-action>
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-list-item v-for="child, childindex in item.get_child" :key="childindex" class="pl-2 my-3">
                            <v-row>
                              <v-col cols="10">
                                {{ child.name }}
                              </v-col>
                              <v-col cols="2">
                                <v-btn text v-model="child.active" @click="add_video_to_issues(child)">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list-group>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog persistent max-width="600" :value="addclientdialog">
        <v-card>
          <v-card-title>
            Add Client
          </v-card-title>
          <v-card-text>
            <v-autocomplete v-model="clients" item-text="name" item-value="id" :items="availableClients" outlined dense
              chips small-chips label="Choose a Client" prepend-icon="mdi-account-outline" required>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="addclientdialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" dark @click="submit(clients)">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


    <v-row justify="center">
      <v-dialog persistent max-width="500" :value="categoryModal">
        <v-card>
          <v-card-title>
            Add Category
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="issueCategory" label="Category Name"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="categoryModal = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" dark @click="submitCategory()">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog persistent max-width="500" :value="delete_category_dialog">
        <v-card>
          <v-card-title>
            Are you sure you want to delete this category?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="delete_category_dialog = false">
              Cancel
            </v-btn>
            <v-btn color="red darken-1" dark @click="delete_category()">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog persistent max-width="500" :value="delete_issue_dialog">
        <v-card>
          <v-card-title>
            Are you sure you want to delete this issue?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="delete_issue_dialog = false">
              Cancel
            </v-btn>
            <v-btn color="red darken-1" dark @click="delete_issue()">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog persistent max-width="500" :value="add_issue_dialog">
        <v-card>
          <v-card-title>
            Add Issue
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="new_issue" label="Issue Name"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="add_issue_dialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" dark @click="submitSubCategory()">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import ListItem from '../../../components/ListItem'
import TheLoader from '../../../components/TheLoader'

import { mapGetters } from 'vuex'

export default {
  components: {
    ListItem,
    TheLoader,
  },
  props: [

  ],
  data: () => ({
    settingsetup: {
      settings: true,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Business', value: 'business' },
        { text: 'Last Login', value: 'updated_at' },
        { text: '% Complete', value: 'complete' },
        { text: 'Link', value: 'link' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      enrolledClients: [],
    },
    addclientdialog: false,
    dialogaddclient: {
      title: 'test'
    },
    clients: [],
    addedClients: [],
    isPublishedProp: false,
    courseheaders: [
      { text: 'Name', value: 'name', sortable: false, align: 'start' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
    ],
    courseitems: [
      { id: 0, name: 'Disabled Roadmap' }
    ],
    dialog: {
      bool: false,
      id: null,
      item: null
    },
    disabled_roadmap: [
      { name: 'Unlocked', value: 0 },
      { name: 'Disabled', value: 1 }
    ],
    showCategory: false,
    issue_category: {
      selectedSubCategory: {
        id: null,
        name: null,
      }
    },
    categoryModal: false,
    issueCategory: null,
    subIssueCategory: null,
    issue_data: [],
    toEditIssueData: {},
    tab: null,
    categoryArr: [],
    add_video: false,
    selected_category: {
      id: null,
      name: null,
    },
    new_issue: null,
    delete_category_dialog: false,
    add_issue_dialog: false,
    delete_issue_dialog: false,
    show_issues_folders: false,
    show_videos_for_issues: false,
  }),
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    hide() {
      this.$store.dispatch('auth/setHide');
    },
    show() {
      this.$store.dispatch('auth/setShow');
    },
    async gVideoList() {
      await this.$axios.get('/course/g_video_list')
    },
    async getclients() {
      this.show();
      await this.$axios.get(`/course/g_enrolled_users/${this.$route.params.id}`)
        .then(({ data }) => {
          if(data.response) {
            this.settingsetup.enrolledClients = data.data;
            this.$store.dispatch('adminmain/setCurrentEnrolledClients', data.data);
          }
          this.hide();
        })
    },
    async getallclients() {
      await this.$axios.get('/clients/list')
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setClientList', data.data)
            return
          } else {
            localStorage.removeItem('token')
            localStorage.removeItem('udata')
            localStorage.removeItem('vuex')
            this.$router.push({ name: 'index' })
          }
        })
    },
    addClient(data) {
      this.addclientdialog = data;
    },
    async submit(data) {
      this.show();

      let course = this.getSelectedCourse;
      const index = course.find(a => a.id == data);
            
      if(index != undefined) {
        this.showSnackBar('This user is already enrolled.');
      } else {
        await this.$axios.patch('/courses/editenrolledclients', { id: this.clients, course_id: this.$route.params.id })
        .then(({ data }) => {
          if (data.response) {
            this.getclients();
            this.addclientdialog = false;
          }
        })
      }
      this.hide();
    },
    isPublishedCourse() {
      let course = this.isPublished.filter((a) => a.id == this.$route.params.id)
      course.map((b) => {
        if (b.publish === 1) {
          this.isPublishedProp = true;
        } else {
          this.isPublishedProp = false;
        }
      });
    },
    async deleteItem(item) {
      this.show();

      let course_result = this.getSelectedCourseList.clients.filter(a => a != item.id);
      let user_result = item.course_id.filter(b => b != this.getSelectedCourseList.id);

      
      let itemtopass = {
        ...item,
        parent_course_id: this.$route.params.id,
        course_update: course_result,
        user_update: user_result
      }

      await this.$axios.patch('course/r_enrolled_users', itemtopass)
        .then(({ data }) => {
          this.settingsetup.enrolledClients = data.data;
          this.$store.dispatch('adminmain/setCurrentEnrolledClients', data.data);
          this.getclients();
          this.hide();
        });
      this.hide();
    },
    async editItem(item) {
      this.show();
      const itemtopass = {
        ...item,
        parent_course_id: this.$route.params.id
      }
      
      await this.$axios.patch('/course/e_enrolled_users', itemtopass)
        .then(({ data }) => {
          if (data.response) {
            this.settingsetup.enrolledClients = data.data;
            this.$store.dispatch('adminmain/setCurrentEnrolledClients', data.data);
            this.getclients();
          }
          this.hide();
        }).catch(err => err);
      this.hide();
    },
    editCourse(obj) {
      this.dialog.id = obj.id 
      this.dialog.bool = true
      this.dialog.item = this.course
    },
    cancelDialog() {
      this.dialog.bool = false
    },
    changeRoadmap() {
      this.$axios.post('courses/edit_disabled_roadmap', this.course)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setSelectedCourse', data.data)
            this.showSnackBar('Success!')
          }
        }).catch(err => err)
    },
    async submitCategory() {
      if (this.issueCategory == null || this.issueCategory == '') {
        this.showSnackBar('Please fill in the details.');
      } else {
        // this.getCategory.push({ text: this.issueCategory, value: this.issueCategory });
        const topass = {
          name: this.issueCategory
        }
        await this.$axios.post('/course/i_category', topass)
          .then(({ data }) => {
            this.$store.dispatch('adminmain/setIssueCategory', data.data)
            this.showSnackBar(data.message);
            this.issueCategory = null
          })
        this.categoryModal = false;

      }
    },
    async gIssueCategory() {
      await this.$axios.get('/course/g_category')
        .then(({ data }) => {
          this.$store.dispatch('adminmain/setIssueCategory', data.data)
        })
    },
    async submitSubCategory() {
      const topass = {
        name: this.new_issue,
        category_id: this.selected_category.id
      }
      await this.$axios.post('/course/create_issue', topass)
        .then(({ data }) => {
          this.$store.dispatch('adminmain/setIssueSubCategory', data.data)
          this.showSnackBar(data.message);
          this.new_issue = null
          this.add_issue_dialog = false
        })
    },
    toggle_add_video() {
      if (this.add_video) {
        this.add_video = false
      }
      else {
        this.add_video = true
      }
    },
    add_video_to_issues(item) {
      let payload = {
        video_id: item.id,
        name: item.name,
        sub_category_id: this.issue_category.selectedSubCategory.id,
      }
      this.$store.dispatch('adminmain/addVideoList', payload)
    },
    remove_video(video, index) {
      let payload = {
        index: index,
        video: video,
      }
      this.$store.dispatch('adminmain/removeVideoList', payload)
    },
    async set_selected_issue(issue) {
      this.add_video = false
      this.show_videos_for_issues = false
      if (issue.id == this.issue_category.selectedSubCategory.id) {
        this.issue_category.selectedSubCategory.id = null
        this.issue_category.selectedSubCategory.name = null
      }
      else {
        this.issue_category.selectedSubCategory.id = issue.id
        this.issue_category.selectedSubCategory.name = issue.name

        await this.$axios.get('/course/get_videos_by_issue', { sub_category_id: issue.id })
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch('adminmain/setIssueVideoList', data.data)
              this.show_videos_for_issues = true
            }
          })
      }
    },
    async set_category(category) {
      this.show_issues_folders = false
      this.show_videos_for_issues = false
      this.add_video = false
      this.issue_category.selectedSubCategory.id = null
      this.issue_category.selectedSubCategory.name = null
      this.selected_category.id = category.id
      this.selected_category.name = category.name
      await this.$axios.get('/course/get_issues_by_category', { category_id: category.id })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setIssueSubCategory', data.data)
            this.show_issues_folders = true
          }
        })
    },
    async edit_category_name() {
      let payload = {
        id: this.selected_category.id,
        name: this.selected_category.name
      }
      await this.$axios.patch('/course/edit_category', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setIssueCategory', data.data)
            this.showSnackBar(data.message)
          }
        })
    },
    async edit_issue_name() {
      let payload = {
        id: this.issue_category.selectedSubCategory.id,
        name: this.issue_category.selectedSubCategory.name,
        category_id: this.selected_category.id
      }
      await this.$axios.patch('/course/edit_issue', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setIssueSubCategory', data.data)
            this.showSnackBar(data.message)
          }
        })
    },
    async delete_category() {
      await this.$axios.delete(`/course/delete_category/${this.selected_category.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setIssueCategory', data.categories)
            this.showSnackBar(data.message)
            this.issue_category.selectedCategory = null
            this.issue_category.selectedSubCategory.id = null
            this.issue_category.selectedSubCategory.name = null
            this.show_issues_folders = false
            this.selected_category.id = null
            this.selected_category.name = null
            this.add_video = false
            this.sub_category = {}
            this.delete_category_dialog = false
            this.show_videos_for_issues = false
          }
        })
    },
    async delete_issue() {
      let payload = {
        id: this.issue_category.selectedSubCategory.id,
        category_id: this.selected_category.id
      }
      await this.$axios.post('/course/delete_issue', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('adminmain/setIssueSubCategory', data.data)
            this.showSnackBar(data.message)
            this.issue_category.selectedSubCategory.id = null
            this.issue_category.selectedSubCategory.name = null
            this.delete_issue_dialog = false
            this.add_video = false
            this.show_videos_for_issues = false
          }
        })
    },
  },
  computed: {
    ...mapGetters({
      availableClients: 'adminmain/getEnrolledClientList',
      isPublished: 'adminmain/getCourseItem',
      loader: 'auth/getLoader',
      course: 'adminmain/getSelectedCourse',
      courselist: 'adminmain/getCourseList',
      getIssueCategory: 'adminmain/getIssueCategory',
      getSubIssueCategory: 'adminmain/getSubIssueCategory',
      getIssueVideoList: 'adminmain/getIssueVideoList',
      getSelectedCourse: 'adminmain/getCurrentEnrolledClients',
      getSelectedCourseList: 'adminmain/getSelectedCourse'
    }),

    // available_clients() {
    //   return this.$store.getters('adminmain/getClientList')(this.clients);
    // }

    // available() {
    //   return this.availableClients.slice(0).filter((a) => a.course_id.includes(this.$route.params.id) === false);
    // },
  },
  mounted() {
    //this.isPublishedCourse();
    //this.gIssueCategory();
  },
  created() {
    this.getclients();
    //this.getallclients();
  },
  watch: {
  }
}
</script>

<style></style>