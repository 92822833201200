<template>
    <v-container fluid>
        <v-card v-if="selected_item != null" style="height: 100vh;overflow-y: auto;">
            <v-card-title>
                <v-icon class="mr-2">{{ selected_item.icon }}</v-icon>
                <small>{{ selected_item.name }}</small>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab text :color="check_if_completed_or_not(selected_item.id) == true ? 'success' : 'secondary'"
                            v-bind="attrs"
                            v-on="on"
                            @click="event_markascompleted(selected_item)">
                            <v-icon>mdi-check-bold</v-icon>                            
                        </v-btn>
                    </template>
                    <span>Mark as Completed</span>
                </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <VideoViewer
                    :lesson="selected_item"
                    :users_id="users_info.id"></VideoViewer>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text v-if="selected_item.contents != 'null'" v-html="selected_item.contents"></v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <AdditionalFilesViewer
                    :files="selected_item.additional_files"></AdditionalFilesViewer>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>                        
                <TagsViewer
                    :tags="selected_item.tags"></TagsViewer>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoViewer from './subcomponents/VideoViewer.vue';
import TagsViewer from './subcomponents/TagsViewer.vue';
import AdditionalFilesViewer from './subcomponents/AdditionalFilesViewer.vue';
import ClientCourse from '../modules/Course.js'

export default {
    data: () => ({
        selected_item: null,
        client_course: new ClientCourse(),
        users_info: null
    }),
    components: {
        VideoViewer,
        TagsViewer,
        AdditionalFilesViewer
    },
    computed: {
        ...mapGetters({
            getLinkData:                        'clientcourse/getLinkDataGetter',
            getSelectedUser:                    'auth/getSelectedUser',
            check_if_completed_or_not:          'clientcourse/check_if_completed_or_not_getter'
        })  
    },
    methods: {
        event_markascompleted(child) {
            this.client_course.update_course_data_by_id(child.id, {
                is_done: 1,
                ucourse_id: child.id,
                users_id: this.users_info.id
            }, this.$store)
        }
    },
    mounted() {
        /** pull record from the API End Point */
        const query = this.$route.query;
        this.client_course.getCourseLessonBasedLink(query.link, this.$store);
    },
    watch: {
        getLinkData() {
            this.selected_item = this.getLinkData.course_lesson
            this.users_info = JSON.parse(this.getSelectedUser)
        }
    }
}
</script>