<template>
<!-- dialog here for overhead calc -->
   <v-dialog
      :value="showOverHeadCalculatorDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
   >
      <v-card>
         <v-toolbar
            dark
            color="primary"
         >
            <v-btn
               icon
               dark
               @click="close"
               >
               <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Overhead Calculator</v-toolbar-title>
         </v-toolbar>
         <v-container class="grey lighten-5">
            <!-- titles -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Salaries</span>
                  </div>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <div class="grey lighten-1 pa-1 text-center">
                     <span class="white--text title">Annual</span>
                  </div>
               </v-col>
            </v-row>
            <!-- Salaries items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Business Owner Salary</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.businessOwnerSalary"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Accountant</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.accountant"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Sales Exec</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.salesExec"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Marketing Exec</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.marketingExec"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Warehouse Staff</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.warehouseStaff"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Office Manager (GM)</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.officeManager"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Office admin</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.officeAdmin"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Other</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.other1"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Other</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="salaries.other2"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Other Employee Costs -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Other Employee Costs</span>
                  </div>
               </v-col>
            </v-row>

            <!-- Other Employee Costs items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Payroll Taxes</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="otherEmployeeCosts.payrollTaxes"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Pension / Other</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="otherEmployeeCosts.pensionOrOther"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Advertising -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Advertising</span>
                  </div>
               </v-col>
            </v-row>

            <!-- Advertising items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Google Adwords</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.googleAdwords"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">FB Ads</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.fbAds"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">GDN Marketing</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.gdnMarketing"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">SEO</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.seo"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Radio</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.radio"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Newspaper & Other Print Media</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.newspaperAndOtherPrintMedia"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Trade Shows</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.tradeShows"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Direct Mail</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.directMall"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">MISC</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.misc"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Bad Debts</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.badDebts"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Collection Costs</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.collectionCosts"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Customer Satisfaction Costs</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.customerSatisfactionCosts"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Bank Charges & Fees</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.bankChargesAndFees"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Credit Card Expenses</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.creditCardExpenses"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Depreciation</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.depreciation"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Professional Fees</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.professionalFees"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Dues & Subscriptions, Softwares</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="advertising.dueAndSubcriptionsSoftwares"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>
            
            <!-- Insurance - Business -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Insurance - Business</span>
                  </div>
               </v-col>
            </v-row>

            <!-- Insurance - Business items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">General Liability</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.generalLiability"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Workers Comp-Non-Field</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.workersCompNonField"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Building Insurance</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.buildingInsurance"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Vehicle Insurance</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.vehicleInsurance"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Licenses & Bonds</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.licenseAndBonds"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>
            
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Meals & Entertainment</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.mealsAndEntertainment"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Miscellaneous</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="insuranceBusiness.miscellaneous"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Office Supplies -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Office Supplies</span>
                  </div>
               </v-col>
            </v-row>
            <!-- Office Supplies items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Office Forms</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="officeSupplies.officeForms"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">MISC</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="officeSupplies.misc"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Computer Expense</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="officeSupplies.computerExpense"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Cleaner</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="officeSupplies.cleaner"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Postage Expense</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="officeSupplies.postageExpense"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Professional Fees -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Professional Fees</span>
                  </div>
               </v-col>
            </v-row>
            <!-- Professional Fees items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Accounting /Ext Bookkeeper</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.accountingOrExtBookkeeper"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Legal</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.legal"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Consulting (Cloud Specialist)</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.consultingCloudSpecialist"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Rent-Building</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.rentBuilding"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Uniforms</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.uniforms"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Other Office Equipment Rental</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.otherOfficeEquipmentRental"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Repairs & Maintenance</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.repairAndMaintenance"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Small Tools</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.smallTools"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Shop Supplies</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.shopSupplies"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Training/Education (Trained Group)</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.trainingOrEducationTrainedGroup"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Travel / Overnights</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="professionalFees.travelOrOvernights"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Utilities -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Utilities</span>
                  </div>
               </v-col>
            </v-row>
            <!-- Utilities items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Office Telephone</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.officeTelephone"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Mobile Phones</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.mobilePhones"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Internet</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.internet"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Water</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.water"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Gas</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.gas"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Electric</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.electric"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Rubbish</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.rubbish"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Rates</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.rates"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Council</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="utilities.council"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Vehicle Expense -->
            <v-row>
               <v-col cols="12" lg="12">
                  <div class="grey lighten-1 pa-1">
                     <span class="white--text title">Vehicle Expense</span>
                  </div>
               </v-col>
            </v-row>
            <!-- Vehicle Expense items -->
            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Fuel</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.fuel"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Tolls</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.tolls"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Maintenance</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.maintenance"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Tax & Insurance </p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.taxAndInsurance"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Tyres</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.tyres"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Lease Expense</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                  v-model.number="vehicleExpense.leaseExpense"
                  required
                  prefix="€"
                  type="number"
                  @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Purchase expense</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                  v-model.number="vehicleExpense.purchaseExpense"
                  required
                  prefix="€"
                  type="number"
                  @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row>
               <v-col lg="6" md="6" sm="6" xs="2" class="text-center">
                  <p class="text-h6">Other</p>
               </v-col>
               <v-col lg="6" md="6" sm="6" xs="2">
                  <v-text-field
                     v-model.number="vehicleExpense.other"
                     required
                     prefix="€"
                     type="number"
                     @keypress="onlyNumber"
                  ></v-text-field>
               </v-col>
            </v-row>

            <!-- Total Expenses -->
            <v-card class="px-4">
               <v-row>
                  <v-col lg="6" md="6" sm="6" xs="2" class="d-flex align-center">
                     <p class="text-h2">Total Expenses:</p>
                  </v-col>
                  <v-col lg="6" md="6" sm="6" xs="2">
                     <v-text-field
                        :value="totalOverhead"
                        :readonly="true"
                        prepend-icon="€"
                        type="number"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12" class="text-end">
                     <v-btn
                        color="primary"
                        depressed
                        @click="save"
                     >
                        Save
                     </v-btn>
                  </v-col>
               </v-row>
               
            </v-card>
            <v-btn
               color="primary"
               large
               dark
               bottom
               left
               fixed
               class="v-btn--example"
               >
               {{ `€ ${totalOverhead.toFixed(2)}` }}
            </v-btn>
            <v-btn
               color="success"
               large
               dark
               bottom
               left
               fixed
               class="v-btn--example mb-16"
               @click="save"
               >
               Save
            </v-btn>
            <v-btn
               color="error"
               large
               dark
               top
               right
               fixed
               class="v-btn--example mr-2 mt-n2"
               @click="clear"
               >
               Clear
            </v-btn>
         </v-container>
      </v-card>
      
   </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { DigitsOnly } from '@/utils/digit';
// DigitsOnly();
export default {
   props: {
      showOverHeadCalculatorDialog: {
         type: Boolean,
         default: false
      },
      id: {
         type: Number,
         default: 0,
      },
   },
   watch: {
      id: {
         handler(newValue, oldValue) {
            this.getItems(newValue);
            console.log('oldValue ',oldValue);
         },
         deep: true
      }
   },
   data() {
      return {
         // SALARIES
         salaries: {
            businessOwnerSalary: 0,
            accountant: 0,
            salesExec: 0,
            marketingExec: 0,
            warehouseStaff: 0,
            officeManager: 0,
            officeAdmin: 0,
            other1: 0,
            other2: 0,

            // businessOwnerSalary: 5,
            // accountant: 5,
            // salesExec: 5,
            // marketingExec: 5,
            // warehouseStaff: 5,
            // officeManager: 5,
            // officeAdmin: 5,
            // other1: 5,
            // other2: 5,
         },
         // OTHER EMPLOYEE COSTS
         otherEmployeeCosts: {
            payrollTaxes: 0,
            pensionOrOther: 0,

            // payrollTaxes: 5000,
            // pensionOrOther: 5000,
         },
         // ADVERTISING
         advertising: {
            googleAdwords: 0,
            fbAds: 0,
            gdnMarketing: 0,
            seo: 0,
            radio: 0,
            newspaperAndOtherPrintMedia: 0,
            tradeShows: 0,
            directMall: 0,
            misc: 0,
            badDebts: 0,
            collectionCosts: 0,
            customerSatisfactionCosts: 0,
            bankChargesAndFees: 0,
            creditCardExpenses: 0,
            depreciation: 0,
            professionalFees: 0,
            dueAndSubcriptionsSoftwares: 0,

            // googleAdwords: 5,
            // fbAds: 5,
            // gdnMarketing: 5,
            // seo: 5,
            // radio: 5,
            // newspaperAndOtherPrintMedia: 5,
            // tradeShows: 5,
            // directMall: 5,
            // misc: 5,
            // badDebts: 5,
            // collectionCosts: 5,
            // customerSatisfactionCosts: 5,
            // bankChargesAndFees: 5,
            // creditCardExpenses: 5,
            // depreciation: 5,
            // professionalFees: 5,
            // dueAndSubcriptionsSoftwares: 5,
         },
         // INSURANCE - BUSINESS
         insuranceBusiness: {
            generalLiability: 0,
            workersCompNonField: 0,
            buildingInsurance: 0,
            vehicleInsurance: 0,
            licenseAndBonds: 0,
            mealsAndEntertainment: 0,
            miscellaneous: 0,

            // generalLiability: 5,
            // workersCompNonField: 5,
            // buildingInsurance: 5,
            // vehicleInsurance: 5,
            // licenseAndBonds: 5,
            // mealsAndEntertainment: 5,
            // miscellaneous: 5,
         },

         // OFFICE SUPPLIES
         officeSupplies: {
            officeForms: 0,
            misc: 0,
            computerExpense: 0,
            cleaner: 0,
            postageExpense: 0,

            // officeForms: 5,
            // misc: 5,
            // computerExpense: 5,
            // cleaner: 5,
            // postageExpense: 5,
         },

         // PROFESSIONAL FEES
         professionalFees: {
            accountingOrExtBookkeeper: 0,
            legal: 0,
            consultingCloudSpecialist: 0,
            rentBuilding: 0,
            uniforms: 0,
            otherOfficeEquipmentRental: 0,
            repairAndMaintenance: 0,
            smallTools: 0,
            shopSupplies: 0,
            trainingOrEducationTrainedGroup: 0,
            travelOrOvernights: 0,

            // accountingOrExtBookkeeper: 5,
            // legal: 5,
            // consultingCloudSpecialist: 5,
            // rentBuilding: 5,
            // uniforms: 5,
            // otherOfficeEquipmentRental: 5,
            // repairAndMaintenance: 5,
            // smallTools: 5,
            // shopSupplies: 5,
            // trainingOrEducationTrainedGroup: 5,
            // travelOrOvernights: 5,
         },

         // UTILITIES
         utilities: {
            officeTelephone: 0,
            mobilePhones: 0,
            internet: 0,
            water: 0,
            gas: 0,
            electric: 0,
            rubbish: 0,
            rates: 0,
            council: 0,

            // officeTelephone: 5,
            // mobilePhones: 5,
            // internet: 5,
            // water: 5,
            // gas: 5,
            // electric: 5,
            // rubbish: 5,
            // rates: 5,
            // council: 5,
         },

         // VEHICLE EXPENSE
         vehicleExpense: {
            fuel: 0,
            tolls: 0,
            maintenance: 0,
            taxAndInsurance: 0,
            tyres: 0,
            leaseExpense: 0,
            purchaseExpense: 0,
            other: 0,

            // fuel: 5,
            // tolls: 5,
            // maintenance: 5,
            // taxAndInsurance: 5,
            // tyres: 5,
            // leaseExpense: 5,
            // purchaseExpense: 5,
            // other: 5,
         },
         totalOverheadToSave: 0,
      }
   },
   computed: {
      ...mapGetters({
         listOfHourlyRateCalculated: "clientstate/getAllHourlyRateGetters",
         hourlyRate: "clientstate/getSingleHourlyRate",
      }),
      totalOverhead() {
         // SALARIES
         let totalSalaries = 
            Number(this.salaries.businessOwnerSalary) +
            Number(this.salaries.accountant) +
            Number(this.salaries.salesExec) +
            Number(this.salaries.marketingExec) +
            Number(this.salaries.warehouseStaff) +
            Number(this.salaries.officeManager) +
            Number(this.salaries.officeAdmin) +
            Number(this.salaries.other1) +
            Number(this.salaries.other2);
         // OTHER EMPLOYEE COSTS
         let totalotherEmployeeCosts = Number(this.otherEmployeeCosts.payrollTaxes) + Number(this.otherEmployeeCosts.pensionOrOther);
         // ADVERTISING
         let totalAdvertising = 
            Number(this.advertising.googleAdwords) +
            Number(this.advertising.fbAds) +
            Number(this.advertising.gdnMarketing) +
            Number(this.advertising.seo) +
            Number(this.advertising.radio) +
            Number(this.advertising.newspaperAndOtherPrintMedia) +
            Number(this.advertising.tradeShows) +
            Number(this.advertising.directMall) +
            Number(this.advertising.misc) +
            Number(this.advertising.badDebts) +
            Number(this.advertising.collectionCosts) +
            Number(this.advertising.customerSatisfactionCosts) +
            Number(this.advertising.bankChargesAndFees) +
            Number(this.advertising.creditCardExpenses) +
            Number(this.advertising.depreciation) +
            Number(this.advertising.professionalFees) +
            Number(this.advertising.dueAndSubcriptionsSoftwares);
            
         // INSURANCE - BUSINESS
         let totalInsuranceBusiness =
            Number(this.insuranceBusiness.generalLiability) +
            Number(this.insuranceBusiness.workersCompNonField) +
            Number(this.insuranceBusiness.buildingInsurance) +
            Number(this.insuranceBusiness.vehicleInsurance) +
            Number(this.insuranceBusiness.licenseAndBonds) +
            Number(this.insuranceBusiness.mealsAndEntertainment) +
            Number(this.insuranceBusiness.miscellaneous);

         // OFFICE SUPPLIES
         let totalOfficeSupplies = 
            Number(this.officeSupplies.officeForms) +
            Number(this.officeSupplies.misc) +
            Number(this.officeSupplies.computerExpense) +
            Number(this.officeSupplies.cleaner) +
            Number(this.officeSupplies.postageExpense);

         // PROFESSIONAL FEES
         let totalProfessionalFees = 
            Number(this.professionalFees.accountingOrExtBookkeeper) +
            Number(this.professionalFees.legal) +
            Number(this.professionalFees.consultingCloudSpecialist) +
            Number(this.professionalFees.rentBuilding) +
            Number(this.professionalFees.uniforms) +
            Number(this.professionalFees.otherOfficeEquipmentRental) +
            Number(this.professionalFees.repairAndMaintenance) +
            Number(this.professionalFees.smallTools) +
            Number(this.professionalFees.shopSupplies) +
            Number(this.professionalFees.trainingOrEducationTrainedGroup) +
            Number(this.professionalFees.travelOrOvernights);

         // UTILITIES
         let totalUtilities = 
            Number(this.utilities.officeTelephone) +
            Number(this.utilities.mobilePhones) +
            Number(this.utilities.internet) +
            Number(this.utilities.water) +
            Number(this.utilities.gas) +
            Number(this.utilities.electric) +
            Number(this.utilities.rubbish) +
            Number(this.utilities.rates) +
            Number(this.utilities.council);

         // VEHICLE EXPENSE
         let totalVehicleExpense = 
            Number(this.vehicleExpense.fuel) +
            Number(this.vehicleExpense.tolls) +
            Number(this.vehicleExpense.maintenance) +
            Number(this.vehicleExpense.taxAndInsurance) +
            Number(this.vehicleExpense.tyres) +
            Number(this.vehicleExpense.leaseExpense) +
            Number(this.vehicleExpense.purchaseExpense) +
            Number(this.vehicleExpense.other);

         // let totalOverheadCosts = 
         //    Number(totalSalaries) +
         //    Number(totalotherEmployeeCosts) +
         //    Number(totalAdvertising) +
         //    Number(totalInsuranceBusiness) +
         //    Number(totalOfficeSupplies) +
         //    Number(totalProfessionalFees) +
         //    Number(totalUtilities) +
         //    Number(totalVehicleExpense);
         let totalOverheadCosts = 
            parseFloat(totalSalaries) +
            parseFloat(totalotherEmployeeCosts) +
            parseFloat(totalAdvertising) +
            parseFloat(totalInsuranceBusiness) +
            parseFloat(totalOfficeSupplies) +
            parseFloat(totalProfessionalFees) +
            parseFloat(totalUtilities) +
            parseFloat(totalVehicleExpense);
         // let totalOverheadCosts = 
         //    totalSalaries +
         //    totalotherEmployeeCosts +
         //    totalAdvertising +
         //    totalInsuranceBusiness +
         //    totalOfficeSupplies +
         //    totalProfessionalFees +
         //    totalUtilities +
         //    totalVehicleExpense;

         return parseFloat(totalOverheadCosts);
      } //totalOverhead
   },
   methods: {
      onlyNumber ($event) {
         // console.log($event.keyCode); //keyCodes value
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
         if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
         }
      },
      ...mapActions({
         saveToStore: 'clientstate/actionSaveToStore'
      }),

      getItems(calculatorId) {
         if(calculatorId >= 1) {
            const overheadData = this.hourlyRate(calculatorId);

            overheadData.map((item) => {
               this.salaries           = item.salaries
               this.otherEmployeeCosts = item.other_employee_costs
               this.advertising        = item.advertising
               this.insuranceBusiness  = item.insurance_business
               this.officeSupplies     = item.office_supplies
               this.professionalFees   = item.professional_fees
               this.utilities          = item.utilities
               this.vehicleExpense     = item.vehicle_expense
            })
         }
      },
      save() {
         this.$emit('compute',this.totalOverhead);
         
         // set all the accumulated variables to store to access in saving of calculated values
         let payload = {
            salaries:           this.salaries,
            otherEmployeeCosts: this.otherEmployeeCosts,
            advertising:        this.advertising,
            insuranceBusiness:  this.insuranceBusiness,
            officeSupplies:     this.officeSupplies,
            professionalFees:   this.professionalFees,
            utilities:          this.utilities,
            vehicleExpense:     this.vehicleExpense,
         }
         console.log('payload', payload);
         this.saveToStore(payload);
      },
      clear() {
         // Object.assign({}, this.salaries);
         Object.assign(this.$data, this.$options.data())
      },
      close() {
         this.$emit('closeDialog',false);
      }
   }
}
</script>