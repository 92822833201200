<template>
  <v-container 
    fluid
  >
    <v-row>
      <v-col
        class="col-lg-3"
      >
        <v-card
          fill-height
          elevation="1"
          class="overflow-auto"
          :min-height="height"
        >
          <v-card-title
            class="d-flex flex-nowrap"
          >
            <v-select
              elevation="0"
              outlined
              dense
              :items="items"
              item-text="text"
              item-value="value"
              v-model="select"
              hide-details
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-2"
              small
              color="error"
              @click="previous"
            >
              Back
            </v-btn>
            <!-- <v-btn
              class="ml-1"
              small
              color="primary"
              @click="submitTick"
              :disabled="selected == 0 ? true : false"
            >
              Save
            </v-btn> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list subheader>
              <v-list-group
                v-for="(item, i) in courselist"
                :key="i"
                no-action
                v-model="item.active"
                :close-on-content-click="false"
              >
                <template
                  v-slot:activator
                >
                  <v-list-item-content
                  >
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(child, childi) in item.get_child"
                  :key="childi"
                >
                  <v-list-item-content
                    style="cursor: pointer;"
                    @click="openVideo(child)"
                  >
                    <v-list-item-title
                      class="d-flex flex-nowrap align-center"
                    >
                      <v-simple-checkbox
                        :ripple="false"
                        :value="Boolean(child[select])"
                        class="ml-1"
                        :disabled="checkboxNull"
                        @click="tickLock(child, select)"
                      ></v-simple-checkbox>
                      {{ child.name.length >= 38 ? child.name.substring(0, 38, -3) + '...' : child.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="col-lg-9"
      >
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListItem from '../../../components/ListItem'
import { mapGetters } from 'vuex'

  export default {
    name: 'video-accessibility',
    component: {
      ListItem
    },
    data: () => ({
      items: [
        {
          text: 'Bridge (5-9 Locked)',
          value: 'bridge'
        },
        {
          text: 'Mastermind (5-9 Unlocked)',
          value: 'mastermind'
        }
      ],
      select: null,
      height: window.innerHeight - 100,
      checkbox: false,
      selected: [],
      selectedParent: null
    }),
    computed: {
      ...mapGetters({
        courselist:   'adminmain/getCourseList'
      }),
      checkboxNull() {
        return !this.select ? true : false;
      },
      
    },
    methods: {
      showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
      },
      async getCourse() {
        await this.$axios.get(`courses/getcourselist?id=${ this.$route.params.id }`)
          .then(({data}) => {
            console.log(data);
          }).catch(err => err);
      },
      openVideo(obj) {
        const { id, name, icon, video_url, file_url, contents, additional_files } = obj;
        scrollTo(0,0);
        this.$router.push({ name: 'video-preview', query: { id: id, name: name, icon: icon, video_url: video_url, file_url: file_url, contents: contents, addiitonal_files: additional_files } }).catch(err => err);
      },
      async tickLock(obj, str) {
        if(this.select) {
        const data = {
          ...obj,
          str,
        }
        await this.$axios.get('courses/tickLock?', data)
          .then(({data}) => {
            if(data.response) {
              this.$store.dispatch('adminmain/setCourseList', data.data);
            }
          }).catch(err => err);
        } else {
          this.showSnackBar('Please select a category first!');
        }
      },
      // async submitTick() {
      //   const data = {
      //     items: this.selected,
      //     str: this.select
      //   }

      //   await this.$axios.get('courses/tickLock', data)
      //     .then(({data}) => {
      //       console.log(data);
      //     }).catch(err => err);
      // },
      previous() {
        this.$router.go(-1);
      },
    },
    mounted() {
      // this.getCourse();
    },
    watch: {

    }
  }
</script>

<style scoped>
</style>