<template>
<v-container fluid class="flex">
      <v-col cols="12">
        <v-icon class="mr-3">{{items.icon}}</v-icon><strong>{{items.name}}</strong>
        <v-spacer></v-spacer>
        <v-btn 
          color="info" 
          dark
          @click="markdone"
        >
          Mark as complete
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12">
        <div v-if="items.video_url">
          <video :key="`${path}${items.video_url}`" width="100%" height="100%" controls controlsList="nodownload">
            <source :src="`${path}${items.video_url}`" type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </div>
        <div
            v-if='("file_url" in this.items)'
            class="mt-2"
          >
            <div
              v-for="(item, itemindex) in items.file_url"
              :key="itemindex"
            >
              <div
                v-if="item.split('.').pop() === 'xlsx' || item.split('.').pop() === 'docx'"
              >
                <VueDocPreview :value="`${path}${item}`" type="office"></VueDocPreview>
              </div>
              <div
                v-if="item.split('.').pop() === 'pdf'"
              >
                <!-- <PdfVuer :src="`${path}${item}`"></PdfVuer> -->
                <iframe :src="`${path}${item}`" width="100%" height="1000px"></iframe>
              </div>
            </div>
          </div>
        <!-- ADDITIONAL VIDEO UPLOAD -->
        <div
          class="mt-2"
        >
          <v-card
            class="card"
            elevation="0"
          >
            <v-card-title>
              Additional Video Files
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions
              v-for="(param, i) in items.additional_files"
              :key="i"
            >
              <strong 
                class="ml-2"
              >{{param.name}}</strong>
              <v-spacer></v-spacer>
              <a 
                id="download"
                :href="`${path}${param.file}`"
                download
                target="_blank"
              >
                Download
              </a>
            </v-card-actions>
          </v-card>
        </div>
        <!-- END OF ADDITIONAL VIDEO UPLOAD -->
        <!-- FILE UPLOAD -->
        <div 
          v-if='("file_url" in this.items)'
          class="mt-2"
        >
          <v-card class="card" elevation="0">
            <v-card-title>
              File Uploaded
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions
              v-for="(param, i) in items.file_url"
              :key="i"
            >
              <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png
              " alt="" style="cursor:default;" width="50" height="50">
              <a :href="`${path}${param}`" v-html="`${param}`" target="_blank"></a>
              <v-spacer></v-spacer>
              <!-- <v-btn 
                color="primary"
                class="mr-5"
                @click="download(param)"
              >
                Download
              </v-btn> -->
              <a
                id="download"
                :href="`${path}${param}`"
                download
                target="_blank"
              >
                Download
              </a>
            </v-card-actions>
          </v-card>
          <small class="red--text">Click the download button, press right click on the photo and then save image.</small>
        </div>
        <!-- END OF FILE UPLOAD -->
        <!-- CONTENT -->
        <div
          v-if="items.contents !== null && items.contents !== 'null' && items.contents !== ''"
          class="mt-2"
        >
          <v-card
            elevation="0"
            class="card"
          >
            <v-card-text
              v-html="items.contents"
            >
            </v-card-text>
          </v-card>
        </div>
        <!-- END OF CONTENT -->
      </v-col>
    </v-container>
</template>

<script>
import qs from 'qs';
import VueDocPreview from 'vue-doc-preview'
import { mapGetters } from 'vuex'

// import VuePdf from 'vue-pdf';
// import PdfVuer from 'pdfvuer';


export default {
  components: {
    VueDocPreview,
    // VuePdf,
    // PdfVuer
  },
  props: [
  ],
  data: () => ({
    urlparams: {},
    path: null,
    publicPath: process.env.VUE_APP_URL,
    items: null
  }),
  mounted () {
    this.decryptLink();
  },
  created () {
    this.urlparams = this.$route.query
    this.path = process.env.VUE_APP_URL
    this.decryptLink();
  },
  computed: {
    queryString() {
      return qs.parse(this.urlparams.query);
    },

    fileExt() {
      if(this.items.file_url) {
        return this.items.file_url[0].split('.').pop();
      } else {
        return null;
      }
    },
    ...mapGetters({
      getUserData: 'auth/getUserData'
    })
  },
  methods: {
    async markdone(){
      await this.$axios.patch(`/client/markdonelesson/${this.items.id}`)
      .then(({data}) => {
        this.$store.dispatch('clientstate/setLessonItems', data.data.original.lessons)
      })
    },
    async decryptLink() {
      await this.$axios.get(`decryptLink/${this.urlparams.query}`)
        .then(({data}) => {
          this.items = data.data;
          // console.log('here =>', this.items);
        }).catch(err => err);
    },
    // async downloadFile(url, url2) {
    //   await axios({
    //     url: `${url}${url2}`,
    //     method: 'GET',
    //     responseType: 'blob',

    //   }).then((res) => {
    //     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                            
    //                         var docUrl = document.createElement('x');
    //                         docUrl.href = FILE;
    //                         docUrl.setAttribute('download', 'file.pdf');
    //                         document.body.appendChild(docUrl);
    //                         docUrl.click();
    //   });
    // }
    // async download(str) {
    //   this.$axios.get('/courses/downloadFile?image=' + str, {responseType: 'blob'})
    //     .then(({data}) => {
    //       // const url = window.URL.createObjectURL(new Blob(data.data));
    //       // const link = document.createElement('a');
    //       // link.href = url;
    //       // link.setAttribute('download', str);
    //       // document.body.appendChild(link);
    //       // link.click();
    //       let blob = fetch(data).then(r => r.blob());
    //     });
    // },
  },
  watch: {
    '$route' (to){
      this.urlparams = to.query
    }
  }
}
</script>

<style scoped lang="scss">
  #download {
    color: #fff;
    background-color: #1976d2;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    text-decoration: none;
  }
  .card {
    border: 1px solid #abb1bc;
  }
</style>