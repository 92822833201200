export default {
    namespaced: true,
    state: {
         authToken: localStorage.getItem('token') || null,
        loading: false,
        toLoad: false,
        islogged: false,
        userdata: {},
        snackbar: {
            show: false,
            message: "",
            button: false,
        },
        selectedUser: null,
        current_route: null
    },
    mutations: {
        setToLoad(state, payload){
            state.toLoad = payload
        },
        setIsLogged(state, payload){
            state.islogged = payload
        },
        setUserData(state, payload){
            state.userdata = payload
        },
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
            state.snackbar.button = v.button
        },
        setShow(state) {
            state.loading = true;
        },
        setHide(state) {
            state.loading = false;
        },
        SET_TOKEN(state, payload) {
            state.authToken = payload;
        },
        SET_SELECTED_USER(state, payload) {
            state.selectedUser = payload;
        },
        SET_CURRENT_ROUTE(state, payload) {
            state.current_route = payload;
        },
    },
    getters: {
        getToLoad(state){
            return state.toLoad
        },
        getIsLogged(state){
            return state.islogged
        },
        getUserData(state){
            return state.userdata
        },
        mSnackbar(state){
            return state.snackbar
        },
        getLoader(state){
            return state.loading
        },
        getAuthToken(state){
            return state.authToken;
        },
        getSelectedUser(state) {
            return state.selectedUser;
        },
        getCurrentRoute(state) {
            return state.current_route;
        }
    },
    actions: {
        setToLoad({commit}, v){
            commit('setToLoad', v)
        },
        setIsLogged({commit}, v){
            commit('setIsLogged', v)
        },
        setUserData({commit}, v){
            commit('setUserData', v)
        },
        setMessage({commit}, v){
            commit('setMessage', v)
        },
        setShow({ commit }){
            commit('setShow')
        },
        setHide({ commit }){
            commit('setHide')
        },
        clearAuthToken({ commit }){
            commit('SET_TOKEN',null)
        },
        setAuthToken({ commit },payload){
            commit('SET_TOKEN',payload)
        },
        setSelectedUser({ commit }, payload) {
            commit('SET_SELECTED_USER' , payload);
        },
        setCurrentRoute({ commit }, payload) {
            commit('SET_CURRENT_ROUTE', payload);
        },
        clearCurrentRoute({ commit }){
            commit('SET_CURRENT_ROUTE', null);
        },
    }
}