<template>
    <div>
        <v-autocomplete
            hide-details
            v-model="available_courses"
            auto-select-first
            class="ma-2"
            :items="items"
            item-text="course_title"
            item-value="id"
            outlined
            dense
            chips
            small-chips
            label="Choose a course"
            prepend-icon="mdi-clipboard-list-outline"
            @change="scourse"
        ></v-autocomplete>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'items'
  ],
  data: () => ({
    available_courses: null
  }),
  mounted () {
    this.$emit('scourse', this.items[0].id);
  },
  created () {
  },
  computed: {
  },
  methods: {
    scourse(data){
      this.$emit('scourse', data)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>