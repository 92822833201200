// import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        dialog: false,
        selected_roadmap: {},
    },

    getters: {
        getDialog: (state) => (state.dialog),
        getSelectedRoadmap: (state) => state.selected_roadmap
    },

    mutations: {
        SET_DIALOG: (state, payload) => (state.dialog = payload),
        SET_SELECTED_ROADMAP: (state, payload) => (state.selected_roadmap = payload)
    },

    actions: {
        setDialog: ({commit}, payload) => (commit('SET_DIALOG', payload)),
        setSelectedRoadmap: ({commit}, payload) => (commit('SET_SELECTED_ROADMAP', payload))
    }
}