<template>
    <div>
        <iframe v-if="getFileExtension(file_url) == 'pdf'" :src="`${this.path}${file_url}`" style="width: 100%;height: 80vh;" frameborder="0"></iframe>
        <iframe v-else :src="viewDocument(file_url)" style="width: 100%;height: 80vh;" frameborder="0"></iframe>        
    </div>
</template>

<script>
export default {
    data: () => ({
        path: process.env.VUE_APP_URL
    }),
    props: {
        file_url: String
    },
    methods: {
        getFileExtension(file_url) {
            const ext = file_url.split(".")
            return ext[ext.length - 1]
        },
        viewDocument(file_path) {            
            return `https://view.officeapps.live.com/op/view.aspx?src=${this.path}${file_path}`           
        }
    }    
}

</script>