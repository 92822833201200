import ClientCoursePage from "@/v3/pages/admin/courses/client/ClientCoursePage.vue"
import ClientRoadmapPage from "@/v3/pages/admin/courses/client/ClientRoadmapPage.vue"
import ClientAnalyticPage from "@/v3/pages/admin/courses/client/ClientAnalyticPage.vue"

export default [
    {
        path: '/admin/client/course/:client_id',
        component: ClientCoursePage,
        name: 'clientcoursepage',
        beforeEnter: (to, from , next) => {
            next()
        }
    },
    {
        path: '/admin/client/roadmap/:client_id',
        component: ClientRoadmapPage,
        name: 'clientroadmappage',
        beforeEnter: (to, from , next) => {
            next()
        }
    },
    {
        path: '/admin/client/analytic/:client_id',
        component: ClientAnalyticPage,
        name: 'clientanalyticpage',
        beforeEnter: (to, from , next) => {
            next()
        }
    }
]