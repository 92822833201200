<template>
  <v-container
    fill-height
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
      >
        <v-card
          max-width="50%"
          class="elevation-0 mx-auto d-flex justify-center pa-5"
          outlined
        >
          <v-card-text>
            <p>Dear {{ JSON.parse(getUserData).firstname }},</p>
            <br>
            <p>
              We regret to inform you that your trial account has now expired. We hope that during the trial period, you were able
              to experience the features and benefits of our product to the fullest extent possible.
            </p>

            <p>
              We understand that this news may be disappointing, but we encourage you to consider upgrading to a paid subscription to
              continue enjoying the benefits of the roadmap. Our team is always available to assist you.
            </p>

            <p>
              Thank you for considering our product, and we hope to see you soon.
            </p>
            
            <p>
              If you have any questions, please feel free to contact our support team.
            </p>

            <br>
            Best regards,
            <br>
            <em>Profitable Tradesmen</em>
            <br/><br/>
            <em>If you have any questions or concerns, please feel free to reach out to us at success@profitabletradesmen.com  /  07480533921</em>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {

    },
    props: [

    ],
    data: () => ({

    }),
    computed: {
      ...mapGetters({
        getUserData: 'auth/getUserData'
      }),
    },
    methods: {

    },
    mounted() {

    },
    watch: {

    }
}
</script>