import Courses from '../components/CoursesPage'
import RoadMap from '../RoadMap'
import Lesson from '../LesSon'
import Curriculum from '../components/CurriCulum'
import CurriculumChild from '../components/CurriculumChild'
import CurrentPlay from '../components/CurrentPlay'
import Preview from '../PreView'
import MainAdminLanding from '../MainLanding'
import ClientDashboard from '../ClientDashboard'
import WysiWyg from '@/components/WysiWyg'
import VideoAccessibility from '@/modules/admin/components/VideoAccessibility'
import PreviewVideoAccessibility from '@/modules/admin/components/VideoAccessibilityPreview'
import InternalUsers from '@/modules/admin/components/InternalUsers'
import OpenAI from '../components/OpenAI'
import TrackerDashboard from '@/modules/admin/TrackerDashboard'

import store from '@/states/index.js'

export default
[
    {
        path: '/clientlist',
        name: 'landing',
        component: MainAdminLanding,
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/tracker',
        name: 'tracker',
        component: TrackerDashboard
    },
    {
        path: '/clientdashboard',
        name: 'clientdashboard',
        component: ClientDashboard,
        children: [
            {
                path: '/currentplay',
                name: 'currentplay',
                component: CurrentPlay,
                beforeEnter: (to, from, next) => {
                    const token = localStorage.getItem('token');
                    if(token) {
                        next();
                    } else {
                        alert('Please login first!');
                        store.dispatch('auth/setCurrentRoute', to.query.query);
                        next('/');
                    }
                    return;
                }
            }
        ],
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/courses',
        name: 'courses',
        component: RoadMap,
        children: [
            {
                path: '/courseslist',
                name: 'coursesinner',
                component: Courses,
                // beforeEnter: (to, from, next) => {
                //     const role = JSON.parse(store.getters['auth/getUserData'])
                //     if(role.role_id === 1){
                //        next()
                //     }else{
                //        next('/')
                //     }
                //     return
                // }
            }
        ],
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/lesson/:id',
        name: 'lesson',
        component: Lesson,
        children: [
            {
                path: '/curriculum/:id',
                name: 'curriculum',
                component: Curriculum,
                // beforeEnter: (to, from, next) => {
                //     const role = JSON.parse(store.getters['auth/getUserData'])
                //     if(role.role_id === 1){
                //        next()
                //     }else{
                //        next('/')
                //     }
                //     return
                // }
            },
            {
                path: 'curriculumchild',
                name: 'curriculumchild',
                component: CurriculumChild,
                // beforeEnter: (to, from, next) => {
                //     const role = JSON.parse(store.getters['auth/getUserData'])
                //     if(role.role_id === 1){
                //        next()
                //     }else{
                //        next('/')
                //     }
                //     return
                // }
            },
        ],
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
      path: '/lesson/:id/accessibility',
      name: 'video-accessibility',
      component: VideoAccessibility,
      children: [
        {
          path: '/lesson/:id/accessibility/preview',
          name: 'video-preview',
          component: PreviewVideoAccessibility,
        //   beforeEnter: (to, from, next) => {
        //         const role = JSON.parse(store.getters['auth/getUserData'])
        //         if(role.role_id === 1){
        //         next()
        //         }else{
        //         next('/')
        //         }
        //         return
        //     }
        }
      ],
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //     next()
        //     }else{
        //     next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/preview/:id',
        name: 'preview',
        component: Preview,
        children: [
            {
                path: 'currentplay',
                name: 'currentplay',
                component: CurrentPlay,
                // beforeEnter: (to, from, next) => {
                //     const role = JSON.parse(store.getters['auth/getUserData'])
                //     if(role.role_id === 1){
                //        next()
                //     }else{
                //        next('/')
                //     }
                //     return
                // }
            }
        ],
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/wysiwyg',
        name: 'wysiwyg',
        component: WysiWyg,
        // beforeEnter: (to, from, next) => {
        //     const role = JSON.parse(store.getters['auth/getUserData'])
        //     if(role.role_id === 1){
        //        next()
        //     }else{
        //        next('/')
        //     }
        //     return
        // }
    },
    {
        path: '/internal-users',
        name: 'internal_users',
        component: InternalUsers
    },
    {
        path: '/openai',
        name: 'openai',
        component: OpenAI
    },
]