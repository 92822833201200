import { render, staticRenderFns } from "./ClientDashboard.vue?vue&type=template&id=740fd2fd&scoped=true"
import script from "./ClientDashboard.vue?vue&type=script&lang=js"
export * from "./ClientDashboard.vue?vue&type=script&lang=js"
import style0 from "./ClientDashboard.vue?vue&type=style&index=0&id=740fd2fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740fd2fd",
  null
  
)

export default component.exports