import Vue from 'vue'

export default {
    namespaced: true,
    state: {
      courselist: [],
      course: [],
      roadmapclient: [],
      todoclient: [],
      videolist: [],
      staff: null,
      tasks: null,
      internal: [],
      archived_admin: [],
      videos_tags_for_search: [],
    },
    mutations: {
      setCourseList(state, payload){
        state.courselist = [...payload];
      },
      setCourse(state, payload){
        const data = payload.reduce(function(previous, current) {
          var object = previous.filter(object => object.name === current.name);
          if (object.length == 0) {
            previous.push(current);
          }
          return previous;
        }, []);
        state.course = [...data]
      },
      setRoadmapClient(state, payload){
        state.roadmapclient = [...payload]
      },
      setTodoClient(state, payload){
        state.todoclient = [...payload]
      },
      setVideoList(state, payload){
        state.videolist = [...payload]
      },
      setStaff(state, payload) {
        state.staff = payload
      },
      setTask(state, payload) {
        state.tasks = payload
      },
      set_internal(state, payload){
        state.internal = [...payload]
      },
      set_archived_admin(state, payload){
        state.archived_admin = [...payload]
      },
      set_videos_tags_for_search(state, payload){
        state.videos_tags_for_search = [...payload]
      }
    },
    getters: {
      getCourseList(state){
        return state.courselist
      },
      getCourse(state){
        return state.course
      },
      getRoadmapClient(state){
        return state.roadmapclient
      },
      getTodoClient(state){
        return state.todoclient
      },
      getVideoList(state){
        return state.videolist
      },
      getStaff(state) {
        return state.staff
      },
      getTask(state) {
        return state.tasks
      },
      get_internal : state => value => {
        if(value === null || value === ''){
          return state.internal
        }
        return state.internal.filter(q => {
          return q.name.toLowerCase().includes(value)
        })
      },
      get_internal_users: state => state.internal,
      get_archived_admin : state => state.archived_admin,
      get_set_videos_tags_for_search : state => value => {
        let data_empty_search_state = []
        let not_empty_search_state = []
        let init = 0
        state.videos_tags_for_search.map(q => {
          let nt = []
          q.tags.map(qq => {
            nt.push(qq.toLowerCase())
          })
          q.tags = nt
          if(init < 12){
            data_empty_search_state.push(q)
            init++
          }
          not_empty_search_state.push(q)
        })
        if(value === null || value === ''){
          return data_empty_search_state
        }
        return not_empty_search_state.filter(q => {
          return q.tags.includes(value)
          || q.name.toLowerCase().includes(value)
        })
      },
      get_all_search_videos_tags: (state) => state.videos_tags_for_search
    },
    actions: {
      setCourseList({commit}, payload){
        commit('setCourseList', payload)
      },
      setCourse({commit}, payload){
        commit('setCourse', payload)
      },
      setRoadmapClient({commit}, payload){
        commit('setRoadmapClient', payload)
      },
      setTodoClient({commit}, payload){
        commit('setTodoClient', payload)
      },
      setVideoList({commit}, payload){
        commit('setVideoList', payload)
      },
      setStaff({commit}, payload) {
        commit('setStaff', payload)
      },
      setTask({ commit }, payload) {
        commit('setTask', payload);
      },
      async set_internal({commit}){
        await Vue.axios.get('/internal/users/get_internal_users')
        .then(({data}) => {
          let internals = []
          let deleted = []
          data.data.forEach(q => {
            if(q.deleted_at === null){
              internals.push(q)
            }else{
              deleted.push(q)
            }
          })
          commit('set_internal', internals)
          commit('set_archived_admin', deleted)
        })
      },
      videos_tags_for_search({commit}, payload){
        // console.log(commit, payload)
        commit('set_videos_tags_for_search', payload)
      }
    }
}