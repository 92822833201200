import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import AuthState from '../states/auth/auth.js'
import AdminMainPage from '../modules/admin/states/main.js'
import ClientState from '../modules/client/states/main.js'
import AdminClientDBoard from '../modules/admin/states/adminclientdboard.js'
import InternalUserState from '@/modules/internal/dashboard/states/index.js'
import RoadmapState from '@/modules/client/states/roadmapState.js'
import TemplateLibraryState from '../../src/components/templatelibrary/states/index.js'
import Course from '@/v2/modules/admin/courses/states/course.js';
import ClientCourse from '@/v2/modules/client/course/states/course.js';

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        auth:                   AuthState,
        adminmain:              AdminMainPage,
        clientstate:            ClientState,
        adminclientdboard:      AdminClientDBoard,
        internal:               InternalUserState,
        roadmapstate:           RoadmapState,
        templatelibrarystate:   TemplateLibraryState,
        course:                 Course,
        clientcourse:           ClientCourse
    },
    plugins: [createPersistedState()]
})

export default store