import Vue from 'vue';

export default class Course {

    constructor() {

    }

    get_course_data_by_users_id(users_id, store) {
        Vue.axios.get(`clients/course/get_course_by_users_id/${users_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    store.dispatch('clientcourse/set_users_progress_action', result.data)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    update_course_data_by_id(course_id, data, store) {        
        Vue.axios.patch(`clients/course/update_ucourse_by_id/${course_id}`, data).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    store.dispatch('clientcourse/set_update_users_progress_action', result.data)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    get_video_courses_by_tags(course_id, users_id, store) {
        Vue.axios.get(`/adminclientdboard/gcourse/${course_id}/${users_id}`)
        .then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    let for_search_data = []
                    result.data.map(q => {
                            q.get_child.map(qq => {
                            for_search_data.push(qq)
                        })
                    })
                    store.dispatch('clientstate/set_issues_admin', `${users_id}`);
                    store.dispatch('adminclientdboard/videos_tags_for_search', for_search_data)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    getCourseLessonBasedLink(link, store) {
        Vue.axios.get(`clients/course/get_course_lesson_basedon_link/${link}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    // Put the link into a state
                    store.dispatch('clientcourse/setLinkDataAction', result.data)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    // Get only data from 30 days ago.
    getLatestCourseLessons(course_id, store) 
    {
        Vue.axios.get(`admin/course/get_latest_lessons/${course_id}`).then( (data) => {
            const result = data.data
            store.dispatch('clientcourse/setLatestCourseContentsAction', result.data)
        })
    }
}