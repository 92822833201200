import Vue from 'vue'

export default {
    namespaced: true,
    state: {
      clientlist: [],
      clientlist_deleted: [],
      currentenrolledclients: [],
      test: false,
      client: {},
      coursesitem: [],

      // lesson parent list
      courselist: [],

      // lesson child list
      coursechildlist: [],
      preview: {},
      previewlists: [],
      parentchapterdata: {},
      selectedcourse: null,
      tags: null,

      listOfInternalUsers: [],
      tagLoadingStatus: false,
      selectedLesson: null,
      selectedLessonChild: null,
      issueCategory: null,
      subIssueCategory: null,
      issueVideoList: null,
      search_issue_category: null,
      membership_type: [],

      // tracker
      tracker_table: null,
      tracker_users: [],
      tracker_roadmap_parent: [],

      // notes
      note_internal_user: null,
    },
    mutations: {
      setToTest(state, payload){
        state.test = payload
      },
      setClientList(state, payload){
        state.clientlist = [...payload]
      },
      set_clientlist_deleted(state, payload){
        state.clientlist_deleted = [...payload]
      },
      setClient(state, payload){
        state.clientlist.push(payload)
      },
      setCourseItem(state, payload){
        state.coursesitem = [...payload]
      },
      setCourseList(state, payload){
        state.courselist = [...payload]
      },
      setCourseChildList(state, payload) {
        state.coursechildlist = [...payload]
      },
      setPreview(state, payload){
        state.preview = payload
      },
      setPreviewLists(state, payload){
        state.previewlists = [...payload]
      },
      setParentChapterData(state, payload){
        state.parentchapterdata = payload
      },
      setCurrentEnrolledClients(state, payload) {
        state.currentenrolledclients = [...payload]
      },
      setSelectedCourse(state, payload) {
        state.selectedcourse = payload
      },
      setTags(state, payload) {
        state.tags = payload;
      },

      SET_TAG_LOADING_STATE(state, payload) {
         state.tagLoadingStatus = payload;
      },
      SET_INTERNAL_USERS_LIST(state, payload) {
         state.listOfInternalUsers = payload;
      },
      SET_SELECTED_LESSON(state, payload) {
        state.selectedLesson = payload;
      },
      SET_SELECTED_LESSON_CHILD(state, payload) {
        state.selectedLessonChild = payload;
      },
      SET_ISSUE_CATEGORY(state, payload) {
        state.issueCategory = [...payload];
      },
      SET_ISSUE_SUB_CATEGORY(state, payload) {
        state.subIssueCategory = [...payload];
      },
      SET_ISSUE_VIDEO_LIST(state, payload) {
        state.issueVideoList = [...payload];
      },
      ADD_VIDEO_LIST(state, payload) {
        state.issueVideoList.push(payload);
      },
      REMOVE_VIDEO_LIST(state, index) {
        state.issueVideoList.splice(index, 1);
      },
      SET_SEARCH_ISSUE_CATEGORY(state, payload) {
        state.search_issue_category = [...payload];
      },
      set_membership_type(state, payload){
        state.membership_type = [...payload]
      },
      SET_TRACKER_TABLE(state, payload) {
        let ongoing = payload.filter(a => a.progress != 100);
        let completed = payload.filter(a => a.progress == 100)
        let header = [
          { value: ''  },
          { value: 'name' },
          { text: 'Task' },
          { text: 'Progress', value: 'progress' },
          { text: 'Assigned', value: 'tagged_to' },
          { text: 'Priority', value: 'color' },
          { text: 'Deadline', value: 'deadline_date' },
          { text: 'Client', value: 'user_id' },
          { text: 'Notes', value: 'notes' },
        ];

        state.tracker_table = {
          ongoing: {btn_name: 'on going', color: 'orange darken-3', header: header, active: false, items: [...ongoing]},
          completed: {btn_name: 'completed', color: 'green darken-4', header: header, active: false, items: [...completed]},
        }
      },
      SET_TRACKER_USER(state, payload) {
        state.tracker_users = [...payload];
      },
      SET_ROADMAP_PARENTS(state, payload) {
        state.tracker_roadmap_parent = [...payload];
      },
      SET_NOTE_INTERNAL_USER(state, payload) {
        state.note_internal_user = state.listOfInternalUsers.filter(a => a.name == payload);
      }
    },
    getters: {
      getToTest(state){
        return state.test
      },
      getClientList : (state) => (search) => {
        if(search === null){
          return state.clientlist
        }
        return state.clientlist.filter(q => {
          return q.membership_type === search
        })
      },
      getEnrolledClientList: state => (state.clientlist),
      getCourseItem(state){
        return state.coursesitem
      },
      getCourseList(state){
        return state.courselist
      },
      getCourseChildList(state) {
        return state.coursechildlist
      },
      getPreview(state){
        return state.preview
      },
      getPreviewLists(state){
        return state.previewlists
      },
      getParentChapterData(state){
        return state.parentchapterdata
      },
      getCourseInformation : state => value => {
        return state.coursesitem.filter( q => {
          return q.id == value
        })
      },
      getCurrentEnrolledClients(state) {
        return state.currentenrolledclients
      },
      getSelectedCourse(state) {
        return state.selectedcourse
      },
      getTags(state) {
        return state.tags
      },
      // unknown function but add it because vuex trigger error
      getToEditData() {
         return;
      },

      listOfInternalUsers: (state) => state.listOfInternalUsers,
      tagLoadingStatus: (state) => state.tagLoadingStatus,
      get_clientlist_deleted : (state) => (search) => {
        if(search === null){
          return state.clientlist_deleted
        }
        return state.clientlist_deleted.filter(q => {
          return q.membership_type === search
        })
      },
      getSelectedLesson: state => state.selectedLesson,
      getSelectedLessonChild: state => state.selectedLessonChild,
      getIssueCategory: state => state.issueCategory,
      getSubIssueCategory: state => state.subIssueCategory,
      getIssueVideoList: state => state.issueVideoList,
      getSearchIssueCategory: state => state.search_issue_category,
      get_membership_type : (state) => state.membership_type,
      // getTrackerTable: state => (a) => {
      //   if(a == null || a == '') {
      //     return state.tracker_table;
      //   } else {
      //     const user = [a];
      //     let ongoing = state.tracker_table.ongoing.items.filter(b => user.includes(b.tagged_to));
      //     let complete = state.tracker_table.completed.items.filter(b => user.includes(b.tagged_to));

      //     let item = state.tracker_table = {
      //       ongoing: {...state.tracker_table.ongoing, items: ongoing},
      //       completed: {...state.tracker_table.completed, items: complete}
      //     } 

      //     // console.log(item, a);
      //     return item
      //   }
      // },
      getTrackerTable: state => state.tracker_table,
      getTrackerUser: state => state.tracker_users,
      getRoadmapParents: state => state.tracker_roadmap_parent,
      getNoteInternalUser: state => state.note_internal_user,
      getLessonFiles: (state) => {
        const s = state.courselist;

        const result = s.map(a => { 
          return a.get_child.filter(b => b.additional_files.length > 0);
        });

        const final_result = result.filter(a => a.length > 0);

        const items = [];

        for(const lessonFiles of final_result) {
          for(const obj of lessonFiles) {
            items.push(...obj.additional_files);
          }
        }

        return items;
      }
    },
    actions: {
      setToTest({commit}, v){
        commit('setToTest', v)
      },
      setClientList({commit}, v){
        commit('setClientList', v)
      },
      set_clientlist_deleted({commit}, payload){
        commit('set_clientlist_deleted', payload)
      },
      setClient({commit}, v){
        commit('setClient', v)
      },
      setCourseItem({commit}, v){
        commit('setCourseItem', v)
      },
      setCourseList({commit}, v){
        let temp = [];
        v.map((a) => {
          temp.push({ active: false, ...a });
        });
        
        commit('setCourseList', temp)
      },
      setCourseChildList({ commit }, v) {
        commit('setCourseChildList', v);
      },
      setPreview({commit}, v){
        commit('setPreview', v)
      },
      setPreviewLists({commit}, v){
        commit('setPreviewLists', v)
      },
      setParentChapterData({commit}, v){
        commit('setParentChapterData', v)
      },
      setCurrentEnrolledClients({commit}, v) {
        commit('setCurrentEnrolledClients', v)
      },
      setSelectedCourse({ commit }, v) {
        commit('setSelectedCourse', v)
      },
      setTags({ commit }, v) {
        commit('setTags', v)
      },

      async getListOfInternalUsers({ commit }) {
         commit('SET_INTERNAL_USERS_LIST', []);
         await Vue.axios.get('/adminclientdboard/getInternalUsers')
            .then((response) => {
               if(response.status == 200) {
                  commit('SET_INTERNAL_USERS_LIST', response.data.data);
               }
            });
      },

      async tagInternalUser({ commit }, payload) {
         commit("SET_TAG_LOADING_STATE", true);

         await Vue.axios.post('/adminclientdboard/tagInternalUsers',{ ...payload } )
            .then((response) => {

               if(response.status == 200) {
                  commit("SET_TAG_LOADING_STATE", false);
               }
            })
      },

      async get_users({commit}){
        await Vue.axios.get('/users/g_client_list')
        .then(({data}) => {
          if(data.response){
            let active = []
            let deleted_at = []
            data.data.forEach(q => {
              if(q.deleted_at === null){
                active.push(q)
              }else{
                deleted_at.push(q)
              }
            })
            commit('setClientList', active)
            commit('set_clientlist_deleted', deleted_at)
          }
        })
      },

      setSelectedLesson({ commit }, v) {
        commit('SET_SELECTED_LESSON', v);
      },

      setSelectedLessonChild({ commit }, v) {
        commit('SET_SELECTED_LESSON_CHILD', v);
      },

      setIssueCategory({ commit }, v) {
        commit('SET_ISSUE_CATEGORY', v);
      },
      setIssueSubCategory({commit}, v) {
        commit ('SET_ISSUE_SUB_CATEGORY', v);
      },

      setIssueVideoList({commit}, v) {
        commit('SET_ISSUE_VIDEO_LIST', v);
      },
      addVideoList({ commit }, payload) {
        commit('ADD_VIDEO_LIST', payload)
        Vue.axios.post('/course/add_video', payload)
      },
      removeVideoList({ commit }, payload) {
        commit('REMOVE_VIDEO_LIST', payload.index)
        Vue.axios.post('/course/remove_video', payload.video)
      },
      setSearchIssueCategory({commit}, v) {
        commit('SET_SEARCH_ISSUE_CATEGORY', v);
      },
      set_membership_type({commit}, payload){
        commit('set_membership_type', payload)
      },
      setTrackerTable({ commit }, payload) {
        commit('SET_TRACKER_TABLE', payload);
      },
      setTrackerUser({ commit }, payload) {
        commit('SET_TRACKER_USER', payload);
      },
      setRoadmapParents({ commit }, payload) {
        commit('SET_ROADMAP_PARENTS', payload);
      },
      setNoteInternalUser({ commit }, payload) {
        commit('SET_NOTE_INTERNAL_USER', payload);
      },
      submitNoteInternalUser(_, payload) {
        Vue.axios.post('roadmap_note/store', payload).catch(e => console.error(e.message));
      },
      submitEditedNoteInternalUser(_, payload) {
        Vue.axios.patch(`roadmap_note/edit/${payload.id}`, payload).catch(e => console.error(e.message));
      },
      deleteNoteInternalUser(_, payload) {
        Vue.axios.delete(`roadmap_note/delete/${payload}`).catch(e => console.error(e.message));
      }
    }
}