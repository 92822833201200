import { computed } from 'vue'
import ActivityFeedsClass from "../../../modules/classes/activity_feeds.class"

export default class ActivityFeedsComputed
{
    constructor() {
        this.activity_feeds_class = new ActivityFeedsClass()
    }

    computed_properties = ( () => {
        
        const getActivityFeeds = computed( () => {
            return this.activity_feeds_class.getClientsActivityFeedDataFromStore()
        })

        return { getActivityFeeds }
    })()
}