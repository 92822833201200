import Vue from 'vue';

export default class Subject {
    
    constructor() {}

    add_lesson_to_a_user( { users_id, course_id, subject_id }) {        
        Vue.axios.patch(`admin/add_subject_to_user/${users_id}/${course_id}/${subject_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    // prompt the user for successful adding lesson to a user
                    console.log('The user is successfully assigned to this lesson.')
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }    
}