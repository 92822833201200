<template>
    <div>
        <v-chip-group
            active-class="primary--text"
            color="primary"
            column>
            <v-chip
                v-for="(tag, index) in tags"
                :key="index">
                {{ tag }}
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
export default {
    props: {
        tags: Array
    }
}
</script>