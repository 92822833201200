<template>
    <v-container fluid>
      <v-col cols="12">
        <v-icon @click="$router.push({name: 'lesson', params: {id: preview_id}})" class="mr-2">mdi-arrow-left-circle</v-icon><small>You are previewing this course</small>
      </v-col>
      <v-col
        cols="12"
      >
        <DataTable 
          class="mb-5"
          :getClientList="tablevalue" 
          :headers="headers"
          :toshow="toshow"
        />
        <v-row>
          <v-col cols="3">
            <ListItem :setup="getPreview" :lists="getPreviewList" />
          </v-col>
          <v-col
            cols="9"
          >
            <router-view />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ListItem from '../../components/ListItem'
import DataTable from '../../components/DataTable'
export default {
  components: {
    ListItem, DataTable
  },
  props: [
  ],
  data: () => ({
    preview_id: null,
    button: {
      title: 'Add Client',
      icon: 'mdi-plus'
    },
    headers: [
      {
        text: 'Billable Staff | Pipelining Staff',
        align: 'start',
        sortable: false,
        value: 'people',
      },
      { text: 'Mindset', value: 'first' },
      { text: 'Leadership', value: 'second' },
      { text: 'Marketing', value: 'third' },
      { text: 'Sales', value: 'fourth' },
      { text: 'Team', value: 'fifth' },
      { text: 'SOP | Systems', value: 'sixth' },
      { text: 'Cash', value: 'seventh' },
    ],
    toshow: {
      frompreview: true,
      hidden: true
    },
    tablevalue: [
      {
        id:               1,
        people:           2,
        first:            'Quadrant',
        ischeckedfirst:   false,
        second:           'Time Management',
        ischeckedsecond:  false,
        third:            'Organic Social Media FB | Linkedin',
        ischeckedthird:   false,
        fourth:           'Sales Process SOP',
        ischeckedfourth:  false,
        fifth:            'PT / FT VA',
        ischeckedfifth:   false,
        sixth:            'Job Management',
        ischeckedsixth:   false,
        seventh:          'Hourly Rate',
        ischeckedseventh: true
      },
      {
        id:               2,
        people:           2,
        first:            'Resilience',
        ischeckedfirst:   false,
        second:           'Delegation',
        ischeckedsecond:  false,
        third:            'Google Reviews',
        ischeckedthird:   false,
        fourth:           'Wow pack',
        ischeckedfourth:  true,
        fifth:            'Job description & contracts',
        ischeckedfifth:   true,
        sixth:            'Xero | Quickboos',
        ischeckedsixth:   false,
        seventh:          'Profit before overheads',
        ischeckedseventh: true
      },
      {
        id:               3,
        people:           4,
        first:            'Morning Routine',
        ischeckedfirst:   false,
        second:           'Cloning yourself',
        ischeckedsecond:  false,
        third:            'Warm Outreach DM Builder',
        ischeckedthird:   false,
        fourth:           'Upselling',
        ischeckedfourth:  false,
        fifth:            'Hiring Funnel',
        ischeckedfifth:   false,
        sixth:            'Pricing Templates (fixed pricing)',
        ischeckedsixth:   false,
        seventh:          'Price Adjusting',
        ischeckedseventh: false
      },
      {
        id:               4,
        people:           5,
        first:            'Learning Strategy',
        ischeckedfirst:   false,
        second:           'Quarterly Reviews',
        ischeckedsecond:  false,
        third:            '3rd Party lead websites',
        ischeckedthird:   false,
        fourth:           'VA QS',
        ischeckedfourth:  false,
        fifth:            'Org Chart Design',
        ischeckedfifth:   false,
        sixth:            'Flow chart Lead > Sale > 5* Review',
        ischeckedsixth:   false,
        seventh:          'Payment Terms',
        ischeckedseventh: true
      },
      {
        id:               5,
        people:           7,
        first:            'Not to do list',
        ischeckedfirst:   false,
        second:           'Team managment / direction / meeting',
        ischeckedsecond:  false,
        third:            'Adwords',
        ischeckedthird:   false,
        fourth:           'Sales Funnel KPIs',
        ischeckedfourth:  false,
        fifth:            'Meeting Rhythm & Agendas',
        ischeckedfifth:   false,
        sixth:            'Pre | During | Post  Job Checklists',
        ischeckedsixth:   false,
        seventh:          'Job Profit Tracking',
        ischeckedseventh: false
      },
      {
        id:               6,
        people:           8,
        first:            '',
        ischeckedfirst:   false,
        second:           'Ideal week',
        ischeckedsecond:  false,
        third:            'SEO',
        ischeckedthird:   false,
        fourth:           'Template script / Followups',
        ischeckedfourth:  false,
        fifth:            'Team KPIs',
        ischeckedfifth:   false,
        sixth:            'Outsource | Automate back office',
        ischeckedsixth:   false,
        seventh:          'Back Costing Jobs',
        ischeckedseventh: false
      },
      {
        id:               7,
        people:           8,
        first:            '',
        ischeckedfirst:   false,
        second:           'Pre-loaded Year',
        ischeckedsecond:  false,
        third:            'Facebook Ads',
        ischeckedthird:   true,
        fourth:           'Weekly sales Meeting',
        ischeckedfourth:  false,
        fifth:            'Admin Staff / GM',
        ischeckedfifth:   false,
        sixth:            'Stock Management',
        ischeckedsixth:   false,
        seventh:          'Monthly P&L',
        ischeckedseventh: false
      },
      {
        id:               8,
        people:           15,
        first:            '',
        ischeckedfirst:   false,
        second:           'Leadership Training',
        ischeckedsecond:  false,
        third:            'Webinars / Events',
        ischeckedthird:   false,
        fourth:           'Business Dev executive',
        ischeckedfourth:  false,
        fifth:            'Onboarding & Performence Reviews',
        ischeckedfifth:   false,
        sixth:            'CRM',
        ischeckedsixth:   false,
        seventh:          'Cash KPI',
        ischeckedseventh: false
      },
      {
        id:               9,
        people:           20,
        first:            '',
        ischeckedfirst:   false,
        second:           'Exit Plan',
        ischeckedsecond:  false,
        third:            'Facebook Groups',
        ischeckedthird:   false,
        fourth:           'Advanced sales',
        ischeckedfourth:  false,
        fifth:            'OPs Manager',
        ischeckedfifth:   false,
        sixth:            'OPs Manual',
        ischeckedsixth:   false,
        seventh:          'Cashflow Forecasting',
        ischeckedseventh: false
      },
      {
        id:               10,
        people:           25,
        first:            '',
        ischeckedfirst:   false,
        second:           'Wealth Plan',
        ischeckedsecond:  false,
        third:            'Dream 100',
        ischeckedthird:   false,
        fourth:           'Upsells | Cross sells | Downsells',
        ischeckedfourth:  false,
        fifth:            'Management Team',
        ischeckedfifth:   false,
        sixth:            'Lean Six Sigma onsite',
        ischeckedsixth:   false,
        seventh:          'Advanced back costing',
        ischeckedseventh: false
      },
      {
        id:               11,
        people:           30,
        first:            '',
        ischeckedfirst:   false,
        second:           '',
        ischeckedsecond:  false,
        third:            'Trade shows',
        ischeckedthird:   false,
        fourth:           'Sales Team',
        ischeckedfourth:  false,
        fifth:            'Core Values',
        ischeckedfifth:   false,
        sixth:            'Admin Manual',
        ischeckedsixth:   false,
        seventh:          'P&L Forecasts & Budgets',
        ischeckedseventh: false
      }
    ]
  }),
  mounted () {
  },
  created () {
    this.preview_id = this.$route.params.id
    this.getlist()
  },
  computed: {
    ...mapGetters({
      getPreview:       'adminmain/getPreview',
      getClientList:    'adminmain/getClientList',
      getPreviewList:   'adminmain/getPreviewLists',
    })
  },
  methods: {
    async getlist(){
      await this.$axios.get(`/courses/preview/${this.preview_id}`)
      .then(({data}) => {
        this.$store.dispatch('adminmain/setPreview', data.data)
        this.$store.dispatch('adminmain/setPreviewLists', data.lists)
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>