
export default {
    namespaced: true,
    state: {        
        users_progress: [],
        link_data: [],
        latest_content: [],
    },
    mutations: {       
        set_users_progress_mutation(state, payload) {
            state.users_progress = [...payload]
        },
        set_update_users_progress_mutation(state, payload) {
            const progress = state.users_progress.filter( progress => progress.ucourse_id == payload.ucourse_id );
            if ( progress.length > 0 ) {
                // Update the user progress
                progress.hash = payload.hash
                progress.is_done = payload.is_done
            } else {
                state.users_progress.push(payload)
            }
        },
        setLinkDataMutation(state, payload) {
            state.link_data = payload
        },
        setLatestCourseContentsMutation(state, payload) {
            state.latest_content = [...payload]
        }
    },
    getters: {
        get_course_by_users_id_getter: state => state.users_progress,
        get_course_completed_count_getter: state => (course_children) => {
            if ( state.users_progress.length > 0 ) { 
                const completed_count = course_children.filter( course => {
                    const completed = state.users_progress.filter( progress => progress.ucourse_id == course.id && progress.is_done == 1)
                    if (completed.length > 0) {
                        return course
                    }
                })            
                return completed_count.length
            } else {
                return 0
            }
        },
        check_if_completed_or_not_getter: state => (ucourse_id) => {
            if ( state.users_progress.length > 0 ) {
                const is_completed = state.users_progress.filter( progress => progress.ucourse_id == ucourse_id && progress.is_done == 1)
                return is_completed.length > 0 ? true : false;
            } else {
                return false
            }
        },
        getUserProgressByUsersIdUCourseIdGetter: state => (users_id, ucourse_id) => {            
            const result = state.users_progress.filter( progress => progress.users_id == users_id && progress.ucourse_id == ucourse_id )
            return result.length > 0 ? result : null
        },
        getLinkDataGetter: state => state.link_data,
        getLatestCourseContentsGetter: state => state.latest_content
    },
    actions: {
        set_users_progress_action({commit}, payload) {
            commit('set_users_progress_mutation', payload)
        },
        set_update_users_progress_action({commit}, payload) {
            commit('set_update_users_progress_mutation', payload)
        },
        setLinkDataAction({commit}, payload) {
            commit('setLinkDataMutation', payload)
        },
        setLatestCourseContentsAction( {commit}, payload ) {
            commit('setLatestCourseContentsMutation', payload)
        }
    }
}