<template>
    <div>
        <video controls style="height: 75vh;width: 100%;" ref="video" 
            playsinline controlsList="nodownload" @click="eventTrackedWatchDuration"
            @play="eventClientTrackedData"
            @pause="eventClientTrackedData"
            @timeupdate="eventTrackedWatchDuration" @abort="eventClientTrackedData"
            @ended="eventClientTrackedData">
            <source :src="source" type="video/mp4" />
        </video>
    </div>
</template>

<script>
import ClientAnalytics from '../../modules/ClientAnalytics'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        path: process.env.VUE_APP_URL,
        source: null,
        watched_duration: 0,
        client_analytics: new ClientAnalytics()
    }),
    props: {
        lesson: Object,
        users_id: Number
    },
    computed: {
        ...mapGetters({
            getUserProgressByUsersIdUCourseId: 'clientcourse/getUserProgressByUsersIdUCourseIdGetter'
        })
    },
    mounted() {
        this.source = `${this.path}${this.lesson.video_url}`
        this.$refs.video.load()
        //console.log(this.lesson)
    },
    methods: {
        eventClientTrackedData() {            
            this.client_analytics.setStore(this.$store)
            this.client_analytics.setUsersId(this.users_id)
            this.client_analytics.setUCourseId(this.lesson.id)
            this.client_analytics.setAnalyticalData({
                user_progress_id: this.getUserProgressByUsersIdUCourseId(this.users_id, this.lesson.id) != null ? this.getUserProgressByUsersIdUCourseId(this.users_id, this.lesson.id)[0].id : -1,
                watch_duration: this.watched_duration
            })
            this.client_analytics.saveClientVideoTrackedData()
        },
        eventTrackedWatchDuration(event) {
            if ( event.srcElement.currentTime > 0 ) {
                this.watched_duration = parseInt(event.srcElement.currentTime)
            }
        }
    },
    watch: {
        lesson() {
            this.source = `${this.path}${this.lesson.video_url}`
            this.$refs.video.load()
        }
    }
}
</script>