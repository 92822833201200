import Vue from "vue"

export default{
    namespaced: true,
    state: {
        data: [],
        toEditData: null
    },
    mutations: {
        set_tagged_data(state, payload){
            state.data = [...payload]
        },
        SET_EDIT_DATA(state, payload) {
            state.toEditData = {...payload};
        }
    },
    actions: {
        async set_tagged_data({commit}, payload){
            await Vue.axios.get(`/internal/tagged/get_tagged/${payload}`)
            .then(({data}) => {
                commit('set_tagged_data', data.data)
            })
        },

        setEditData({commit}, payload) {
            commit('SET_EDIT_DATA', payload);
        }
    },
    getters: {
        get_tagged_data : state => (search) => {
            if(search === null || search === ''){
                return state.data
            }
            return state.data.filter(q => {
                return q.name.toLowerCase().includes(search)
            })
        },
        getToEditData: state => state.toEditData
    }
}