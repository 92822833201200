<template>
    <v-container fluid>
      <TheLoader 
        :visible="loader"
      />
      <div v-if="Object.keys(urlparams).length === 0">
        <Empty :emptypage="emptypage"/>
      </div>
      <div v-else>
        <TextField 
          :params="urlparams"
          :lesson="lessonchild"
          :tags="tags"
          @saveparentchanges="saveparentchanges"
          @savechildchanges="savechildchanges"
          @deletedata="deletedata"
          @deletefile="deletefile"
          @deletevideofile="deletevideofile"
          @discardchanges="discardchanges"
          @deletevideo="deletevideo"
          @submitTags="submitTags"
          @removeTag="removeTag"
          @archiveLesson="archiveLesson"
        />        
      </div>      
    </v-container>
</template>

<script>
import Empty from '../../../components/EmptyPage'
import TextField from '../../../components/TextField'
import TheLoader from '../../../components/TheLoader'
import { mapGetters } from 'vuex'

export default {
  components: {
    Empty,
    TextField,
    TheLoader
  },
  props: [
  ],
  data: () => ({
    urlparams: {},
    emptypage: {
      icon: 'mdi-book-open-page-variant',
      header: 'Choose a chapter or lesson to get started',
      subheader: 'preview your course as a student to make sure everything is looking sharp.'
    },
  }),
  created () {
  },
  computed: {
    ...mapGetters({
      loader:       'auth/getLoader',
      tags:         'adminmain/getTags',
      lessonchild:       'adminmain/getSelectedLessonChild'
    })
  },
  methods: {
    hide() {
      this.$store.dispatch('auth/setHide');
    },
    show() {
      this.$store.dispatch('auth/setShow');
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async saveparentchanges(datafromemit){
      this.show();
      await this.$axios.post('/courses/editlesson', datafromemit)
      .then(({data}) => {
        this.$store.dispatch('adminmain/setCourseList', data.data.original.data)
        this.urlparams = {}
        this.hide();
      })
    },
    async savechildchanges(datafromemit){
      this.show();
      const formData = new FormData()
      formData.append('video_url', datafromemit.video)
      formData.append('name', datafromemit.name)
      formData.append('id', datafromemit.id)
      formData.append('contents', datafromemit.contents)
      formData.append('additional_file', datafromemit.additional_file)
      formData.append('additional_file_name', datafromemit.additional_file_name)
      formData.append('tag', datafromemit.tag)
      formData.append('issue_solution', datafromemit.issue_solution)

      await this.$axios.post(`/courses/editlessoninner`, formData)
      .then(({data}) => {
        if(data.response) {
          this.$store.dispatch('adminmain/setCourseList', data.data.original.data)
          this.urlparams = {}
          this.hide();
        } else {
          this.showSnackBar('An error has occured, please contact the developer.');
          this.hide();
        }
      }).catch(err => console.error(err));
    },
    async deletedata(data){
      this.show();
      await this.$axios.delete(`/courses/deletelesson/${data.data}`)
      .then(({data}) => {
        this.$store.dispatch('adminmain/setCourseList', data.data)
        this.urlparams = {}
        this.hide();
      })
    },
    async deletefile(obj) {
      this.show();
      await this.$axios.post(`/courses/deletefile`, obj)
      .then(({data}) => {
        this.$store.dispatch('adminmain/setCourseList', data.data)
        this.urlparams = {}
        this.hide();
      })
    },
    async deletevideofile(obj) {
      this.show();
      await this.$axios.post('/courses/deleteVideoFile', obj)
        .then(({data}) => {
        this.$store.dispatch('adminmain/setCourseList', data.data)
        this.urlparams = {}
        this.hide();
        })
    },
    discardchanges() {
      this.urlparams = {};
    },
    async deletevideo(id) {
      this.show();
      await this.$axios.delete(`/courses/deletevideo/${id}`)
        .then(({data}) => {
          if(data.response) {
            this.$router.push({ name: 'courses' })
            this.urlparams = {};
            this.hide();
          }
        });
    },
    async submitTags(item) {
      this.show();
      await this.$axios.post('/courses/submitTags', item)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('adminmain/setCourseList', data.data.original.data)
            this.urlparams = {};
            this.hide();
          }
        }, () => {
          this.showSnackBar('Error! please contact the admin / developer to issue this bug.');
        }).catch(err => err);
    },
    async removeTag(item) {
      this.show();
      await this.$axios.post('/courses/removeTags', item)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('adminmain/setCourseList', data.data.original.data)
            this.urlparams = {};  
          }
          this.hide();
        }, () => {
          this.hide();
          this.showSnackBar('Error! please contact the admin / developer to issue this bug.');
          this.urlparams = null;
        }).catch(err => err);
    },
    async archiveLesson(item) {
      this.show();
      await this.$axios.post('/courses/archiveLesson', item)
        .then(({data}) => {
          if(data.response) {
            this.$store.dispatch('adminmain/setCourseList', data.data.original.data)
            this.urlparams = {};  
            this.$forceUpdate();
            // this.$router.push({ name: 'curriculumchild', query: { id: data.parent.id, name: data.parent.name, parent: true } }).catch(err => err);
          } 
          this.hide();
        }, () => {
          this.hide();
          this.showSnackBar('Error! please contact the admin / developer to issue this bug.');
          this.urlparams = null;
        }).catch(err => err);
        
    }
  },
  watch: {
    '$route' (to){
      this.urlparams = to.query
    },
  }
}
</script>

<style scoped lang="scss">
</style>