import Vue from 'vue';
// import axios from 'axios';

export default {
    namespaced: true,
    state: {
        lessonFiles: [],
        filterFile: null,
        filterCategory: null,
        textFilter: null,
        showProgress: false,
        showSnackbar: false,
        snackbarMessage: [],
        libraryCategory: []
    },

    mutations: {
        SET_SNACKBAR(state, payload) {
            state.showSnackbar = payload;
        },
        SET_TEMPLATE(state, payload) {
            state.lessonFiles = payload;
        },
        SET_FILTER_TEMPLATE(state, payload) {
            state.filterFile = payload;
        },
        SET_TEXT_FILTER(state, payload) {
            state.textFilter = payload;
        },
        SET_FILE_PROGRESS_SNACKBAR(state, payload) {
            state.snackbarMessage = payload;
        },
        SET_LESSON_FILES(state, payload) {
            state.lessonFiles = payload;
        },
        SET_UPDATED_LESSON_FILES(state, payload) {
            state.lessonFiles.push(payload);
        },
        SET_CATEGORY(state, payload) {
            state.libraryCategory = payload;
        },
        SET_FILTER_CATEGORY(state, payload) {
            state.filterCategory = payload;
        }
    },

    getters: {
        getLessonFiles: (state) => (state.lessonFiles),
        getFilterTemplate(state) {
            if(!state.textFilter && !state.filterCategory && !state.filterFile) return state.lessonFiles;

            for(const obj of state.lessonFiles) {
                const parts = obj.file.split('.');
                const file_type = parts[parts.length - 1].toLowerCase();

                obj.extension = file_type;
            }

            const filtered = state.lessonFiles.filter((a) => {
                const containsSearchText = !state.textFilter || a.name.toLowerCase().includes(state.textFilter.toLowerCase());
                const matchesCategory = !state.filterCategory || a.category_id == state.filterCategory;
                const matchesFormat = !state.filterFile || a.extension === state.filterFile;
                return containsSearchText && matchesCategory && matchesFormat;
            })

            return filtered;
        },
        getSnackbar: (state) => (state.showSnackbar),
        getSnackbarMessage: (state) => (state.snackbarMessage),
        getCategory(state) {
            const newArr = [];
            newArr.push({ text: '-', value: null });
            for(const item of state.libraryCategory) {
                const obj = {
                    text: item.name,
                    value: item.id,
                    course_id: item.course_id
                };

                newArr.push(obj);
            }

            return newArr;
        }
    },

    actions: {
        setSnackbar({ commit }, payload) {
            commit('SET_SNACKBAR', payload);
        },
        setFileProgressSnackbar({ commit }, payload) {
            commit('SET_FILE_PROGRESS_SNACKBAR', payload);
        },
        setLessonFiles({ commit }, payload) {
            commit('SET_LESSON_FILES', payload);
        },
        setUpdatedLessonFiles({ commit}, payload) {
            commit('SET_UPDATED_LESSON_FILES', payload);
        },
        setFilterTemplate({ commit },  payload) {
            commit('SET_FILTER_TEMPLATE', payload);
        },
        setTemplate({ commit }, payload) {
            commit('SET_TEMPLATE', payload);
        },
        setTextFilter({ commit }, payload) {
            commit('SET_TEXT_FILTER', payload);
        },
        setFiles({ commit }, payload) {
            Vue.axios.get(`/library/read/${payload.course_id}`)
                .then(({ data }) => {
                    commit('SET_LESSON_FILES', data.data);
                }).catch(err => console.error(err));
        },
        setUploadFile({ commit }, payload) {
            commit('SET_SNACKBAR', true);

            Vue.axios.post('/library/submit', payload.form, {
                onUploadProgress: ({ loaded, total }) => {

                    payload.progress.loading = Math.floor((loaded / total) * 100);

                    commit('SET_FILE_PROGRESS_SNACKBAR', payload.progress);
                    if(loaded == total) {
                        const fileSize = (total < 1024) ? total + ' KB' : (loaded / (1024 * 1024)).toFixed(2) + ' MB';
                        payload.progress.file_size = fileSize;


                        setTimeout(() => {
                            commit('SET_SNACKBAR', false);
                        }, 4000);
                    }
                }
            }).then(({data}) => {
                if(data.response) {
                    Vue.axios.get(`/library/read/${payload.progress.course_id}`)
                        .then(({data}) => {
                            commit('SET_LESSON_FILES', data.data);
                        }).catch(err => console.error(err)); 
                }
            }).catch(err => console.error(err));
        },
        saveCategory({ dispatch }, payload) {
            Vue.axios.post('/library_category/submit_category', payload)
                .then(({data}) => {
                    if(data.response) {
                        dispatch('setCategory', payload.course_id);
                    }
                }).catch(err => console.error(err));
        },
        setCategory({ commit }, payload) {
            Vue.axios.get(`/library_category/read/${payload}`)
                .then(({data}) => {
                    commit('SET_CATEGORY', data.data);
                }).catch(err => console.error(err));
        },
        updateCategory({ dispatch }, payload) {
            Vue.axios.patch('/library_category/update', payload)
                .then(({data}) => {
                    if(data.response) {
                        dispatch('setCategory', payload.course_id);
                    }
                }).catch(err => console.error(err));
        },
        deleteCategory({ dispatch }, payload) {
            Vue.axios.delete(`/library_category/delete/${payload.id}`)
                .then(({data}) => {
                    if(data.response) {
                        dispatch('setCategory', payload.course_id);
                    } 
                }).catch(err => console.error(err));
        },
        updateUploadFile({ dispatch }, payload) {
            Vue.axios.patch('/library/update', payload)
                .then(({data}) => {
                    if(data.response) {
                        dispatch('setFiles', payload);
                    }
                }).catch(err => console.error(err));
        },
        removeFile({ dispatch }, payload) {
            Vue.axios.delete(`/library/delete/${payload.id}`)
                .then(({data}) => {
                    if(data.response) {
                        dispatch('setFiles', payload);
                    }
                }).catch(err => console.error(err));
        },

        setFilterCategory({ commit }, payload) {
            commit('SET_FILTER_CATEGORY', payload);
        }

    },
}