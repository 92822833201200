<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="v-card-title-border"> Welcome {{ users_info.name }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>              
                <v-btn-toggle
                    v-model="toggle_menu"
                    tile
                    color="deep-purple accent-3"
                    group>
                    <v-btn value="roadmap">
                        Roadmap
                    </v-btn>
                    <v-btn value="course">
                        Course
                    </v-btn>
                </v-btn-toggle>
                <div v-if="toggle_menu == 'roadmap'">
                    <ClientRoadMapTemp
                        :course_id="course_id"></ClientRoadMapTemp>
                </div>
                <div v-if="toggle_menu == 'course'">
                    <CourseByClient class="d-none d-sm-none d-md-flex"
                            :users_info="users_info"></CourseByClient>
                    <CourseMobileView class="d-flex d-none d-sm-flex d-md-none flex-column"
                        :users_info="users_info"></CourseMobileView>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Course from '@/v2/modules/admin/courses/modules/Course.js'
import ClientCourse from './modules/Course.js'

import ClientRoadMapTemp from './components/RoadMapTemp.vue'
import CourseByClient from './components/CourseByClient.vue'
import CourseMobileView from './components/CourseMobileView.vue'

export default {
    data: () => ({
        course_tab: null,
        users_info: null,
        course: new Course(),
        client_course: new ClientCourse(),
        dialog: true,
        trialUserDialog: false,
        course_id: null,
        toggle_menu: 'roadmap'
    }),
    components: {
        ClientRoadMapTemp,
        CourseByClient,
        CourseMobileView
    },
    computed: {
        ...mapGetters({
            getSelectedUser:            'auth/getSelectedUser',
            get_courses_by_user:        'course/get_courses_by_user_getter'
        })
        
    },
    methods: {
    },
    created() {
        this.course.get_all_courses(this.$store)        
        this.users_info = JSON.parse(this.getSelectedUser)        
        this.client_course.get_course_data_by_users_id(this.users_info.id, this.$store)
        const courses = this.get_courses_by_user(this.users_info.course_id)
        this.course_id = courses[0].id        
    }
}
</script>