<template>
    <v-dialog
        :value="value"
        persistent
        max-width="25%"
    >
        <v-card>
            <v-card-title>
                <strong>You are about to restore this account</strong>
                <v-spacer/>
                <v-icon
                    @click="close_dialog"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text>
                If you encountered an error, just message us (PT Dev Team)
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    color="primary"
                    class="pl-10 pr-10"
                    @click="restore_account"
                    block
                >
                    <v-icon class="mr-3" small>mdi-delete-restore</v-icon>Restore 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  components: {

  },
  props: [
    'value',
    'data'
  ],
  data () {
    return {

    }
  },
  mounted () {

  },
  created () {

  },
  computed: {

  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    async restore_account(){
        await this.$axios.patch(`internal/users/restore_admin/${this.data.id}`)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('adminclientdboard/set_internal')
                this.close_dialog()
            }
        })
    }
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>