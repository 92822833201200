<template>
    <v-container fluid>
        <v-row            
            justify="space-between">
            <v-col cols="3">    
                <v-combobox
                    v-model="selected_course"
                    placeholder="Select Course"
                    label="Select Course"
                    :items="get_courses_by_user(users_info.course_id)"
                    item-text="course_title"></v-combobox>
                <v-spacer></v-spacer>
                <v-btn
                    class="mt-1 mb-3"
                    color="primary"
                    style="width: 100%;"
                    @click="search_video_tags_fn">
                    <v-icon class="mr-2">mdi-magnify-expand</v-icon>
                    <small>Search</small>
                </v-btn>
                <SearchVideoTag
                    :value="search_video_tags"
                    @close_dialog="close_dialog"></SearchVideoTag>

                <v-list style="max-height: 100vh;overflow-y: auto;">
                    <v-list-group>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title><strong>New Videos</strong></v-list-item-title>
                                <v-list-item-subtitle>{{ latest_course_content.length }} newly added videos</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <v-list-item link
                            v-for="lesson in latest_course_content"
                            :key="lesson.id"
                            @click="select(lesson)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ lesson.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-group
                        v-for="ucourse in get_ucourse_parent_by_users_id(selected_course != null ? selected_course.id : selected_course_id, users_info.membership_type)"
                        :key="ucourse.id"
                        color="primary"
                        no-action>                       
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="ucourse.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item link
                            v-for="child in ucourse.children"
                            :key="child.id"
                            @click="select(child)">
                            <v-list-item-content>
                                <v-list-item-title v-text="child.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-col>
            <v-col cols="9">
                <v-card v-if="selected_item != null">
                    <v-card-title>
                        <v-icon class="mr-2">{{ selected_item.icon }}</v-icon>
                        <small>{{ selected_item.name }}</small>
                        <v-spacer></v-spacer>
                        <v-btn small fab text color="success" @click="generateLink(selected_item)">
                            <v-icon>mdi-link</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <VideoViewer v-if="selected_item.video_url != null"
                            :video_url="selected_item.video_url"></VideoViewer>

                        <FileViewer v-if="selected_item.file_url != null"
                            :file_url="selected_item.file_url[0]"></FileViewer>                        
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="selected_item.contents != 'null'" v-html="selected_item.contents"></v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <AdditionalFilesViewer
                            :files="selected_item.additional_files"></AdditionalFilesViewer>
                    </v-card-text>                    
                    <v-divider></v-divider>
                    <v-card-text>                        
                        <TagsViewer
                            :tags="selected_item.tags"></TagsViewer>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import VideoViewer from './subcomponents/VideoViewer.vue';
import TagsViewer from './subcomponents/TagsViewer.vue';
import AdditionalFilesViewer from './subcomponents/AdditionalFilesViewer.vue';
import FileViewer from './subcomponents/FileViewer.vue';

import SearchVideoTag from '@/modules/admin/pchildcomponents/DialogSearchVideoTags.vue'
import ClientCourse from '@/v2/modules/client/course/modules/Course.js';
import Course from '@/v2/modules/admin/courses/modules/Course.js'

export default {
    data: () => ({
        active: null,
        selected_item: null,
        users_name: null,
        selected_course_id: 3,
        selected_course: null,
        search_video_tags: false,
        client_course: new ClientCourse(),
        course: new Course()
    }),
    components: {
        VideoViewer,
        TagsViewer,
        AdditionalFilesViewer,
        SearchVideoTag,
        FileViewer
    },
    props: {
        users_info: Object
    },
    computed: {
        ...mapGetters({
            get_ucourse_parent_by_users_id:     'course/get_ucourse_parent_by_users_id_getter',
            get_courses_by_user:                'course/get_courses_by_user_getter',
            latest_course_content:              'clientcourse/getLatestCourseContentsGetter'
        })
    },
    methods: {
        select(child) {                       
            this.selected_item = child
            this.active = 'red'
        },
        search_video_tags_fn(){
            this.search_video_tags = true
        },
        close_dialog(){
            this.search_video_tags = false
        },
        generateLink(selected_item) {
            this.course.generateCourseLessonLink(this.users_info.id, selected_item.id, this.$store)
        }
    },
    created() {        
        const courses = this.get_courses_by_user(this.users_info.course_id)        
        if ( courses.length > 0 ) {
            this.selected_course_id = courses[0].id
            this.client_course.get_video_courses_by_tags(this.selected_course_id, this.users_info.id, this.$store)
            this.client_course.getLatestCourseLessons(this.selected_course_id, this.$store)
        }
    },
    watch: {
        selected_course() {
            //this.client_course.getLatestCourseLessons(this.selected_course.id, this.$store)
        }
    }
}
</script>

<style scoped>
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 50px !important;
}
</style>