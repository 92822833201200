import Vue from 'vue'

export default class Lesson 
{
    constructor() {

    }

    deleteVideoFromLesson(lesson_id) {
        return Vue.axios.delete(`courses/deletevideo/${lesson_id}`).then( (data) => {
            if ( data.status == 200 ) {
                return data.data
            }
        }).catch( (err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}