<template>
  <div>
    <!-- ROADMAP -->
    <div
      v-if="dialogsvalue.isTable"
    >
      <v-row justify="center">
        <v-dialog
          persistent
          :value="value"
        >
          <v-card>
            <v-card-title>
              <small>{{dialogsvalue.title}}</small>
            </v-card-title>
            <v-card-text>
              <v-data-table
                dense
                :headers="dialogsvalue.headers"
                :items="dialogsvalue.tablevalue"
                class="elevation-2"
                :hide-default-footer="true"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item['people'] }}</td>
                    <td>
                      <v-checkbox
                        v-if="props.item['first'] != ''"
                        v-model="props.item['ischeckedfirst']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['first']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lfirst'] != ''" :href="props.item['lfirst']">Video</a>
                      <label v-else></label>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedsecond']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['second']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lsecond'] != ''" :href="props.item['lsecond']">Video</a>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedthird']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['third']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lthird'] != ''" :href="props.item['lthird']">Video</a>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedfourth']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['fourth']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lfourth'] != ''" :href="props.item['lfourth']">Video</a>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedfifth']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['fifth']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lfifth'] != ''" :href="props.item['lfifth']">Video</a>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedsixth']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['sixth']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lsixth'] != ''" :href="props.item['lsixth']">Video</a>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item['ischeckedseventh']"
                        hide-details
                      >
                        <template v-slot:label>
                          <strong>{{props.item['seventh']}}</strong>
                        </template>
                      </v-checkbox>
                      <a target="_blank" v-if="props.item['lseventh'] != ''" :href="props.item['lseventh']">Video</a>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closedialog"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF ROADMAP -->
    <!-- PRIORITY -->
    <!-- <div v-else-if="dialogsvalue.color">
      <v-row justify="center">
        <v-dialog
          persistent
          max-width="600px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              <small>{{dialogsvalue.title}}</small>
            </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="text"
                  outlined
                  dense
                >

                </v-text-field>
                <v-autocomplete
                  v-model="color"
                  :items="dialogsvalue.colors"
                  item-text="name"
                  item-value="color"
                  outlined
                  dense
                  chips
                  small-chips
                  label="Set Priority"
                  prepend-icon="mdi-account-group"
                ></v-autocomplete>
              </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closedialog"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="savecolor()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div> -->
    <!-- END OF PRIORITY -->    
    <!-- VIDEO -->
    <div v-else-if="dialogsvalue.video">
      <v-row justify="center">
        <v-dialog
          persistent
          max-width="960px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              <small>Viewing Video: {{videoitem.name}}</small>
            </v-card-title>
            <v-card-text>
              swipe
              <video v-if="videoitem.video_url != null" :key="`${path}${videoitem.video_url}`" width="100%" height="650px" controls playsinline controlsList="nodownload">
                <source :src="`${path}${videoitem.video_url}`" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <label v-else>No Video Uploaded Yet</label>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closedialog"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF VIDEO -->
    <!-- WYSIWYG -->
    <div v-else-if="dialogsvalue.wysiwyg">
      <v-row justify="center">
        <v-dialog
          persistent
          :value="value"
        >
          <v-card>
            <v-card-title>
              <small>{{dialogsvalue.title}}</small>
            </v-card-title>
            <v-card-text>
              <VueEditor 
                v-model="addclient.content"
                :editorToolbar="customToolbar"
              />
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closedialog"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="savedata"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF WYSIWYG -->
    <!-- TRELLO -->
    <div
      v-else-if="dialogsvalue.trello">
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="80%"
          :value="value"
          :fullscreen="$vuetify.breakpoint.mobile"
        >
          <v-card>
            <v-card-title class="d-flex d-inline">
              <span class="blue--text">{{ info.name }}</span>
              <v-btn
                v-show="JSON.parse(user).role_id == 1"
                class="ml-3"
                color="primary"
                small
                @click="editRoadmapDialog =  true"
              >
                Edit Roadmap 
              </v-btn>
              <!-- EDIT ROADMAP DIALOG -->
              <v-dialog
                v-model="editRoadmapDialog"
                persistent
                width="500">
                <v-card>
                  <v-card-title>
                    {{ info.name }}
                    <v-spacer></v-spacer>
                    <v-icon small color="red" @click="closeRoadmapDialog()">mdi-close</v-icon>                
                  </v-card-title>
                  <v-card-text
                    class="mt-2"
                  >
                    <v-text-field
                      v-model="editRoadmap"
                      dense
                      outlined
                      :label="`Edit ${info.name}`"
                    ></v-text-field>
                  </v-card-text>
                    <v-btn
                      block
                      color="primary"
                      class="rounded-0"
                      @click="submitEditRoadmap(editRoadmap, info)"
                    >
                      Submit
                    </v-btn>
                </v-card>
              </v-dialog>
              <!-- END OF EDIT ROADMAP DIALOG -->
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row>
                <v-col
                  cols="9"
                >
                  <div
                    class="d-flex flex-column"
                  >
                    <div class="mb-1">
                      <div
                        v-show="getUser.role_id == 1"
                      >
                        <v-text-field 
                          label="Link to the video"
                          v-model="link"
                          outlined
                          dense
                          hide-details
                        />
                        <div
                          class="d-flex float-right mt-2"
                        >
                          <div
                          >
                            <v-btn
                              small
                              color="error"
                              class="mr-2"
                              @click="removeLink(info)"
                            >
                              Remove link
                            </v-btn>
                            <v-btn
                            small
                            :disabled="linkValidation"
                            color="primary"
                            @click="submitLink(link)"
                          >
                            Submit
                          </v-btn>
                          </div>
                        </div>
                        <div
                          class="mt-3"
                          v-show="link"
                        >
                          <small>Preview:</small>
                          <br>
                          <a :href="link" target="_blank">Click here to preview</a>
                        </div>
                      </div>
                      <div
                          v-show="info.video_url"
                          class="mt-2"
                        >
                            <v-icon class="mr-2 blue--text">mdi-play-speed</v-icon><a :href="`${info.video_url}`" target="_blank" class="text-decoration-none blue--text font-weight-medium">Click here to proceed to the video</a>
                      </div>
                      <div
                        v-show="(getUser.role_id === 1)"
                        class="mt-2"
                      >
                        <v-btn
                          small
                          color="secondary"
                          class="mr-2"
                          @click="textTask = true"
                        >
                          Add Task
                        </v-btn>
                      </div>
                      <div
                        class="mt-5 d-flex justify-space-between"
                      >
                        <div>
                          <v-icon color="black">mdi-note-check-outline</v-icon>
                          Task
                        </div>
                        <v-btn
                          v-show="this.tasks.length > 0"
                          x-small
                          text 
                          elevation="0"
                          color="blue"
                          @click="showTask = !showTask"
                        >
                          Show tasks
                        </v-btn>
                      </div>
                      <div>
                          <v-col
                            width="250"
                          >
                            <v-text-field
                              v-if="textTask"
                              class="my-2"
                              :key="index"
                              dense
                              hide-details
                              v-model="trelloTask"
                              @keypress.enter="submitTask(trelloTask, index); textTask = false"
                            >
                              <template v-slot:append>
                                <label>Press ENTER to save</label>
                              </template>
                            </v-text-field>
                          </v-col>
                      </div> 
                      <div
                        v-if="showTask"
                      >
                        <v-row
                          class="mt-2"
                          v-for="(n, index) in tasks"
                          :key="index"
                          style="position: relative; left: 10px;"
                        >
                          <v-simple-checkbox
                            :disabled="n.tasks ? false : true"
                            class="mr-1 ml-2 mt-2"
                            :value="n.is_done"
                            :ripple="false"
                            :key="index"
                            @click="toggleTask(n)"
                            dense
                          ></v-simple-checkbox>
                          <label
                            class="mt-2 red--test"
                            :key="index"
                            :style="n.is_done ? 'text-decoration: line-through' : ''"
                          >
                            {{n.tasks}}
                          </label>
                          <v-btn
                            class="ml-2 mr-2"
                            color="red"
                            @click="removeTask(n)"
                            small
                            icon
                            v-show="(getUser.role_id === 1)"
                            style="position: relative; top: 5px;"
                          >
                            <v-icon>
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </v-row>
                      </div> 
                      <div
                        v-show="this.tasks.length == 0"
                        class="grey--text"
                      >
                        No tasks added yet.
                      </div>
                    </div>
                    <div class="mt-5">
                      <v-icon class="mr-2 mb-2">mdi-comment-outline</v-icon>Activity
                      <VueEditor
                        v-model="activity"
                        :editorToolbar="customToolbar"
                        width="1000px"
                      />
                      <div
                        class="d-flex justify-end mt-1"
                      >
                        <v-btn 
                          :disabled="activityComment" 
                          small 
                          class="mt-1" 
                          color="primary"
                          @click="submitComment(activity)"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <v-btn
                      width="15%"
                      x-small
                      color="primary"
                      text
                      @click="showComments = !showComments"
                    >
                      Activity Log
                    </v-btn>

                    <!-- <div>
                      {{comments}}
                      <div>
                        <v-btn x-small depressed>Edit</v-btn>
                        <v-btn x-small depressed>Delete</v-btn>
                      </div>
                    </div> -->
                    <v-card
                      v-show="showComments"
                      v-for="(comment, i) in comments"
                      :key="i"
                      elevation="0"
                    >
                      <v-card-title class="pb-0">
                        <small>{{comment.user}}<span class="grey--text caption">- {{comment.created_at}}</span></small>
                      </v-card-title>
                      <v-card-text class="pb-0">
                        <div>
                          <p v-if="!comment.isEdit" class="ml-3" v-html="comment.comments"></p>
                          <div
                            v-else
                          >
                            <v-text-field
                              outlined
                              dense
                              v-model="toEditItem"
                              hide-details
                            ></v-text-field>
                            <v-btn 
                              class="mt-1"
                              x-small 
                              color="primary"
                              @click="submitEdit(comment, toEditItem)"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              class="mt-1 ml-1"
                              x-small
                              color="error"
                              @click="cancelEdit(comment)"
                            >
                              Cancel
                            </v-btn>
                          </div>
                          <ul style="borderLeft: 2px solid gray;">
                            <div
                              v-for="(ref, ii) in comment.nested_comment"
                              :key="ii"
                            >
                                <strong class="black--text" :key="ii">Admin</strong>
                                <p :key="ii" v-html="ref.comments"></p>
                            </div>
                          </ul>
                        </div>
                      </v-card-text>
                      <div
                        v-show="getUser.role_id == 1"
                      >
                        <v-btn
                          :key="i"
                          x-small
                          depressed
                          class="ml-5"
                          @click="adminComment(comment, i)"
                        >Reply</v-btn>
                      </div>
                      <!-- REPLY COMMENT -->
                      <v-dialog
                        v-model="commentAdminDialog"
                        width="800"
                      >
                        <v-card
                          :key="i"
                        >
                          <v-card-title>
                            Add Comment
                            <v-spacer></v-spacer>
                            <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text
                            class="mt-5"
                          >
                            <VueEditor
                              v-model="commentAdmin"
                              :editorToolbar="customToolbar"
                              width="1000px"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              block
                              color="primary"
                              small
                              @click="submitAdminComment(commentAdmin)"
                              :disabled="!commentAdmin ? true : false"
                            >
                              Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- END OF REPLY COMMENT -->
                      <v-card-actions
                        v-show="comment.user_id == getUser.id"
                      >
                        <!--<v-btn 
                          x-small 
                          depressed 
                          @click="editItem(comment)"
                        >
                          Edit
                        </v-btn>-->
                        <v-menu
                          absolute
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                              class="ml-1"
                              v-bind="attrs"
                              v-on="on"
                              x-small 
                              depressed
                              @click="deleteItem(comment)"
                            >
                              Delete
                            </v-btn>   
                          </template>
                          <v-card
                            width="300px"
                          >
                            <v-card-title
                              class="d-flex justify-center"
                            >
                              <small>Delete comment?</small>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                              Deleting a comment is forever. There is no undo.
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                block
                                color="error"
                                small
                                @click="submitDelete(toDeleteItem)"
                              >
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu> 
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-col>
              
                <v-col
                  lg="3" md="3" sm="12" xs="12"
                  class="mt-5"
                  style="position: sticky; top: 20px;">
                  <v-card
                    class="pa-4 grey lighten-5"
                    elevation="0"
                  >
                  <div
                      class="percentage_implemented"
                    >
                      <small><strong>Percentage Implemented</strong></small>
                      <v-menu
                        absolute 
                        :close-on-content-click="false"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-text-field
                            :light="percentageColor(info.progress) == '#FFE790'"
                            :dark="percentageColor(info.progress) != '#FFE790'"
                            :background-color="percentageColor(info.progress)"
                            readonly
                            class="mt-2 font-weight-bold"
                            v-on="on"
                            v-bind="attrs"
                            solo
                            flat
                            :value="info.progress ? `${info.progress}%` : '-'"
                          ></v-text-field>
                        </template>

                        <v-select
                          v-model="percentage"
                          :items="dialogsvalue.progress"
                          item-value="value"
                          item-text="name"
                          @change="submitPercentage(percentage)"
                          ref="select"
                          label="Set Percentage"
                          dense
                          solo
                          flat
                          elevation="0"
                          hide-details
                        ></v-select>
                      </v-menu>
                    </div>
                    <div
                      class="priority"
                    >
                      <small><strong>Priority</strong></small>
                      <v-select
                        v-model="color"
                        :items="dialogsvalue.colors"
                        bg-color="primary"
                        item-text="name"
                        item-value="color"
                        dense
                        outlined
                        class="text--white"                       
                        :background-color="info.color == null ? '#000' : info.color"
                        @change="savecolor(color)">
                        <template v-slot:selection="{ item }">
                          <strong class="white--text">{{ item.name }}</strong>
                        </template>
                      </v-select>                                         
                    </div>
                    <div
                      class="deadline_date"
                    >
                      <small><strong>Deadline Date</strong></small>
                      <v-menu
                        absolute
                        :close-on-content-click="false"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            label="Set Deadline Date"
                            class="mt-2 font-weight-bold"
                            dense
                            solo
                            flat
                            elevation="0"
                            :value="info.deadline_date"
                            :disabled="getUser.role_id == 2"
                          ></v-text-field>
                        </template>

                        <v-card
                          width="300px"
                        >
                          <v-card-title>
                            <small>Deadline Date</small>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-date-picker
                              v-model="picker"
                              reactive
                              :close-on-content-click="false"
                            >
                            </v-date-picker>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              small
                              @click="submitWithInfo(picker)"
                            >
                              Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                    <!-- <div
                      class="priority"
                    >
                      <small><strong>Priority</strong></small>
                      <v-menu
                        absolute
                        :close-on-content-click="false"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-text-field
                            :background-color="info.color"
                            dark
                            prepend-inner-icon="mdi-flag"
                            readonly
                            class="mt-2 font-weight-bold"
                            solo
                            flat
                            v-on="on"
                            v-bind="attrs"
                            :value="returnColor(info.color)"
                          ></v-text-field>
                        </template>
                        <v-select
                          :items="dialogsvalue.colors"
                          item-text="name"
                          item-value="color"
                          label="Set Priority"
                          dense
                          solo
                          flat
                          elevation="0"
                          v-model="color"
                          @change="savecolor(color)"
                          hide-details
                        ></v-select>
                      </v-menu>
                    </div> -->
                    <div
                      class="asigned_to"
                    >
                      <small><strong>Assigned To</strong></small>
                      <div
                        class="d-inline-flex align-center justify-center"
                      >
                        <v-avatar
                          color="primary"
                          size="25"
                          class="mr-2 white--text"
                        >
                          <small>{{ getAssignedUserInitials(info.tagged_to) }}</small>
                        </v-avatar>

                        <v-menu
                          absolute
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :prepend-inner-icon="info.tagged_to ? '' : 'medi-plus-circle-outline'"
                              readonly
                              class="mt-2 font-weight-bold"
                              solo
                              flat
                              v-on="on"
                              v-bind="attrs"
                              :value="info.tagged_to"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-autocomplete
                            v-if="getUser.role_id == 1 || getUser.role_id == 4"
                            v-model="selectedPersonnel"
                            dense
                            solo
                            flat
                            hide-details
                            class="mt-1"
                            :items="listOfInternalUsers"
                            item-text="name"
                            item-value="name"
                            :prepend-inner-icon="info.tagged_to ? '' : 'mdi-plus-circle-outline'"
                            :value="info.tagged_to"
                            @change="tagInternalUserMethod"
                          >
                            <template
                              v-slot:item="{ item }"
                            >
                              <!-- {{ props.item.name }} -->
                              <v-avatar
                                color="primary"
                                size="25"
                                class="mr-2 white--text"
                              >
                                <small>{{ getAssignedUserInitials(item.name) }}</small>
                              </v-avatar>
                              {{ item.name }}
                            </template>
                            <template
                              v-slot:selection="{ item }"
                            >
                              <v-avatar
                                color="primary"
                                size="25"
                                class="mr-2 white--text"
                              >
                                <small>{{ getAssignedUserInitials(item.name) }}</small>
                              </v-avatar>
                              {{ item.name }}
                            </template>
                          </v-autocomplete>
                          <v-card
                            v-else
                            elevation="0"
                            class="pa-2 mt-2"
                          >
                            <v-avatar
                              color="primary"
                              size="25"
                              class="mr-2 white--text"
                            >
                              <small>{{ getAssignedUserInitials(info.tagged_to) }}</small>
                            </v-avatar>
                            {{ info.tagged_to }}
                          </v-card>
                        </v-menu>
                      </div>
                      <v-btn
                        x-small
                        color="red"
                        class="white--text mt-4"
                        v-show="getUser.role_id == 1 || getUser.role_id == 4"
                        :disabled="info.tagged_to == null"
                        @click="removeAssignedUser(info)"
                      >
                        Remove Assigned Internal
                      </v-btn>
                      <!-- <v-menu
                        absolute
                        dense
                        :close-on-content-click="false"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-row
                            class="mt-1"
                          >
                            <v-col
                              cols="2"
                            >
                              <v-avatar
                                v-if="info.tagged_to"
                                class="mt-1 ml-1 white--text"
                                color="primary"
                                size="30"
                              >
                                {{ getAssignedUserInitials(info.tagged_to) }}
                              </v-avatar>
                              <v-icon
                                v-else
                                color="grey lighten-1"
                                large
                              >
                                mdi-plus-circle-outline
                              </v-icon>
                            </v-col>
                            <v-col
                              cols="10"
                            >
                              <v-text-field
                                readonly
                                solo
                                flat
                                dense
                                v-on="on"
                                v-bind="attrs"
                                :value="info.tagged_to"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <v-select
                          v-if="getUser.role_id == 1"
                          :items="listOfInternalUsers"
                          v-model="selectedPersonnel"
                          item-text="name"
                          item-value="name"
                          label="Add an assignee"
                          dense
                          solo
                          flat
                          elevation="0"
                          @change="tagInternalUserMethod"
                          hide-details
                        ></v-select>

                      </v-menu> -->
                    </div>
                  </v-card>
                    <!-- <v-row><small>Actions</small></v-row> -->
                    <!-- <v-row
                      class="mt-5"
                    >
                      <v-menu
                        absolute
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            depressed
                          >
                            <small><v-icon small>mdi-update</v-icon> Deadline Date</small>
                          </v-btn>
                        </template>
                        <v-card
                          width="300px"
                        >
                          <v-card-title
                            class="d-flex justify-center"
                          >
                            <small>Deadline Date</small>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-date-picker
                              v-model="picker"
                              reactive
                              :close-on-content-click="false"
                            >

                            </v-date-picker>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              small
                              @click="submitWithInfo(picker)"
                            >
                              Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-row> -->
                    <!-- <v-row
                      class="mt-5"
                    >
                      <v-menu
                        absolute
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            depressed
                          >
                            <small><v-icon small>mdi-priority-high</v-icon> Set Priority</small>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            class="d-flex justify-center"
                          >
                            <small>Set Priority</small>
                          </v-card-title>
                            <v-card-text>
                              <v-autocomplete
                                v-model="color"
                                :items="dialogsvalue.colors"
                                item-text="name"
                                item-value="color"
                                outlined
                                dense
                                chips
                                small-chips
                                label="Set Priority"
                                prepend-icon="mdi-account-group"
                              ></v-autocomplete>
                            </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                          <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              dark
                              @click="savecolor()"
                              small
                            >
                              Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-row> -->
                    <!-- <v-list>
                      <v-list-item-group
                        v-for="item of dialogsvalue.items"
                        :key="item.id"
                        v-model="item.active"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>
                              test
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item
                        >
                        <small><v-icon small class="mr-2">{{item.icon}}</v-icon>{{item.title}}</small>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list> -->
                  <!-- <div>
                    <div
                      class="mt-2"
                      v-show="info.deadline_date"
                    >
                      <small>Deadline Date:</small>
                      <br />
                      <small><strong>{{info.deadline_date}}</strong></small>
                    </div>
                    <div
                      class="mt-2"
                      v-show="info.progress"
                    >
                      <small>Progress:</small>
                      <br />
                      <small><strong>{{ info.progress || getProgress }}% Complete</strong></small>
                    </div>
                  </div> -->
                  <!-- <v-row class="mt-5" v-show="(getUser.role_id === 1)">
                     <div>
                        <v-autocomplete
                           class="pa-0 mb-n1"
                           :items="listOfInternalUsers"
                           v-model="selectedPersonnel"
                           outlined
                           dense
                           clearable
                           label="List of Internal Users"
                           item-text="name"
                           item-value="name"
                           :hint="selectedPersonnel ? `Name: ${selectedPersonnel}` : ''"
                           :persistent-hint="true"
                        ></v-autocomplete>
                        <v-btn x-small color="primary" dark class="float-right mb-1" v-if="selectedPersonnel" :loading="loadingStatus" @click="tagInternalUserMethod">Tag</v-btn>
                     </div>
                  </v-row> -->
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF TRELLO -->
    <!-- USER -->
    <div
      v-else-if="dialogsvalue.user"
      
    >
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="400px"
          :value="value"
          class="flex-column"
        >
          <v-card
            class="flex-column"
          >
            <v-card-title>
              <small>User Information</small>
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div
                class="my-2"
              >
                <label>Name: </label>
                <br>
                <span id="item">&nbsp;<strong>{{info.name}}</strong></span>
              </div>
              <div
                class="my-2"
              >
                <label>Business: </label>
                <br>
                <span id="item">&nbsp;<strong>{{info.business}}</strong></span>
              </div>
              <div
                class="my-2"
              >
                <label>Email: </label>
                <br>
                <span id="item">&nbsp;<strong>{{info.email}}</strong></span>
              </div>
              <div
                class="my-2"
              >
                <label>Phone: </label>
                <br>
                <span id="item">&nbsp;<strong>{{info.phone}}</strong></span>
              </div>
              <div
                class="my-2"
              >
                <label>Link: </label>
                <br>
                <span id="item">&nbsp;<strong>{{info.link}}</strong></span>
              </div>
              <div
                class="my-2"
              >
                <label>Password: </label>
                <br>
                <v-btn
                  outlined
                  small
                  color="blue"
                  @click="submit(info)"
                >
                  Reset Password
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF USER -->
    <!-- RESET PASSWORD -->
    <div
      v-else-if="dialogsvalue.reset"
      
    >
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="400px"
          :value="value"
          class="flex-column"
        >
          <v-card
            class="flex-column"
          >
            <v-card-title>
              <small>Reset Password</small>
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div
                class="my-2"
              >
                <v-text-field
                  dense
                  v-model="resetPassword.password"
                  label="Password"
                  outlined
                  hide-details    
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                >
                </v-text-field>
              </div>
              <div
                class="my-2"
              >
                <v-text-field
                  dense
                  v-model="resetPassword.password_confirmation"
                  label="Password Confirmation"
                  outlined
                  hide-details
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                @click="submitWithInfo(resetPassword)" 
                color="primary"
                small
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF RESET PASSWORD -->
    <!-- EDIT USER -->
    <div v-else-if="edititem">
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="600px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              Edit {{ edititem.name }}
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              ref="form"
              lazy-validation
            >
              <v-card-text>
                <v-text-field
                  label="Name"
                  outlined
                  dense
                  v-model="submittedEditUser.name"
                  :rules="strRules"
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Email"
                  outlined
                  dense
                  v-model="submittedEditUser.email"
                  disabled
                >
                </v-text-field>
                <v-text-field
                  label="Phone"
                  outlined
                  dense
                  v-model="submittedEditUser.phone"
                >
                </v-text-field>
                <v-text-field
                  label="Business"
                  outlined
                  dense
                  v-model="submittedEditUser.business"
                  :rules="strRules"
                  required
                >
                </v-text-field>
                <v-text-field
                  label="Link"
                  outlined
                  dense
                  v-model="submittedEditUser.link"
                >
                </v-text-field>
                <v-text-field
                  label="Alternative Email"
                  outlined
                  dense
                  v-model="submittedEditUser.alternative_email"
                >

                </v-text-field>
                <v-select
                  label="Membership Type"
                  outlined
                  dense
                  v-model="submittedEditUser.membership_type"
                  :items="membershipType"
                >

                </v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="primary"
                  @click="submitform(submittedEditUser)"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF EDIT USER -->
    <!-- DELETE USER -->
    <div v-else-if="deleteitem">
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="600px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
              class="d-flex justify-center p-5 mt-10"
            >
              <p class="black--text font-weight-medium">Are you sure you want to delete {{ deleteitem.name }}? There is no undo on this action.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                class="primary"
                color="error"
                small
                @click="submitdelete(deleteitem)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF DELETE USER -->
    <div v-else-if="timespent">
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="600px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              Time spent for {{ timespent.name }}
              <v-spacer></v-spacer>
              <v-icon small color="red" @click="closedialog">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                :items="timespent.time_spent"
                :headers="timespentheaders"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- EDIT DISABLED ROADMAP -->
    <div v-else-if="dialogsvalue.id == 0">
      <v-row
        justify="center"
      >
        <v-dialog
          persistent
          max-width="600px"
          :value="dialogsvalue.bool"
        >
          <v-card>
            <v-card-title
              class="justify-center"
            >
              Disabled Roadmap
            </v-card-title>
            <v-card-text>
              <v-text-field
                outlined
                dense
                small
                label="Disabled Roadmap"
              ></v-text-field>
            </v-card-text>
            <v-card-actions
              class="justify-center"
            >
              <v-btn 
                @click="cancel()"
                color="red darken-1"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <!-- END OF EDIT DISABLED ROADMAP -->
    <div v-else>
      <v-row justify="center">
        <v-dialog
          persistent
          max-width="600px"
          :value="value"
        >
          <v-card>
            <v-card-title>
              <small>{{dialogsvalue.title}}</small>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col 
                    cols="12"
                    v-for="(item, index) in dialogsvalue.text_field"
                    :key="index"
                  >
                    <v-text-field
                      :label="item.label"
                      :hint="item.hint"
                      :type="item.type"
                      v-model="addclient.textfield[item.id]"
                      prepend-icon="mdi-clipboard-text-outline"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-select
                      outlined
                      dense
                      required
                      v-model="addclient.select"
                      :label="select_field.label"
                      :hint="select_field.hint"
                      :items="select_field.selection"
                      prepend-icon="mdi-clipboard-text-outline"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    v-for="item in dialogsvalue.file_input"
                    :key="item.id"
                  >
                    <v-file-input
                      small-chips
                      outlined
                      dense
                      v-model="addclient.video"
                      :label="item.label"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" v-if="showclists && dialogsvalue.checkbox[0].hasselection">
                    <v-autocomplete
                      v-model="addclient.clists"
                      :items="getClientList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      label="Clients"
                      multiple
                      prepend-icon="mdi-account-group"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row
                  v-for="item in dialogsvalue.checkbox"
                  :key="item.id"
                >
                  <v-col
                    cols="4"
                  >
                    <v-checkbox 
                      v-model="addclient.checkbox[item.id]"
                      dense
                    >
                      <template v-slot:label>
                        {{item.label}}
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="dialogsvalue.hasvideolink"
                >
                  <v-col cols="12">
                    <v-autocomplete
                      :items="getVideoList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      label="SELECT A LESSON"
                      v-model="addclient.videolesson"
                      prepend-icon="mdi-video"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row
                  v-if="dialogsvalue.hasdatepicker"
                >
                  <v-date-picker dense v-model="addclient.date" full-width></v-date-picker>
                </v-row>
                <v-row v-if="dialogsvalue.isUpdateChapter">
                  <v-col sm="6">
                    <v-autocomplete
                      v-model="addclient.parent_course_id"
                      :items="courseList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      label="Chapter list"
                      class="mt-6 mr-2"
                  ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="closedialog"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="savedata"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    VueEditor,
  },
  props: [
    'value',
    'dialogsvalue',
    'select_field',
    'getVideoList',
    'videoitem',
    'settings',
    'info',
    'comments',
    'trelloitems',
    'edititem',
    'deleteitem',
    'timespent',
    'timespentheaders',
    'tasks'
  ],
  data () {
    return {
      addclient: {
        textfield: [],
        video: [],
        checkbox: [],
        clists: [],
        date: '',
        videolesson: {},
        content: '',
        parent_course_id: ''
      },
      wysiwyg: '',
      showclists: false,
      color: null,
      text: '',
      path: null,
      customToolbar: ['bold', 'italic', 'underline'],
      activity: null,
      picker: null,
      percentage: null,
      resetPassword: {
        password: null,
        password_confirmation: null
      },
      show: false,
      show1: false,
      isEditable: true,
      link: null,
      toEditItem: null,
      toDeleteItem: null,
      strRules: [
        v => !!v || 'This item is required!'
      ],
      emailRules: [
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid!'
      ],
      toEditUser: null,
      submittedEditUser: null,
      dialogdisabledroadmap: false,
      commentAdminDialog: false,
      commentAdmin: null,
      parentComment: null,
      editRoadmapDialog: false,
      editRoadmap: null,
      membershipType: [ 'Mastermind', 'Bridge', 'Cancelled', 'Trial' ],
      input: 0,
      // trelloTasks: [],
      trelloTask: null,
      trelloTaskCheckbox: [],
      readonly: true,
      edittasks: null,
      textTask: false,
      showTask: true,
      selectedPersonnel: '',
      sortBy: 'date',
      sortDesc: true,
      percentageColor1: {
        backgroundColor: '#8A8A8A',
        color: '#FFF'
      },
      percentageColor2: {
        backgroundColor: '#FFE790',
        color: '#000'
      },
      percentageColor3: {
        backgroundColor: '#FF720D',
        color: '#FFF'
      },
      percentageColor4: {
        backgroundColor: '#0F6B00',
        color: '#FFF'
      },
      showComments: true,
      selectedTaggedTo: this.info,
      internalUserValue: null
    }
  },
  mounted () {
    
  },
  created () {
    this.path = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
      getClientList:       'adminmain/getClientList',
      user:                'auth/getUserData',
      courseList:          'adminmain/getCourseList',
      listOfInternalUsers: 'adminmain/listOfInternalUsers',
      loadingStatus:       'adminmain/tagLoadingStatus',
      getProgress:         'clientstate/getPercentage'
    }),

    activityComment() {
      if(this.activity) {
        return false;
      } else {
        return true;
      }
    },

    linkValidation() {
      if(this.link) {
        return false;
      } else {
        return true;
      }   
    },

    getUser() {
      return JSON.parse(this.user);
    }
  },
  methods: {
   ...mapActions({
      tagInternalUser: 'adminmain/tagInternalUser'
   }),
    percentageColor(progress) {
      if(progress == 10 || progress == 20 || progress == 30) {
        return '#8A8A8A';
      } else if(progress == 40 || progress == 50 || progress == 60) {
        return '#FFE790';
      } else if (progress == 70 || progress == 80 || progress == 90) {
        return '#FF720D';
      } else if (progress == 100) {
        return '#0F6B00';
      }
    },
    returnColor(hex) {
      switch(hex) {
        case '#A76571':
          return 'Priority 1';
        // new color scheme
        case '#984453':
          return 'Priority 1';
        case '#BD8C94':
          return 'Priority 2';
        case '#D3B2B8':
          return 'Priority 3';
      }
   },
   getAssignedUserInitials(username) {
      return username == null
        ? "---"
        : username
            .split(" ")
            .map((x) => x[0])
            .join("");
    },
    closedialog(){
      this.color = null;
      this.$emit('closedialog', false)
      this.toEdit = false
      this.input = 0;
    },
    savedata(){
      this.$emit('savedata', { input: this.addclient })
      this.addclient = {
        textfield: [],
        video: [],
        checkbox: [],
        clists: [],
        date: '',
        videolesson: {},
        content: '',
        parent_course_id: '',
        select: ''
      }
    },
    // saveeditor(){
    //   this.$emit('saveeditor', { input: this.wysiwyg })
    // },
    savecolor(item){
      const tp = {
        input: item,
        item: this.info
      }
      
      /*this.$nextTick(() => {
        this.color = this.info.color;
      });*/

      this.$emit('savecolor', tp);
    },
    submit(item){
      this.$emit('submit', item);
    },
    submitWithInfo(item) {
      let toSubmit = {
        item: item,
        data: this.info
      }
      this.$emit('submitWithInfo', toSubmit);
      this.resetPassword = {};
    },
    submitComment(item) {
      let toSubmit = {
        item: item,
        data: this.info,
      }

      this.$emit('submitComment', toSubmit);
      this.activity = '';
    },
    submitPercentage(item) {
      let toSubmit = {
          item: item,
          data: this.info
      }

      this.$nextTick(() => {
        this.percentage = 0
      });
      this.$emit('submitPercentage', toSubmit);
    },
    submitLink(item) {
      let toSubmit = {
        item: item,
        data: this.info
      }
      this.$emit('submitLink', toSubmit);
      this.link = null;
    },
    removeLink(item) {
      this.$emit('removeLink', item);
    },
    editItem(item) {
      item.isEdit = true;
      this.toEditItem = item.comments;
    },
    submitEdit(obj, item) {
      let toSubmit = {
        item: item,
        data: obj
      }
      this.$emit('submitEdit', toSubmit);      
    },
    cancelEdit(item) {
      item.isEdit = false;
      return false;
    },
    deleteItem(item) {
      this.toDeleteItem = item;
    },
    submitDelete(obj) {
      this.$emit('submitDelete', obj);
      this.toDeleteItem = null;
    },
    development() {
      alert('Under development!');
    },
    resetform() {
      this.submittedEditUser = this.originalData
    },
    submitform(obj) {
      if (this.$refs.form.validate()) {
        this.$emit('submitForm', obj);
      }
    },
    submitdelete(obj) {
      this.$emit('submitDelete', obj);
    },
    cancel() {
      this.$emit('cancel', false)
    },
    adminComment(obj, index) {
      this.parentComment = {
        ...obj,
        index: index
      }      
      this.commentAdminDialog = true;
      this.commentIndex = index;
    },
    submitAdminComment(item) {
      this.commentAdminDialog = false;
      let toSubmit = {
        item: item,
        data: this.info,
        parent: this.parentComment
      }
      this.$emit('submitComment', toSubmit);
    },
    submitEditRoadmap(a, b) {
      const obj = {
        edited_name: a,
        ...b
      }

      this.$emit('submitEditRoadmap', obj)
    },
    removeindex(id) {
      this.trelloTasks.splice(id, 1);
      this.input--;
    },
    submitTask(val) {
      const { course_id, user_id, id, is_parent, name } = this.info;
      const tp = {
        id: val.id,
        task: val.tasks || val,
        user_id: user_id,
        course_id: course_id,
        roadmap_id: id,
        is_parent: is_parent,
        name: name
      }

        this.$emit('submitTask', tp);
        this.readonly = true;
        this.trelloTask = null
    },
    toggleTask(obj) {
      this.$emit('toggleTask', obj);
    },
    removeTask(obj) {
      this.$emit('deleteTask', obj);
    },
    closeRoadmapDialog() {
      this.editRoadmapDialog = false;
      this.trelloTasks = [];
      this.percentage = 0;
      this.selectedPersonnel = null;
    },

   tagInternalUserMethod() {
      let payload = {
         internalUser: this.selectedPersonnel,
         roadmap_id:   this.info.id,
         course_id:    this.info.course_id
      }

      this.$nextTick(() => {
        this.selectedPersonnel = null;
      });

      this.tagInternalUser(payload).then(() => {
         this.$emit('taggedPersonnel', payload);
      });
   },
   removeAssignedUser(obj) {
    this.$emit('removeAssignedUser', obj);
   },

  },
  watch: {
    'addclient.checkbox': {
      handler(data){
        if(data[1]){
          this.showclists = true
        }else{
          this.showclists = false
        }
      },
      deep: true
    },
    edititem() {
      if(this.edititem) {
        this.submittedEditUser = this.edititem;
      }
    },
    value() {
      if ( this.value == true ) {
        this.color = {name: this.returnColor(this.info.color), color: this.info.color}
      }
    }
    // editTasks() {
    //   if(this.tasks) {
    //     this.edittasks = this.tasks;
    //     console.log('here =>', this.edittasks);
    //   }
    // }

  //  info: {
  //     handler(newValue, oldValue) {
  //        this.selectedPersonnel = newValue.tagged_to;
  //        console.log('oldValue ',oldValue);
  //     },
  //     deep: true
  //  }
  }

}
</script>

<style scoped>
  #item {
    border-left: 5px solid #1976d2;
  }
  /* .v-text-field.v-text-field--filled .v-input__control {
    min-height: 10px !important;
  } */
</style>
