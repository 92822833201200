<template>
  <v-row
    justify="center"
  >
    <v-dialog
      max-width="100"
      max-height="500"
      :value="visible"
      persistent
      class="background"
    >
      <v-card
        elevation="0"
        class="loader"
      >
        <v-card-title
          class="justify-center"
          
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: [
    'visible'
  ],
  methods: {

  }
}
</script>

<style lang="scss">
.loader {
  padding-bottom: 6px;
}

.loading {
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 100px;
}

</style>