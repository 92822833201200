<template>
   <v-container fluid>
      <v-overlay :value="loadingStatus">
         <v-progress-circular
            indeterminate
            color="primary"
            size="64"
         >
         </v-progress-circular>
      </v-overlay>
      <v-row>
         <!-- List of calculated  -->
         <v-col cols="12" lg="4" md="12" sm="12">
            <v-card
               class="mt-4 "
               elevation="2"
            >
               <!-- Heading -->
               <v-card-text>
                  <v-row>
                     <!-- <v-col cols="12" lg="8" md="10" sm="12">
                        <p class="text-h5 text--primary">
                           Hourly Rate Calculator
                        </p>
                     </v-col> -->
                     <v-col cols="12" lg="4" md="2" sm="12">
                        <v-btn color="success" @click="resetForm"> Start New </v-btn>
                     </v-col>
                  </v-row>
               </v-card-text>

               <!-- List of items -->
               <v-list class="pa-4 overflow-y-auto" height="60%" style="max-height: 600px">
                  <v-list-item-group v-model="selectedItem">
                     <v-list-item
                        v-for="(item, i) in listOfHourlyRateCalculated"
                        :key="i"
                     >
                        <v-list-item-content  @click="getItem(item.id)">
                           <v-list-item-title>
                              <span class="font-weight-bold"> {{ item.name }} </span>
                           </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                           <span class="text--secondary"> created {{ formatDate(item.created_at) }} </span>
                        </v-list-item-action>
                        <v-btn
                           class="mx-2"
                           fab
                           dark
                           x-small
                           right
                           color="error"
                           @click="deleteItem(item.id)"
                        >
                           <v-icon dark>
                           mdi-delete-outline
                           </v-icon>
                        </v-btn>
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </v-card>
         </v-col>
         
         <!-- Calculator -->
         <v-col cols="12" lg="8" md="12" sm="12">
            <v-card class="mt-4 pb-6">
               <!-- Header -->
               <div class="pa-4">
                  <!-- Profitable Tradesmen Logo here -->
                  <v-img
                     width="30%"
                     src="../../../assets/Logo - Profitable Tradesmen.png"
                     class="mx-auto"
                  ></v-img>

                  <!-- 1st tradesmen list -->
                  <!-- title hourly rate calc -->
                  <v-card-title class="d-flex justify-center">
                     <h1 class="blue--text">Hourly Rate Calculator</h1>
                  </v-card-title>

                  <!-- Calculator Name -->
                  <v-card class="pa-6 ma-2">
                     <v-row>
                        <v-col cols="4" lg="4" class="d-flex align-center">
                           <p class="text-h6"> Calculator Name </p>
                        </v-col>
                        <v-col cols="8" lg="8">
                           <v-text-field
                              v-model="calculatorName"
                              required
                              type="text"
                              placeholder="Hourly Rate Calculator"
                              hint="For example, Hourly Rate Calculator1"
                              persistent-hint
                              :error-messages="errorCalculatorName"
                              @blur="$v.calculatorName.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-card>
                  
                  <!-- tradesmen labor rate header -->
                  <v-card class="d-flex justify-space-between pa-6 ma-2">
                     <v-row>
                        <v-col cols="12" lg="9" md="9">
                           <h3>Tradesmen Labor Rate</h3>
                        </v-col>
                        <v-col cols="12" lg="3" md="3">
                           <v-btn color="primary" @click.prevent="addTradesmen">
                              <v-icon>mdi-plus</v-icon>
                              <span> Add Tradesmen</span>
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-card>

                  <!-- 1st tradesmen list -->
                  <!--Tradesmen control when adding new tradesmen -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    v-model.number="tradesmen.rate"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    required
                                    type="number"
                                    @blur="computeDirectLaborCostPerAvailableHour(); computeDirectLaborCostPerBillableHour();
                                    computeLaborCostPlusOverheadBillableHour(); computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate()"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </div>

               <!-- divider -->
               <!-- Actual Anual Work Days -->
               <div class="grey lighten-1 pa-1">
                  <span class="white--text ml-12">Actual Anual Work Days</span>
               </div>

               <!-- 2nd tradesmen lists -->
               <v-card class="px-2 pb-4 ma-6">
                  <v-container fluid class="px-16">
                     <!-- Total Annual hours -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Total Annual Hours </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model="totalAnnualHours"
                              required
                              type="number"
                              :error-messages="errorTotalAnnualHours"
                              @blur="computeDirectLaborCostPerAvailableHour(); computeDirectLaborCostPerBillableHour();
                                 computeLaborCostPlusOverheadBillableHour(); computeMiscPercentageCosts();
                                 computeMiscPoundCosts(); computeFlatRate();
                                 $v.totalAnnualHours.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- Paid Annual leave -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Paid Annual Leave Days </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model="paidAnnualLeaveDays"
                              required
                              type="number"
                              :error-messages="errorPaidAnnualLeaveDays"
                              @blur="computeDirectLaborCostPerAvailableHour(); computeDirectLaborCostPerBillableHour();
                              computeLaborCostPlusOverheadBillableHour(); computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate(); $v.paidAnnualLeaveDays.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- Public holidays -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Public Holidays </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model="publicHolidays"
                              required
                              type="number"
                              :error-messages="errorPublicHolidays"
                              @blur="computeDirectLaborCostPerAvailableHour(); computeDirectLaborCostPerBillableHour();
                              computeLaborCostPlusOverheadBillableHour(); computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate(); $v.publicHolidays.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- Paid sick days -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Paid Sick Days </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model="paidSickDays"
                              required
                              type="number"
                              :error-messages="errorPaidSickDays"
                              @blur="computeDirectLaborCostPerAvailableHour(); computeDirectLaborCostPerBillableHour();
                              computeLaborCostPlusOverheadBillableHour(); computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate(); $v.paidSickDays.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- label with value -->
                  <!-- Productive hours available -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Productive Hours Available</span>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <span class="white--text">{{ productiveHoursAvailableComputed }}</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- readonly text-field -->
                  <!-- Percent of Available Hours to Total -->
                  <v-container fluid class="px-16">
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Percent of Available Hours to Total </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              :value="percentOfAvailableHoursToTotalComputed"
                              append-icon="mdi-percent-outline"
                              :readonly="true"
                              solo
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- title for tradesmen -->
                  <!-- Direct Labor Cost per Available Hour -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Direct Labor Cost per Available Hour</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 2nd tradesmen lists -->
                  <!-- Direct Labor Cost per Available Hour -->
                  
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.directLaborCostPerAvailableHour"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-card>

               <!-- divider  -->
               <!-- Billable time -->
               <div class="grey lighten-1 pa-1">
                  <span class="white--text ml-12">Billable Time</span>
               </div>

               <!-- 3rd tradesmen list  -->
               <v-card class="px-2 pb-4 ma-6">
                  <v-container fluid class="px-16">
                     <!-- Average Billable Hours Per Day -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Average Billable Hours Per Day</p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model="averageBillableHoursPerDay"
                              required
                              type="number"
                              :error-messages="errorAverageBillableHoursPerDay"
                              @blur="computeDirectLaborCostPerBillableHour();computeLaborCostPlusOverheadBillableHour();
                              computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate(); $v.averageBillableHoursPerDay.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!--  Billed Time Percentage readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Billed Time Percentage </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              :value="billedTimePercentageComputed"
                              append-icon="mdi-percent-outline"
                              :readonly="true"
                              solo
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- divider title only -->
                  <!-- Direct Labor Cost per Billable Hour -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Direct Labor Cost per Billable Hour</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 3rd tradesmen list  -->
                  <!--Direct Labor Cost per Available Hour -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.directLaborCostPerBillableHour"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-card>

               <!-- divider title -->
               <!-- Overhead Costs -->
               <div class="grey lighten-1 pa-1">
                  <span class="white--text ml-12">Overhead Costs</span>
               </div>

               <!-- 4th tradesmen list -->
               <v-card class="px-2 pb-4 ma-6">
                  <v-container fluid class="px-16">
                     <!-- Total Overhead Costs readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" md="6" class="d-flex align-center">
                           <p class="text-h6"> Total Overhead Costs </p>
                        </v-col>
                        <v-col cols="12" lg="3" md="3">
                           <v-text-field
                              :value="totalOverheadCosts"
                              required
                              :readonly="true"
                           ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="2" md="2">
                           <v-btn 
                              color="primary"
                              @click="showOverheadDialog">
                              <v-icon>mdi-calculator</v-icon>
                              <span> Overhead Calc.</span>
                           </v-btn>
                        </v-col>
                     </v-row>
                     <!-- Number of Full Time Trades readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" md="6" class="d-flex align-center">
                           <p class="text-h6"> Number of Full Time Trades </p>
                        </v-col>
                        <v-col cols="12" lg="3" md="3">
                           <v-text-field
                              v-model.number="countOfTradesmen"
                              required
                              :readonly="true"
                              type="number"
                              @blur="computeLaborCostPlusOverheadBillableHour"
                           ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="2" md="2">
                           <v-btn color="primary" @click.prevent="addTradesmen">
                              <v-icon>mdi-plus</v-icon>
                              <span> Add Tradesmen</span>
                           </v-btn>
                        </v-col>
                     </v-row>
                     <!-- Total Annual Billable Hours Per Tech readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Total Annual Billable Hours Per Tech </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              :value="totalAnnualBillableHoursPerTechComputed"
                              type="number"
                              :readonly="true"
                              solo
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- Total Company Annual Billable Hours readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Total Company Annual Billable Hours </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              :value="totalCompanyAnnualBillableHoursComputed"
                              type="number"
                              :readonly="true"
                              solo
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- Company Overhead Per Billable Hour readonly -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Company Overhead Per Billable Hour </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              :value="companyOverheadPerBillableHourComputed"
                              type="number"
                              :readonly="true"
                              solo
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- divider  -->
                  <!--Labor Cost Plus Overhead Billable Hour -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Labor Cost Plus Overhead Billable Hour</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 4th tradesmen list -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.laborCostPlusOverheadBillableHour"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-card>

               <!-- divider -->
               <!-- Misc. Expenses -->
               <div class="grey lighten-1 pa-1">
                  <span class="white--text ml-12">Misc. Expenses</span>
               </div>

               <!-- 5th 6th 7th tradesmen list -->
               <v-card class="px-2 pb-4 ma-6">
                  <v-container fluid class="px-16">
                     <!-- dynamic value Misc. Percentage Costs -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Misc. Percentage Costs </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model.number="miscPercentageCosts"
                              required
                              type="number"
                              append-icon="mdi-percent-outline"
                              :error-messages="errorMiscPercentageCosts"
                              @blur="computeMiscPercentageCosts(); computeMiscPoundCosts(); computeFlatRate(); $v.miscPercentageCosts.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                     <!-- dynamic value Misc. Pound Costs -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Misc. Pound Costs </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model.number="miscPoundCosts"
                              required
                              type="number"
                              :error-messages="errorMiscPoundCosts"
                              @blur="computeMiscPoundCosts(); computeMiscPercentageCosts(); computeFlatRate(); $v.miscPoundCosts.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- divider Misc. Percentage Costs (Calculation) -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Misc. Percentage Costs (Calculation)</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 5th tradesmen list -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.laborMiscPercentageCosts"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>

                  <!-- divider Misc. Pound Costs (Calculation) -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text">Misc. Pound Costs (Calculation)</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 6th tradesmen list -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.laborMiscPoundCosts"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>

                  <!-- divider Break Even Labour Costs Before Taxes (Calculation) -->
                  <div class="grey lighten-1 pa-1 ml-16">
                     <v-row>
                        <v-col cols="12" lg="12">
                           <span class="white--text">Break Even Labour Costs Before Taxes (Calculation)</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 7th tradesmen list -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.laborBreakEvenLabourCostsBeforeTaxes"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-card>

               <!-- divider -->
               <!-- Profit -->
               <div class="grey lighten-1 pa-1">
                  <span class="white--text ml-12">Break Even Labour Costs Before Taxes (Calculation)</span>
               </div>

               <!-- 8th tradesmen list -->
               <!-- Goal margin -->
               <v-card class="px-2 pb-4 ma-6">
                  <v-container fluid class="px-16">
                     <!-- Goal Margin -->
                     <v-row>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                           <p class="text-h6"> Goal Margin </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                           <v-text-field
                              v-model.number="goalMargin"
                              required
                              append-icon="mdi-percent-outline"
                              type="number"
                              :error-messages="errorGoalMargin"
                              @blur="computeFlatRate(); $v.goalMargin.$touch();"
                           ></v-text-field>
                        </v-col>
                     </v-row>
                  </v-container>

                  <!-- Flat rate -->
                  <div class="grey lighten-1 pa-2 ml-16">
                     <v-row>
                        <v-col cols="12" lg="6">
                           <span class="white--text text-h5">Your Flat Rate Per Hour is:</span>
                        </v-col>
                     </v-row>
                  </div>

                  <!-- 8th tradesmen list -->
                  <v-card
                     class="overflow-y-auto overflow-x-hidden pa-4 ma-2"
                     elevation="1"
                     height="220"
                  >
                     <v-row>
                        <v-col
                           cols="12"
                           lg="4"
                           md="4"
                           sm="6"
                           xs="12"
                           v-for="(tradesmen,i) in tradesmens" :key="i"
                        >
                           <v-card class="pa-2 mb-4">
                                 <v-text-field
                                    v-model="tradesmen.name"
                                    :label="`Trademen Name ${i+1}`"
                                    required
                                 ></v-text-field>
                                 <v-text-field
                                    :value="tradesmen.laborFlatRate"
                                    :label="`Rate ${i+1}`"
                                    prefix="€"
                                    :readonly="true"
                                 ></v-text-field>
                                 <v-row>
                                    <v-col cols="12" lg="12" md="12">
                                       <v-btn v-if="tradesmens.length > 1" color="error" block @click.prevent="removeTradesmen(i)">Remove Tradesmen</v-btn>
                                    </v-col>
                                 </v-row>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card>
               </v-card>

               <!-- save button -->
               <div class="d-flex pa-4 ma-4">
                  <v-btn color="success" large @click.prevent="save" :disabled="$v.$invalid" v-if="this.calculatorId <= 0">
                     <span> Save</span>
                  </v-btn>
                  <v-btn color="success" large @click.prevent="update" :disabled="$v.$invalid" v-else>
                     <span> Update</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" large @click.prevent="backToTop">
                     <span> Back to Top</span>
                  </v-btn>
               </div>
            </v-card>
         </v-col>
      </v-row>
      <!-- :data="dataFromAPI" -->
      <OverHeadCalculator :showOverHeadCalculatorDialog="showOverHeadCalculatorDialog" @compute="sum" @closeDialog="closeDialog" :id="overheadId"></OverHeadCalculator>
   </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OverHeadCalculator from '@/modules/client/component/OverheadCalculatorDialogComponent';
import { vuelidateUtil } from '@/utils/vuelidate-util';
export default {
   components: { OverHeadCalculator },
   data() {
      return {
         tradesmens: [
            {
               name: '',
               rate: 0,
               directLaborCostPerAvailableHour: 0,
               directLaborCostPerBillableHour: 0,
               laborCostPlusOverheadBillableHour: 0,
               laborMiscPercentageCosts: 0,
               laborMiscPoundCosts: 0,
               laborBreakEvenLabourCostsBeforeTaxes: 0,
               laborFlatRate: 0,
            }
         ],

         totalAnnualHours: 0,
         paidAnnualLeaveDays: 0,
         publicHolidays: 0,
         paidSickDays: 0,
         averageBillableHoursPerDay: 0,

         countOfTradesmen: 1,
         
         miscPercentageCosts: 0,
         miscPoundCosts: 0,
         goalMargin: 0,

         totalOverheadCosts: 0,
         calculatorName: 'Hourly Rate Calculator_',
         showOverHeadCalculatorDialog: false,
         // this overhead id is used to match the current calculator id when getting overhead details
         overheadId: 0,
         calculatorId: 0,
         overlay: false,
         selectedItem: null,
      }
   },
   async mounted() {
      await this.getAllHourlyRates()
   },
   computed: {
      ...mapGetters({
         listOfHourlyRateCalculated: "clientstate/getAllHourlyRateGetters",
         hourlyRate: "clientstate/getSingleHourlyRate",
         loadingStatus: "clientstate/getHourlyRateLoadingStatusGetters",
      }),
      // pang value sa mga percentage
      productiveHoursAvailableComputed() {
         // sample data : 2080 - 160 - 72 = 1848 total
         return Number(this.totalAnnualHours) - Number(this.paidAnnualLeaveDays) - Number(this.publicHolidays) - Number(this.paidSickDays);
      },
      // pang value sa mga percentage
      percentOfAvailableHoursToTotalComputed() {
         if(this.productiveHoursAvailableComputed <= 0 ) {
            return 0;
         }
         // sample data : 1848 / 2080 * 100 = 88.8 total
         return ( (this.productiveHoursAvailableComputed / Number(this.totalAnnualHours) ) * 100).toFixed(2);
      },
      // pang value sa mga percentage
      billedTimePercentageComputed() {
         // sample data: 8 / 8 *100 = 100 total
         return ((Number(this.averageBillableHoursPerDay)/8)*100);
      },
      totalAnnualBillableHoursPerTechComputed() {
         // sample data : 2080 - 160 - 72 = 1848 total
         // sample data: 6 / 8 = .75 total
         // sample data: 1848 * .75 = 1386 total
         return ( Number(this.totalAnnualHours) - Number(this.paidAnnualLeaveDays) - Number(this.publicHolidays) - Number(this.paidSickDays) ) 
         * (Number(this.averageBillableHoursPerDay)/8)
         
      },
      totalCompanyAnnualBillableHoursComputed() {
         // sample data : 2080 - 160 - 72 = 1848 total
         // sample data: 6 / 8 = .75 total
         // sample data: 1848 * .75 = 1386 total
         // sample data: 1386 * 2 = 2772 1386 total
         let productiveHoursAvailableTotal =  Number(this.totalAnnualHours) -  Number(this.paidAnnualLeaveDays) -  Number(this.publicHolidays) -  Number(this.paidSickDays);
         let totalCompanyAnnualBillableHoursTotal = productiveHoursAvailableTotal * (Number(this.averageBillableHoursPerDay) /8) *  Number(this.countOfTradesmen);
         return totalCompanyAnnualBillableHoursTotal;
      },
      companyOverheadPerBillableHourComputed() {
         // sample data : 2080 - 160 - 72 = 1848 total
         // sample data: 6 / 8 = .75 total
         // sample data: 1848 * .75 = 1386 total
         // sample data: 1386 * 2 = 2772 total
         // totaloverhead / total of company annual billable
         // sample data: 10000 * 2772 = 3.61 total

         if(this.totalOverheadCosts == 0){
            return 0
         }
         let totalProductiveHoursAvailable = ( Number(this.totalAnnualHours) - Number(this.paidAnnualLeaveDays) - Number(this.publicHolidays) - Number(this.paidSickDays) );
         let totalBilledTimePercentage = parseFloat(Number(this.averageBillableHoursPerDay)/8);

         let preTotal = parseFloat(totalProductiveHoursAvailable * totalBilledTimePercentage * this.countOfTradesmen)

         let totalCompanyOverheadPerBillableHour = parseFloat(parseFloat(Number(this.totalOverheadCosts) / preTotal).toFixed(2));

         return totalCompanyOverheadPerBillableHour;
      },

      validationRules() {
         let fields = {
            totalAnnualHours: {},
            paidAnnualLeaveDays: {},
            publicHolidays: {},
            paidSickDays: {},
            averageBillableHoursPerDay: {},
            
            miscPercentageCosts: {},
            miscPoundCosts: {},
            goalMargin: {},

            totalOverheadCosts: {},
            calculatorName: {},
         }

         fields = vuelidateUtil.setFieldsRequired(fields,true);
         return fields;
      },
      errorCalculatorName() {
         return this.setValidationMessage('calculatorName', 'Calculator name is required');
      },
      errorTotalAnnualHours() {
         return this.setValidationMessage('totalAnnualHours', 'Total Annual Hours is required');
      },
      errorPaidAnnualLeaveDays() {
         return this.setValidationMessage('paidAnnualLeaveDays', 'Paid Annual Leave Days is required');
      },
      errorPublicHolidays() {
         return this.setValidationMessage('publicHolidays', 'Public Holidays is required');
      },
      errorPaidSickDays() {
         return this.setValidationMessage('paidSickDays', 'Paid Sick Days is required');
      },
      errorAverageBillableHoursPerDay() {
         return this.setValidationMessage('averageBillableHoursPerDay', 'Average Billable Hours Per Day is required');
      },
      errorMiscPercentageCosts() {
         return this.setValidationMessage('miscPercentageCosts', 'Misc Percentage Costs is required');
      },
      errorMiscPoundCosts() {
         return this.setValidationMessage('miscPoundCosts', 'Misc Pound Costs is required');
      },
      errorGoalMargin() {
         return this.setValidationMessage('goalMargin', 'Goal Margin is required');
      },
      
   },
	validations() {
		return this.validationRules;
	},
   methods: {
      ...mapActions({
         getAllHourlyRates: "clientstate/actionGetHourlyRateLists",
         saveHourlyRate: "clientstate/actionSaveHourlyRate",
         // updateHourlyRate: "clientstate/actionUpdateCalculator",
         deleteHourlyRate: "clientstate/actionDeleteCalculator"
      }),
      setValidationMessage(field, message) {
         const err = [];
         if (this.$v[field].$dirty && !this.$v[field].required) {
            err.push(message);
         }
         return err;
      },
      setNumberFormat(model) {
         return model
                  .toString().replace(/,/g, "")
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      addTradesmen() {
         // this.count++;
         // const key = this.tradesmens.length;
         this.tradesmens.push({
            name: '',
            rate: 0,
            directLaborCostPerAvailableHour: 0,
            directLaborCostPerBillableHour: 0,
            laborCostPlusOverheadBillableHour: 0,
            laborMiscPercentageCosts: 0,
            laborMiscPoundCosts: 0,
            laborBreakEvenLabourCostsBeforeTaxes: 0,
            laborFlatRate: 0,
         })
         this.countOfTradesmen++
         
         this.computeDirectLaborCostPerAvailableHour();
         this.computeDirectLaborCostPerBillableHour();
         this.computeLaborCostPlusOverheadBillableHour();
         this.computeMiscPercentageCosts();
         this.computeMiscPoundCosts();
         this.computeFlatRate();
      },
      removeTradesmen(index) {
         this.tradesmens.splice(index,1);
         this.countOfTradesmen--;
         this.computeDirectLaborCostPerAvailableHour();
         this.computeDirectLaborCostPerBillableHour();
         this.computeLaborCostPlusOverheadBillableHour();
         this.computeMiscPercentageCosts();
         this.computeMiscPoundCosts();
         this.computeFlatRate();
      },

      computeDirectLaborCostPerAvailableHour() {
         let tradesmens = this.tradesmens;

         const productiveHoursAvailable = Number(this.totalAnnualHours) - Number(this.paidAnnualLeaveDays) - Number(this.publicHolidays) - Number(this.paidSickDays);
         const percentOfAvailableHoursToTotal =  parseFloat(
            (parseFloat(productiveHoursAvailable / Number(this.totalAnnualHours)) * 100).toFixed(2)
         );
         for(let i = 0; i < tradesmens.length; i++){
            // sample data: 25 / 88.85 * 100 = total 28.14
            if(!isNaN(percentOfAvailableHoursToTotal)) {
               tradesmens[i].directLaborCostPerAvailableHour = parseFloat(
                  (parseFloat(tradesmens[i].rate /  percentOfAvailableHoursToTotal)*100).toFixed(2)
               );
            } else {
               this.tradesmens[i].directLaborCostPerAvailableHour = 0;
            }
         }
      },
      computeDirectLaborCostPerBillableHour() {
         const tradesmens = this.tradesmens;

         // sample data: 8 / 8 *100 = 100 total
         const billedTimePercentage = (Number(this.averageBillableHoursPerDay)/8)*100 ;
         for(let i = 0; i < tradesmens.length ; i++){
            // sample data: 28.14 / 100 = total
            this.tradesmens[i].directLaborCostPerBillableHour = parseFloat((tradesmens[i].directLaborCostPerAvailableHour /  billedTimePercentage)*100).toFixed(2);
         }
      },
      computeLaborCostPlusOverheadBillableHour() {
         const tradesmens = this.tradesmens;

         // sample data : 2080 - 160 - 72 = 1848 total
         // sample data: 6 / 8 = .75 total
         // sample data: 1848 * .75 = 1386 total
         // sample data: 1386 * 2 = 2772 total
         // totaloverhead / total of company annual billable
         // sample data: 10000 * 2772 = 3.61 total
         const companyOverheadPerBillableHour = Number(this.totalOverheadCosts) / (
                  ( Number(this.totalAnnualHours) - Number(this.paidAnnualLeaveDays) - Number(this.publicHolidays) - Number(this.paidSickDays) ) * 
                  ( Number(this.averageBillableHoursPerDay)/8) * this.countOfTradesmen)

         for(let i = 0; i < tradesmens.length ; i++){
            // sample data: 3.61 + 28.14 = 31.75 total
            if(!isNaN(companyOverheadPerBillableHour)) {
               this.tradesmens[i].laborCostPlusOverheadBillableHour = (
                  parseFloat(companyOverheadPerBillableHour) +  parseFloat(this.tradesmens[i].directLaborCostPerBillableHour)
               ).toFixed(2);
            } else {
               this.tradesmens[i].laborCostPlusOverheadBillableHour = 0;
            }
         }
      },
      computeMiscPercentageCosts() {
         const tradesmens = this.tradesmens;
         for(let i = 0; i < tradesmens.length ; i++){
            // sample data: 33.55 * (5/100) + 33.55 = 112.5 total
            this.tradesmens[i].laborMiscPercentageCosts = parseFloat(
               (parseFloat(this.tradesmens[i].laborCostPlusOverheadBillableHour) * 
               (parseFloat(Number(this.miscPercentageCosts)/100)) + 
               parseFloat(this.tradesmens[i].laborCostPlusOverheadBillableHour)).toFixed(2)
            );
         }
      },
      computeMiscPoundCosts() {
         const tradesmens = this.tradesmens;
         for(let i = 0; i < tradesmens.length ; i++){
            // sample data: 112.5 + 50 = 162.5 total
            this.tradesmens[i].laborMiscPoundCosts = parseFloat(
               (parseFloat(parseFloat(this.tradesmens[i].laborMiscPercentageCosts) + Number(this.miscPoundCosts))).toFixed(2)
            );
            
            this.tradesmens[i].laborBreakEvenLabourCostsBeforeTaxes = parseFloat(
               (parseFloat(parseFloat(this.tradesmens[i].laborMiscPercentageCosts) + Number(this.miscPoundCosts))).toFixed(2)
            );
         }
      },
      computeFlatRate() {
         const tradesmens = this.tradesmens;
         for(let i = 0; i < tradesmens.length ; i++){
            // sample data: 162.5 / 1 * 100 = 1.625 
            // (20/100) = .2 
            // 1.625 - .2 = 1.425 total
               this.tradesmens[i].laborFlatRate = (parseFloat(parseFloat(this.tradesmens[i].laborBreakEvenLabourCostsBeforeTaxes)) / parseFloat(1 - (Number(this.goalMargin)/100)) ).toFixed(2);
         }
      },

      // get the current id of calculator to get all overhead datas
      showOverheadDialog() {
         if(this.calculatorId > 0) {
            this.overheadId = this.calculatorId;
         }
         this.showOverHeadCalculatorDialog = true
      },
      // sum total overhead costs
      sum(totalOverhead) {
         this.totalOverheadCosts = parseFloat(totalOverhead.toFixed(2));
         this.showOverHeadCalculatorDialog = false
         if(this.countOfTradesmen > 0) {
            this.computeLaborCostPlusOverheadBillableHour();
            this.computeMiscPercentageCosts();
            this.computeMiscPoundCosts();
            this.computeFlatRate();
         }
      },
      closeDialog(status) {
         this.showOverHeadCalculatorDialog = status;
      },

      async resetForm() {
         this.tradesmens = [
            {
               name: '',
               rate: 0,
               directLaborCostPerAvailableHour: 0,
               directLaborCostPerBillableHour: 0,
               laborCostPlusOverheadBillableHour: 0,
               laborMiscPercentageCosts: 0,
               laborMiscPoundCosts: 0,
               laborBreakEvenLabourCostsBeforeTaxes: 0,
               laborFlatRate: 0,
            }
         ];
         this.calculatorName = 'Hourly Rate Calculator_';
         this.totalAnnualHours = 0;
         this.paidAnnualLeaveDays = 0;
         this.publicHolidays = 0;
         this.paidSickDays = 0;
         this.averageBillableHoursPerDay = 0;

         this.countOfTradesmen = 1;
         
         this.miscPercentageCosts = 0;
         this.miscPoundCosts = 0;
         this.goalMargin = 0;

         this.totalOverheadCosts = 0;

         this.calculatorId = 0;
         this.overheadId = 0;

         this.selectedItem = null;

         this.$v.$reset();
         scrollTo(0,0);
         await this.getAllHourlyRates();
      },
      backToTop() {
         scrollTo(0,0);
      },

      // fetch the response data of api in local property of component
      getItem(id) {
         const data = this.hourlyRate(id);
         this.selectedItem = id;
         data.map((item) => {
            // tradesmen
            this.tradesmens = item.tradesmens

            this.countOfTradesmen = item.tradesmens.length;
            this.calculatorName = item.name;
            
            this.totalAnnualHours = item.total_annual_hours;
            this.paidAnnualLeaveDays = item.paid_annual_leave_days;
            this.publicHolidays = item.public_holidays;
            this.paidSickDays = item.paid_sick_days;
            this.averageBillableHoursPerDay = item.average_billable_hours_per_day;
            
            this.miscPercentageCosts = item.misc_percentage_costs;
            this.miscPoundCosts = item.misc_pound_costs;
            this.goalMargin = item.goal_margin;

            this.totalOverheadCosts = item.total_overhead_costs;

            this.calculatorId = item.id
         })
      },
      async save() {
         this.$v.$touch();
         if(this.$v.$invalid) {
            return;
         }
         // loop into form data then save
         let payload = {
            tradesmens : this.tradesmens,
            totalAnnualHours: this.totalAnnualHours,
            paidAnnualLeaveDays: this.paidAnnualLeaveDays,
            publicHolidays: this.publicHolidays,
            paidSickDays: this.paidSickDays,
            averageBillableHoursPerDay: this.averageBillableHoursPerDay,
            miscPercentageCosts: this.miscPercentageCosts,
            miscPoundCosts: this.miscPoundCosts,
            goalMargin: this.goalMargin,
            totalOverheadCosts: this.totalOverheadCosts,
            calculatorName: this.calculatorName,
         }
         await this.saveHourlyRate(payload);
         this.resetForm();
      },

      async update() {
         this.$v.$touch();
         if(this.$v.$invalid) {
            return;
         }
         // loop into form data then save
         let payload = {
            id : this.calculatorId,
            tradesmens : this.tradesmens,
            totalAnnualHours: this.totalAnnualHours,
            paidAnnualLeaveDays: this.paidAnnualLeaveDays,
            publicHolidays: this.publicHolidays,
            paidSickDays: this.paidSickDays,
            averageBillableHoursPerDay: this.averageBillableHoursPerDay,
            miscPercentageCosts: this.miscPercentageCosts,
            miscPoundCosts: this.miscPoundCosts,
            goalMargin: this.goalMargin,
            totalOverheadCosts: this.totalOverheadCosts,
            calculatorName: this.calculatorName,
         }
         await this.saveHourlyRate(payload);
         this.resetForm();
      },

      deleteItem(calculatorId) {
         this.deleteHourlyRate(calculatorId)
         this.resetForm();
      },

      formatDate(date) {
         const d = new Date(date);
         var datestring = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;

         return datestring;
      }
   },


}
</script>