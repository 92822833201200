<template>
    <v-dialog
        :value="value"
        persistent
        max-width="100%"
        scrollable
    >
        <v-card>
            <v-card-title>
                <small>Client Video / Tags</small>
                <v-spacer/>
                <v-icon
                    @click="close_dialog"
                    
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text
                      style="height: 70%;"
            >
            <v-tabs
              v-model="tab"
              color="basil"
              grow
            >
              <v-tab
                href="#tab-1"
              >
                Videos
              </v-tab>
              <v-tab
                href="#tab-2"
              >
                Issues
              </v-tab>
            </v-tabs>
              <v-tabs-items
                v-model="tab"
              >
                <v-tab-item
                  value="tab-1"
                >
                  <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      label="search video / tags"
                      v-model="search"
                      append-icon="mdi-close"
                      filled
                      dense
                      @click:append="search = null"
                  >
                  </v-text-field>
                      <v-row
                        class="mt-2 fill-height"
                      >
                          <v-col
                              cols="3"
                              fill-height
                              v-for="(item, itemid) in get_set_videos_tags_for_search"
                              :key="itemid"
                              align-self="stretch"
                          >
                            <v-card
                                @click="proceedLink(item)"
                            >
                                    <video v-if="item.video_url != null" :key="`${path}${item.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload" class="mb-3">
                                      <source :src="`${path}${item.video_url}`" type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                    <v-img
                                        v-else
                                        src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                        contain
                                        width="250"
                                        height="175"
                                    />
                                    <!-- <v-icon>mdi-video</v-icon> -->
                              <v-card-actions
                                class="d-flex"
                              >
                                <strong
                                  class="pl-5"  
                                >{{item.name}}</strong>
                                <!-- <label
                                  v-if="item.tags.length > 0"
                                >
                                  <v-icon>mdi-exclamation-thick</v-icon>
                                  Has additional file
                                </label>
                                <label
                                  v-else
                                >
                                  <v-icon>mdi-exclamation-thick</v-icon>
                                  No additional file
                                </label> -->

                              </v-card-actions>
                            </v-card>   
                          </v-col>
                      </v-row>
                </v-tab-item>
                <v-tab-item
                  value="tab-2"
                >
                  <v-row
                  >
                    <v-col
                      cols="3"
                      align-self="stretch"
                      class="fill-height"
                    >
                      <v-card
                        class="elevation-1 rounded-0"
                      >
                        <!-- <v-treeview
                          :items="getSearchIssueCategory"
                        >
                        </v-treeview> -->
                        <div
                          v-for="item in getSearchIssueCategory"
                          :key="item.id"
                        >
                          <v-list-group>
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title><strong>{{ item.name }}</strong></v-list-item-title>
                              </v-list-item-content>
                            </template>

                            <v-list-item
                              v-for="item_child in item.children"
                              :key="item_child.id"
                            >
                              <v-list-item-content
                                @click="filterVideos(item_child)"
                                class="d-flex align-center"
                              >
                                <v-list-item-title id="cursor"><em>{{ item_child.name }}</em> <small color="grey--text" class="ml-1">({{ item_child.children.length }})</small></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="9"
                      class="mt-5"
                    >
                      <v-card
                        class="elevation-0"
                        v-if="selected_issue.length > 0"
                      >
                        <v-row>
                          <v-col
                            cols="4"
                            v-for="items in selected_issue"
                            :key="items.id"
                          >
                            <v-card
                              v-for="items_child in items.children"
                              :key="items_child.id"
                              class="elevation-1 d-block-flex justify-center"
                              @click="proceedLink(items_child)"
                            >
                              <v-card-text>
                                <video v-if="items_child.video_url != null" width="100%" height="100%" :key="items_child.video_url" controls controlsList="nodownload">
                                  <source :src="`${path}${items_child.video_url.video_url}`" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                                <v-img
                                  v-else
                                  src="https://cdn-icons-png.flaticon.com/512/813/813795.png"
                                  contain
                                  width="250"
                                  height="175"
                                />
                                <p class="black--text">{{ items_child.name }}</p>
                              </v-card-text>
                              <v-card-actions
                                class="pt-0"
                                v-if="items_child.additional_files.length > 0"
                              >
                                <v-icon class="grey--text">mdi-exclamation</v-icon>
                                <span class="grey--text">has additional file</span>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card
                        class="elevation-0"
                        v-else
                      >
                        Select an issue category first!
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import qs from 'qs';
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  props: [
    'value'
  ],
  data () {
    return {
      search: null,
      path: null,
      tab: null,
      search_issues: null,
      issue_header: [
        { text: 'Problem', value: 'tags', sortable: false },
        { text: 'Solution', value: 'issue_solution', sortable: false },
        { text: 'Video', value: 'video_url', sortable: false },
        { text: 'Documents', value: 'file_url', sortable: false },
        { text: 'Percentage' },
        { text: 'Priority' }
      ],
      selected_issue: []
    }
  },
  mounted () {
    this.getIssueCategory();
  },
  created () {
    this.path = process.env.VUE_APP_URL
  },
  computed: {
    get_set_videos_tags_for_search(){
      return this.$store.getters['adminclientdboard/get_set_videos_tags_for_search'](this.search)
    },
    get_get_issues(){
      return this.$store.getters['clientstate/get_issues']
    },

    ...mapGetters({
      getSearchIssueCategory: 'adminmain/getSearchIssueCategory'      
    })
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    proceedLink(item) {
        const query = qs.stringify(item);
        const route = this.$router.resolve({ name: 'clientcurrentlesson', query: { query } });
        window.open(route.href, '_blank');
    },
    async getIssueCategory() {
      await this.$axios.get('/course/g_issue')
        .then(({data}) => {
          this.$store.dispatch('adminmain/setSearchIssueCategory', data.data);
        })
    },
    filterVideos(item) {
      this.selected_issue = [];
      this.selected_issue.push(...item.children);
    }
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">
  #cursor {
    cursor: pointer;
  }
</style>