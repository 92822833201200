// Vuelidate validators
import { required } from 'vuelidate/lib/validators';


export const vuelidateUtil = {
   setFieldsRequired(fieldNames, isRequired) {
      let requiredFields = {};
      for(let fieldName in fieldNames) {
         if(isRequired) {
            requiredFields[fieldName] ={ required };
         }
      }
      
      return requiredFields;
   }
}