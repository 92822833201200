<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="2"
            >
                <v-card
                    class="mx-auto"
                    max-width="300"
                    tile
                >
                    <v-list flat>
                        <v-subheader><v-icon>mdi-content-copy</v-icon> Manage Learning Content</v-subheader>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                        >
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content @click="$router.push(item.path)">
                                    <v-list-item-title>{{item.text}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col
                cols="10"
            >
                <router-view />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    selectedItem: 0,
    items: [
        { 
          text: 'Courses', 
          icon: 'mdi-book-multiple',
          path: {
            name: 'coursesinner'
          }
        },
        //{
        //  text: 'Video Library',
        //  icon: 'mdi-video-box',
        //  path: {
        //    name: 'coursesinner'
        //  }
        //}
    ],
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    selectCourse() {
        
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>