import ActivityFeedsClass from "../../../modules/classes/activity_feeds.class"

export default class ActivityFeedsMethods
{
    constructor() {        
        // Classes
        this.activity_feed_class = new ActivityFeedsClass()
    }

    methods = ( () => {

        const getAccountActivityFeeds = (account_id) => {            
            this.activity_feed_class.getClientsActivityFeedData(account_id)
        }

        return { getAccountActivityFeeds }
    })()
}