<template>
    <v-container fill-height fluid>
      <v-row justify="center" align="center" class="mt-5">
        <v-icon x-large>{{emptypage.icon}}</v-icon>
        <v-card
          elevation="0"
        >
          <v-card-title class="text-h6">{{emptypage.header}}</v-card-title>
          <v-card-subtitle>{{emptypage.subheader}}</v-card-subtitle>
        </v-card>
      </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
    'emptypage'
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>