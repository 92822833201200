export default {
    namespaced: true,
    state: {
        courses: [],
        activity_feeds: []
    },
    mutations: {
        set_all_courses_mutation(state, payload) {
            state.courses = [...payload]
        },
        setActivityFeedsMutation(state, payload) {
            state.activity_feeds = [...payload]
        }
    },
    getters: {
        get_ucourse_parent_by_users_id_getter: (state) => (course_id, membership_type) => {   
            if ( state.courses.length > 0 ) {         
                const result = state.courses.filter( course => course.id === course_id)
                const parent = result[0].get_ucourse.map( ucourse => {                    
                    const children = ucourse.get_child.filter( child => membership_type == 'Mastermind' ? child.mastermind == 1 : child.bridge == 1)
                    ucourse.children = children
                    return ucourse
                })
                return parent.filter( parent => membership_type == 'Mastermind' ? parent.mastermind == 1 : parent.bridge == 1)
            }
        },
        get_courses_by_user_getter: (state) => (course_ids) => {            
            return state.courses.filter( course => course_ids.includes(course.id))
        },
        getAllCourses: (state) => {
            return state.courses
        },
        getActivityFeedsGetter: (state) => state.activity_feeds,
        getCourseNameByIdGetter: (state) => (course_id) => state.courses.find( (course) => course.id == course_id).course_title
    },
    actions: {
        set_all_courses_action({ commit }, payload) {
            commit('set_all_courses_mutation', payload)
        },
        setActivityFeedsAction({ commit }, payload) {
            commit('setActivityFeedsMutation', payload)
        }
    }
}