import axios from 'axios'
import Vue from 'vue'
import store from '@/states'
import { clientActivityFeedsStore } from './../../store/client_activityfeeds.store.js'

export default class ActivityFeedsClass
{
    constructor() {
        this.api_path = process.env.VUE_APP_API_URL
        this.api = axios.create({
            baseURL: this.api_path
        })
        this.bearer_token = localStorage.getItem('token')
        this.toast = Vue.$toast

        this.client_activity_feeds_store = clientActivityFeedsStore()
    }

    getClientsActivityFeedData(user_id) {
        this.api.get(`admin/clientanalytics/getclientactivities/${user_id}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( async (data) => {
            const result = data.data
            if ( result.response == true ) {
                const wrangled_data = await this._processClientsActivityFeedDataForViewing(result.data)
                this.client_activity_feeds_store.setActivityFeeds(wrangled_data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    getClientsActivityFeedDataFromStore() {
        return this.client_activity_feeds_store.getActivityFeeds
    }

    /** private function */
    _processClientsActivityFeedDataForViewing(data) {        
        const result = data.map( (item) => {
            const course_title = store.getters['course/getCourseNameByIdGetter'](item.get_u_course_child.get_u_course_parent.course_id)
            const total_watch_minutes = item.get_lastest_viewed_video.reduce( (total_watch_minutes, current_watch_data) => {
                total_watch_minutes += current_watch_data.watch_duration
                return total_watch_minutes
            }, 0)
            
            return { 
                content: `${course_title}: ${item.get_u_course_child.get_u_course_parent.name} | ${item.get_u_course_child.name}`,
                viewed_date: item.get_lastest_viewed_video.length > 0 ? new Date(item.get_lastest_viewed_video[0].updated_at).toLocaleDateString() : null,
                completed_date: item.is_done == 1 ? new Date(item.updated_at).toLocaleDateString() : null,
                watch_duration: (total_watch_minutes / 60).toFixed(2)
            }
        })
        return result.sort((a, b) => new Date(b.viewed_date) - new Date(a.viewed_date))
    }
}