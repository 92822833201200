<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
            >
                <v-text-field
                    label="search 'topic'"
                    prepend-icon="mdi-magnify"
                    v-model="search_item"
                >
                </v-text-field>
            </v-col>
            <v-col
                cols="3"
                v-for="(item, itemindex) in get_tagged_data"
                :key="itemindex"
            >
                <v-card>
                    <v-card-title
                        :style="{backgroundColor: item.color}"
                    >
                        <small
                            style="fontSize: 14px;"
                        >{{item.get_client[0].name}}</small>
                        <v-spacer/>
                        <small
                            style="fontSize: 12px;"
                        >
                            {{item.updated_at}}
                        </small>
                    </v-card-title>
                    <v-card-subtitle
                        :style="{backgroundColor: item.color}"
                    >
                        Deadline: <strong>{{item.deadline_date}}</strong>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                Topic: <strong>{{item.name}}</strong>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <video v-if="item.video_url != null" :key="`${path}${item.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload" class="mb-3">
                                    <source :src="`${path}${item.video_url}`" type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  components: {

  },
  props: [

  ],
  data () {
    return {
        user_data: JSON.parse(localStorage.getItem('udata')) || {},
        path: null,
        search_item: null
    }
  },
  async mounted () {
    await this.$store.dispatch('internal/set_tagged_data', this.user_data.name)
  },
  created () {
    this.path = process.env.VUE_APP_URL
  },
  computed: {
    // ...mapGetters({
    //     get_tagged_data:        'internal/get_tagged_data'
    // })
    get_tagged_data(){
        return this.$store.getters['internal/get_tagged_data'](this.search_item)
    }
  },
  methods: {
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>