<template>
    <div style="min-width: 1500px;" class="d-flex">
        <div style="min-width: 100px;margin-right:10px;">
            <v-card class="mx-1 grey lighten-3" style="min-width: 100px">
                <v-card-title class="justify-center v-card-title black--text">
                    Billable Staff
                </v-card-title>
                <v-card-text 
                    style="padding: 0">
                    <v-card
                        no-gutters
                        v-for="(staff, index) in staff.get_child"
                        :key="index"
                        style="margin: 5px; height: 92px; cursor: pointer;"
                        class="my-0 rounded-0 elevation-0 pb-4 pt-1 black--text"
                        :disabled="
                            user.role_id === 1
                            ? false
                            : (user.is_unlocked2 === 0 && index >= 7) ||
                                (user.is_unlocked === 0 && index >= 3 && index <= 6)
                            ? true
                            : false
                        "
                        v-model="staff.ischecked"
                        active-class="false"
                        @click="tickStaff(staff, index)"
                        :style="staff.ischecked ? done : false"
                        :class="staff.ischecked ? 'white--text' : ''">
                        <v-card-title class="v-card-title justify-center py-6">
                            {{ staff.people }}
                        </v-card-title>
                    </v-card>
                </v-card-text>
            </v-card>
        </div>

        <!-- Roadmap Details -->
        <div
            v-for="(roadmap, index) in roadmaps"
            no-gutters
            style="min-width: 200px;"
            :key="index">
            <v-card 
            no-gutters 
            class="mx-1 grey lighten-3" 
            style="padding-bottom: 10px">
            <v-card-title class="justify-center v-card-title black--text">
                {{ roadmap.name }}
            </v-card-title>
            <v-card-text style="padding: 0">
                <v-card
                no-gutters
                v-for="(child, index) in roadmap.get_child"
                :key="index"
                style="margin: 5px; padding-right: 10px"
                :style="`border-left:5px solid ${
                    child.ischecked == true
                    ? '#0F6B00'
                    : child.color == null
                    ? '#FFF'
                    : child.color
                }`"
                @click="openmodal(child, index)"
                >
                <v-card-title class="v-card-title">
                    <div class="d-inline">
                    <v-simple-checkbox
                        v-model="child.ischecked"
                        :ripple="false"
                        @click="submitPercentage(child)"
                    ></v-simple-checkbox>
                    </div>
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                        class="v-card-child-title-name d-inline"
                        v-bind="attrs"
                        v-on="on"
                        >
                        {{ child.name }}
                        </div>
                    </template>
                    <span>{{ child.name }}</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text
                    class="
                    no-gutter
                    d-flex d-sm-flex d-md-flex d-lg-flex d-xl-none
                    flex-wrap
                    justify-space-between
                    v-card-text-font-size
                    "
                    style="padding-left: 3px; height: 45px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <div
                    class="d-flex v-card-text-div"
                    v-bind="attrs"
                    v-on="on"
                    >
                    <img
                        :src="setProgresssIcon(child.progress, child.ischecked)"
                        class="icon"
                        width="16"
                        style="margin-right: 5px; margin-left: 5px"
                    />
                    {{ child.progress ? child.progress : "---" }}
                    </div>
                </template>
                <div>
                    <v-icon
                        small
                    >
                        mdi-clock-time-seven-outline
                    </v-icon>
                    {{ child.deadline_date ? getPriorityDate(child.deadline_date) : '---' }}
                    <div>
                        <v-icon
                        small
                        >
                        mdi-progress-alert
                        </v-icon>
                        {{ child.progress ? child.progress + '%' : 'Not Set' }}
                    </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div
                    class="d-flex align-end"
                    >
                    <v-avatar
                        v-if="getAssignedUserInitials(child.tagged_to) == '---' ? false : true"
                        color="primary"
                        size="26"
                        class="white--text mb-1"
                        style="font-size: 12px; margin-left: 5px"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ getAssignedUserInitials(child.tagged_to) }}
                    </v-avatar>
                </div>
                </v-card-text>
                <v-card-actions
                    class="no-gutter d-flex d-sm-flex d-md-flex d-lg-flex d-xl-none"
                >
                </v-card-actions>
                <v-card-text
                    class="
                    no-gutter
                    d-none d-sm-none d-md-none d-lg-none d-xl-flex
                    flex-wrap
                    v-card-text-font-size
                    px-1
                    "
                >
                    <div>
                    <v-icon
                        small
                    >
                        mdi-clock-time-seven-outline
                    </v-icon>
                    {{ child.deadline_date ? getPriorityDate(child.deadline_date) : '---' }}
                    <div>
                        <v-icon
                        small
                        >
                        mdi-progress-alert
                        </v-icon>
                        {{ child.progress ? child.progress + '%' : 'Not Set' }}
                    </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div
                    class="d-flex align-end"
                    >
                    <v-avatar
                        v-if="getAssignedUserInitials(child.tagged_to) == '---' ? false : true"
                        color="primary"
                        size="26"
                        class="white--text mb-1"
                        style="font-size: 12px; margin-left: 5px"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ getAssignedUserInitials(child.tagged_to) }}
                    </v-avatar>
                    </div>
                </v-card-text>
                </v-card>
            </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import Priority1Icon from "./../../../../assets/roadmaps/priority-1-icon.svg";
import Priority2Icon from "./../../../../assets/roadmaps/priority-2-icon.svg";
import Priority3Icon from "./../../../../assets/roadmaps/priority-3-icon.svg";
import PriorityCompletedIcon from "./../../../../assets/roadmaps/priority-completed.svg";

import PenIcon from "./../../../../assets/roadmaps/pen-icon.svg";
import ProgressIconGray from "./../../../../assets/roadmaps/progress-gray-icon.svg";
import ProgressFortyOneToSeventyIcon from "./../../../../assets/roadmaps/progress-orange-icon.svg";
import ProgressCompletedIcon from "./../../../../assets/roadmaps/progress-completed-icon.svg";
import DeadlineDateIcon from "./../../../../assets/roadmaps/deadline-icon.svg";
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    staffs: [
      { id: 2, name: "Pipelining Staff" },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 7 },
      { id: 8 },
      { id: 9 },
      { id: 15 },
      { id: 20 },
      { id: 25 },
      { id: 30 },
    ],
    Priority1Icon,
    Priority2Icon,
    Priority3Icon,
    PenIcon,
    ProgressIconGray,
    ProgressFortyOneToSeventyIcon,
    ProgressCompletedIcon,
    DeadlineDateIcon,
    PriorityCompletedIcon,
    browser_width: window.innerWidth,
    done: {
      backgroundColor: "#479DFF",
      color: "#ffffff",
    },
  }),
  props: ["roadmaps", "staff", "user"],
  components: {},
  computed: {
    ...mapGetters({

    }),
  },
  methods: {
    submitPercentage(child) {
      let tp = {
        data: child,
        ischecked: child.ischecked
      }

      this.$emit('submitPercentage', tp);
    },
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async end(a) {
      let tp = {
        previous_table_id: Number(
          a.from.parentElement.attributes.parent_id.value
        ),
        new_table_id: Number(a.to.parentElement.attributes.parent_id.value),
        child_id: Number(a.item.attributes.child_id.value),
      };
      try {
        await this.$axios.patch("client/adjustroadmap", tp);
        return this.roadmaps;
      } catch (e) {
        console.error(e);
      }
    },
    getPriorityDate(date) {
      let temp = date.split(' ');
      
      // date
      let tempdate = temp[0].slice(0, 3).toUpperCase();
      let tempday = temp[1];

      return (tempdate + ' ' + tempday);
    },
    getAssignedUserInitials(username) {
      return username == null
        ? "---"
        : username
            .split(" ")
            .map((x) => x[0])
            .join("");
    },
    setAssignedUsersOnToolTips(user) {
      return user == null ? `Assigned To: None` : `Assigned To: ${user}`;
    },
    setProgresssIcon(_progress, _isProgressCompleted) {
      if (_isProgressCompleted == true) {
        return ProgressCompletedIcon;
      } else {
        if (_progress >= 0 && _progress <= 40) {
          return ProgressIconGray;
        } else if (_progress >= 41 && _progress <= 70) {
          return ProgressFortyOneToSeventyIcon;
        } else {
          return ProgressCompletedIcon;
        }
      }
    },
    setPriorityIcon(_priority, _isProgressCompleted) {
      if (_isProgressCompleted == true) {
        return PriorityCompletedIcon;
      } else {
        switch (_priority) {
          case "#984453":
            return Priority1Icon;
          case "#A76571":
            return Priority2Icon;
          case "#BD8C94":
            return Priority3Icon;
          default:
            return Priority1Icon;
        }
      }
    },
    setPriorityNumber(_priority, _isProgressCompleted) {
      if (_isProgressCompleted == false) {
        switch (_priority) {
          case "#984453":
            return `P1`;
          case "#A76571":
            return `P2`;
          case "#BD8C94":
            return `P3`;
          default: {
            return "---";
          }
        }
      } else {
        if (this.browser_width >= 1920 && this.browser_width < 2400) {
          return "C...";
        } else {
          return "Completed";
        }
      }
    },
    setDeadlineDate(deadline) {
      if (deadline == null) {
        return `---`;
      } else {
        return moment(new Date(deadline)).format("MMM DD, YYYY");
      }
    },
    resize_browser_window(e) {
      e.preventDefault();
      this.browser_width = window.innerWidth;
    },
    openmodal(obj, index) {
      const errorText =
        "You are unable to view this content, please message us at 07480533921 to have your account upgraded.";
      if (this.user.role_id === 1) {
        this.$emit("openmodal", obj);
      } else if (this.user.is_unlocked === 0 && index >= 3 && index <= 6) {
        this.showSnackBar(errorText);
      } else if (this.user.is_unlocked2 === 0 && index >= 7) {
        this.showSnackBar(errorText);
      } else {
        this.$emit("openmodal", obj);
      }
    },
    tickcheckroadmap(data, index) {
      const errorText =
        "You are unable to view this content, please message us at 07480533921 to have your account upgraded.";

      if (this.user.role_id === 1) {
        this.$emit("tickcheckroadmap", data);
      } else if (this.user.is_unlocked === 0 && index >= 3 && index <= 6) {
        this.showSnackBar(errorText);
      } else if (this.user.is_unlocked2 === 0 && index >= 7) {
        this.showSnackBar(errorText);
      } else {
        this.$emit("tickcheckroadmap", data);
      }
    },
    tickStaff(data, index) {
      const errorText =
        "You are unable to view this content, please message us at 07480533921 to have your account upgraded.";

      if (this.user.role_id === 1) {
        this.$emit("tickStaff", data);
      } else if (this.user.is_unlocked === 0 && index >= 3 && index <= 6) {
        this.showSnackBar(errorText);
      } else if (this.user.is_unlocked2 === 0 && index >= 7) {
        this.showSnackBar(errorText);
      } else {
        this.$emit("tickStaff", data);
      }
    },
    getPriorityDescription(priority, isCompleted) {
        if ( !isCompleted) {
            switch (priority) {
                case "#A76571":
                    return `This is priority number 1.`;
                case "#BD8C94":
                    return `This is priority number 2.`;
                case "#D3B2B8":
                    return `This is priority number 3.`;
                default: {
                    return `No priority has been set.`;
                }
            }
        } else {
            return `Completed`;
        }
    },
  },
  watch: {},
  mounted() {
    window.addEventListener("resize", this.resize_browser_window);    
  },
};
</script>

<style scoped>
.no-gutter {
  margin: 0;
  padding: 0;
}

.v-card-title {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.v-card-child-title-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.v-card-text-deadline-date-icon {
  margin-right: 2px;
  margin-left: 5px;
}

.v-card-text-font-size {
  font-size: 11px;
}

.v-card-text-div {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>