<template>
    <v-row justify="center">
        <v-dialog
            v-model="assign_lesson_to_user_dialog"
            width="350">
            <v-card>
                <v-card-title>Assign Lesson to a User</v-card-title>
                <v-card-text>
                    <v-combobox
                        v-model="selected_user"
                        :items="getClientList(null)"    
                        item-text="name"
                        item-value="id"                 
                        return-object
                        label="Select client to assign to this lesson.">
                    </v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="event_close_dialog">Cancel</v-btn>
                    <v-btn color="success" @click="event_assignlessontouser()">Assign</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Subject from '../modules/Subject'

export default {
    data: () => ({
        selected_user: null,
        assign_lesson_to_user_dialog: false
    }),
    props: {
        open_dialog: Boolean,
        course_id: String,
        subject_id: Number
    },
    computed: {
        ...mapGetters({
            getClientList:    'adminmain/getClientList'
        })
    },
    methods: {
        event_close_dialog() {
            this.$emit('close_dialog')
        },
        event_assignlessontouser() {
            const subject = new Subject();            
            subject.add_lesson_to_a_user({
                users_id: this.selected_user.id,
                course_id: this.course_id,
                subject_id: this.subject_id
            });
            this.showSnackBar(`The selected user: ${this.selected_user.name} is successfully assigned to this lesson.`);
            this.event_close_dialog();
        },
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
    },
    mounted() {
        
    },
    watch: {
        open_dialog() {
            this.assign_lesson_to_user_dialog = this.open_dialog
        }
    }
}
</script>