<template>
    <v-container
    >
        <v-row>
            <v-col
                cols="12"
            >
                <v-card
                    class="elevation-0 mt-5 card"
                >
                    <v-card-title>Search filter</v-card-title>
                    <v-card-text
                        class="d-flex"
                    >
                        <v-autocomplete
                            :items="getInternalUsers"
                            dense
                            outlined
                            hide-details
                            item-text="name"
                            item-value="id"
                            v-model="selectedInternal"
                            small-chips
                            @change="getTrackerTable(selectedInternal)"
                        ></v-autocomplete>
                    </v-card-text>
                </v-card>
                <v-card
                    v-for="(item, index) in getTracker"
                    :key="index"
                    class="elevation-0 mt-5 card"
                >
                    <v-card-title>
                        <v-icon></v-icon>
                        <v-btn
                            class="elevation-0 white--text"
                            width="20%"
                            :color="item.color"
                            @click="item.active = !item.active"
                        >
                            {{ item.btn_name }} ({{ item.items.length }})
                        </v-btn>
                        <v-card-text
                            v-if="item.active"
                        >
                            <v-data-table
                                :items="item.items"
                                :headers="item.header"
                                no-results-text="No results found"
                                no-data-text="No results found"
                                :page="item.length"
                            >
                                <template v-slot:item="{ item }">
                                    <tr
                                    >
                                        <td @click="setRoadmap(item)" :style="`border-left: 10px solid ${item.progress == 100 ? '#1D7012' : item.color}`"> <v-simple-checkbox :ripple="false" @click="setProgress(item)"></v-simple-checkbox> </td>
                                        <td @click="setRoadmap(item)"> <strong>{{ item.name }}</strong> <br> <small><span :style="`color: grey`">{{ getRoadmapParent(item.parent_id)[0].name.toUpperCase() }}</span></small> </td>    
                                        <td @click="setRoadmap(item)">{{ setTrackProgress(item.progress, item.deadline_date) }}</td>  
                                        <td @click="setRoadmap(item)"> <v-chip :style="getProgressColor(item.progress)" width="100%"><strong>{{ item.progress || '0' }}%</strong></v-chip> </td>

                                        <td @click="setRoadmap(item)"> 
                                            <v-tooltip
                                                bottom
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-avatar 
                                                        :style="`font-weight: 700; font-size: 10px;`" color="#306AFF" size="26"   class="white--text"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                    >
                                                        {{ getAssignedUser(item.tagged_to) }}
                                                    </v-avatar> 
                                                </template>
                                                <small>{{ item.tagged_to || 'Not Set' }}</small>
                                            </v-tooltip>
                                        </td>

                                        <td @click="setRoadmap(item)"> <v-icon class="mr-1" :color="getPriorityColor(item.color).color">mdi-flag</v-icon> {{getPriorityColor(item.color).text || 'Not Set' }} </td>

                                        <td @click="setRoadmap(item)"> <v-icon class="mr-1" size="20" color="#1565C0">mdi-clock-time-eight-outline</v-icon> {{ getDateFormat(item.deadline_date) || 'Not Set' }} </td>

                                        <td @click="setRoadmap(item)"> {{ getUser(item.user_id)[0].name }}  </td>

                                        <td>
                                            <v-btn v-if="item.notes.length == 0" @click="openDialogNote(item)" color="primary" x-small>Add Note</v-btn>
                                            <v-btn v-else @click="openDialogNote(item)" color="primary" x-small>See Note</v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <!-- NOTE DIALOG -->
        <v-dialog
            v-model="dialog_note"
            width="50%"
        >
            <v-card>
                <v-card-title>
                    Notes
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-5"
                        small
                        color="primary"
                        @click="add_note = !add_note"
                    >
                        <v-icon small>mdi-plus</v-icon> Add New
                    </v-btn>

                    <v-icon small color="red" @click="closedialog()">mdi-close</v-icon>                
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                >
                    <v-card
                        v-if="edited_note.length == 0 && add_note == false"
                        class="mx-auto elevation-0 mt-5"
                    >
                        <center>
                            <v-icon>mdi-clipboard-account-outline</v-icon>
                            <br>
                            No notes for this roadmap
                            <br>
                            Click the Add New button to create one.
                        </center>
                    </v-card>
                    <v-card
                        v-else-if="add_note == true"
                        class="elevation-0 mt-5"
                    >
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <v-textarea
                                v-model="note"
                                required
                                hide-details
                                outlined
                                dense
                                height="100px"
                                :rules="[v => !!v || 'Note is required']"
                            ></v-textarea>
                        </v-form>
                    </v-card>
                    <v-card
                        v-else
                        class="elevation-0 mt-5"
                    >
                        <v-card
                            class="elevation-1 mt-2"
                            v-for="(item, index) in edited_note"
                            :key="index"
                        >
                            <v-card-title>
                                <v-spacer></v-spacer>
                                <v-icon class="mr-3" small @click="edit_note(item)">mdi-pencil-outline</v-icon> 
                                <v-menu
                                    :offset-y="true"
                                >
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-icon 
                                            small 
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-trash-can-outline
                                        </v-icon>                
                                    </template>
                                    <v-list
                                        dense
                                        class="m-0 p-0"
                                    >
                                        <v-list-item
                                            dense
                                            color="red"
                                        >
                                            <v-btn 
                                                small
                                                block 
                                                color="error"
                                                @click="delete_note(item)"
                                            >
                                                Delete?
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>               

                            </v-card-title>
                            <v-card-text>
                                {{ item.note }}
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions
                                class="d-flex justify-space-around"
                            >
                                <div><small>Date: <strong> {{ item.updated_at }} </strong></small></div>
                                <div><small>Edited By: <strong> {{ getInternalUser(item.user_id)[0].name }} </strong></small></div>
                            </v-card-actions>
                        </v-card>
                    </v-card>
                </v-card-text>
                <v-btn
                    v-if="add_note == true"
                    block
                    color="primary"
                    class="rounded-0"
                    @click="submit_note()"
                >Submit</v-btn>
            </v-card>
        </v-dialog>

        <!-- EDIT NOTE DIALOG -->
        <v-dialog
            v-model="edit_dialog_note"
            width="50%"
        >
            <v-card>
                <v-card-title>
                    Edit Note
                    <v-spacer></v-spacer>
                    <v-icon small color="red" @click="closedialog()">mdi-close</v-icon>                
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form
                        ref="form"
                        lazy-validation
                        class="mt-5"
                    >
                        <v-textarea
                            v-model="selected_edited_note.note"
                            required
                            hide-details
                            outlined
                            dense
                            height="100px"
                            :rules="[v => !!v || 'Note is required']"
                        ></v-textarea>
                    </v-form>
                </v-card-text>
                <v-btn
                    block
                    color="primary"
                    class="rounded-0"
                    @click="submit_edit_note()"
                >Submit</v-btn>
            </v-card>
        </v-dialog>


        <!-- DIALOG -->
        <DialogVue 
            v-model="dialogtrelloroadmap"
            :value="dialogtrelloroadmap"
            :info="getSelectedRoadmap"
            :dialogsvalue="dialogtrello"
            :tasks="getTask"
            :comments="selectedcomments"
            @savecolor="savecolordata"            
            @closedialog="closedialog"
            @submitWithInfo="submitDeadline"
            @submitComment="submit"
            @submitPercentage="submitPercentage"
            @submitLink="submitLink"
            @removeLink="removeLink"
            @submitDelete="submitDelete"
            @submitEdit="submitEdit"
            @submitEditRoadmap="submitEditRoadmap"
            @submitTask="submitTask"
            @toggleTask="toggleTask"
            @deleteTask="deleteTask"
            @taggedPersonnel="successTagPersonnel"
            @removeAssignedUser="removeAssignedUser"
        />
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import dayjs from 'dayjs';
    import DialogVue from '../../components/DiaLog'

    export default {
        components: {
            DialogVue
        },
        props: [

        ],
        data: () => ({
            selectedInternal: null,
            roadmaps: null,
            dialogtrelloroadmap: false,
            dialogtrello: {
                trello: true,
                items: [
                    {
                    id: 0,
                    title: 'Priority',
                    items: [
                        {
                            id: 0,
                            label: 'Set Priority',
                            type: 'text'
                        }
                    ],
                    icon: 'mdi-priority-high'
                    },
                    {
                    id: 1,
                    title: 'Deadline Date',
                    icon: 'mdi-update',
                    items: [
                        {
                            id: 1,
                            label: 'Deadline Date',
                            type: 'text'
                        }
                    ]
                    }
                ],
                colors: [
                    {
                        name: 'Priority 1',
                        color: '#A76571'
                    },
                    {
                        name: 'Priority 2',
                        color: '#BD8C94'
                    },
                    {
                        name: 'Priority 3',
                        color: '#D3B2B8'
                    }
                ],
                progress: [
                    {
                        name: '10%',
                        value: 10
                    },
                    {
                        name: '20%',
                        value: 20
                    },
                    {
                        name: '30%',
                        value: 30
                    },
                    {
                        name: '40%',
                        value: 40
                    },
                    {
                        name: '50%',
                        value: 50
                    },
                    {
                        name: '60%',
                        value: 60
                    },
                    {
                        name: '70%',
                        value: 70
                    },
                    {
                        name: '80%',
                        value: 80
                    },
                    {
                        name: '90%',
                        value: 90
                    },
                    {
                        name: '100%',
                        value: 100
                    }
                ]
            },
            selectedcomments: null,

            // notes
            dialog_note: false,
            edited_note: [],
            payload_note: null,
            add_note: false,
            note: null,
            note_internal_user: null,
            edit_dialog_note: false,
            selected_edited_note: [],
        }),
        computed: {
            ...mapGetters({
                getTracker:     'adminmain/getTrackerTable',
                getTrackerUser: 'adminmain/getTrackerUser',
                getRoadmapParents: 'adminmain/getRoadmapParents',
                getUserData:    'auth/getUserData',
                getInternalUsers:   'adminclientdboard/get_internal_users',
                getSelectedRoadmap: 'clientstate/getSelectedRoadmap',
                comments:      'clientstate/getComments',
                getTask:                  'adminclientdboard/getTask',
                getNoteInternalUser: 'adminmain/getNoteInternalUser'
            }),
            admin_user() {
                return JSON.parse(this.getUserData);
            },
            get_note_internal_user(){
                return this.$store.getters['adminmain/getInternalUser'](this.note_internal_user)
            },
        },
        mounted() {
            this.setup();
            this.getAllInternalUsers();

        },
        methods: {
            ...mapActions({
                getAllInternalUsers : 'adminmain/getListOfInternalUsers',
            }),

            showSnackBar(message){
                this.$store.commit("auth/setMessage", 
                {show: true, message: message}, 
                {root: 1})
            },
            setup() {
                this.$axios.get(`/tracker/g_track_roadmap`)
                    .then(({data}) => {
                        this.$store.dispatch('adminmain/setTrackerTable', data.data);
                    });

                this.$axios.get('tracker/g_track_user')
                    .then(({data}) => {
                        this.$store.dispatch('adminmain/setTrackerUser', data.data);
                    })

                this.$axios.get('tracker/g_roadmap_parents')
                    .then(({data}) => {
                        this.$store.dispatch('adminmain/setRoadmapParents', data.data);
                    })

                this.$store.dispatch('adminclientdboard/set_internal');
            },
            getTrackRoadmap() {
                this.$axios.get(`/tracker/g_track_roadmap`)
                   .then(({data}) => {
                        this.$store.dispatch('adminmain/setTrackerTable', data.data);
                    });
            },
            getProgressColor(num) {
                if (num == 0) {
                    return { backgroundColor: '#E9E9E9', color: '#8A8A8A' };
                } else if (num >= 50 && num <= 60) {
                    return { backgroundColor: '#FFE790', color: '#000000' };
                } else if (num >= 70 && num <= 90) {
                    return { backgroundColor: '#FF720D', color: '#FFFFFF' };
                } else if (num == 100) {
                    return { backgroundColor: '#0F6B00', color: '#FFFFFF' };
                }
            },
            getAssignedUser(str) {
                return str == null ? '-' : str.split(' ').map(x => x[0]).join('');
            },
            getPriorityColor(str) {
                if (str == '#D3B2B8') {
                    return { color: str, text: 'Priority 3' }
                } else if (str == '#BD8C94') {
                    return { color: str, text: 'Priority 2' }
                } else if (str == '#A76571') {
                    return { color: str, text: 'Priority 1' }
                } else {
                    return { text: 'Not Set' }
                }
            },
            setTrackProgress(str, date) {
                const now = dayjs();
                const user_date = dayjs(date);

                if (str < 100 && now.isAfter(user_date, 'day')) {
                    return 'Overdue'
                } 

                if (str == null) {
                    return 'Not Started'
                } 
                
                if (str == 100) {
                    return 'Completed'
                } else {
                    return 'On Going'
                }
            },
            getDateFormat(date) {
                return date == null ? 'Not Set' : dayjs(date).format('MMM. DD YYYY').toUpperCase();
            },
            getUser(id) {
                return this.getTrackerUser.filter(a => a.id == id);
            },
            getInternalUser(id) {
                return this.getInternalUsers.filter(a => a.id == id);
            },
            getRoadmapParent(id) {
                return this.getRoadmapParents.filter(a => a.id == id);
            },
            getTrackerTable(id) {
                this.$axios.get(`/tracker/g_track_roadmap/${id}`)
                .then(({data}) => {
                    this.$store.dispatch('adminmain/setTrackerTable', data.data);
                });
            },
            async setProgress(obj) {
                await this.$axios.patch(`/roadmap/e_check_roadmap/${obj.id}`)
                .then(({data}) => {
                    if(data.response) {
                        const id = this.selectedInternal != null ? this.selectedInternal : null;
                        this.closedialog()
                        this.getTrackerTable(id);
                    }
                })
            },
            setRoadmap(obj) {
                this.$store.dispatch('clientstate/setSelectedRoadmap', obj);
                this.dialogtrelloroadmap = true;

                const tp = {
                    course_id: obj.course_id,
                    roadmap_id: obj.id,
                    name: obj.name,
                    user_id: obj.user_id
                }

                this.trelloSetup(tp);
            },
            trelloSetup(tp) {
                this.getComments(tp);
                this.getTasks(tp);
            },
            async getComments(obj) {
                await this.$axios.get(`client/getAdminComments`, obj)
                    .then(({data}) => {
                        this.selectedcomments = data.data;
                        this.$store.dispatch('clientstate/setComments', data.data);
                    });
            },

            async getTasks(obj) {
                await this.$axios.get(`client/getTask`, obj)
                .then(({data}) => {
                    this.$store.dispatch('adminclientdboard/setTask', data.data);
                }).catch(err => this.showSnackBar('An error has occured, please contact the admin / developer.' + err));
            },
            async savecolordata(obj){
                let tp = {
                    color: obj.input,
                    course_id: obj.item.course_id,
                    item_id: obj.item.id
                }
                await this.$axios.post('/client/addpriorityroadmap', tp)
                .then(({data}) => {
                    if(data.response){
                      const id = this.selectedInternal != null ? this.selectedInternal : null;
                      this.getTrackerTable(id);

                      this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                    }
                })
            },
            closedialog(){
                this.dialogtrelloroadmap = false
                this.showdialog = false
                this.showdialogchild = false
                this.dialogcolorroadmap = false
                this.videodialog = false
                this.wysiwygdialog = false
                this.dialogtrelloroadmap = false
                this.dialoguser = false
                this.dialog_note = false
                this.edit_dialog_note = false
            },
            submitDeadline(obj) {
                this.$axios.post('client/setDeadline', obj)
                .then(({data}) => {
                    if(data.response) {
                        const id = this.selectedInternal != null ? this.selectedInternal : null;

                        this.getTrackerTable(id);

                        this.$store.dispatch('clientstate/setSelectedRoadmap', data.roadmap);
                    }
                })
            },
            async submit(item) {
                const tp = {
                    comments: item.item,
                    course_id: item.data.course_id,
                    roadmap_id: item.data.id,
                    user_id: this.admin_user.id,
                    user_role_id: this.admin_user.role_id,
                    name: item.data.name
                }

                await this.$axios.post('/client/addComment', tp)
                .then(({data}) => {
                    if(data.response) {
                        this.closedialog();
                        const id = this.selectedInternal != null ? this.selectedInternal : null;

                        this.getTrackerTable(id);
                        this.$axios.post('emails/notifyUsers', tp)
                        .then(({data}) => {
                            if(data.response) {
                            this.showSnackBar(data.message);
                            }
                        }).catch(err => err);

                        this.closedialog();

                        this.getTrackerTable(id);
                    }
                }).catch(err => err);
            },
            async submitPercentage(obj) {
                await this.$axios.post('client/setProgress', obj)
                .then(({data}) => {
                    this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                    const id = this.selectedInternal != null ? this.selectedInternal : null;

                    this.getTrackerTable(id);
                })
            },
            async submitDelete(obj) {
                await this.$axios.delete(`client/deleteComment/${obj.id}`)
                    .then(({data}) => {
                        if(data.response) {
                            this.showSnackBar(data.message);
                            this.closedialog();
                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                        }
                    }).catch(err => err);
            },
            async submitEdit(obj) {
                await this.$axios.patch('client/editComment', obj)
                    .then(({data}) => {
                        if(data.response) {
                            this.showSnackBar(data.message);
                            this.closedialog();

                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                        }
                    }).catch(err => err);
            },
            async toggleTask(obj) {
                await this.$axios.post('client/toggleTask', obj)
                    .then(({data}) => {
                        this.$store.dispatch('clientstate/setTask', data.data);
                    }).catch(err => console.error(err));
            },
            async submitLink(obj) {
                await this.$axios.post('client/setVideo', obj)
                    .then(({data}) => {
                        if(data.response) {
                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                            this.showSnackBar(data.message);
                        }
                    }).catch(err => err);
            },
            async removeLink(obj) {
                await this.$axios.post('client/removeVideo', obj)
                    .then(({data}) => {
                        if(data.response) {
                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                            this.showSnackBar(data.message);
                        }
                    })
            },
            async submitEditRoadmap(obj) {
                await this.$axios.post('adminclientdboard/editRoadmap', obj)
                    .then(({data}) => {
                        if(data.response) {
                            this.showSnackBar(data.message);
                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                            this.$store.dispatch('clientstate/setSelectedRoadmap', data.data);
                        } else {
                            this.showSnackBar(`An error has occured, please contact the developer. Error: ${data.message}`)
                        }
                    }).catch(err => this.showSnackBar(`An error has occured, please contact the developer. Error: ${err}`));
            },
            async submitTask(obj) {
                await this.$axios.post('client/submitTask', obj)
                    .then(({data}) => {
                        this.$store.dispatch('adminclientdboard/setTask', data.data);
                        this.closedialog();
                    }).catch(err => console.error(err));
            },
            async deleteTask(obj) {
                await this.$axios.patch('client/deleteTask', obj)
                    .then(({data}) => {
                        this.$store.dispatch('adminclientdboard/setTask', data.data);
                        this.closedialog();
                    }).catch(err => console.error(err));
            },
            successTagPersonnel(payload) {
                const id = this.selectedInternal != null ? this.selectedInternal : null;

                this.getTrackerTable(id);
                this.showSnackBar(`Succefully tagged ${payload.internalUser} to this Roadmap`);
                this.closedialog();
            },
            async removeAssignedUser(payload) {
                await this.$axios.patch(`roadmap/r_assigned_user/${payload.id}`)
                    .then(({data}) => {
                        if(data.response) {
                            this.showSnackBar(data.message);
                            this.closedialog();
                            const id = this.selectedInternal != null ? this.selectedInternal : null;

                            this.getTrackerTable(id);
                        }
                    })
            },
            openDialogNote(payload) {
                this.edited_note = payload.notes;
                this.dialog_note = true;
                this.payload_note = payload;
            },
            async submit_note() {
                const res = this.$refs.form.validate();
                // this.$store.dispatch('adminmain/setNoteInternalUser', this.payload_note.tagged_to);

                const tp = {
                    roadmap_id: this.payload_note.id,
                    // user_id: this.getNoteInternalUser[0].id,
                    user_id: this.admin_user.id,
                    course_id: this.payload_note.course_id,
                    note: this.note
                };

                if(res == true) {
                    try {
                        this.$store.dispatch('adminmain/submitNoteInternalUser', tp);

                        this.dialog_note = false;
                        this.note = null;
                        this.add_note = false;
                        
                        this.closedialog();
                        const id = this.selectedInternal != null ? this.selectedInternal : null;

                        this.getTrackerTable(id);
                    } catch (e) {
                        console.error(e.message);
                    }                
                } else {
                    this.showSnackBar('Please fill up the note!');
                }
            },
            edit_note(payload) {
                this.selected_edited_note = payload;
                this.dialog_note = false;
                this.edit_dialog_note = true;
            },
            delete_note(payload) {
                try {
                    this.$store.dispatch('adminmain/deleteNoteInternalUser', payload.id);
                    this.closedialog();
                    const id = this.selectedInternal != null ? this.selectedInternal : null;

                    this.getTrackerTable(id);
                } catch (e) {
                    console.error(e.message);
                }
            },
            submit_edit_note() {
                const res = this.$refs.form.validate();

                if(res == true) {
                    try {
                        const tp = {
                            id: this.selected_edited_note.id,
                            user_id: this.admin_user.id,
                            note: this.selected_edited_note.note
                        };

                        this.$store.dispatch('adminmain/submitEditedNoteInternalUser', tp);

                        this.closedialog();
                        const id = this.selectedInternal != null ? this.selectedInternal : null;

                        this.getTrackerTable(id);
                    } catch (e) {
                        console.error(e.message);
                    }
                } else {
                    this.showSnackBar('Please fill up the note!');
                }
            }
        },
        watch: {

        }
    }
</script>

<style scoped>
    .card {
        border: 2px solid rgb(236, 236, 236);
    }

    .v-chip {
        width: 65%;
        display: flex;
        justify-content: center;
    }
</style>