<template>
    <div id="nav-bar">
        <v-app-bar
            v-if="Object.entries(getUserData).length !== 0 && isLogged"
            app
            color="blue darken-3"
            dark
            elevation="1"
        >
          <div v-if="JSON.parse(getUserData).role_id == 1">
            <v-btn
              text
              @click="gotoLink('/clientlist')"
            >
              <v-icon class="mr-2">mdi-account-group</v-icon>Client List
            </v-btn>

            <!-- TRACKER -->
            <v-btn
              text
              @click="$router.push({name: 'tracker'})"
            >
              <v-icon class="mr-2">mdi-playlist-check</v-icon>Tracker
            </v-btn>

            <v-btn
              text
              @click="$router.push({name: 'courses'})"
            >
              <v-icon class="mr-2">mdi-sitemap</v-icon>Roadmap
            </v-btn>

              <!-- <v-btn
                v-if="JSON.parse(getUserData).role_id == 1 || JSON.parse(getUserData).role_id == 2"
                text
                @click="$router.push({name: 'wysiwyg'})"
              >
                <v-icon class="mr-2">mdi-square-edit-outline</v-icon>Wysiwyg
              </v-btn> -->

            <v-btn
              text
              @click="$router.push({name: 'internal_users'})"
            >
              <v-icon class="mr-2">mdi-account-group-outline</v-icon>Admin
            </v-btn>

            <v-btn
              text
              @click="logout"
            >
              <v-icon class="mr-2">mdi-logout</v-icon>Logout
            </v-btn>

          </div>
          <div v-if="JSON.parse(getUserData).role_id == 2 || JSON.parse(getUserData).role_id == 5">
            <v-btn
              text
              @click="$router.push({name: 'clientv2'})"
            >
              <v-icon class="mr-2">mdi-home</v-icon>Home
            </v-btn>
            <v-btn
              text
              @click="logout"
            >
              <v-icon class="mr-2">mdi-logout</v-icon>Logout
            </v-btn>
          </div>
          <div v-if="JSON.parse(getUserData).role_id == 4">
            <v-btn
              text
              @click="$router.push({name: 'internal'})"
            >
              <v-icon class="mr-2">mdi-home</v-icon>Home
            </v-btn>
            <v-btn
              text
              @click="logout"
            >
              <v-icon class="mr-2">mdi-logout</v-icon>Logout
            </v-btn>
          </div>
          <v-spacer></v-spacer>
            <v-btn
              text
              @click="opendialog()"
            >
              <span class="mr-2">Hello, {{JSON.parse(getUserData).name}}</span>
              <v-icon>mdi-account</v-icon>
          </v-btn>
         </v-app-bar>
         <v-app-bar
          v-else
          app
          color="blue darken-3"
          dark
         >
          Profitable Tradesmen
         </v-app-bar>
         <div>
            <DialogVue
              v-model="dialoguser"
              :value="dialoguser"
              :dialogsvalue="uservalue"
              :info="user"
              @closedialog="closedialog"
              @submit="resetPassword"
            />

            <DialogVue
              v-model="dialogpassword"
              :value="dialogpassword"
              :dialogsvalue="passwordvalue"
              :info="user"
              @closedialog="closedialog"
              @submitWithInfo="submitResetPassword"
            />
         </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogVue from '../../components/DiaLog'

export default {
  components: {
    DialogVue
  },
  props: [
  ],
  data: () => ({
    udata: {},
    loader: false,
    dialoguser: false,
    uservalue: {
      user: true
    },
    dialogpassword: false,
    passwordvalue: {
      reset: true
    }
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
      getToLoad:    'auth/getToLoad',
      getUserData:  'auth/getUserData',
      isLogged:     'auth/getIsLogged'
    }),

    user() {
      return this.getUserData.length > 0  ? JSON.parse(this.getUserData) : '';
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async logout(){
      let payload = {
        last_check_out: null,
        login: false
      }
      
      await this.$axios.post('clients/check_out', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$axios.post('/logout', { id: this.user.id }).catch(err => console.error(err));
            this.$store.dispatch('auth/setIsLogged', false)
            this.$store.dispatch('auth/clearAuthToken');
            this.$store.dispatch('auth/clearCurrentRoute');

            localStorage.clear();
            sessionStorage.clear();

            setTimeout( () => {
              this.$router.push({ name: 'index' }).catch(err => err)
            }, 500)
          }
      })
    },
    closedialog() {
      this.dialoguser = false;
      this.dialogpassword = false;
    },
    opendialog() {
      this.dialoguser = true;
    },
    resetPassword() {
      this.dialogpassword = true;
      this.dialoguser = false;
    },
    async submitResetPassword(obj) {
      await this.$axios.post('client/resetpassword', obj)
        .then(({data}) => {
          this.showSnackBar(data.message);
          this.logout();
          this.closedialog();
        }).catch(err => err);
    },
    gotoLink(url) {
        window.location.href = url;
    } 
  },
  watch: {
    //getUserData(data){
    //  console.log(data)
    //}
  }
}
</script>

<style scoped lang="scss">
</style>