<template>
  <v-container fluid>
    <TheLoader 
      :visible="loader"
    />
    <v-row>
      <v-col cols="4">
        <ListItem
          :setup="getSetup"
          :lessonList="lessonList"
          @addchapter="addchapter"
          @addlesson="addlesson"
        />
        <!-- <DialogVue
          v-model="dialogaddchapter" 
          :value="dialogaddchapter" 
          :dialogsvalue="dialogchapters" 
          @closedialog="closedialog"
          @savedata="savenewchapter"
        /> -->
        <!-- <DialogVue
          v-model="dialogaddlesson" 
          :value="dialogaddlesson" 
          :dialogsvalue="dialoglessons"
          @closedialog="closedialog"
          @savedata="savelesson"
        /> -->

        <!-- CHAPTER -->
        <v-dialog
          v-model="dialogaddchapter"
          width="35%"
        >
          <v-card>
            <v-card-title>
              Add Chapter
            </v-card-title>
            <v-card-text>
              <v-text-field
                dense
                v-model="chaptertextfield"
                label="Chapter *"
                hint="Chapter Name"
                type="text"
                outlined
                class="mt-5"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="savenewchapter(chaptertextfield)"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- LESSON -->
        <v-dialog
          v-model="dialogaddlesson"
          width="35%"
        >
          <v-card>
            <v-card-title>
              Add Lesson
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                class="mt-5"
                type="text"
                outlined
                dense
                label="Lesson *"
                hint="Lesson Name"
                v-model="lessontextfield"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="savelesson(lessontextfield)"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="8">
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListItem from '@/components/ListItem'
// import DialogVue from '../../../components/DiaLog'
import TheLoader from '../../../components/TheLoader'
import { mapGetters } from 'vuex'
export default {
  components: {
    ListItem,
    // DialogVue,
    TheLoader
  },
  props: [
  ],
  data: () => ({
    content: false,
    dialogchapters: {
      title: 'Add Chapter',
      text_field: [
        {
          id:         0,
          label:      'Chapter *',
          hint:       'Chapter Name',
          type:       'text'
        }
      ]
    },
    dialogaddchapter: false,
    dialoglessons: {
      title: 'Add Lesson',
      text_field: [
        {
          id:         0,
          label:      'Lesson *',
          hint:       'Lesson Name',
          type:       'text'
        }
      ]
    },
    dialogaddlesson: false,
    emptypage: {
      icon: 'mdi-book-open-page-variant',
      header: 'Choose a chapter or lesson to get started',
      subheader: 'preview your course as a student to make sure everything is looking sharp.'
    },
    lessonList: false
  }),
  mounted () {
  },
  created () {
    //console.log(this.$route.params.id)
    this.flessonlist()
    //this.fchapterlist()
  },
  computed: {
    ...mapGetters({
      getSetup:                 'adminmain/getCourseList',
      getParentChapterData:     'adminmain/getParentChapterData',
      loader:       'auth/getLoader',
    })
  },
  methods: {
    hide() {
      this.$store.dispatch('auth/setHide');
    },
    show() {
      this.$store.dispatch('auth/setShow');
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    addchapter(data){
      this.dialogaddchapter = data
    },
    closedialog(){
      this.dialogaddchapter = false
      this.dialogaddlesson = false
    },
    addlesson(){
      this.dialogaddlesson = true
    },
    async flessonlist(){
      this.show();
      await this.$axios.get(`/courses/getcourselist/${this.$route.params.id}`)
      .then(({data}) => {
        if(data.response) {
          // this.$axios.get(`/courses/getHierarchy/${this.$route.params.id}`)
          //   .then(({data}) => {
          //     if(data.response) {
                this.$store.dispatch('adminmain/setCourseList', data.data);
                this.$forceUpdate();
                this.hide();
            //   }
            // });
        }
      })
    },
    async savelesson(data){
      this.show();
      let tp = {
        name: data,
        parent_id: this.getParentChapterData.id,
        parent_name: this.getParentChapterData.name
      }

      await this.$axios.post(`/courses/addlesson/${this.$route.params.id}`, tp)
      .then(({data}) => {
        this.hide();
        if(data.response){
          this.flessonlist()
        }else{
          this.showSnackBar(data.message)
          this.show();
        }
      })
      this.closedialog()
    },
    async fchapterlist(){
      this.show();
      await this.$axios.get(`/courses/getchapters/${this.$route.params.id}`)
      .then(({data}) => {
        if(data.response) {
          this.$store.dispatch('adminmain/setChapter', data.data)
          this.hide();
        }
      })
    },
    async savenewchapter(data){
      this.show();
      let tp = {
        name: data
      }
      await this.$axios.post(`/courses/addchapter/${this.$route.params.id}`, tp)
      .then(({data}) => {
        if(data.response){
          this.hide()
          this.flessonlist()
        }else{
          this.hide()
          this.showSnackBar(data.message)
        }
      })
      this.closedialog()
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>