import { render, staticRenderFns } from "./RoadmapComponent.vue?vue&type=template&id=40a6f918&scoped=true"
import script from "./RoadmapComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./RoadmapComponent.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RoadmapComponent.vue?vue&type=style&index=0&id=40a6f918&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a6f918",
  null
  
)

export default component.exports