<template>
    <v-container fluid>
        <div>
            <v-combobox
                v-model="selected_course"
                placeholder="Select Course"
                label="Select Course"
                :items="get_courses_by_user(users_info.course_id)"
                item-text="course_title"></v-combobox>
        </div>
        <div>
            <v-btn
                class="mt-1 mb-3"
                color="primary"
                style="width: 100%;"
                @click="search_video_tags_fn">
                <v-icon class="mr-2">mdi-magnify-expand</v-icon>
                <small>Search</small>
            </v-btn>
        </div>
        <div>
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="ucourse in get_ucourse_parent_by_users_id(selected_course != null ? selected_course.id : selected_course_id, users_info.membership_type)"
                    :key="ucourse.id">
                    <v-expansion-panel-header style="font-size:10px;">
                        <span style="font-size:16px;color:#1565C0;font-weight: 600;">{{ ucourse.name }}</span>
                        <v-spacer></v-spacer>
                        {{ get_course_completed_count(ucourse.children) }} / {{ ucourse.children.length }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card outlined
                            v-for="child in ucourse.children"
                            :key="child.id"
                            active-class="pink--text"
                            class="mb-2">
                            <v-card-title style="font-size:14px;cursor: pointer;" @click="show_course_lesson(child)">{{ child.name }}</v-card-title>
                            <v-card-text>
                                <v-btn outlined style="font-size:10px;" v-if="check_if_completed_or_not(child.id) == false" @click="event_markascompleted(child)">
                                    <v-icon class="mr-2">mdi-check-bold</v-icon>
                                    Mark as Completed
                                </v-btn>
                                <v-chip color="success" outlined v-else>Completed</v-chip>
                            </v-card-text>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <ViewCourseLesson
            :show_course_lesson_dialog="show_course_lesson_dialog"
            :lesson="selected_item"
            :users_info="users_info"
            @closeCourseLessonDialog="closeCourseLessonDialog()"></ViewCourseLesson>
        
        <SearchVideoTags
            :show_search_video_tags_dialog="search_video_tags"
            @close_dialog="close_dialog"></SearchVideoTags>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewCourseLesson from './subcomponents/ViewCourseLesson.vue'
import SearchVideoTags from './SearchVideoTags.vue';

import CourseByClient from '@/v2/modules/client/course/modules/Course.js';

export default {
    data: () => ({
        selected_course_id: 3,
        selected_course: null,
        search_video_tags: false,
        show_course_lesson_dialog: false,
        selected_item: null,
        client_course: new CourseByClient(),
    }),
    components: {
        ViewCourseLesson,
        SearchVideoTags
    },
    props: {
        users_info: Object
    },
    computed: {
        ...mapGetters({
            get_ucourse_parent_by_users_id:     'course/get_ucourse_parent_by_users_id_getter',
            get_courses_by_user:                'course/get_courses_by_user_getter',
            get_course_completed_count:         'clientcourse/get_course_completed_count_getter',
            check_if_completed_or_not:          'clientcourse/check_if_completed_or_not_getter'
        })
    },
    methods: {
        search_video_tags_fn(){
            this.search_video_tags = true
        },
        close_dialog() {
            this.search_video_tags = false
        },
        show_course_lesson(item) {
            this.selected_item = item
            this.show_course_lesson_dialog = true
        },
        closeCourseLessonDialog() {
            this.show_course_lesson_dialog = false
        },
        event_markascompleted(child) {
            this.client_course.update_course_data_by_id(child.id, {
                is_done: 1,
                ucourse_id: child.id,
                users_id: this.users_info.id
            }, this.$store)
        }
    }
}

</script>