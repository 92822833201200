<template>
    <v-container fluid>
        <v-row class="mt-5 mb-5">
            <v-col cols="2">
                <v-select
                    :items="years"
                    v-model="selected_year"
                    label="Year"
                    @change="get_data"
                >
                </v-select>
            </v-col>
            <v-col cols="2">
                <v-select
                    :items="quarters"
                    v-model="selected_quarter"
                    label="Quarter"
                    @change="get_data"
                >
                </v-select>
            </v-col>
        </v-row>
        <div v-if="selected_year != null && selected_quarter != null">
            <v-row 
                v-for="week in weeks"
                :key="week"
                class="mb-5"
                style="border:solid 1px lightgrey;"
            >
                <v-col
                    cols="1"
                    style="background-color:#1565C0;"
                    class="d-flex align-center justify-center"
                >
                    <p style="color:white;font-size:16px;" class="pr-2 pt-1"><b>week</b></p>
                    <p style="color:white;font-size:24px;"><b>{{ week }}</b></p>
                </v-col>
                <v-col
                    cols="11"
                    class="pb-0"
                >
                    <v-col cols="12">
                        <v-text-field 
                            v-if="getQuarterlyGoals[week] == null"
                            v-model="new_goal.parent_id[week]"
                            placeholder="Add goal"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="add_goal(week)"
                            v-on:keyup.enter="add_goal(week)"
                            style="margin-top:-20px;"
                        >
                            
                        </v-text-field>
                        <v-edit-dialog
                            v-else
                            style="cursor: pointer;"
                            :return-value.sync="getQuarterlyGoals[week][0].task"
                            large
                            @save="update_goal(getQuarterlyGoals[week][0])"
                        >
                            <div style="cursor: pointer;font-size:16px;"><b>Goal:</b> {{ getQuarterlyGoals[week][0].task }}</div>
                            <template v-slot:input>
                                <v-text-field
                                v-model="getQuarterlyGoals[week][0].task"
                                label="Edit"
                                single-line
                                counter
                                autofocus
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </v-col>
                    <v-col cols="12" v-if="getQuarterlyTasks[week] != null" style="margin-top:-10px;">
                        <small class="d-flex align-center justify-center"><b>score</b></small>
                        <v-progress-linear
                            :buffer-value="getQuarterlyTasks[week].length"
                            :value="((scores[week] / getQuarterlyTasks[week].length) * 100)"
                            striped
                            height="15"
                            color="#7b9ff9"
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ value.toFixed(2) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </v-col>
                    <v-data-table 
                        v-if="getQuarterlyTasks[week] != null"
                        :headers="headers"
                        :items="getQuarterlyTasks[week]"
                        hide-default-footer 
                        disable-pagination
                        style="margin-top:-40px;"
                    >
                        <template v-slot:item="{ item }">
                            <tr style="background-color: transparent;">
                                <td style="height:10px!important;">
                                    <v-checkbox style="margin:0px!important;padding-bottom:2px;" @click="update_task(item)" v-model="item.is_done"></v-checkbox>
                                </td>
                                <td style="height:10px!important;">
                                    <v-edit-dialog
                                        style="cursor: pointer;"
                                        :return-value.sync="item.task"
                                        large
                                        @save="update_task(item)"
                                    >
                                        <div style="cursor: pointer;">{{ item.task }}</div>
                                        <template v-slot:input>
                                            <v-text-field
                                            v-model="item.task"
                                            label="Edit"
                                            single-line
                                            counter
                                            autofocus
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </td>
                                <td style="height:10px!important;">
                                    <small v-if="item.get_user">added by {{ item.get_user.name }}</small>
                                </td>
                                <td style="height:10px!important;">
                                    <v-icon small class="mr-2" @click="open_delete(item)">
                                        mdi-delete-outline
                                    </v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-col cols="12">
                        <v-text-field
                            v-model="new_task.parent_id[week]"
                            placeholder="Add task"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="add_task(week)"
                            v-on:keyup.enter="add_task(week)"
                        >
                        </v-text-field>
                    </v-col>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="delete_dialog" persistent max-width="300">
            <v-card>
                <v-card-title class="text-h6">
                    Are you sure?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_delete()">
                        Cancel
                    </v-btn>
                    <v-btn color="red darken-1" text @click="delete_task()">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    computed: {

        ...mapGetters({
            getUser: 'auth/getUserData',
            getUserData: 'auth/getSelectedUser',
            getQuarterlyTasks: 'clientstate/get_quarterly_tasks',
            getQuarterlyGoals: 'clientstate/get_quarterly_goals',
        }),
    },
    async mounted() {
        
    },
    async created() {
        this.set_current_year_and_quarter()
    },
    data() {
        return {
            headers: [
                { text: '', value: 'is_done', sortable: false, width: '5%' },
                { text: '', value: 'task', sortable: false, width:'70%' },
                { text: '', sortable: false, width:'20%' },
                { text: '', sortable: false, width: '5%' },
            ],
            years: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
            quarters: [1, 2, 3, 4],
            weeks: 12,
            selected_year: null,
            selected_quarter: null,
            scores: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
            new_task: {
                parent_id: []
            },
            new_goal: {
                parent_id: []
            },
            delete_dialog: false,
            to_delete: null,
        }
    },
    methods: {
        set_current_year_and_quarter() {
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth()
            let quarter = null
            if (month < 3) { quarter = 1 }
            if (month > 2 && month < 6) { quarter = 2 }
            if (month > 5 && month < 9) { quarter = 3 }
            if (month > 8 && month < 12) { quarter = 4 }
            
            this.selected_year = year
            this.selected_quarter = quarter
            this.get_data()
        },
        get_data() {
            this.get_tasks()
            this.get_goals()
        },
        async get_tasks() {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
            }
            await this.$axios.get('/quarterly/get_tasks', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_tasks', data.data)
                        this.calculate_scores(this.getQuarterlyTasks)
                    }
                })
        },
        async add_task(week) {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                created_by: JSON.parse(this.getUser).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
                week: week,
                task: this.new_task.parent_id[week]
            }
            await this.$axios.post('/quarterly/add_task', payload)
                .then(({ data }) => {
                    this.$delete(this.new_task.parent_id, week)
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_tasks', data.data)
                        this.calculate_scores(this.getQuarterlyTasks)
                    }
                })
        },
        async update_task($data) {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
                task_id: $data.id,
                is_done: $data.is_done,
                task: $data.task
            }
            await this.$axios.patch('/quarterly/update_task', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_tasks', data.data)
                        this.calculate_scores(this.getQuarterlyTasks)
                    }
                })
        },
        open_delete(task) {
            this.to_delete = task
            this.delete_dialog = true
        },
        close_delete() {
            this.to_delete = null
            this.delete_dialog = false
        },
        async delete_task() {
            await this.$axios.delete(`/quarterly/delete_task/${this.to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.get_tasks()
                        this.close_delete()
                    }
                })
        },
        async get_goals() {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
            }
            await this.$axios.get('/quarterly/get_goals', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_goals', data.data)
                    }
                })
        },
        async add_goal(week) {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                created_by: JSON.parse(this.getUser).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
                week: week,
                task: this.new_goal.parent_id[week],
                is_goal: true
            }
            await this.$axios.post('/quarterly/add_goal', payload)
                .then(({ data }) => {
                    this.$delete(this.new_goal.parent_id, week)
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_goals', data.data)
                    }
                })
        },
        async update_goal($data) {
            let payload = {
                user_id: JSON.parse(this.getUserData).id,
                year: this.selected_year,
                quarter: this.selected_quarter,
                task_id: $data.id,
                task: $data.task
            }
            await this.$axios.patch('/quarterly/update_goal', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('clientstate/set_quarterly_goals', data.data)
                    }
                })
        },
        calculate_scores(tasks) {
            for (let i = 1; i < 13; i++) {
                if (tasks[i]) {
                    this.scores[i] = 0
                    tasks[i].forEach((item) => {
                        if (item.is_done) {
                            this.scores[i] += 1
                        }
                    })
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep .v-input__control .v-messages{
    display:none;
}
::v-deep .v-input--selection-controls__ripple {
    display:none;
}
</style>