<template>
    <v-container fluid>
        <v-row
            no-gutters
            class="mt-3"
        >
            <v-container
            color="blue darken-5"
            >
                <v-row>
                    <!-- <v-col cols="6">
                        <v-select
                            :disabled="disable_input"
                            :items="models"
                            v-model="selected_model"
                            item-text="text"
                            item-value="value"
                            return-object
                            label="Select GPT-3 Model"
                        >
                        </v-select>
                    </v-col> -->
                    <v-col cols="12" class="d-flex align-center">
                        <h5>
                            {{ selected_model.description }}
                        </h5>
                    </v-col>
                </v-row>
            </v-container>
            <hr style="border:1px solid gray; width: 100%;"/>

            <div id="chatbox" class="overflow-y-auto" style="overflow-x:hidden;height:1000px;width: 100%">
                <v-row
                    v-for="(message, messageIndex) in messages"
                    :key="messageIndex"
                    class="p-0"
                >
                    <v-col cols="12">
                    <v-container>
                        <v-card
                            color="blue darken-3"
                            style="float: right;min-width:20%;max-width:70%"
                            class="d-flex rounded-xl"
                            elevation="0"
                        >
                            <v-card-text>
                                <h4 class="white--text">{{ message.prompt }}</h4>
                                <small class="white--text">{{ message.created_at }}</small>
                            </v-card-text>
                        </v-card>
                    </v-container>
                    </v-col>
                    <v-col cols="12">
                    <v-container>
                        <!-- <img src="https://i.gifer.com/YlWC.gif"/> -->
                        <v-card
                            color="#f9f9f9"
                            style="float: left;min-width:20%;max-width:70%"
                            class="d-flex rounded-xl"
                            elevation="0"
                        >
                            <v-card-text>
                                <h3 class="mb-2">
                                    {{ message.model_name }}
                                </h3>
                                <h4 class="ml-5">
                                    <span 
                                        v-if="message.response != null"
                                        style="white-space:pre-wrap"
                                    >{{ message.response }}
                                    </span>
                                    <span v-else>
                                        <i>Failed response</i>
                                    </span>
                                </h4>
                                <small class="ml-5">{{ message.updated_at }}</small>
                            </v-card-text>
                        </v-card>
                    </v-container>
                    </v-col>
                </v-row>
                <v-row v-if="disable_input" class="p-0">
                    <v-col cols="12">
                    <v-container>
                        <v-card
                            color="blue darken-3"
                            style="float: right;min-width:20%;max-width:70%"
                            class="d-flex"
                        >
                            <v-card-text>
                                <h4 class="white--text">{{ prompt }}</h4>
                            </v-card-text>
                        </v-card>
                    </v-container>
                    </v-col>
                </v-row>
            </div>
            <v-container fluid class="mb-5">
                <v-row
                    class="pa-2"
                >
                    <v-text-field
                        placeholder="Write your message here"
                        v-model="prompt"
                        :disabled="disable_input"
                        append-outer-icon="mdi-send"
                        @click:append-outer="generate"
                        v-on:keyup.enter="generate"
                    >
                    </v-text-field>
                </v-row>
            </v-container>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="isLoading"
                persistent
                width="auto"
            >
                <v-overlay color="white">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    computed: {
    },
    async mounted() {
        document.querySelector('#chat-widget').style.display = 'none'
        document.querySelector('#nav-bar').style.display = 'none'
    },
    async created() {
        this.get_messages()
    },
    data() {
        return {
            // models: [
            //     {
            //         text: 'Davinci',
            //         value: 'text-davinci-003',
            //         description: 'Most capable, higher quality, better instruction-following'
            //     },
            //     {
            //         text: 'Curie',
            //         value: 'text-curie-001',
            //         description: 'Very capable, but faster and lower cost than Davinci'
            //     },
            //     {
            //         text: 'Babbage',
            //         value: 'text-babbage-001',
            //         description: 'Capable of straightforward tasks, very fast, and lower cost'
            //     },
            //     {
            //         text: 'Ada',
            //         value: 'text-ada-001',
            //         description: 'Capable of very simple tasks, usually the fastest model, and lowest cost'
            //     }
            // ],
            selected_model: 
            {
                text: 'GPT-3',
                value: 'gpt-3.5-turbo',
                description: 'Most capable GPT-3.5 model and optimized for chat at 1/10th the cost of text-davinci-003. Will be updated with our latest model iteration.'
            },
            messages: [],
            prompt: null,
            disable_input: false,
            isLoading: false
        }
    },
    methods: {
        async get_messages() {
            await this.$axios.get('/openai/get_messages')
                .then(({ data }) => {
                    if (data.response) {
                        this.messages = [...data.data]
                        this.scroll_to_bottom()
                    }
                })
        },
        async generate() {
            if (this.prompt != null && this.prompt.trim().length == 0)
                return
            this.disable_input = true
            this.scroll_to_bottom()
            let payload = {
                prompt: this.prompt,
                model: this.selected_model.value,
                model_name: this.selected_model.text
            }
            this.isLoading = true
            await this.$axios.post('/openai/send_message', payload)
                .then(({ data }) => {
                    this.disable_input = false
                    this.prompt = null
                    if (data.response) {
                        this.messages = [...data.data]
                        this.scroll_to_bottom()
                        this.isLoading = false
                    }
                    
                })
        },
        scroll_to_bottom() {
            this.$nextTick(() => {
                var element = document.getElementById('chatbox').scrollHeight
                document.getElementById('chatbox').scrollTop = element
            });
        }
    }

}
</script>

<style lang="scss" scoped>
</style>