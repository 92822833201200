<template>
    <div>
        <v-list
            two-line>
            <v-subheader><h4>Additional Files</h4></v-subheader>
            <v-divider></v-divider>
            <v-list-item
                v-for="(file, index) in files"
                :key="index">
                <v-list-item-avatar>
                    <v-icon
                        class="primary"
                        dark>
                        mdi-file-outline
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ file.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon color="success"
                        :href="file.file"
                        download>
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>                  
        </v-list>
    </div>
</template>

<script>
export default {
    data: () => ({

    }),
    props: {
        files: Array
    }
}
</script>