import Vue from 'vue';

export default class ClientAnalytics {
    constructor() {
        this.store = null
        this.usersid = null
        this.ucourse_id = null
        this.analytical_data = null
    }

    setStore(store) {
        this.store = store
    }
    getStore() {
        return this.store
    }

    setUsersId(users_id) {
        this.users_id = users_id
    }
    getUsersId() {
        return this.users_id
    }

    setUCourseId(ucourse_id) {
        this.ucourse_id = ucourse_id
    }
    getUCourseId() {
        return this.ucourse_id
    }

    setAnalyticalData(analytical_data) {
        this.analytical_data = analytical_data
    }
    getAnalyticalData() {
        return this.analytical_data
    }


    saveClientVideoTrackedData() {
        Vue.axios.post(
            `clients/analytics/save_client_analytical_data/${this.getUsersId()}/${this.getUCourseId()}`, 
            this.getAnalyticalData()).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data;
                if ( result.response == true ) {
                    //console.log('Analytical data saved successfully.')
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err.message)
        })
    }

}