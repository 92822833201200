<template>
  <v-container
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-card
        elevation="1"
        min-width="500px"
      >
        <v-card-title
          class="blue darken-3 white--text"
        >
          Forgot Password
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card-text>
            <v-text-field
              prepend-icon="person"
              outlined
              dense
              label="Email"
              v-model="email"
              :rules="emailRules"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="previous()"
            >
              Back
            </v-btn>
            <v-btn
              class="blue darken-3 white--text"
              @click="submit(email)"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <!-- OTP DIALOG -->
      <v-dialog
        v-model="otpDialog"
        width="500"
        persistent
      >
        <v-card
          elevation="1"
        >
          <v-card-title
            class="blue darken-3 white--text"
          >
            OTP
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-card-text>
              <v-text-field
                outlined
                dense
                label="OTP"
                v-model="otp"
                :rules="strRules"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                class="blue darken-3 white--text"
                @click="submitOtp(otp)"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- END OF OTP DIALOG -->
      <!-- PASSWORD DIALOG -->
      <v-dialog
        v-model="passwordDialog"
        width="500"
        persistent
      >
        <v-card
          elevation="1"
        >
          <v-card-title
            class="blue darken-3 white--text"
          >
            Reset Password
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-card-text>
              <v-text-field
                prepend-icon="lock"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                outlined
                dense
                label="Password"
                v-model="password"
                :rules="strRules"
                required
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                outlined
                dense
                label="Re enter Password"
                v-model="password_confirm"
                :rules="strRules"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                class="blue darken-3 white--text"
                @click="submitPassword(password, password_confirm)"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- END OF PASSWORD DIALOG -->
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {

  },
  data: () => ({
    email: null,
    otp: null,
    otpDialog: false,
    passwordDialog: false,
    password: null,
    password_confirm: null,
    show1: false,
    show2: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    strRules: [
      v => !!v || 'This item is required'
    ]
  }),
  computed: {

  },
  mounted() {

  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    previous() {
      this.$router.go(-1)
    },
    async submit(str) {
      if (this.$refs.form.validate()) {
        const toPass = {
          email: str
        }

        await this.$axios.post('emailOtp', toPass)
          .then(({data}) => {
            if (data.response) {
              this.showSnackBar(data.message);
              this.otpDialog = true
            } else {
              this.showSnackBar(data.message);
            }
          }).catch(err => err);
      }
    },
    async submitOtp(str) {
      if (this.$refs.form.validate()) {
        const toPass = {
          otp: str,
          email: this.email
        }

        await this.$axios.post('verifyOtp', toPass)
          .then(({data}) => {
            if (data.response) {
              this.showSnackBar(data.message)
              this.otp = null
              this.otpDialog = false
              this.passwordDialog = true
            } else {
              this.showSnackBar(data.message)
            }
          }).catch(err => err);
      }
    },
    async submitPassword(a, b) {
      if (this.$refs.form.validate()) {
        if (a !== b) {
          this.showSnackBar('Password does not match, please try again.')
        } else {
          const toPass = {
            email: this.email,
            password: a
          }

          await this.$axios.post('/forgotPassword', toPass)
            .then(({data}) => {
              if (data.response) {
                this.showSnackBar(data.message)
                this.email = null
                this.passwordDialog = false
                this.$router.push({ name: 'index' }).catch(err => err)
              } else {
                this.showSnackBar(data.message)
              }
            })
        }
      }
    }
  }
}
</script>

<style scoped>
</style>