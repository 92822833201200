import { defineStore } from "pinia";

export const clientActivityFeedsStore = defineStore('client_activity_feeds_store', {
    state: () => ({
        activity_feeds: []
    }),
    getters: {
        getActivityFeeds: (state) => state.activity_feeds
    },
    actions: {
        setActivityFeeds(payload) {
            this.activity_feeds = [...payload]
        }
    }
})