<template>
   <v-container fluid>
      <v-overlay :value="loadingStatus">
         <v-progress-circular indeterminate color="primary" size="64">
         </v-progress-circular>
      </v-overlay>
      <v-snackbar v-model="snackbar.status" :timeout="snackbar.timeout" tile
         :color="snackbar.success ? 'success' : 'red accent-2'">
         {{ snackbar.text }}
      </v-snackbar>

      <!-- List of year  -->
      <v-row>
         <v-col cols="12" lg="2" md="2" sm="4" v-if="newCalculator">
            <v-text-field v-model.number="year" solo label="Year" type="number" placeholder="Year"
               @keypress="onlyNumber"></v-text-field>
         </v-col>
         <v-col cols="12" lg="2" md="2" sm="4" v-else>
            <v-select v-model="selectedYear" :items="yearLists" outlined class="text-white" dense
               label="Choose Year" @change="getItem">
            </v-select>
         </v-col>
         <v-col cols="12" lg="2" md="2" sm="4" v-if="newCalculator">
            <v-btn color="error" @click="newCalculator = false" large> Cancel</v-btn>
         </v-col>
         <v-col cols="12" lg="2" md="2" sm="4" v-else>
            <v-btn color="success" @click="newKPI" large> New KPI</v-btn>
         </v-col>
      </v-row>

      <!-- TABS -->
      <v-row>
         <v-col cols="12" lg="12" md="12" sm="12">
            <v-tabs v-model="selectedTab" align-with-title background-color="primary" dark>
               <v-tabs-slider color="yellow"></v-tabs-slider>

               <!-- <v-tab
                  v-for="item in tabItems"
                  :key="item" :value="item"
               >
               {{ item }}
               </v-tab> -->
               <v-tab href="#KPITARGETS">
                  KPI TARGETS
               </v-tab>
               <v-tab href="#KPIACTUALS">
                  KPI ACTUALS
               </v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedTab">

               <!-- KPI targets -->
               <v-tab-item :key="1" value="KPITARGETS">

                  <v-card flat>
                     <v-card-subtitle>
                        Use this calculator to prepare your KPI targets.
                     </v-card-subtitle>

                     <v-card-text>
                        <v-container fluid class="grey lighten-5 py-8">
                           <v-row>
                              <!-- main -->
                              <v-col cols="12" lg="6">
                                 <!-- Average Sales Value -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Average Sales Value</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field @wheel="$event.target.blur()" solo
                                          v-model.number="averageSalesValue" type="number"
                                          :hint="Number(averageSalesValue).toLocaleString()"
                                          @keypress="onlyNumber" @blur="setupForTable1(items1); setupForTable2(items2)"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Conversion Rate -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Conversion Rate (Close Rate)</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field @wheel="$event.target.blur()" solo v-model.number="conversionRate"
                                          type="number" @keypress="onlyNumber"
                                          append-icon="mdi-percent-outline" @blur="setupForTable1(items1); setupForTable2(items2)"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Revenue -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Revenue</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field solo v-model.number="revenue" type="number" @keypress="onlyNumber"
                                          :hint="Number(revenue).toLocaleString()"
                                          @wheel="$event.target.blur()" @blur="setupForTable1(items1); setupForTable2(items2)"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Materials And Subcontractors -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Materials And Subcontractors</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field solo v-model.number="materialsAndSubcontractors" type="number"
                                          :hint="Number(materialsAndSubcontractors).toLocaleString()"
                                          @keypress="onlyNumber" @wheel="$event.target.blur()" @blur="setupForTable1(items1); setupForTable2(items2)"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Net Revenue -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Net Revenue</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field outlined @wheel="$event.target.blur()" :readonly="true"
                                          persistent-hint :value="netRevenue"
                                          :hint="Number(netRevenue).toLocaleString()" type="number">
                                       </v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Overhead Costs -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Overhead Costs</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field solo @wheel="$event.target.blur()" v-model.number="overheadCosts"
                                          type="number" :hint="Number(overheadCosts).toLocaleString()"
                                          @keypress="onlyNumber" @blur="setupForTable1(items1); setupForTable2(items2)"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!-- Income Before Taxes -->
                                 <v-row>
                                    <v-col cols="12" lg="4">
                                       <p class="font-weight-black title">Income Before Taxes (Profit)</p>
                                    </v-col>
                                    <v-col cols="12" lg="8">
                                       <v-text-field outlined @wheel="$event.target.blur()" :readonly="true"
                                          persistent-hint :value="incomeBeforeTaxes"
                                          :hint="Number(incomeBeforeTaxes).toLocaleString()" type="number">
                                       </v-text-field>
                                    </v-col>
                                 </v-row>
                              </v-col>

                              <!-- Revenue per year,months days -->
                              <v-col cols="12" lg="6" class="">
                                 <i class="subtitle-2">
                                    <strong>NOTE:</strong> Use this inputs to manipulate table values
                                 </i>
                                 <v-container fluid class="grey lighten-1">
                                    <v-row>
                                       <v-col cols="12" lg="6">
                                          <p class="font-weight-black title">Revenue Months per Year</p>
                                       </v-col>
                                       <v-col cols="12" lg="6">
                                          <v-text-field @wheel="$event.target.blur()" solo
                                             v-model.number="revenueMonthsPerYear" type="number" @keypress="onlyNumber"
                                             @blur="computeTargets(); setupForTable1(items1); setupForTable2(items2)"
                                             :disabled="disableRevenue"
                                          >
                                          </v-text-field>
                                       </v-col>
                                    </v-row>
                                    <v-row>
                                       <v-col cols="12" lg="6">
                                          <p class="font-weight-black title">Revenue Weeks per Year</p>
                                       </v-col>
                                       <v-col cols="12" lg="6">
                                          <v-text-field @wheel="$event.target.blur()" solo
                                             v-model.number="revenueWeeksPerYear" type="number" @keypress="onlyNumber"
                                             @blur="computeTargets(); setupForTable1(items1); setupForTable2(items2)"
                                             :disabled="disableRevenue"
                                          >
                                          </v-text-field>
                                       </v-col>
                                    </v-row>
                                    <v-row>
                                       <v-col cols="12" lg="6">
                                          <p class="font-weight-black title">Revenue Days per Week</p>
                                       </v-col>
                                       <v-col cols="12" lg="6">
                                          <v-text-field @wheel="$event.target.blur()" solo
                                             v-model.number="revenueDaysPerYear" type="number" @keypress="onlyNumber"
                                             @blur="computeTargets(); setupForTable1(items1); setupForTable2(items2)"
                                             :disabled="disableRevenue"
                                          >
                                          </v-text-field>
                                       </v-col>
                                    </v-row>
                                 </v-container>
                              </v-col>
                           </v-row>

                           <!-- show computation -->
                           <v-row class="px-16">
                              <v-col col="12">
                                 <v-card class="mx-auto px-16 py-16">
                                    <v-card-text>

                                       <!-- CLOSED SALES REQUIRED title -->
                                       <v-row>
                                          <v-col cols="12">
                                             <h4 class="text-uppercase">CLOSED SALES REQUIRED</h4>
                                          </v-col>
                                       </v-row>

                                       <!-- CLOSED SALES REQUIRED item -->
                                       <v-row class="mb-16">
                                          <v-col cols="12" lg="6">
                                             <p class="subtitle-1">
                                                The number of sales required at which a business earns a target revenue
                                             </p>
                                             <p class="title text-center">Revenue</p>
                                             <v-divider class="mt-n4"></v-divider>
                                             <p class="title text-center">Average Sale Value</p>
                                          </v-col>
                                          <v-col cols="12" lg="2" class="d-flex justify-center align-end">
                                             <p class="text-center font-weight-bold display-1 mb-6">=</p>
                                          </v-col>
                                          <v-col cols="12" lg="4" class="d-flex justify-center align-end">
                                             <v-sheet color="white" elevation="1" height="100" width="250"
                                                class="mx-auto d-flex justify-center align-center">
                                                <p class="subtitle-1">{{ closedSalesRequiredComputed }}</p>
                                             </v-sheet>
                                          </v-col>
                                       </v-row>

                                       <!-- QUOTES REQUIRED title-->
                                       <v-row>
                                          <v-col cols="12">
                                             <h4 class="text-uppercase">QUOTES REQUIRED</h4>
                                          </v-col>
                                       </v-row>

                                       <!-- QUOTES REQUIRED Item -->
                                       <v-row>
                                          <v-col cols="12" lg="6">
                                             <p class="subtitle-1">
                                                Leads required to acheive desired number of sales to earn target revenue
                                             </p>
                                             <p class="title text-center">Closed Sales Required</p>
                                             <v-divider class="mt-n4"></v-divider>
                                             <p class="title text-center">Close Rate</p>
                                          </v-col>
                                          <v-col cols="12" lg="2" class="d-flex justify-center align-end">
                                             <p class="text-center font-weight-bold display-1 mb-6">=</p>
                                          </v-col>
                                          <v-col cols="12" lg="4" class="d-flex justify-center align-end">
                                             <v-sheet color="white" elevation="1" height="100" width="250"
                                                class="mx-auto d-flex justify-center align-center">
                                                <p class="subtitle-1">{{ quotesRequiredComputed }}</p>
                                             </v-sheet>
                                          </v-col>
                                       </v-row>
                                    </v-card-text>
                                 </v-card>
                              </v-col>
                           </v-row>

                           <!-- Mini table -->
                           <v-row class="px-2">
                              <v-col cols="12" lg="12">
                                 <v-data-table :headers="kpiTargetsHeader" :items="data" hide-default-footer
                                    class="elevation-1">

                                    <template v-slot:[`item.title`]="{ item }">
                                       <span class="title text--secondary">{{ item.title }}</span>
                                    </template>

                                    <template v-slot:[`item.monthly`]="{ item }">
                                       {{ item.monthly }}
                                    </template>

                                    <template v-slot:[`item.weekly`]="{ item }">
                                       {{ item.weekly }}
                                    </template>

                                    <template v-slot:[`item.daily`]="{ item }">
                                       {{ item.daily }}
                                    </template>
                                 </v-data-table>
                              </v-col>
                           </v-row>

                        </v-container>
                     </v-card-text>
                  </v-card>

               </v-tab-item>

               <v-tab-item :key="2" value="KPIACTUALS">

                  <v-card flat>

                     <v-card-text>
                        <v-container fluid fill-height class="grey lighten-5">
                           <!-- 1st table -->
                           <v-row>
                              <v-col cols="12" lg="12" class="overflow-x-auto">
                                 <v-card>
                                    <v-data-table :headers="kpiActualsHeaders1" :items="items1" hide-default-footer disable-pagination
                                       hide-default-header class="elevation-1" id="table1">

                                       <template #header="{ }">
                                          <thead>
                                             <tr>
                                                <th 
                                                   v-for="(h, i) in kpiActualsHeaders1" :key="i"
                                                   class="text-center parent-header td-border-style white--text py-0"
                                                   id="headers"
                                                   :rowspan="h.children ? 1 : 2"
                                                   :colspan="h.children ? h.children.length : 1"
                                                   style="height: 30px; width: 200px;"
                                                   >
                                                   {{ h.text }} 
                                                </th>
                                             </tr>
                                             <tr>
                                                <th v-for="(h1, i1) in getSubHeader(kpiActualsHeaders1)" :key="i1" style="height: 30px;"
                                                   class="text-center child-header td-border-style white--text py-0">
                                                   {{ h1.text }}
                                                </th>
                                             </tr>
                                          </thead>
                                       </template>

                                       <template v-slot:[`item.title`]="{ item }">
                                          <td style="height: 20px;" class="text-center">
                                             {{ item.title }}
                                          </td>
                                       </template>


                                       <template v-slot:[`item.january`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="mr-2">{{ Number(item.januaryTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[1]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.januaryTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.januaryTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[1]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.januaryTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.januaryActual, item.januaryTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field 
                                                      v-if="`${Object.keys(item)[7]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.januaryActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.januaryActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[7]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.januaryActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.february`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="px-1">{{ Number(item.februaryTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[2]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.februaryTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.februaryTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[2]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.februaryTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.februaryActual, item.februaryTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field v-if="`${Object.keys(item)[8]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.februaryActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.februaryActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[8]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.februaryActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.march`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="px-1">{{ Number(item.marchTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[3]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.marchTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.marchTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[3]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.marchTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.marchActual, item.marchTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field v-if="`${Object.keys(item)[9]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.marchActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.marchActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[9]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.marchActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.april`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="px-1">{{ Number(item.aprilTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[4]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.aprilTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.aprilTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[4]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.aprilTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.aprilActual, item.aprilTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field v-if="`${Object.keys(item)[10]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.aprilActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.aprilActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[10]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.aprilActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.may`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="px-1">{{ Number(item.mayTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[5]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.mayTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.mayTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[5]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.mayTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.mayActual, item.mayTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field v-if="`${Object.keys(item)[11]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.mayActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.mayActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[11]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.mayActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.june`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p class="px-1">{{ Number(item.juneTarget).toLocaleString() }}</p> -->
                                                      <v-text-field 
                                                         v-if="`${Object.keys(item)[6]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.juneTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.juneTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[6]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.juneTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col :style="colorbg(item.juneActual, item.juneTarget, index)" lg="6" class="px-1 mt-2" style="height:48px">
                                                   <v-text-field v-if="`${Object.keys(item)[12]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.juneActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.juneActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[12]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.juneActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>

                                    </v-data-table>
                                 </v-card>
                              </v-col>
                           </v-row>
                           <!-- 2nd table -->
                           <v-row>
                              <v-col cols="12" lg="12">
                                 <v-card width="100vw">
                                    <v-data-table :headers="kpiActualsHeaders2" :items="items2" hide-default-footer hide-default-header disable-pagination
                                       class="elevation-1" id="table2">

                                       <template #header="{ }">
                                          <thead class="v-data-table-header">
                                             <tr>
                                                <th v-for="(h, e) in kpiActualsHeaders2" :key="e"
                                                   class="text-center parent-header td-border-style white--text"
                                                   id="headers"
                                                   :rowspan="h.children ? 1 : 2"
                                                   :colspan="h.children ? h.children.length : 1"
                                                   style="height: 30px; width: 200px;"  
                                                >
                                                   {{ h.text }}
                                                </th>
                                             </tr>
                                             <tr>
                                                <th v-for="(h1, e1) in getSubHeader(kpiActualsHeaders2)" :key="e1" style="height: 30px;"
                                                   class="text-center child-header td-border-style white--text">
                                                   {{ h1.text }}
                                                </th>
                                             </tr>
                                          </thead>
                                       </template>

                                       <template v-slot:[`item.title`]="{ item }">
                                          <td style="height: 20px;"  class="border text-center">
                                             {{ item.title }}
                                          </td>
                                       </template>

                                       <template v-slot:[`item.july`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.julyTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[1]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.julyTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.julyTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[1]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.julyTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col  lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.julyActual, item.julyTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[7]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.julyActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.julyActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[7]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.julyActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.august`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.augustTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[2]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.augustTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.augustTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[2]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.augustTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.augustActual, item.augustTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[8]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.augustActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.augustActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[8]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.augustActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.september`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.septemberTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[3]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.septemberTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.septemberTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[3]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.septemberTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.septemberActual, item.septemberTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[9]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.septemberActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.septemberActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[9]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.septemberActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.october`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.octoberTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[4]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.octoberTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.octoberTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[4]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.octoberTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.octoberActual, item.octoberTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[10]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.octoberActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.octoberActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[10]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.octoberActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.november`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.novemberTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[5]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.novemberTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.novemberTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[5]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.novemberTarget).toLocaleString() }}</p>
                                                      </div>
                                                   </div>
                                                </v-col>
                                                <v-col lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.novemberActual, item.novemberTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[11]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.novemberActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.novemberActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[11]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.novemberActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       <template v-slot:[`item.december`]="{ item, index }">
                                          <td class="mx-0 px-3" colspan="2">
                                             <v-row style="height: 63px;" class="">
                                                <v-col lg="6" class="mt-2">
                                                   <div class="d-flex flex-row justify-center">
                                                      <!-- <p>{{ Number(item.decemberTarget).toLocaleString() }}</p> -->
                                                      <v-text-field v-if="`${Object.keys(item)[6]+index}` == editNow"
                                                         @wheel="$event.target.blur()" v-model.number="item.decemberTarget"
                                                         @blur="editNow = ''" @keypress="onlyNumber"
                                                         :disabled="item.disabled"
                                                         :hint="Number(item.decemberTarget).toLocaleString()" dense class="shrink" autofocus single-line>
                                                      </v-text-field>

                                                      <div @click="edit(Object.keys(item)[6]+index)" v-else class="text-center">
                                                         <p class="mt-2">{{ Number(item.decemberTarget).toLocaleString() }}</p>
                                                      </div>

                                                   </div>
                                                </v-col>
                                                <v-col lg="6" class="px-1 mt-2" style="height:48px" :style="colorbg(item.decemberActual, item.decemberTarget, index)">
                                                   <v-text-field v-if="`${Object.keys(item)[12]+index}` == editNow"
                                                      @wheel="$event.target.blur()" v-model.number="item.decemberActual"
                                                      @blur="editNow = ''" @keypress="onlyNumber"
                                                      :disabled="item.disabled"
                                                      :hint="Number(item.decemberActual).toLocaleString()" dense class="shrink" autofocus single-line>
                                                   </v-text-field>

                                                   <div @click="edit(Object.keys(item)[12]+index)" v-else class="text-center">
                                                      <p class="mt-2">{{ Number(item.decemberActual).toLocaleString() }}</p>
                                                   </div>
                                                </v-col>
                                             </v-row>
                                          </td>
                                       </template>
                                       
                                    </v-data-table>
                                 </v-card>
                              </v-col>

                           </v-row>

                           <v-row>
                              <v-col cols="12" lg="12">
                                 <v-col cols="12" lg="6" v-if="newCalculator">
                                    <v-btn color="success" @click="save">Save</v-btn>
                                 </v-col>
                                 <v-col cols="12" lg="6" v-if="selectedYear != ''">
                                    <v-btn color="primary" @click="save" v-if="!newCalculator" class="mr-4">Update</v-btn>
                                    <v-btn color="error" @click="deleteSelectedKpi">Delete</v-btn>
                                 </v-col>
                              </v-col>
                           </v-row>

                           <!-- Actions to Fix -->
                           <v-row>
                              <v-col cols="12" lg="12">
                                 <v-card elevation="6" class="pa-4" color="primary" dark>
                                    Actions to Fix
                                 </v-card>
                              </v-col>
                           </v-row>

                           <!-- Actions to Fix  item-->
                           <!-- vue2 editor -->
                           <v-row>
                              <v-col cols="12" lg="12">
                                 <VueEditor v-model="actionToFix" :editorToolbar="customToolbar" />
                              </v-col>
                           </v-row>

                        </v-container>
                     </v-card-text>
                  </v-card>
               </v-tab-item>

            </v-tabs-items>
         </v-col>
      </v-row>
      <!-- <v-row>
         <v-col cols="12" lg="12">
            <v-spacer></v-spacer>
            <v-col cols="12" lg="6" v-if="newCalculator">
               <v-btn color="success" @click="save">Save</v-btn>
            </v-col>
            <v-col cols="12" lg="6" v-if="selectedYear != ''">
               <v-btn color="primary" @click="save" v-if="!newCalculator" class="mr-4">Update</v-btn>
               <v-btn color="error" @click="deleteSelectedKpi">Delete</v-btn>
            </v-col>
         </v-col>
      </v-row> -->
   </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor'
// import { vuelidateUtil } from '@/utils/vuelidate-util';
export default {
   components: {
      VueEditor
   },
   data() {
      return {
         id: '',
         newCalculator: false,
         year: '',
         selectedYear: '',
         selectedMonth: 'january',
         selectedTab: '',
         // selectedTab: 'KPIACTUALS',
         tabItems: ['KPI TARGETS', 'KPI ACTUALS'],
         monthLists: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],

         // for test data
         // averageSalesValue: 15000,
         // conversionRate: 20,
         // revenue: 2000000,
         // materialsAndSubcontractors: 1000000,
         // overheadCosts: 200000,

         // revenueMonthsPerYear: 12,
         // revenueWeeksPerYear: 52,
         // revenueDaysPerYear: 5,

         averageSalesValue: 0,
         conversionRate: 0,
         revenue: 0,
         materialsAndSubcontractors: 0,
         overheadCosts: 0,

         revenueMonthsPerYear: 0,
         revenueWeeksPerYear: 0,
         revenueDaysPerYear: 0,

         // additionals
         averageSalesValueActuals: 0,
         salesRequiredActuals: 0,
         leadsActuals: 0,

         // for kpi targets
         kpiTargetsHeader: [
            { text: 'BREAKDOWN TARGET DATA', class: 'headline', align: 'start', sortable: false, value: 'title' },
            { text: 'Monthly', class: 'title', align: 'start', sortable: false, value: 'monthly' },
            { text: 'Weekly', class: 'title', align: 'start', sortable: false, value: 'weekly' },
            { text: 'Daily', class: 'title', align: 'start', sortable: false, value: 'daily' },
         ],
         data: [
            {
               title: 'Closed Sales Required',
               monthly: 0,
               weekly: 0,
               daily: 0
            },
            {
               title: 'Closed Revenue',
               monthly: 0,
               weekly: 0,
               daily: 0
            },
            {
               title: 'Overhead Costs',
               monthly: 0,
               weekly: 0,
               daily: 0
            },
            {
               title: 'Quotes Required',
               monthly: 0,
               weekly: 0,
               daily: 0
            },
         ],

         actionToFix: '',

         kpiActualsHeaders1: [
            { text: 'Measures', align: 'center', class: 'headline', sortable: false, value: 'title' },
            {
               text: 'January',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'january',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'February',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'february',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'March',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'march',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'April',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'april',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'May',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'may',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'June',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'june',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            }
         ],

         kpiActualsHeaders2: [
            { text: 'Measures', align: 'center', class: 'headline', sortable: false, value: 'title' },
            {
               text: 'July',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'july',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'August',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'august',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'September',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'september',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'October',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'october',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'November',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'november',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            },
            {
               text: 'December',
               align: 'center',
               class: 'headline',
               sortable: false,
               value: 'december',
               children: [
                  { text: 'Target' },
                  { text: 'Actual' }
               ]
            }
         ],

         items1: [
            // Leads
            {
               title: "Leads",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0
            },
            // Quote Number
            {
               title: "Quote Number",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Quoted Revenue
            {
               title: "Quoted Revenue",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Closed Number
            {
               title: "Closed Number",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Closed Revenue
            {
               title: "Closed Revenue",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Close Rate %
            {
               title: "Close Rate %",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Net Profit %
            {
               title: "Net Profit %",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Net Profit
            {
               title: "Net Profit",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Overheads
            {
               title: "Overheads",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Materials & Subcontractors
            {
               title: "Materials & Subcontractors",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // Salary
            {
               title: "Salary",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
            // VAT
            {
               title: "VAT",
               januaryTarget: 0,
               februaryTarget: 0,
               marchTarget: 0,
               aprilTarget: 0,
               mayTarget: 0,
               juneTarget: 0,

               januaryActual: 0,
               februaryActual: 0,
               marchActual: 0,
               aprilActual: 0,
               mayActual: 0,
               juneActual: 0,
            },
         ],

         items2: [
            // Leads
            {
               title: "Leads",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Quote Number
            {
               title: "Quote Number",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,

            },
            // Quoted Revenue
            {
               title: "Quoted Revenue",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Closed Number
            {
               title: "Closed Number",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Closed Revenue
            {
               title: "Quoted Revenue",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Close Rate %
            {
               title: "Close Rate %",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Net Profit %
            {
               title: "Net Profit %",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Net Profit
            {
               title: "Net Profit",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Overheads
            {
               title: "Overheads",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Materials & Subcontractors
            {
               title: "Materials & Subcontractors",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // Salary
            {
               title: "Salary",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },
            // VAT
            {
               title: "VAT",
               julyTarget: 0,
               augustTarget: 0,
               septemberTarget: 0,
               octoberTarget: 0,
               novemberTarget: 0,
               decemberTarget: 0,

               julyActual: 0,
               augustActual: 0,
               septemberActual: 0,
               octoberActual: 0,
               novemberActual: 0,
               decemberActual: 0,
            },

         ],

         // vue2 editor
         customToolbar: ['bold', 'italic', 'underline'],

         editNow: '',
         kpiActuals: null,
      }
   },
   computed: {
      ...mapGetters({
         loadingStatus: 'clientstate/getKPILoadingStatusGetters',
         yearLists: 'clientstate/getYearListOfKPI',
         getKPI: 'clientstate/getSingleKPI',
         snackbar: 'clientstate/getSnackbar',
         getUserData: 'auth/getSelectedUser'
      }),

      netRevenue() {
         return Math.ceil(Number(this.revenue) - Number(this.materialsAndSubcontractors));
      },
      incomeBeforeTaxes() {
         const netRevenue = Number(this.revenue) - Number(this.materialsAndSubcontractors);
         return Math.ceil(netRevenue - Number(this.overheadCosts));
      },
      // KPI TARGETS
      closedSalesRequiredComputed() {
         if (this.revenue <= 0 || this.averageSalesValue <= 0) {
            return 0
         }
         return Math.ceil(Number(this.revenue) / Number(this.averageSalesValue));
      },
      quotesRequiredComputed() {
         if (this.revenue <= 0 || this.conversionRate <= 0 || this.averageSalesValue <= 0) {
            return 0;
         }
         const closedSalesRequired = Math.ceil(Number(this.revenue) / Number(this.averageSalesValue));
         return Math.ceil(closedSalesRequired / Number(this.conversionRate) * 100);
      },

      // ACTUALS
      quoteNumberComputed() {
         if (this.revenue <= 0 || this.conversionRate <= 0 || this.averageSalesValue <= 0) {
            return 0;
         }
         // 2,000,000 / 15,000 = 133.3333333333333
         const closedSalesRequired = Math.ceil(Number(this.revenue) / Number(this.averageSalesValue));
         // 133.3333333333333 / 20 * 100 = 
         const quotesRequired = Math.ceil(closedSalesRequired / Number(this.conversionRate) * 100);

         // this.item1 = [{ januaryTarget: quotesRequired / Number(this.revenueMonthsPerYear) }];
         return Math.ceil(quotesRequired / Number(this.revenueMonthsPerYear));
      },
      quotedRevenueComputed() {
         if (this.revenue <= 0 || this.conversionRate <=0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         const closedRevenue = Number(this.revenue) / Number(this.revenueMonthsPerYear);
         return Math.ceil(closedRevenue / Number(this.conversionRate) * 100);
      },
      closedNumberComputed() {
         if (this.revenue <= 0 || this.averageSalesValue <= 0 || this.revenueMonthsPerYear <= 0) {
            return 0;
         }
         const closedSalesRequired = Math.ceil(Number(this.revenue) / Number(this.averageSalesValue));
         return Math.ceil(closedSalesRequired / Number(this.revenueMonthsPerYear));
      },
      closedRevenueComputed() {
         // no round up
         if (this.revenue <= 0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         return parseFloat(Number(this.revenue) / Number(this.revenueMonthsPerYear)).toFixed(2);
      },
      closeRatePercentageComputed() {
         // closed revenue  /quoted revenue = close rate % 
         if (this.revenue <= 0 || this.conversionRate <=0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         const closedRevenue = Number(this.revenue) / Number(this.revenueMonthsPerYear); // no round up
         const quotedRevenue = Math.ceil(closedRevenue / Number(this.conversionRate) * 100);

         return Math.ceil((closedRevenue / quotedRevenue)*100);
      },
      netProfitPercentageComputed() {
         // net profit / closed revenue = net profit %
         if (this.revenue <= 0 || this.conversionRate <=0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         const closedRevenue = Number(this.revenue) / Number(this.revenueMonthsPerYear);

         return Math.ceil((this.netProfitComputed / closedRevenue)*100);
      },
      netProfitComputed() {
         // close revenue - overheads - materials - salary - vat = net profit %
         if (this.revenue <= 0 || this.revenueMonthsPerYear <=0 || this.overheadCosts <= 0 || this.materialsAndSubcontractors <= 0) {
            return 0
         }
         // no round up
         const closedRevenue = Number(this.revenue) / Number(this.revenueMonthsPerYear);
         const overheads = Number(this.overheadCosts) / Number(this.revenueMonthsPerYear);

         const materials = Math.ceil(this.materialsAndSubcontractors / 12)
         const salary = 0;
         const vat = Math.ceil(closedRevenue * 0.2);

         return Math.ceil(closedRevenue - overheads - materials - salary - vat);

      },
      overheadsComputed() {
         // overhead costs / revenue months per year = overheads no round up
         if (this.overheadCosts <= 0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         return parseFloat(parseFloat(Number(this.overheadCosts) / Number(this.revenueMonthsPerYear)).toFixed(2));
      },
      materialsAndSubcontractorsComputed() {
         // materialsTarget / 12 = materials
         if (this.materialsAndSubcontractors <= 0) {
            return 0
         }
         return Math.ceil(this.materialsAndSubcontractors / 12);
      },
      // no computations
      salaryComputed() {
         return 0
      },
      vatComputed() {
         // closed revenue * 0.2 = vat
         if (this.revenue <= 0 || this.revenueMonthsPerYear <= 0) {
            return 0
         }
         const closedRevenue = Number(this.revenue) / Number(this.revenueMonthsPerYear);

         return Math.ceil(closedRevenue * 0.2);
      },
      disableRevenue() {
         if(this.averageSalesValue == 0 || this.conversionRate == 0 || this.revenue == 0 || this.materialsAndSubcontractors == 0 || this.overheadCosts == 0) {
            return true;
         } else {
            return false;
         }
      }
   },
   async mounted() {
      await this.getAllKPI(JSON.parse(this.getUserData).id);
   },

   async created() {
      // await this.dateTargets();
      // await this.fetch_kpi(JSON.parse(this.getUserData).id);
      await this.setupForTable1(this.items1);
      await this.setupForTable2(this.items2);
   },

   methods: {
      // dateTargets() {
      //    this.item1.map(v => ({ ...v, januaryTarget: this.quoteNumberComputed }));
      // },
      // async fetch_kpi(id) {
      //    // console.log('id =>', id);
      //    await this.$axios.get(`client/fetch_kpi/${id}`)
      //       .then(({data}) => {
      //          console.log(data);
      //       }).catch(err => console.error(err));
      // },
      setupForTable1(obj) {
         
         const date = ['january', 'february', 'march', 'april', 'may', 'june'];
         const numbers = [this.quoteNumberComputed * 3, this.quoteNumberComputed, this.quotedRevenueComputed, this.closedNumberComputed, this.closedRevenueComputed, this.closeRatePercentageComputed, this.netProfitPercentageComputed, this.netProfitComputed, this.overheadsComputed, this.materialsAndSubcontractorsComputed, this.salaryComputed, this.vatComputed];
         
         // for(let k = 0; k < numbers.length; k++) {
            for(let j = 0; j < obj.length; j++) {
               for(let i = 0; i < date.length; i++) {
                  obj[j][`${date[i]}Target`] = numbers[j];
               }
            }
         // }
         this.items1 = obj;
      },
      setupForTable2(obj) {
         const date = ['july', 'august', 'september', 'october', 'november', 'december'];
         const numbers = [this.quoteNumberComputed * 3, this.quoteNumberComputed, this.quotedRevenueComputed, this.closedNumberComputed, this.closedRevenueComputed, this.closeRatePercentageComputed, this.netProfitPercentageComputed, this.netProfitComputed, this.overheadsComputed, this.materialsAndSubcontractorsComputed, this.salaryComputed, this.vatComputed];
         
         // for(let k = 0; k < numbers.length; k++) {
            for(let j = 0; j < obj.length; j++) {
               for(let i = 0; i < date.length; i++) {
                  obj[j][`${date[i]}Target`] = numbers[j];
               }
            }
         // }
         this.items2 = obj;
      },
      colorbg(actual, target, i) {
         if(actual === 0) {
            return '#ffffff';
         }
         
         if(i >= 0 && i <= 7) {
            if(actual < target) {
            return 'background: #ff4140';
            }

            if(actual > target) {
               return 'background: #72d342';
            }

            if(actual == target) {
               return 'background: #fabc05';
            }
         }

         if(i >= 8 && i <= 11) {
            if(actual > target) {
            return 'background: #ff4140';
            }

            if(actual < target) {
               return 'background: #72d342';
            }

            if(actual == target) {
               return 'background: #fabc05';
            }  
         }
      },
      edit(index) {
         this.editNow = index;
      },
      ...mapActions({
         getAllKPI: 'clientstate/actionGetKPILists',
         saveKpi: 'clientstate/actionSaveKPI',
         deleteKpi: 'clientstate/actionDeleteKPI',
      }),
      getSubHeader(headers) {
         let result = [];
         headers
            .filter((i) => i.children)
            .forEach((v) => {
               result = result.concat(v.children);
            });
         return result;
      },
      newKPI() {
         this.newCalculator = true;
         this.resetForm();
      },
      async resetForm() {
         this.id = '';
         this.year = '';
         this.selectedYear = '';

         this.averageSalesValue = 0;
         this.conversionRate = 0;
         this.revenue = 0;
         this.materialsAndSubcontractors = 0;
         this.overheadCosts = 0;

         this.revenueMonthsPerYear = 0;
         this.revenueWeeksPerYear = 0;
         this.revenueDaysPerYear = 0;

         this.data.forEach((item) => {
            item.daily = 0;
            item.weekly = 0;
            item.monthly = 0;
         })

         this.items1.forEach((item) => {
            item.januaryTarget = 0;
            item.januaryActual = 0;
            item.februaryTarget = 0;
            item.februaryActual = 0;
            item.marchTarget = 0;
            item.marchActual = 0;
            item.aprilTarget = 0;
            item.aprilActual = 0;
            item.mayTarget = 0;
            item.mayActual = 0;
            item.juneTarget = 0;
            item.juneActual = 0;
         })
         this.items2.forEach((item) => {
            item.julyTarget = 0;
            item.julyActual = 0;
            item.augustTarget = 0;
            item.augustActual = 0;
            item.septemberTarget = 0;
            item.septemberActual = 0;
            item.octoberTarget = 0;
            item.octoberActual = 0;
            item.novemberTarget = 0;
            item.novemberActual = 0;
            item.decemberTarget = 0;
            item.decemberActual = 0;
         })

         this.actionToFix = '';

         this.selectedTab = 'KPITARGETS';
         scrollTo(0, 0);
         await this.getAllKPI(JSON.parse(this.getUserData).id);
      },
      getItem() {
         const KPI = this.getKPI(this.selectedYear);

         KPI.map((item) => {
            this.id = item.id;
            this.year = item.year;

            this.averageSalesValue = item.average_sales_value;
            this.conversionRate = item.conversion_rate;
            this.revenue = item.revenue;
            this.materialsAndSubcontractors = item.materials_and_subcontractors;
            this.overheadCosts = item.overhead_costs;

            this.revenueMonthsPerYear = item.revenue_months_per_year;
            this.revenueWeeksPerYear = item.revenue_weeks_per_year;
            this.revenueDaysPerYear = item.revenue_days_per_year;

            this.data[0].monthly = item.monthly[0].closedSalesRequired;
            this.data[1].monthly = item.monthly[1].closedRevenue;
            this.data[2].monthly = item.monthly[2].overheadCosts;
            this.data[3].monthly = item.monthly[3].quotesRequired;

            this.data[0].weekly = item.weekly[0].closedSalesRequired;
            this.data[1].weekly = item.weekly[1].closedRevenue;
            this.data[2].weekly = item.weekly[2].overheadCosts;
            this.data[3].weekly = item.weekly[3].quotesRequired;

            this.data[0].daily = item.daily[0].closedSalesRequired;
            this.data[1].daily = item.daily[1].closedRevenue;
            this.data[2].daily = item.daily[2].overheadCosts;
            this.data[3].daily = item.daily[3].quotesRequired;

            // reset data of each items to insert new objects
            this.items1 = [];
            this.items2 = [];

            this.items1.push({ ...item.leads_first_quarter });
            this.items1.push({ ...item.quote_number_first_quarter });
            this.items1.push({ ...item.quoted_revenue_first_quarter });
            this.items1.push({ ...item.closed_number_first_quarter });
            this.items1.push({ ...item.closed_revenue_first_quarter });
            this.items1.push({ ...item.close_rate_percent_first_quarter });
            this.items1.push({ ...item.net_profit_percent_first_quarter });
            this.items1.push({ ...item.net_profit_first_quarter });
            this.items1.push({ ...item.overheads_first_quarter });
            this.items1.push({ ...item.materials_and_subcontractors_first_quarter });
            this.items1.push({ ...item.salary_first_quarter });
            this.items1.push({ ...item.vat_first_quarter });

            this.items2.push({ ...item.leads_second_quarter });
            this.items2.push({ ...item.quote_number_second_quarter });
            this.items2.push({ ...item.quoted_revenue_second_quarter });
            this.items2.push({ ...item.closed_number_second_quarter });
            this.items2.push({ ...item.closed_revenue_second_quarter });
            this.items2.push({ ...item.close_rate_percent_second_quarter });
            this.items2.push({ ...item.net_profit_percent_second_quarter });
            this.items2.push({ ...item.net_profit_second_quarter });
            this.items2.push({ ...item.overheads_second_quarter });
            this.items2.push({ ...item.materials_and_subcontractors_second_quarter });
            this.items2.push({ ...item.salary_second_quarter });
            this.items2.push({ ...item.vat_second_quarter });

            this.actionToFix = item.action_to_fix;
         })
      },
      async save() {
         let payload = {
            id: this.id ? this.id : '',
            year: this.year,

            averageSalesValue:          Math.round(this.averageSalesValue),
            conversionRate:             this.conversionRate,
            revenue:                    this.revenue,
            materialsAndSubcontractors: this.materialsAndSubcontractors,
            overheadCosts:              this.overheadCosts,

            revenueMonthsPerYear:       this.revenueMonthsPerYear,
            revenueWeeksPerYear:        this.revenueWeeksPerYear,
            revenueDaysPerYear:         this.revenueDaysPerYear,

            monthly: [
               { closedSalesRequired:   this.data[0]['monthly'] },
               { closedRevenue:         this.data[1]['monthly'] },
               { overheadCosts:         this.data[2]['monthly'] },
               { quotesRequired:        this.data[3]['monthly'] },
            ],
            weekly: [
               { closedSalesRequired:   this.data[0]['weekly'] },
               { closedRevenue:         this.data[1]['weekly'] },
               { overheadCosts:         this.data[2]['weekly'] },
               { quotesRequired:        this.data[3]['weekly'] },
            ],
            daily: [
               { closedSalesRequired:   this.data[0]['daily'] },
               { closedRevenue:         this.data[1]['daily'] },
               { overheadCosts:         this.data[2]['daily'] },
               { quotesRequired:        this.data[3]['daily'] },
            ],

            leadsFirstQuarter:                      this.items1[0],
            quoteNumberFirstQuarter:                this.items1[1],
            quotedRevenueFirstQuarter:              this.items1[2],
            closedNumberFirstQuarter:               this.items1[3],
            closedRevenueFirstQuarter:              this.items1[4],
            closeRatePercentFirstQuarter:           this.items1[5],
            netProfitPercentFirstQuarter:           this.items1[6],
            netProfitFirstQuarter:                  this.items1[7],
            overheadsFirstQuarter:                  this.items1[8],
            materialsAndSubcontractorsFirstQuarter: this.items1[9],
            salaryFirstQuarter:                     this.items1[10],
            vatFirstQuarter:                        this.items1[11],

            leadsSecondQuarter:                      this.items2[0],
            quoteNumberSecondQuarter:                this.items2[1],
            quotedRevenueSecondQuarter:              this.items2[2],
            closedNumberSecondQuarter:               this.items2[3],
            closedRevenueSecondQuarter:              this.items2[4],
            closeRatePercentSecondQuarter:           this.items2[5],
            netProfitPercentSecondQuarter:           this.items2[6],
            netProfitSecondQuarter:                  this.items2[7],
            overheadsSecondQuarter:                  this.items2[8],
            materialsAndSubcontractorsSecondQuarter: this.items2[9],
            salarySecondQuarter:                     this.items2[10],
            vatSecondQuarter:                        this.items2[11],

            actionToFix:                             this.actionToFix,
            client_id:                               JSON.parse(this.getUserData).id
         }

         console.log(payload);
         await this.saveKpi(payload).then((response) => {
            if(response === 'success') {
               this.newCalculator = false;
               this.resetForm();
            }
         })
      },

      async deleteSelectedKpi() {
         await this.deleteKpi(this.id);
         await this.resetForm();
      },
      // FOR KPI TARGETS ONLY
      // compute table
      computeTargets() {
         // closed sales required
         const closedSalesRequired = Math.ceil(Number(this.revenue) / Number(this.averageSalesValue));
         const quotesRequired = Math.ceil(closedSalesRequired / Number(this.conversionRate) * 100);


         // Closed Sales Required
         this.data[0]['monthly'] = Math.ceil(closedSalesRequired / Number(this.revenueMonthsPerYear));
         this.data[0]['weekly'] = Math.ceil(closedSalesRequired / Number(this.revenueWeeksPerYear));
         this.data[0]['daily'] = Math.ceil(closedSalesRequired / Number(this.revenueWeeksPerYear) / Number(this.revenueDaysPerYear));

         // Closed Revenue
         this.data[1]['monthly'] = Math.ceil(Number(this.revenue) / Number(this.revenueMonthsPerYear));
         this.data[1]['weekly'] = Math.ceil(Number(this.revenue) / Number(this.revenueWeeksPerYear));
         this.data[1]['daily'] = Math.ceil(Number(this.revenue) / Number(this.revenueWeeksPerYear) / Number(this.revenueDaysPerYear));

         // Overhead Costs
         this.data[2]['monthly'] = Math.ceil(Number(this.overheadCosts) / Number(this.revenueMonthsPerYear));
         this.data[2]['weekly'] = Math.ceil(Number(this.overheadCosts) / Number(this.revenueWeeksPerYear));
         this.data[2]['daily'] = Math.ceil(Number(this.overheadCosts) / Number(this.revenueWeeksPerYear) / Number(this.revenueDaysPerYear));

         // Quotes Required
         this.data[3]['monthly'] = Math.ceil(quotesRequired / Number(this.revenueMonthsPerYear));
         this.data[3]['weekly'] = Math.ceil(quotesRequired / Number(this.revenueWeeksPerYear));
         this.data[3]['daily'] = Math.ceil(quotesRequired / Number(this.revenueWeeksPerYear) / Number(this.revenueDaysPerYear));
      },

      onlyNumber($event) {
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
         if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
         }
      },
      saveKPI(arr1, arr2) {
         console.log('array 1 =>', arr1);
         console.log('array 2 =>', arr2);
      }
   }

}
</script>

<style lang="scss" scoped>

.text-white input {
   color: white !important;
}

.custom-label-color .v-label {
   color: white;
   opacity: 1;
}

.custom-placeholer-color input::placeholder {
   color: white !important;
   opacity: 1;
}
.border {
   td {
      padding: 5px 8px;
      border-color: rgba(0, 0, 0, 0.12);
      border-style: solid;
      border-left-width: 0px;
      border-right-width: 1px;
      border-top-width: 0px;
      border-bottom-width: 1px;
      font-size: 30px;
   }
}
.v-data-table-header {
   color: white
}
.v-data-table {
   td {
      padding: 5px 8px;
      border-color: rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 0px;
      border-bottom-width: 1px;
   }

   th {
      // border-color: rgba(0, 0, 0, 0.5);
      border-color: white;
      border-style: solid;
      border-left-width: 0px;
      border-right-width: 1px;
      border-top-width: 0px;
      border-bottom-width: 1px;
      background-color: #666666;
   }
}

.v-data-table table {
   border: solid 1px #a1a1a1;
   border-radius: 5px;
}

.v-data-table table thead tr th {
   font-weight: bold;
   padding: 0px 2px;
}

.shrink{
      width: 80px;
}

#headers {
   font-size: 20px;
}

// .custom-placeholer-color input,
// .custom-label-color input{
//   color: red!important;
// }
</style>