<template>
    <v-dialog
        :value="value"
        persistent
        max-width="40%"
    >
        <v-card>
            <v-card-title>
                <small>Add Internal User</small>
                <v-spacer/>
                <v-icon
                    style="cursor: pointer;"
                    @click="close_dialog"
                >mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="a.email"
                    label="email"
                    outlined
                    dense
                    prepend-inner-icon="mdi-email-outline"
                >

                </v-text-field>
            
                <v-text-field
                    v-model="a.password"
                    placeholder="password"
                    label="password"
                    outlined
                    dense
                    prepend-inner-icon="mdi-lock"
                >
                </v-text-field>
            
                <v-text-field
                    v-model="a.firstname"
                    label="firstname"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account-outline"
                >
                </v-text-field>
            
                <v-text-field
                    v-model="a.lastnme"
                    label="lastnme"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account-outline"
                >
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    small
                    color="primary"
                    @click="add_internal_user"
                    class="pl-10 pr-10"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  components: {

  },
  props: [
    'value'
  ],
  data () {
    return {
        a: {
            email: null,
            password: null,
            firstname: null,
            lastname: null
        },
        show_pass: false
    }
  },
  mounted () {

  },
  created () {

  },
  computed: {

  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
        this.a = {}
    },
    async add_internal_user(){
        await this.$axios.post('/internal/users/add_internal_users', this.a)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('adminclientdboard/set_internal')
                this.close_dialog()
            }
        }).catch(err => console.error(err));
    }
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">

</style>