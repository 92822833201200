<template>
   <v-container fluid>
      <!-- Header -->
      <v-col cols="12">
         <v-icon class="mr-3">{{queryString.icon}}</v-icon><strong>{{queryString.name}}</strong>
         <v-spacer></v-spacer>
         <v-btn @click="copyLink()" color="primary">Copy Link</v-btn>
      </v-col>

      <!-- container -->
      <v-col cols="12">

         <!-- video -->
         <div v-if="queryString.video_url.length > 0">
            <video id="video" :key="`${path}${queryString.video_url}`" width="100%" height="100%" controls playsinline controlsList="nodownload">
            <source :src="`${path}${queryString.video_url}`" type="video/mp4"/>

            Your browser does not support the video tag.
            </video>
         </div>

         <!-- FILE PREVIEW VIEWER [PDF] -->
         <div v-if="queryString.file_url != '' && queryString.file_url != null" class="mt-2">

            <div v-for="(item, itemindex) in queryString.file_url" :key="itemindex">
               <div v-if="item.split('.').pop() === 'xlsx' || item.split('.').pop() === 'docx'">
                  <VueDocPreview :value="`${path}${item}`" type="office"></VueDocPreview>
               </div>
               <div v-if="item.split('.').pop() === 'pdf'">
                  <!-- <PdfVuer :src="`${path}${item}`"></PdfVuer> -->
                  <iframe :src="`${path}${item}`" width="100%" height="1000px"></iframe>
               </div>
            </div>
         </div>

         <!-- Hourly rate -->
         <!-- <v-card class="pa-2 mt-2" v-if="queryString.name == 'Hourly Rate Training Basic & Advanced'">
            <v-card-actions>
               <strong>Hourly Rate Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'hourly.rate.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         <!-- KPI's -->
         <!-- <v-card class="pa-2 mt-2" v-else-if="queryString.name == 'Week 34 - Key Performance Indicators'">
            <v-card-actions>
               <strong>KPI Calculator</strong>

               <v-spacer></v-spacer>

               <v-btn
                  color="primary"
                  dark
                  large
                  @click="$router.push({name: 'kpi.calculator'})"
                  >
                  Get Started
               </v-btn>
            </v-card-actions>
         </v-card> -->
         
         <div class="mt-2" v-else>
            <!-- Additional Video Files  START-->
            <v-card class="card" elevation="0" v-if="queryString.additional_files != null && queryString.additional_files != ''">
               
               <v-card-title>
                  Additional Video Files
               </v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="(param, i) in queryString.additional_files" :key="i">
                  <strong class="ml-2">{{param.name}}</strong>
                  <v-spacer></v-spacer>
                  <a id="download" :href="`${path}${param.file}`" download target="_blank"> Download </a>
               </v-card-actions>
            </v-card>
            <!-- Additional Video Files END -->

            <!-- FILE UPLOAD START-->
            <v-card class="card" elevation="0" v-if="queryString.file_url != null && queryString.file_url != '' && JSON.parse(getUserData).role_id != 5">
               <v-card-title>File Uploaded</v-card-title>

               <v-divider></v-divider>

               <v-card-actions v-for="param in queryString.file_url" :key="param">
                  <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png"
                     alt=""
                     style="cursor:default;"
                     width="50"
                     height="50"
                  />

                  <strong v-html="`${param}`" target="_blank"></strong>
                  <v-spacer></v-spacer>

                  <a id="download" :href="`${path}${param}`" download target="_blank"> Download </a>
               </v-card-actions>
            </v-card>
            <!-- FILE UPLOAD END-->

            <!-- CONTENT -->
            <v-card elevation="0" class="card" v-if="queryString.contents != null && queryString.contents != '' && queryString.contents != 'null'">
               <v-card-text
                  v-html="queryString.contents"></v-card-text>
            </v-card>
            <!-- END OF CONTENT -->
         </div>
      </v-col>
   </v-container>
</template>

<script>
import qs from 'qs';
// import axios from 'axios';
import VueDocPreview from 'vue-doc-preview'
import { mapGetters } from 'vuex'
// import VuePdf from 'vue-pdf';
// import PdfVuer from 'pdfvuer';

export default {
  components: {
    VueDocPreview,
    // VuePdf,
    // PdfVuer
  },
  props: [
  ],
  data: () => ({
    urlparams: {},
    path: null,
    user: JSON.parse(localStorage.getItem('udata')),
  }),
  mounted () {
  },
  created () {
   this.urlparams = this.$route.query
   this.path = process.env.VUE_APP_URL
   if(this.user.role_id === 2){
      // detected client role redirect user with query params
      this.$router.push({name: 'clientcurrentlesson', query: this.urlparams})
   }
  },
  computed: {
    queryString() {
      return qs.parse(this.urlparams.query);
    },

    fileExtTest(data) {
      if(data && Array.isArray(data)) {
        return 'foo';
      } else {
        return 'faz';
      }
    },
    
    ...mapGetters({
      getUserData: 'auth/getUserData'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async copyLink() {
      const { id } = qs.parse(this.urlparams.query);

      const tp = {
        link: qs.stringify(this.urlparams),
        id: id
      }

      await this.$axios.post('adminclientdboard/hashLink', tp)
        .then(({data}) => {
            if(data.data.hash === null){
               this.copyLink()
            }
            const link = window.location.protocol + '//' + window.location.host + '/' + 'clientdashboard/currentplay?' + `query=${data.data.hash}`;
            // console.log(link)
            // navigator.clipboard.writeText(window.location.host + '/' + 'clientdashboard/currentplay?' + `query=${data.data.hash}`)
            //   .then(() => {
            //     this.showSnackBar(data.message); 
            //   }).catch(err => alert('Error!', err));
            // window.Clipboard.copy(link);
            navigator.clipboard.writeText(link)
                .then(() => {this.showSnackBar(data.message);
                }, () => {
                  this.showSnackBar('Please try again!');
                });
        }).catch(err => err);

      // const link = (window.location.host + '/' + 'clientdashboard/currentplay?' + qs.stringify(this.urlparams));

      // const tp = {
      //   group_guid: process.env.VUE_APP_BITLY_GROUP_GUID,
      //   long_url: 'https://' + link
      // }

      // console.log(tp);

      // await axios.post('https://api-ssl.bitly.com/v4/shorten', tp, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': process.env.VUE_APP_BITLY_AUTH_API
      //   },
      //   baseURL: process.env.VUE_APP_API_URL
      // })
      //   .then(({data}) => {
      //     navigator.clipboard.writeText(data.link)
      //     this.showSnackBar('Copied!');
      //   }).catch(err => this.showSnackBar('Error!' + err));
    },
  },
  watch: {
    '$route' (to){
      this.urlparams = to.query
    },
  }
}
</script>

<style scoped>
  #download {
    color: #fff;
    background-color: #1976d2;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    text-decoration: none;
  }

  .card {
    border: 1px solid #abb1bc;
  }
</style>