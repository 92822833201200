<template>
    <div>
        <video controls style="height: 75vh;width: 100%;" ref="video" 
            playsinline controlsList="nodownload" >
            <source :src="source" type="video/mp4" />
        </video>
    </div>
</template>

<script>
export default {
    data: () => ({
        path: process.env.VUE_APP_URL,
        source: null,
        watch_duration: 0,
    }),
    props: {
        video_url: String
    },
    mounted() {
        this.source = `${this.path}${this.video_url}`
        this.$refs.video.load()
    },
    methods: {
        
    },
    watch: {
        video_url() {
            this.source = `${this.path}${this.video_url}`       
            this.$refs.video.load()                      
        }
    }
}
</script>