import SharedCourseLesson from '../components/SharedCourseLesson.vue';
//import store from '@/states/index.js'

export default [
    {
        path: '/sharedcourselesson',
        component: SharedCourseLesson,
        name: 'sharedcourselesson',
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');
            console.log(to, from)
            if(token) {
                next();
            } else {
                alert('Please login first!');
                //store.dispatch('auth/setCurrentRoute', {query: to.query.link, path: to.name});
                next('/');
            }
            return;
        }
    }
]