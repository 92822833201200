import axios from 'axios'
import { clientCourseStore } from './../../store/client_course.store'
import Vue from 'vue'
import store from '@/states'

export default class CourseClass
{
    constructor() {
        this.api_path = process.env.VUE_APP_API_URL
        this.api = axios.create({
            baseURL: this.api_path
        })
        this.bearer_token = localStorage.getItem('token')
        this.course_store = clientCourseStore()

        this.toast = Vue.$toast
    }

    getAllCourses() {
        this.api.get('admin/course/get_courses', {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.course_store.setCourseList(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    getAllNewlyAddedVideos(course_id) {
        this.api.get(`admin/course/get_latest_lessons/${course_id}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.course_store.setNewlyAddedVideos(result.data)
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    generateCourseLessonLink(users_id, lesson_id) {
        this.api.patch(`admin/course/generate_course_lesson_link/${users_id}/${lesson_id}`, {} ,{
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + '/sharedcourselesson/?link=' + result.data)
                this.toast.success('Generated a link for a lesson to be shared to other users.')
            }
        }).catch( (err) => {
            this.toast.error(err.message)
        })
    }

    /** Client Progress */
    getClientProgress(account_id) {
        
    }

    /** Store */
    getCourseListFromStore() {
        return this.course_store.getCourseList
    }

    getNewlyAddedVideosFromStore() {
        return this.course_store.getNewlyAddedVideos
    }

    /** Search Video Feature */
    async getVideoCourseByTags(course_id, user_id) {
        await this.api.get(`/adminclientdboard/gcourse/${course_id}/${user_id}`, {
            headers: {
                Authorization: `Bearer ${this.bearer_token}`,
            },
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {
                this.course_store.setActivityFeeds(result.data)
                let for_search_data = []
                result.data.map(q => {
                    q.get_child.map(qq => {
                        for_search_data.push(qq)
                    })
                })
                store.dispatch('adminclientdboard/videos_tags_for_search', for_search_data)
            }
        }).catch( (err) => {
            //this.toast.error(err.message)
        })
    }

    getVideoCourseByTagsFromStore() {
        return this.course_store.getActivityFeeds
    }
}