<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          class="col-lg-12"
        >
          <v-card>
            <v-card-title class="indigo white--text text-h5">
              Analytics Score
              <v-spacer></v-spacer>
              <v-progress-circular
                :value="40"
              ></v-progress-circular>
              <!-- {{items[0].children[0].progress}} -->
            </v-card-title>
            <v-row
              class="pa-4"
            >
              <v-col
                class="col-lg-12"
              >
              <!-- {{roadmaps}} -->
                <v-treeview
                  activatable
                  color="primary"
                  open-on-click
                  transition
                  :items="items"
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon v-if="!item.children">
                      mdi-dock-window
                    </v-icon>
                  </template>
                  <template v-slot:append="{ item }">
                    <v-progress-linear
                      v-model="item.progress"
                      color="indigo"
                      height="20"
                    ></v-progress-linear>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: [
    'roadmaps'
  ],
  data: () => ({
    users: [{name: 'Item'}, {name: 'Item 2'}]
  }),
  computed: {
      items() {
        let items = [];
        for(let i = 0; i < this.roadmaps.length; i++) {
          let toAdd = {
            name: this.roadmaps[i].name,
            children: this.roadmaps[i].get_child
          }

          items.push(toAdd);
        }
        return items;
      }

  },
  methods: {

  },
  mounted() {
    this.items();
  }
}
</script>

<style>

</style>